const TicketsIcon = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={size}
    width={size}
  >
    <path
      d="M1.99515 11.7702c-0.2361 0 -0.46254 -0.0938 -0.62949 -0.2607 -0.16695 -0.167 -0.26074 -0.3934 -0.26074 -0.6295V8.20928c0.47221 0 0.92508 -0.18759 1.25898 -0.52149s0.52149 -0.78677 0.52149 -1.25898 -0.18759 -0.92508 -0.52149 -1.25898 -0.78677 -0.52149 -1.25898 -0.52149v-2.6707c0 -0.23611 0.09379 -0.46254 0.26074 -0.62949 0.16695 -0.16695 0.39339 -0.26075 0.62949 -0.26075H18.0194c0.2361 0 0.4625 0.0938 0.6295 0.26075 0.1669 0.16695 0.2607 0.39338 0.2607 0.62949v2.6707c-0.4722 0 -0.9251 0.18759 -1.259 0.52149 -0.3339 0.3339 -0.5215 0.78677 -0.5215 1.25898s0.1876 0.92508 0.5215 1.25898 0.7868 0.52149 1.259 0.52149v0.87455"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m22.1332 15.9557 0.5714 -2.6089c0.0505 -0.2306 0.0073 -0.4719 -0.12 -0.6707 -0.1274 -0.1988 -0.3285 -0.3388 -0.5592 -0.3894L6.37225 8.85843c-0.23064 -0.05051 -0.4719 -0.00734 -0.6707 0.12003 -0.19881 0.12737 -0.33887 0.32849 -0.38939 0.55913l-0.57138 2.60891c0.46127 0.101 0.86352 0.3811 1.11826 0.7787 0.25473 0.3976 0.34108 0.8801 0.24006 1.3414 -0.10103 0.4613 -0.38116 0.8635 -0.77877 1.1183 -0.39761 0.2547 -0.88012 0.3411 -1.3414 0.24l-0.57139 2.6089c-0.05051 0.2306 -0.00733 0.4719 0.12003 0.6707 0.12737 0.1988 0.3285 0.3389 0.55913 0.3894l15.6532 3.4283c0.2306 0.0505 0.4719 0.0073 0.6707 -0.12 0.1988 -0.1274 0.3389 -0.3285 0.3894 -0.5592l0.5714 -2.6088c-0.4613 -0.101 -0.8636 -0.3812 -1.1183 -0.7788 -0.2547 -0.3976 -0.3411 -0.8801 -0.2401 -1.3414 0.1011 -0.4613 0.3812 -0.8635 0.7788 -1.1182 0.3976 -0.2548 0.8801 -0.3411 1.3414 -0.2401Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.77194 3.73884c-0.08685 0 -0.15725 -0.07041 -0.15725 -0.15726 0 -0.08686 0.0704 -0.15726 0.15725 -0.15726"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.77157 3.73884c0.08686 0 0.15726 -0.07041 0.15726 -0.15726 0 -0.08686 -0.0704 -0.15726 -0.15726 -0.15726"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.4952 12.2559c-0.08484 -0.0186 -0.13855 -0.1024 -0.11997 -0.1872 0.01858 -0.0849 0.10242 -0.1386 0.18726 -0.12"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.49485 12.2558c0.08484 0.0186 0.16868 -0.0352 0.18726 -0.12 0.01859 -0.0848 -0.03513 -0.1687 -0.11997 -0.1873"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.77194 6.586c-0.08685 0 -0.15725 -0.0704 -0.15725 -0.15726 0 -0.08685 0.0704 -0.15726 0.15725 -0.15726"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.77157 6.586c0.08686 0 0.15726 -0.0704 0.15726 -0.15726 0 -0.08685 -0.0704 -0.15726 -0.15726 -0.15726"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.88583 15.0377c-0.08484 -0.0186 -0.13856 -0.1025 -0.11998 -0.1873 0.01859 -0.0848 0.10243 -0.1386 0.18727 -0.12"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.88548 15.0375c0.08484 0.0186 0.16868 -0.0351 0.18726 -0.12 0.01858 -0.0848 -0.03513 -0.1686 -0.11997 -0.1872"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.27548 17.8189c-0.08484 -0.0186 -0.13856 -0.1024 -0.11998 -0.1873 0.01858 -0.0848 0.10243 -0.1385 0.18727 -0.1199"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.27512 17.8188c0.08485 0.0185 0.16869 -0.0352 0.18727 -0.12 0.01858 -0.0849 -0.03513 -0.1687 -0.11998 -0.1873"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
export default TicketsIcon;
