export const RQ_KEY = {
  ACTIVE_CARDS: "activeCards",
  ALL_CARDS: "allCards",
  ALL_EVENTS_FILTERED: "allEventsFiltered",
  ALL_EVENTS: "allEvents",
  ALL_TRIPS_FILTERED: "allTripsFiltered",
  ALL_GROUPING: "allGrouping",
  ALL_ORGANIZATIONS: "allOrganizations",
  ALL_CITIES: "allCities",
  ASSISTANTS_EVENT_ID: "assistantsEventId",
  ALL_RRPPS: "allRrpps",
  CARDS_DOMAIN: "cardsDomain",
  CARDS_STATISTICS: "cardsStatistics",
  CATEGORIES_DOMAIN: "categoriesDomain",
  CATEGORIES_DOMAINS_BY_PREMISE: "categoriesDomainsByPremise",
  DOMAINS: "domains",
  EVENTS_DOMAIN: "eventsDomain",
  ORDERS_EVENT_ID: "ordersEventId",
  ORDERS_LIST: "ordersList",
  RRPP_BY_ID: "rrppById",
  RRPP_BY_EVENT_ID: "rrppByEventId",
  SPONSORS_DOMAIN: "sponsorsDomain",
  USER_CARDS: "userCards",
  USER_INFO: "userInfo",
  USERS_FOR_EVENT: "usersForEvent",
  USERS_LIST: "usersList",
  HOME: "home",
  EVENT_STATISTICS: "eventStatistics",
  ASSISTANT_DETAIL: "assistantDetail",
  PREMISE_ID: "premiseId",
  CARDS_PREMISE: "cardsPremise",
  MONTH_STATISTICS_GENERAL: "monthStatisticsGeneral",
  MONTH_STATISTICS_SALES: "monthStatisticsSales",
  MONTH_STATISTICS_USERS: "monthStatisticsUsers",
  HOME_NEXT_EVENTS: "homeNextEvents",
  HOME_STATISTICS: "homeStatistics",
  EVENT_SALES: "eventSales",
};
