import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useQuerySponsorsDomain } from "../../hooks/queries/useQuerySponsorsDomain";
import { sponsorSchema } from "../../schemas/styles";
import { deleteSponsorDomain, postSponsorDomain } from "../../services/domainsServices";
import ColorInput from "../shared/ColorInput";
import CustomDialog from "../shared/CustomDialog";
import ImageUpload from "../shared/ImageUpload";
import InfoToolTip from "../shared/InfoToolTip";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import UpdateSponsor from "./domainDialogs/UpdateSponsor";

const DomainSponsors = ({ domainId }) => {
  const { t } = useTranslation();
  const { data: sponsors, refetch } = useQuerySponsorsDomain(domainId);
  const [newSponsor, setNewSponsor] = useState({
    name: "",
    link: "",
    logo: "",
  });
  const { watch, setValue } = useFormContext();
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const sponsorsBackgroundColor = watch("style.sponsors_background_color");

  const handleSelectImage = (url) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setNewSponsor({ ...newSponsor, logo: null });
      } else {
        setNewSponsor({ ...newSponsor, logo: imageArray });
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setNewSponsor({ ...newSponsor, logo: imageObject });
    }
  };

  const handleAddSponsor = async () => {
    setLoadingSubmit(true);
    try {
      //validate fields
      sponsorSchema.parse(newSponsor);

      const bodyToSend = {
        domain_id: domainId,
        name: newSponsor.name,
        link: newSponsor.link || "",
        image_url: newSponsor.logo,
      };
      await postSponsorDomain(bodyToSend);
      toastMessageSuccess(t("SUCCESS_ADD_SPONSOR"));
      refetch();
      setNewSponsor({ name: "", link: "", logo: "" });
    } catch (e) {
      if (e instanceof z.ZodError) {
        e.errors.forEach((error) => toastMessageError(error.message));
      } else {
        toastMessageError(e.response.data.error || t("ERROR_UPDATE_STYLES"));
      }
    }
    setLoadingSubmit(false);
  };

  const handleDeleteSponsor = async () => {
    try {
      await deleteSponsorDomain(selectedSponsor.id);
      toastMessageSuccess(t("SUCCESS_DELETE_SPONSOR"));
    } catch (e) {
      toastMessageError(e.response.data.error || t("ERROR"));
    } finally {
      setOpenDelete(false);
      setSelectedSponsor(null);
      refetch();
    }
  };

  const handleOpenSponsor = (sponsor) => {
    setSelectedSponsor(sponsor);
    setOpenDelete(true);
  };

  const handleOpenUpdate = (sponsor) => {
    setSelectedSponsor(sponsor);
    setOpenUpdate(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t("DOMAIN_SPONSORS")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom mb={3}>
          {t("DOMAIN_SPONSORS_DESCRIPTION")}
        </Typography>
      </Grid>
      <Grid container spacing={2} paddingInline={2}>
        {sponsors?.map((sponsor) => (
          <Grid item xs={6} md={3} key={sponsor.id}>
            <Paper
              style={{
                padding: 10,
                textAlign: "center",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{sponsor.name}</Typography>
              <Typography variant="caption">{sponsor.link}</Typography>
              <img
                src={sponsor.image_url}
                alt={sponsor.name}
                style={{ height: "100px", width: "100px" }}
              />
              <div>
                <IconButton aria-label="update" onClick={() => handleOpenUpdate(sponsor)}>
                  <Edit sx={{ "&:hover": { color: "var(--oniria-gold)" } }} />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => handleOpenSponsor(sponsor)}>
                  <DeleteIcon sx={{ "&:hover": { color: "var(--oniria-red)" } }} />
                </IconButton>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12} container alignItems="center" gap={2}>
          <Typography>{t("SPONSORS_BACKGROUND_COLOR")}: </Typography>
          <ColorInput
            value={sponsorsBackgroundColor}
            onChange={(e) => setValue("style.sponsors_background_color", e.target.value)}
          />
          <InfoToolTip text={t("SPONSORS_BACKGROUND_COLOR_TOOLTIP")} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ImageUpload
            multi={false}
            onImageSelect={(e) => handleSelectImage(e)}
            url={newSponsor.logo}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            label={t("SPONSOR_NAME")}
            variant="outlined"
            fullWidth
            value={newSponsor.name}
            sx={focusColor}
            onChange={(e) => setNewSponsor({ ...newSponsor, name: e.target.value })}
          />
          <TextField
            label={t("SPONSOR_LINK")}
            variant="outlined"
            fullWidth
            sx={focusColor}
            value={newSponsor.link}
            onChange={(e) => setNewSponsor({ ...newSponsor, link: e.target.value })}
          />
          <Button
            variant="contained"
            onClick={handleAddSponsor}
            className="oniria-btn"
            sx={{ color: "#fff" }}
            disabled={!newSponsor.name || !newSponsor.logo || loadingSubmit}
          >
            {loadingSubmit ? t("SENDING") : t("ADD_SPONSOR")}
          </Button>
        </Grid>
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_SPONSOR")}
        content={t("DELETE_SPONSOR_DESCRIPTION")}
        onAccept={handleDeleteSponsor}
      />
      <UpdateSponsor
        isOpen={openUpdate}
        onClose={() => setOpenUpdate(false)}
        sponsor={selectedSponsor}
        refetch={refetch}
      />
    </Grid>
  );
};

export default DomainSponsors;
