
import React, {useState} from 'react'
import { Box, Checkbox, FormControlLabel, Grid, Paper, Typography, Link } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { allFieldsOk } from '../shared/FormsValidator';
import ButtonsForms from './forms/ButtonsForms';

const Signature = (props) => {

    const { t } = useTranslation();
    const formController = props.formController
    const { legalDisclaimer: legalDisclaimerErrors } = formController.validation
    const { setFormErrors } = formController
    const [buttonClicked, setButtonClicked] = useState(false)

    const handleCheckboxPrivacyPolicy = (event) => {
        setFormErrors('legalDisclaimer', 'privacy_policy', event.target.checked ? "" : "empty")
    }
    const handleCheckboxTerms = (event) => {
        setFormErrors('legalDisclaimer', 'terms', event.target.checked ? "" : "empty")
    }
    const handleCheckboxDataSubmited = (event) => {
        setFormErrors('legalDisclaimer', 'data_submitted', event.target.checked ? "" : "empty")
    }
    // to set next button form clicked 
    const catchButtonClicked = (value) =>{
        setButtonClicked(value)
    }

    return (
        <Grid container sx={{ mt: 10, mb: 20, px: 1, color: "black", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Grid item xs={11} md={9} lg={7.5} >
                <Box component={Paper} elevation={3}
                    sx={{
                        py: 2,
                        px: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px"
                    }}
                >
                    <Grid container sx={{ p: 2}}>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <Typography className="oniria-colorText" variant="h5" sx={{ mb: 1 }}>
                                {t("SITE_TITLE")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <Typography variant='subtitle'>{t("SITE_SUBTITLE")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Box component="form" sx={{
                            mt: 1, display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }} >
                            <Grid container sx={{display:"flex", justifyContent:"center"}}>
                                <Grid item xs={12} md={10}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={handleCheckboxPrivacyPolicy}
                                            sx={{
                                                color: "var(--secondary-color)", '&.Mui-checked': {
                                                    color: "var(--secondary-color)"
                                                },
                                            }} />}
                                    />
                                    {t("PRIVACY_POLICY_READ_1")}
                                    <Link
                                        href="https://www.oniriastudios.com/dpo"
                                        target="_blank"
                                        color="var(--secondary-color)"
                                        variant="body"
                                        underline="none"
                                    >
                                        {t("PRIVACY_POLICY_READ_2")}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container sx={{display:"flex", justifyContent:"center"}}>
                                <Grid item xs={12} md={10}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={handleCheckboxTerms}
                                            sx={{
                                                color: "var(--secondary-color)", '&.Mui-checked': {
                                                    color: "var(--secondary-color)"
                                                },
                                            }} />}
                                    />
                                    {t("TERMS_AND_CONDITIONS_1")}
                                    <Link
                                        href="https://www.oniriastudios.com/terminos-y-condiciones"
                                        target="_blank"
                                        color="var(--secondary-color)"
                                        variant="body"
                                        underline="none"
                                    >
                                        {t("TERMS_AND_CONDITIONS_2")}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container sx={{display:"flex", justifyContent:"center"}}>
                                <Grid item xs={12} md={10}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={handleCheckboxDataSubmited}
                                            sx={{
                                                color: "var(--secondary-color)", '&.Mui-checked': {
                                                    color: "var(--secondary-color)"
                                                },
                                            }} />}
                                    />
                                    <Link
                                        color="inherit"
                                        variant="body"
                                        underline="none"
                                    >
                                        {t("DATA_SUBMITTED")}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <ButtonsForms formController={formController} allFieldsOk={allFieldsOk} errors={legalDisclaimerErrors} sendButtonClicked={catchButtonClicked}/>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Signature