import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CardStatistics from "../../components/cards/CardStatistics";
import Header from "../../components/Header";
import { ROUTES } from "../../constants/routes";
import useUserRoles from "../../hooks/useUserRoles";
import Orders from "../orders/Orders";

const Cards = () => {
  const { t } = useTranslation();
  const { isScanner, isOfficeAdmin, isOfficeEditor } = useUserRoles();
  const canNotSeeStatistics = isScanner || isOfficeAdmin || isOfficeEditor;

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_, newValue) => {
    setValueTab(newValue);
  };

  const breadcrumbs = [
    {
      name: t("CARDS"),
      link: ROUTES.CARDS,
    },
  ];

  return (
    <div>
      <Header breadcrumbs={breadcrumbs} description={t("CARDS_VIEW_DESCRIPTION")} />
      <Tabs
        value={valueTab}
        onChange={handleChangeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        sx={{ mb: 2 }}
      >
        {!canNotSeeStatistics && <Tab label={t("STATISTICS")} />}
        <Tab label={t("ORDERS")} />
      </Tabs>
      {valueTab === 0 && !canNotSeeStatistics && <CardStatistics />}
      {(valueTab === 1 || (canNotSeeStatistics && valueTab === 0)) && <Orders fromCards />}
    </div>
  );
};

export default Cards;
