import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { useTranslation } from "react-i18next";
import { ZGZ_CENTER } from "../../../constants/variables";
import LocationMarker from "./LocationMarker";
import SearchField from "./Search";

export default function MapContent({ lat, lng, marker, isDisabled, handleChangeLatLong }) {
  const { t } = useTranslation();
  const [position, setPosition] = useState(null);
  const mapCenter = lat && lng ? [lat, lng] : null;

  useEffect(() => {
    if (lat && lng) {
      setPosition({ lat: lat, lng: lng });
    }
  }, [lat, lng]);

  const handleSetPosition = (lat, lng) => {
    setPosition({ lat: lat, lng: lng });
    handleChangeLatLong(lat, lng);
  };

  const prov = new OpenStreetMapProvider();

  return (
    <MapContainer
      id="map"
      center={mapCenter ? mapCenter : ZGZ_CENTER}
      zoom={15}
      style={{ height: "100%", width: "100%" }}
      dragging={!isDisabled}
      scrollWheelZoom={!isDisabled}
      doubleClickZoom={!isDisabled}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marker && !isDisabled && (
        <LocationMarker position={position} handleSetPosition={handleSetPosition} />
      )}
      <SearchField
        provider={prov}
        showMarker={false}
        retainZoomLevel={false}
        animateZoom={true}
        autoClose={false}
        searchLabel={t("SEARCH_PLACEHOLDER_ADDRESS")}
        keepResult={true}
      />
    </MapContainer>
  );
}
