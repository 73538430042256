import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ mt: 9, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid item xs={11} sm={12}>
        <Box sx={{ height: 300 }} component="img" alt="Logo" src={'/media/Error404.svg'} />
      </Grid>
      <Grid item xs={11} sm={12}>
        <Typography className='white' variant="h1" sx={{ fontWeight: "bolder",mb:1 }}>
          404
        </Typography>
      </Grid>
      <Grid item xs={11} sm={12}>
        <Typography className='white' variant="h5" sx={{ fontWeight: "bolder", }}>
          OOPS...
        </Typography>
      </Grid>
      <Grid item xs={11} sm={10} md={4} lg={4}>
        <Typography className='white' variant="h5" sx={{ fontWeight: "bolder", textAlign: "center" }}>
          {t("404_ERROR")}
        </Typography>
      </Grid>
      <Grid item xs={11} sm={12}>
        <Button
          className='oniria-btn'
          href='/home'
          fullWidth
          variant="contained"
          size='small'
          sx={{ mt: 5, mb: 25, fontSize: "18px", borderRadius: 3 }}
        >
          {t("BACK_HOME")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Error404