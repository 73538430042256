import { Button, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ButtonsCreateEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid item>
      <Button
        fullWidth
        variant="contained"
        sx={{ height: "33px", borderRadius: 3, mb: 1 }}
        className="oniria-btn"
        onClick={() => navigate("/create_event")}
      >
        {t("CREATE_EVENT")}
      </Button>
    </Grid>
  );
};

export default ButtonsCreateEvent;
