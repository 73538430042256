export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.map((item, index) => {
    item.position = index;
    item.subcategories?.map((item, index) => (item.position = index));
  });
  return result;
};
