import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../textEditor/tools";

const ExtraDescription = ({
  checkExtraField,
  index,
  extras,
  errors,
  sendButtonClicked,
  isDisabled,
  extra_language_infos,
  handleChangeLanguageParam,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  const selectedLanguageIndex = extra_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  const handleChangeDescription = (data) => {
    const e = { target: { value: data } };
    checkExtraField("description", e, /^.[\s\S]{0,5000000}$/, t("MAX_1000"), true, index);
  };

  return (
    <Grid item xs={12}>
      <Typography>{t("DESCRIPTION")}</Typography>
      {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
        checkRenderEditorJs("", extras[index].description) ? (
          <>
            <TextEditor
              data={extras[index].description}
              setData={handleChangeDescription}
              id={`extra-description-${index}`}
            />
            {errors[index].description !== "" && errors[index].description !== "empty" ? (
              <p style={{ color: "red" }}>{errors[index].description}</p>
            ) : (
              ""
            )}
          </>
        ) : (
          <TextField
            onChange={(e) =>
              checkExtraField(
                "description",
                e,
                /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{1,1000}$/,
                t("MAX_1000"),
                true,
                index
              )
            }
            value={extras[index].description}
            margin="normal"
            fullWidth
            multiline
            rows={4}
            id="description"
            name="description"
            label={t("DESCRIPTION")}
            autoComplete="description"
            error={
              (errors[index].description !== "" && errors[index].description !== "empty") ||
              (errors[index].description === "empty" && sendButtonClicked)
            }
            helperText={errors[index].description !== "empty" ? errors[index].description : ""}
            disabled={isDisabled}
            sx={focusColor}
          />
        )
      ) : checkRenderEditorJs("", extra_language_infos[selectedLanguageIndex]?.description) ? (
        <TextEditor
          data={extra_language_infos[selectedLanguageIndex]?.description}
          setData={(data) => handleChangeLanguageParam(data, "description", selectedLanguageIndex)}
          id={`extra-description-${selectedLanguage}-${index}`}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeLanguageParam(e.target.value, "description", selectedLanguageIndex)
          }
          value={extra_language_infos[selectedLanguageIndex]?.description}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          id="description"
          name="description"
          label={t("DESCRIPTION")}
          autoComplete="description"
          //   error={
          //     (errors[index].description !== "" && errors[index].description !== "empty") ||
          //     (errors[index].description === "empty" && sendButtonClicked)
          //   }
          //   helperText={errors[index].description !== "empty" ? errors[index].description : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default ExtraDescription;
