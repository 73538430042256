import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createRefund } from "../../services/refundsServices";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const RefundDialog = (props) => {
  const { t } = useTranslation();
  const { openRefund, handleCloseRefund, maxPrice, user, order_id } = props;
  const [refundData, setRefundData] = useState({ amount: "", description: "" });
  const [refundErrors, setRefundErrors] = useState({ amount: "", description: "" });

  const handleChangeRefund = (e, field) => {
    let value = e.target.value;
    let errorMessage = "";
    if (field === "amount") {
      if (parseFloat(value) > parseFloat(maxPrice())) {
        errorMessage = t("AMOUNT_ERROR");
      }
    } else if (field === "description") {
      if (value.length > 500) {
        errorMessage = t("MAX_500");
      }
    }
    setRefundData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setRefundErrors((prev) => ({
      ...prev,
      [field]: errorMessage,
    }));
  };
  const handleApplyRefund = async (e) => {
    e.preventDefault();
    let body = {
      user_id: user.id,
      amount: parseFloat(refundData.amount),
      description: refundData.description,
      order_id: order_id,
    };
    try {
      await createRefund(body);
      toastMessageSuccess(t("REFUND_OK"));
    } catch (error) {
      console.log("error", error);
      toastMessageError(error?.response?.data?.error ?? "Something went wrong");
    }
    handleCloseRefund();
  };
  return (
    <Dialog
      open={openRefund}
      onClose={handleCloseRefund}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
        {t("ORDER_REFUND")}:
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ paddingTop: 2 }} fullWidth>
          <TextField
            label={t("AMOUNT") + " €"}
            sx={[focusColor, { marginBottom: 2 }]}
            type="number"
            value={refundData.amount}
            onChange={(e) => handleChangeRefund(e, "amount")}
            inputProps={{
              min: 0,
              max: maxPrice(),
            }}
            error={!!refundErrors.amount}
            helperText={refundErrors.amount}
            fullWidth
            required
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            label={t("DESCRIPTION")}
            sx={focusColor}
            multiline
            value={refundData.description}
            onChange={(e) => handleChangeRefund(e, "description")}
            error={!!refundErrors.description}
            helperText={refundErrors.description}
            required
          />
        </FormControl>
        <Typography marginTop={2} textAlign="center">
          {t("CONFIRM_REFUND")}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          paddingBottom: 2,
        }}
      >
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleCloseRefund}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleApplyRefund}
          disabled={
            refundErrors.amount !== "" ||
            refundErrors.description !== "" ||
            refundData.amount === "" ||
            refundData.description === ""
          }
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
