// Función para crear un mapa de categorías y sus subcategorías ordenadas
export const createCategoryMap = (categories) => {
  return categories.reduce((acc, category) => {
    acc[category.id] = {
      ...category,
      subcategories: category?.subcategories
        ? category.subcategories.sort((a, b) => a.position - b.position)
        : [],
    };
    return acc;
  }, {});
};

// Función para filtrar y ordenar categorías principales
export const getMainCategories = (category_styles, categoryMap) => {
  return category_styles
    .filter((style) => {
      return (
        categoryMap[style.category_id] && !style.subcategory_id && style.category_id !== "undefined"
      );
    })
    .sort((a, b) => {
      const posA = categoryMap[a.category_id].position;
      const posB = categoryMap[b.category_id].position;
      return posA - posB;
    });
};

// Función para insertar subcategorías después de sus categorías principales
export const insertSubcategories = (mainCategories, categoryMap, category_styles) => {
  const orderedCategories = [];
  mainCategories.forEach((mainCategory) => {
    orderedCategories.push(mainCategory);

    const subcategories = categoryMap[mainCategory.id]?.subcategories;
    subcategories?.forEach((subcategory) => {
      const subcategoryStyle = category_styles.find(
        (style) => style.subcategory_id === subcategory.id
      );
      if (subcategoryStyle) {
        orderedCategories.push(subcategoryStyle);
      }
    });
  });
  return orderedCategories;
};
