import { Box, Button, Grid, Typography, Paper } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { LOGOS } from '../../constants/variables';

const Register = (props) => {
    const { t } = useTranslation();


    return (
        <>
            <Grid item xs={11} md={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="img" alt="Letras" src={LOGOS.PRINCIPAL_WHITE} sx={{ height: 80, mb: 4, mt: 8 }} />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 5, mb: 15,px:1, color: "black", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Box component={Paper} elevation={3}
                    sx={{
                        px: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px"
                    }}
                >
                    <Box sx={{ my: 2 }}>
                        <Typography className="oniria-colorText" variant="h6">
                            {t("REGISTRATION_TITLE")}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="body1" sx={{ mb: 2 }} >
                            {t("NO_ENTITY_EXISTS")}
                        </Typography>
                        <Typography variant="body1" >{t("CREATE_YOUR_OWN_ENTITY")} </Typography>
                        <Typography variant="body1" >{t("FILL_FORM")}</Typography>
                        <Typography variant="body1" >{t("NEED_FISCAL_DATA")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button type="submit"
                            className='oniria-btn'
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, px: 6, fontSize: "20px", borderRadius: 3 }} onClick={props.formController.next}> {t("NEXT")}</Button>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default Register