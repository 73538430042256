import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import LanguageTabs from "../../events/languages/LanguageTabs";
import ImageUpload from "../../shared/ImageUpload";
import { focusColor } from "../../shared/textFieldStyle";
import CategoryLanguageSelect from "./CategoryLanguageSelect";
import CategoryDescription from "./form/CategoryDescription";
import CategoryName from "./form/CategoryName";
import CategorySlug from "./form/CategorySlug";

function AddCategoryDialog({
  newCategory,
  setNewCategory,
  isOpen,
  onClose,
  onAccept,
  title,
  isSubcategory,
  image,
  setImage,
}) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);

  const handleSelectImage = (url) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setImage(null);
      } else {
        setImage(imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setImage(imageObject);
    }
  };

  const languagesInfos =
    newCategory?.category_language_infos || newCategory?.subcategory_language_infos || [];

  const handleChange = (e) => {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>{title}</DialogTitle>
      <DialogContent>
        <CategoryLanguageSelect
          category_language_infos={languagesInfos}
          setNewCategory={setNewCategory}
        />
        {newCategory && languagesInfos.length > 0 ? (
          <LanguageTabs
            event_languages_info={
              newCategory?.category_language_infos || newCategory?.subcategory_language_infos || []
            }
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        ) : null}
        <CategoryName
          newCategory={newCategory}
          setNewCategory={setNewCategory}
          selectedLanguage={selectedLanguage}
        />
        <CategoryDescription
          newCategory={newCategory}
          setNewCategory={setNewCategory}
          selectedLanguage={selectedLanguage}
        />
        <CategorySlug
          newCategory={newCategory}
          setNewCategory={setNewCategory}
          selectedLanguage={selectedLanguage}
        />
        <TextField
          id="outlined-basic"
          label={t("KEYWORDS")}
          variant="outlined"
          name="keywords"
          sx={[focusColor, { mt: 2 }]}
          value={newCategory.keywords || ""}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label={t("META_TITLE")}
          variant="outlined"
          name="meta_title"
          sx={[focusColor, { mt: 2 }]}
          value={newCategory.meta_title || ""}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label={t("META_DESCRIPTION")}
          variant="outlined"
          name="meta_description"
          sx={[focusColor, { mt: 2 }]}
          value={newCategory.meta_description || ""}
          onChange={handleChange}
          fullWidth
        />
        {isSubcategory && (
          <ImageUpload
            url={image || ""}
            multi={false}
            onImageSelect={(e) => handleSelectImage(e)}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onAccept}
          disabled={!newCategory?.name}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCategoryDialog;
