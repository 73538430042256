import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import EventFilters from "../components/events/filters/EventFilters";
import { Loading } from "../components/shared/Loading";
import { getAllEventsFiltered } from "../services/eventsServices";

let perPage = "20";
let numberEvents = "";
let totalEvents = 0;

// Configura moment para que el primer día de la semana sea lunes
moment.updateLocale("es", {
  week: {
    dow: 1, // Configura el primer día de la semana en lunes
  },
});
const localizer = momentLocalizer(moment);

const CalendarEvents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [dateFilter, setFilterDates] = useState("upcoming");
  const [cityFilter, setCityFilter] = useState("all");
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [cityNoEvents, setCityNoEvents] = useState(false);
  const [page, setPage] = useState(1);
  const [grouping, setGrouping] = useState("all");
  const [premise, setPremise] = useState("all");
  const [eventType, setEventType] = useState("all");
  const [organization, setOrganization] = useState("all");
  const userInfo = useSelector((state) => state.userInfo);
  const breadcrumbs = [
    {
      name: t("CALENDAR"),
      link: "/calendar",
    },
  ];
  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  const handleQuerySuccessFiltered = (data) => {
    const activities = data.data;
    perPage = data.perpage;
    numberEvents = data.total;
    setEventsFiltered(transformEvents(activities));
    setCityNoEvents(activities?.length === 0);
  };

  const handleCityFilter = (data) => {
    setCityFilter(data);
    setPage(1);
  };
  const handleDateFilter = (data) => {
    setFilterDates(data);
    setPage(1);
  };

  const transformEvents = (events) => {
    return events.map((event) => ({
      title: event.name,
      start: new Date(event.start_date),
      end: new Date(event.end_date),
      allDay: false,
      resource: event,
    }));
  };

  const { isLoading, isRefetching } = useQuery(
    ["activitiesFilter", cityFilter, search, dateFilter, page, grouping, premise, eventType],
    () =>
      getAllEventsFiltered(
        cityFilter,
        search,
        dateFilter,
        page,
        perPage,
        grouping,
        premise,
        eventType
      ),
    {
      onSuccess: handleQuerySuccessFiltered,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    setPage(1);
  };

  const handleChangeEventType = (e) => {
    setEventType(e);
    setPage(1);
  };

  const handleGroupingFilter = (data) => {
    setGrouping(data);
    setOrganization("all");
    setPremise("all");
    setPage(1);
  };
  const handlePremiseFilter = (data) => {
    setPremise(data);
    setPage(1);
  };
  const handleOrganizationFilter = (data) => {
    setOrganization(data);
    setPremise("all");
    setPage(1);
  };

  const renderNoEventsFound = () => <Grid ml={3}>{t("EVENTS_NO_FOUND")}</Grid>;

  const handleSelectEvent = (event) => {
    navigate(`/event/${event.resource.id}`);
  };

  const renderEvents = () => (
    <Grid
      container
      rowSpacing={2}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        mb: 20,
      }}
    >
      <Calendar
        localizer={localizer}
        events={eventsFiltered}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%", width: "100%", minHeight: 700 }}
        onSelectEvent={handleSelectEvent} // Manejar la selección de eventos
      />
    </Grid>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <EventFilters
          onSearchChange={handleSearchChange}
          grouping={grouping}
          onGroupingFilter={handleGroupingFilter}
          premise={premise}
          onPremiseFilter={handlePremiseFilter}
          cityFilter={cityFilter}
          onCityFilter={handleCityFilter}
          dateFilter={dateFilter}
          onDateFilter={handleDateFilter}
          eventType={eventType}
          onEventFilter={handleChangeEventType}
          organization={organization}
          onOrganizationFilter={handleOrganizationFilter}
        />
      </Grid>
      {cityNoEvents ? (
        renderNoEventsFound()
      ) : isRefetching | isLoading ? (
        <Loading />
      ) : (
        renderEvents()
      )}
    </>
  );
};

export default CalendarEvents;
