import Close from "@mui/icons-material/Close";
import { FormControl, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { findDomainById, updateDomain } from "../../../services/domainsServices";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import ChoseEntity from "../choseEntity/ChooseEntity";

function AssignDomain({ isOpen, onClose, domainId, refetch }) {
  const { t } = useTranslation();
  const [selectedEntity, setSelectedEntity] = useState({
    grouping_id: "all",
    organization_id: "all",
    premise_id: "all",
  });
  const [loading, setLoading] = useState(false);

  const handleSetEntities = (data) => {
    setSelectedEntity({
      grouping_id: data.grouping_id || "all",
      organization_id: data.organization_id || "all",
      premise_id: data.premise_id || "all",
    });
  };

  const { data } = useQuery(["stylesDomain"], () => findDomainById(domainId), {
    refetchOnWindowFocus: false,
    onError: () => {
      onClose();
      toastMessageError(t("ERROR_FETCHING_DOMAIN"));
    },
    enabled: isOpen,
    onSuccess: handleSetEntities,
  });

  const handleChooseEntity = (name, value) => {
    setSelectedEntity({
      grouping_id: "all",
      organization_id: "all",
      premise_id: "all",
      [name]: value,
    });
  };

  const handleUpdateDomain = async () => {
    setLoading(true);
    const { grouping_id, organization_id, premise_id } = selectedEntity;
    try {
      const bodyToSend = {
        ...data,
        grouping_id: grouping_id === "all" ? "" : grouping_id,
        organization_id: organization_id === "all" ? "" : organization_id,
        premise_id: premise_id === "all" ? "" : premise_id,
      };
      await updateDomain(domainId, bodyToSend);
      toastMessageSuccess(t("SUCCESS_CREATE_STYLES"));
    } catch (e) {
      toastMessageError(e.response?.data?.error ?? t("ERROR_CREATE_STYLES"));
    } finally {
      onClose();
      refetch();
      setSelectedEntity({
        grouping_id: "all",
        organization_id: "all",
        premise_id: "all",
      });
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", paddingTop: 0 }}>
        {t("ADD_DOMAIN")}
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <ChoseEntity
            grouping={selectedEntity.grouping_id}
            premise={selectedEntity.premise_id}
            organization={selectedEntity.organization_id}
            handleChooseEntity={handleChooseEntity}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "16px", borderRadius: 3, fontWeight: "600" }}
          onClick={handleUpdateDomain}
          disabled={loading}
        >
          {!loading ? t("SAVE") : t("SAVING")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignDomain;
