import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getCardsByDomain } from "../../services/domainsServices";

export function useQueryCardsDomain(domainId) {
  return useQuery([RQ_KEY.CARDS_DOMAIN, domainId], () => getCardsByDomain(domainId), {
    refetchOnWindowFocus: false,
    enabled: !!domainId,
  });
}
