import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../constants/utils";

const UserTripData = ({ userInfo }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={4}>
      <TableContainer size="small">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="center" sx={{ fontWeight: "bold" }}>
                {t("USER_TRIP_DATA")}
              </TableCell>
            </TableRow>
            {userInfo?.address && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("ADDRESS")}:
                </TableCell>
                <TableCell align="left">
                  {userInfo.address}, {userInfo.city} {userInfo.postal_code} {userInfo.country}
                </TableCell>
              </TableRow>
            )}
            {userInfo?.nationality && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("NATIONALITY")}:
                </TableCell>
                <TableCell align="left">{userInfo.nationality}</TableCell>
              </TableRow>
            )}
            {userInfo?.identity_number && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("IDENTITY_DOCUMENT")}:
                </TableCell>
                <TableCell align="left">
                  {userInfo.identity_document}: {userInfo.identity_number}
                </TableCell>
              </TableRow>
            )}
            {userInfo?.doc_expedition_date && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("EXPEDITION_DATE")}:
                </TableCell>
                <TableCell align="left">{formatDate(userInfo.doc_expedition_date)}</TableCell>
              </TableRow>
            )}
            {userInfo?.doc_expiration_date && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("EXPIRATION_DATE")}:
                </TableCell>
                <TableCell align="left">{formatDate(userInfo.doc_expiration_date)}</TableCell>
              </TableRow>
            )}
            {userInfo?.born_at && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("BORN_AT")}:
                </TableCell>
                <TableCell align="left">{formatDate(userInfo.born_at)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UserTripData;
