import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEventLanguage from "../../../../hooks/useEventLanguage";
import { checkField } from "../../../shared/FormsValidator";
import { focusColor } from "../../../shared/textFieldStyle";
import LanguageTabs from "../../languages/LanguageTabs";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";

const EventSubtitle = ({
  trip,
  formParams,
  setFormErrors,
  detailsErrors,
  isDisabled,
  buttonClicked,
  setFormData,
  setEventLanguageInfoError,
}) => {
  const { t } = useTranslation();
  const { event_language_infos } = trip;

  const {
    selectedLanguage,
    setSelectedLanguage,
    selectedLanguageInfo,
    selectedLanguageIndex,
    handleChangeAttribute,
  } = useEventLanguage(event_language_infos, setFormData, setEventLanguageInfoError);

  const languageErrors = detailsErrors?.event_language_infos[selectedLanguageIndex];

  return (
    <>
      {event_language_infos?.length > 0 ? (
        <LanguageTabs
          event_languages_info={trip.event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      ) : null}
      {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "subtitle",
              e,
              /^.{0,255}$/,
              t("MAX_255"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.subtitle || ""}
          margin="normal"
          fullWidth
          id="subtitle"
          name="subtitle"
          label={t("SUBTITLE")}
          autoComplete="subtitle"
          error={
            (detailsErrors.subtitle !== "" && detailsErrors.subtitle !== "empty") ||
            (detailsErrors.subtitle === "empty" && buttonClicked)
          }
          helperText={detailsErrors.subtitle !== "empty" ? detailsErrors.subtitle : ""}
          disabled={isDisabled}
          sx={[focusColor, { mb: 1 }]}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeAttribute("subtitle", e.target.value, /^.{0,255}$/, t("MAX_255"))
          }
          value={selectedLanguageInfo?.subtitle || ""}
          margin="normal"
          fullWidth
          id="subtitle"
          name="subtitle"
          label={t("SUBTITLE")}
          autoComplete="subtitle"
          error={
            (languageErrors?.subtitle !== "" && languageErrors?.subtitle !== "empty") ||
            (languageErrors?.subtitle === "empty" && buttonClicked)
          }
          helperText={languageErrors?.subtitle !== "empty" ? languageErrors?.subtitle : ""}
          disabled={isDisabled}
          sx={[focusColor, { mb: 1 }]}
        />
      )}
    </>
  );
};

export default EventSubtitle;
