import { Grid, Tab, Tabs } from "@mui/material";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";

const LanguageTabs = ({ event_languages_info, selectedLanguage, setSelectedLanguage }) => {
  const handleChange = (_, newValue) => {
    setSelectedLanguage(newValue);
  };

  return (
    <Grid container justifyContent="center" height={"50px"}>
      <Tabs
        value={selectedLanguage}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { backgroundColor: "var(--secondary-color)" },
        }}
        sx={{ mb: 2, color: "var(--primary-color)" }}
      >
        <Tab
          label={DEFAULT_EVENT_LANGUAGE}
          value={DEFAULT_EVENT_LANGUAGE}
          sx={{
            "&.Mui-selected": {
              color: "var(--secondary-color)",
            },
            padding: 0,
          }}
        />
        {event_languages_info.map((language) => (
          <Tab
            key={language.language}
            label={language.language}
            value={language.language}
            sx={{
              "&.Mui-selected": {
                color: "var(--secondary-color)",
              },
              padding: 0,
            }}
          />
        ))}
      </Tabs>
    </Grid>
  );
};

export default LanguageTabs;
