import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  eventLanguageErrors,
  eventLanguageInfo,
  extraLanguageInfo,
  ticketLanguageInfo,
} from "../../../classes/tripClass";
import InfoToolTip from "../../shared/InfoToolTip";

const EventLanguages = ({ formController }) => {
  const { t } = useTranslation();
  const steps = [{ label: t("ENGLISH"), value: "EN" }];
  const {
    formData: {
      details: { event_language_infos },
    },
    setFormData,
    setFormParamsErrors,
  } = formController;

  const handleChangeCheckBox = (e, value, checkboxIndex) => {
    const newLanguage = {
      ...eventLanguageInfo,
      language: value,
    };
    const newTicketLanguageInfo = {
      ...ticketLanguageInfo,
      language: value,
    };

    const newExtraLanguageInfo = {
      ...extraLanguageInfo,
      language: value,
    };

    if (e.target.checked) {
      setFormData((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: [...prev.details.event_language_infos, newLanguage],
        },
        tickets: prev.tickets.map((ticket) => ({
          ...ticket,
          ticket_language_infos: [...ticket.ticket_language_infos, newTicketLanguageInfo],
        })),
        extras: prev.extras.map((extra) => ({
          ...extra,
          extra_language_infos: [...extra.extra_language_infos, newExtraLanguageInfo],
        })),
      }));
      setFormParamsErrors((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: [
            ...prev.details.event_language_infos,
            JSON.parse(JSON.stringify(eventLanguageErrors)),
          ],
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: prev.details?.event_language_infos?.filter(
            (lang) => lang.language !== value
          ),
        },
        tickets: prev.tickets.map((ticket) => ({
          ...ticket,
          ticket_language_infos: ticket.ticket_language_infos.filter(
            (lang) => lang.language !== value
          ),
        })),
        extras: prev.extras.map((extra) => ({
          ...extra,
          extra_language_infos: extra.extra_language_infos.filter(
            (lang) => lang.language !== value
          ),
        })),
      }));
      setFormParamsErrors((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: prev.details.event_language_infos.filter(
            (_, index) => index !== checkboxIndex
          ),
        },
      }));
    }
  };

  const isLanguageChecked = (language) => {
    return event_language_infos?.some((lang) => lang.language === language);
  };

  return (
    <Grid container justifyContent="center" mt={2} paddingInline={"8px"}>
      <Grid item xs={11} md={8}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Typography>{t("EVENT_LANGUAGES")}</Typography>
              <InfoToolTip text={t("EVENT_LANGUAGES_TOOLTIP")} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {steps.map((step, index) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Checkbox
                    className="checkbox-oniria"
                    checked={isLanguageChecked(step.value)}
                    onChange={(e) => handleChangeCheckBox(e, step.value, index)}
                  />
                  <Typography>{step.label}</Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default EventLanguages;
