import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getCardsByUser } from "../../services/cardsServices";

export function useQueryUserCards(userId) {
  return useQuery([RQ_KEY.USER_CARDS, userId], () => getCardsByUser(userId), {
    refetchOnWindowFocus: false,
    enabled: !!userId,
  });
}
