import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { cardLanguageInfo } from "../../../classes/premiseClass";

const CardLanguageSelect = ({ affiliated_card_language_infos, setFormData }) => {
  const { t } = useTranslation();
  const steps = [{ label: t("ENGLISH"), value: "EN" }];

  const cardLanguages = (idiom) => {
    return affiliated_card_language_infos?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = JSON.parse(JSON.stringify(cardLanguageInfo));
    if (isChecked) {
      newLanguage.language = value;
      setFormData((prev) => ({
        ...prev,
        affiliated_card_language_infos: [...prev.affiliated_card_language_infos, newLanguage],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        affiliated_card_language_infos: prev?.affiliated_card_language_infos?.filter(
          (language) => language?.language !== value
        ),
      }));
    }
  };

  return (
    <Grid container justifyContent="center" sx={{ margin: "20px 0" }} px={4}>
      <Grid item xs={12} md={8.5} component={Paper}>
        <Accordion
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Typography>{t("PREMISE_CARD_LANGUAGES")}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {steps.map((step, index) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Checkbox
                    className="checkbox-oniria"
                    checked={cardLanguages(step.value)}
                    onChange={(e) => handleChangeCheckBox(e, step.value)}
                  />
                  <Typography>{step.label}</Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default CardLanguageSelect;
