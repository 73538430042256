import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

const GenderGraph = ({ salesByGender }) => {
  const { t } = useTranslation();

  const isXsScreen = useMediaQuery("(max-width:863px)");

  const genderData = [
    { name: t("MAN"), value: salesByGender?.men, fill: "var(--secondary-color)" },
    { name: t("WOMAN"), value: salesByGender?.women, fill: "var(--oniria-gold)" },
    { name: t("OTHER"), value: salesByGender?.others, fill: "var(--oniria-sky)" },
  ];

  const renderColorfulLegendText = (value, _, index) => {
    return (
      <span
        style={{
          color: "#596579",
          fontWeight: 400,
          padding: "5px",
          display: "inline-flex",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <div>{value}</div>
        <div>
          {(() => {
            if (index === 0) {
              return Math.floor(salesByGender?.men * 100) + "%";
            }
            if (index === 1) {
              return Math.floor(salesByGender?.women * 100) + "%";
            }
            if (index === 2) {
              return Math.floor(salesByGender?.others * 100) + "%";
            }
          })()}{" "}
        </div>
      </span>
    );
  };

  return (
    <Grid
      item
      xs={12}
      component={Paper}
      elevation={3}
      sx={{
        padding: "10px",
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        width: "100%",
      }}
    >
      <Typography variant="h6">{t("CUSTOMER_GENDER")}</Typography>
      <ResponsiveContainer width="100%" height={isXsScreen ? 374 : "85%"}>
        <PieChart width={300} height={isXsScreen ? 400 : 200}>
          <Pie
            data={genderData}
            cx={isXsScreen ? "50%" : "45%"}
            cy={"50%"}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          />
          <Legend
            layout="vertical"
            iconType="circle"
            iconSize={15}
            formatter={renderColorfulLegendText}
            align={isXsScreen ? "center" : "right"}
            verticalAlign="bottom"
            height={130}
            width={isXsScreen ? "90%" : "40%"}
          />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default GenderGraph;
