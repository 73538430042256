import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useCategoriesContext } from "../categoriesContext/CategoriesContext";
import "./categories-preview.scss";
import { createCategoryMap, getMainCategories, insertSubcategories } from "./orderCategories";

const CategoriesPreview = ({ isMobile }) => {
  const { watch, setValue } = useFormContext();
  const { categories } = useCategoriesContext();

  const category_styles = watch("style.category_styles");
  const header_type = watch("style.header_type");
  const appFont = watch("style.font_text");

  const getCategoryName = (categoryId) => {
    for (let category of categories) {
      if (category.id === categoryId) {
        return category.name;
      }
      if (category.subcategories) {
        for (let subcategory of category.subcategories) {
          if (subcategory.id === categoryId) {
            return subcategory.name;
          }
        }
      }
    }
    return "";
  };

  useEffect(() => {
    if (categories && categories.length > 0) {
      const categoryMap = createCategoryMap(categories);
      const mainCategories = getMainCategories(category_styles, categoryMap);
      const orderedCategories = insertSubcategories(mainCategories, categoryMap, category_styles);
      setValue("style.category_styles", orderedCategories);
    }
  }, [categories]);

  const squareWidth = isMobile ? "200px" : "100px";
  const format59Width = isMobile ? "260px" : "130px";
  const portraitWidth = isMobile ? "180px" : "90px";
  const defaultWidth = isMobile ? "150px" : "75px";

  return (
    <Grid
      sx={{
        backgroundColor: "#F2F3F3",
        padding: "0 10px 20px 10px",
        paddingTop: header_type === "middleCircle" && "30px",
        overflowX: isMobile ? "auto" : "",
        overflowY: isMobile ? "auto" : "",
        height: isMobile ? "541px" : "fit-content",
        minHeight: isMobile ? "541px" : "70%",
      }}
    >
      {category_styles?.map((category, index) => (
        <Grid key={`${category.id}-${index}`}>
          <Typography fontFamily={`${appFont} !important`}>
            {getCategoryName(category.subcategory_id || category.category_id)}
          </Typography>
          {category.format !== "fullWidth" ? (
            <Grid container gap={2} sx={{ flexWrap: "nowrap", overflowX: "auto" }}>
              {Array.from({ length: 5 }).map((_, index) => (
                <Grid
                  key={index}
                  item
                  sx={{
                    backgroundColor: "lightgray",
                    height: isMobile ? "200px" : "100px",
                    width:
                      category.format === "square"
                        ? squareWidth
                        : category.format === "format59"
                        ? format59Width
                        : category.format === "portrait"
                        ? portraitWidth
                        : defaultWidth,
                    borderRadius: "10px",
                    flexShrink: 0,
                  }}
                  className={category.format}
                />
              ))}
            </Grid>
          ) : (
            <Grid
              sx={{
                backgroundColor: "lightgray",
                height: isMobile ? "180px" : "100px",
                width: "100%",
                borderRadius: "10px",
              }}
            ></Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoriesPreview;
