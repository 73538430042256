import { Input } from "@mui/material";

const ColorInput = ({ value, onChange }) => {
  return (
    <Input
      type="color"
      value={value}
      onChange={onChange}
      sx={{
        width: "60px",
        "&:before": { borderBottom: "none" },
        "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
        cursor: "pointer",
      }}
    />
  );
};

export default ColorInput;
