import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import Warning from "@editorjs/warning";
import { uploadImage } from "../../services/utilsServices";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  underline: Underline,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async function (event) {
              const base64 = event.target.result;
              const base64WithoutType = base64?.split(";")[1];
              const mimeType = base64?.split(";")[0]?.split(":")[1];
              const response = await uploadImage({ image: base64WithoutType, mime: mimeType });
              resolve({
                success: 1,
                file: {
                  url: response.imageURL,
                },
              });
            };
            reader.onerror = function (error) {
              reject(error);
            };
            reader.readAsDataURL(file);
          });
        },
      },
    },
  },
};

//Check if render EditorJs or not
const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
};

export const checkRenderEditorJs = (path, data) => {
  if (path.includes("create")) return true;
  if (typeof data === "string" && data === "") return true;
  if (!data) return false;

  const jsonData = typeof data === "string" ? parseJson(data) : data;

  return jsonData && jsonData.blocks && Array.isArray(jsonData.blocks);
};
