export const organization = {
    legal_name: "",
    description: "",
    logo_url: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    phone_code: "",
    phone: "",
    email: "",
    //schedule: "",
    fiscal_name: "",
    cif: "",
    iban: "",
    bic:"",
    vat_number: "",
    fiscal_address: "",
    fiscal_city: "",
    fiscal_state: "",
    fiscal_postal_code: "",
    fiscal_country: "",
    //comments: "",
    bank_account_url: "",
    nif_certificate_url: ""
    // payment_type_id: string;
    // payment_method_id: string;
    // payment_day: number;
    // accounting_email?: string;
    // commercial_email?: string;
    // fiscal_email?: string;
    // currency_id: string;
    // agent_id: string;
    // account_number: number;
    // last_modified_at?: number;
    // is_active: boolean;
}

export const organizationErrors = {
    legal_name: "empty",
    description: "empty",
    address: "empty",
    postal_code: "empty",
    country: "empty",
    state: "empty",
    city: "empty",
    phone: "empty",
    phone_code: "empty",
    email: "empty",
    logo_url: "",
    fiscal_name: "empty",
    cif: "empty",
    // bic:"empty",
    // vat_number: "empty",
    iban: "empty",
    fiscal_address: "empty",
    fiscal_state: "empty",
    fiscal_city: "empty",
    fiscal_postal_code: "empty",
    fiscal_country: "empty",
    //comments: "",
    bank_account_url: "",
    nif_certificate_url: ""
};

export const organizationErrorsAllOk = {
    legal_name: "",
    description: "",
    logo_url: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    phone_code: "",
    phone: "",
    email: "",
    //schedule: "",
    fiscal_name: "",
    cif: "",
    iban: "",
    bic:"",
    vat_number: "",
    fiscal_address: "",
    fiscal_city: "",
    fiscal_state: "",
    fiscal_postal_code: "",
    fiscal_country: "",
    //comments: "",
    bank_account_url: "",
    nif_certificate_url: ""
    // payment_type_id: string;
    // payment_method_id: string;
    // payment_day: number;
    // accounting_email?: string;
    // commercial_email?: string;
    // fiscal_email?: string;
    // currency_id: string;
    // agent_id: string;
    // account_number: number;
    // last_modified_at?: number;
    // is_active: boolean;
}
