import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { newSubcategoryEmpty } from "../../../classes/premiseClass";
import { normalizeVisibility } from "../../../constants/utils";
import CustomDialog from "../../shared/CustomDialog";
import AddCategoryDialog from "./AddCategoryDialog";

export const Subcategories = ({
  id,
  subcategories,
  handleSetSubcategories,
  handleDeleteSubcategory,
  handleEditSubcategory,
}) => {
  const { t } = useTranslation();
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [openEditSubcategory, setOpenEditSubcategory] = useState(false);
  const [image, setImage] = useState("");
  const [newSubcategory, setNewSubcategory] = useState({ ...newSubcategoryEmpty });

  const handleOpenDelete = (index) => {
    setOpenRemove(true);
    setSelectedIndex(index);
  };

  const handleConfirmDelete = () => {
    handleDeleteSubcategory(id, selectedIndex);
    setOpenRemove(false);
  };

  const handleOpenEdit = (index) => {
    const subcategory = {
      ...subcategories[index],
      category_language_infos: subcategories[index].subcategory_language_infos,
    };
    setNewSubcategory(subcategory);
    setImage(subcategories[index].image_url);
    setOpenEditSubcategory(true);
    setSelectedIndex(index);
  };

  const handleConfirmEdit = () => {
    handleEditSubcategory(id, selectedIndex, newSubcategory, image);
    setOpenEditSubcategory(false);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleSetSubcategories}>
        <Typography variant="h7" sx={{ color: "var(--secondary-color)", width: "100%", mb: 2 }}>
          {t("SUBCATEGORIES")}:
        </Typography>
        <Droppable droppableId={id}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
            >
              {subcategories
                .sort((a, b) => a.position - b.position)
                .map((item, index) => (
                  <Fragment key={index}>
                    <Draggable draggableId={index + "hola"} index={index}>
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          component={Paper}
                          sx={{
                            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            padding: 1,
                            paddingLeft: 2,
                          }}
                        >
                          <Grid container>
                            <Grid item xs={6} container alignItems="center">
                              <DragIndicatorIcon sx={{ marginRight: 2 }} />
                              <Grid container flexDirection="column" item xs={10}>
                                <ListItemText
                                  primary={
                                    <Grid container alignItems="center">
                                      <img
                                        style={{
                                          height: "13 px",
                                          width: "20px",
                                          objectFit: "cover",
                                          marginRight: 10,
                                        }}
                                        alt="Flag"
                                        src={`/media/flags/es.svg`}
                                      />
                                      {item.name}
                                    </Grid>
                                  }
                                  sx={{ margin: 0, display: "flex", alignItems: "center", gap: 2 }}
                                  secondary={` ( /${item.slug} )` || ""}
                                />
                                {item.subcategory_language_infos &&
                                item.subcategory_language_infos.length > 0
                                  ? item.subcategory_language_infos.map((item) => {
                                      const flag =
                                        item.language === "EN"
                                          ? "sh"
                                          : item?.language?.toLowerCase();
                                      return (
                                        <ListItemText
                                          primary={
                                            <Grid container alignItems="center">
                                              <img
                                                style={{
                                                  height: "13 px",
                                                  width: "20px",
                                                  objectFit: "cover",
                                                  marginRight: 10,
                                                }}
                                                alt="Flag"
                                                src={`/media/flags/${flag}.svg`}
                                              />
                                              {item.name}
                                            </Grid>
                                          }
                                          sx={{
                                            margin: 0,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                          }}
                                          secondary={` ( /${item.slug} )` || ""}
                                        />
                                      );
                                    })
                                  : null}
                              </Grid>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                              {normalizeVisibility(item.is_visible) ? (
                                <Tooltip title={t("HOME_VISIBLE")} placement="top">
                                  <IconButton
                                    onClick={() =>
                                      handleEditSubcategory(id, index, item, item.image_url, false)
                                    }
                                    sx={{ color: "var(--secondary-color)" }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t("HOME_INVISIBLE")} placement="top">
                                  <IconButton
                                    onClick={() =>
                                      handleEditSubcategory(id, index, item, item.image_url, true)
                                    }
                                    sx={{ color: "var(--secondary-color)" }}
                                  >
                                    <VisibilityOffIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title={t("EDIT_SUBCATEGORY")}>
                                <IconButton
                                  onClick={() => handleOpenEdit(index)}
                                  sx={{
                                    color: "var(--secondary-color)",
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("REMOVE_SUBCATEGORY")}>
                                <IconButton
                                  onClick={() => handleOpenDelete(index)}
                                  sx={{
                                    color: "var(--secondary-color)",
                                  }}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </ListItem>
                      )}
                    </Draggable>
                  </Fragment>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CustomDialog
        title={t("DELETE_SUBCATEGORY")}
        isOpen={openRemove}
        onClose={() => setOpenRemove(false)}
        onAccept={handleConfirmDelete}
      />
      <AddCategoryDialog
        newCategory={newSubcategory}
        setNewCategory={setNewSubcategory}
        isOpen={openEditSubcategory}
        onClose={() => setOpenEditSubcategory(false)}
        onAccept={handleConfirmEdit}
        title={t("EDIT_SUBCATEGORY")}
        isSubcategory
        image={image}
        setImage={setImage}
      />
    </>
  );
};
