import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const ToastTicketInfo = ({ icon, title, message, clickButton }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: "20px",
        borderRadius: "5px",
        color: "white",
        minHeight: "80px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "2rem",
        }}
      >
        {icon && (
          <span
            style={{
              marginRight: 10,
              fontSize: "2rem",
              border: "2px solid #fff",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </span>
        )}
        <strong>{title}</strong>
      </div>
      <div style={{ marginTop: 5, fontSize: "1rem" }}>{message}</div>
      {clickButton && (
        <Grid container justifyContent="center" alignItems="center">
          <Button
            onClick={clickButton}
            sx={{
              color: "#fff ",
              border: "2px solid #fff",
              borderRadius: "5px",
              marginTop: "10px",
              width: "fit-content",
            }}
          >
            {t("SEE_TICKET_DETAILS")}
          </Button>
        </Grid>
      )}
    </div>
  );
};

export default ToastTicketInfo;
