import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Checkbox, Chip, Grid, ListItemText, TextField } from "@mui/material";

import { optionsValue } from './OptionsMultiSelect'
import { useTranslation } from "react-i18next";
import { focusColor } from "./textFieldStyle";

function MultiSelect(props) {

  const { t } = useTranslation();

  const formData = props.formController.formData.moreInformation;
  const selectedOptionsFromForm = formData.included_options;

  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsFromForm);


  const handleChange = (event, value) => {
    setSelectedOptions(value);
  };

  useEffect(() => {
    formData.included_options = selectedOptions
  }, [selectedOptions]);

  return (
    <Autocomplete
      multiple
      options={optionsValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      value={selectedOptions}
      renderGroup={(option, { selected }) => (
        <Grid item sx={{ display: "flex", flexDirection: "row" }}>
          <Avatar alt={option.name} src={option.img} />
          <ListItemText primary={option.value} />
          <Checkbox checked={selected} />
        </Grid>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index}
            avatar={<Avatar alt={option.name} src={option.img} />}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("INCLUDING_OPTIONS")}
          fullWidth
          placeholder={t("WRITE_INCLUDING")}
          sx={focusColor}
        />
      )}
    />
  );
}

export default MultiSelect