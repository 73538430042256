import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { partnerLanguageInfos } from "../../../../constants/partner";

const PartnerLanguageSelect = ({ partners, setPartners }) => {
  const { t } = useTranslation();
  const steps = [{ label: t("ENGLISH"), value: "EN" }];

  const somePartnerHasLanguage = (language) => {
    return (
      partners?.some((partner) =>
        partner.partner_language_infos?.some((info) => info.language === language)
      ) || false
    );
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newPartners = partners?.map((partner) => {
      const needsUpdate = partner.partner_language_infos?.some((info) => info.language === value);
      if (isChecked && !needsUpdate) {
        return {
          ...partner,
          partner_language_infos: [
            ...partner.partner_language_infos,
            {
              ...partnerLanguageInfos,
              language: value,
            },
          ],
        };
      } else if (!isChecked && needsUpdate) {
        return {
          ...partner,
          partner_language_infos: partner.partner_language_infos?.filter(
            (info) => info.language !== value
          ),
        };
      }
      return partner;
    });
    setPartners(newPartners);
  };

  return (
    <Grid container justifyContent="center" sx={{ margin: "20px 0" }}>
      <Grid item xs={12}>
        <Accordion
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Typography>{t("PARTNER_LANGUAGES")}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {steps.map((step, index) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Checkbox
                    className="checkbox-oniria"
                    checked={somePartnerHasLanguage(step.value)}
                    onChange={(e) => handleChangeCheckBox(e, step.value)}
                  />
                  <Typography>{step.label}</Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default PartnerLanguageSelect;
