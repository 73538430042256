import { Grid, Typography } from '@mui/material'
import React from 'react'

const HeaderForms = (props) => {


  return (
    <Grid container sx={{ mt: 8, mb: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Grid item xs={11} md={9} sx={{ mb: 5 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      </Grid>
      <Grid item xs={10} md={9} lg={7.5}>
        <Typography variant="body1" >{props.description}</Typography>
      </Grid>
    </Grid>
  )
}

export default HeaderForms