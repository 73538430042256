import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { rrppClientErrors, rrppClientErrorsAllOk } from "../../classes/rrppClass";
import {
  allFieldsOk,
  handleImageSelect,
  transformObjectWithUrls,
} from "../../components/shared/FormsValidator";
import ImageUpload from "../../components/shared/ImageUpload";
import { focusColor } from "../../components/shared/textFieldStyle";
import { RQ_KEY } from "../../constants/query";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { createRrpp, editRrpp, getAllRrpp } from "../../services/rrppsServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const RrppForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const url = window.location.href;
  const path = url.split("/").slice(4).join("/");
  const isEdit = path?.includes("edit_rrpp");

  const { imageDisable, rrppId } = props;
  const [formData, setFormData] = useState(props.formData);
  const [userError, setUserError] = useState(
    JSON.parse(JSON.stringify(isEdit ? rrppClientErrorsAllOk : rrppClientErrors))
  );
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { data: rrpps } = useQuery([RQ_KEY.ALL_RRPPS], () => getAllRrpp(), {
    refetchOnWindowFocus: false,
  });

  const { data: domains } = useQueryDomains();

  const setFormErrors = (_, field, value) => {
    let object = userError;
    object[field] = value;
  };
  const setFormParamsNotEvent = (_, field, value) => {
    let object = formData;
    object[field] = value;
    setFormData((_) => ({ ...object }));
  };
  const handleFieldChange = (event, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      [fieldName]: fieldValue,
    }));
    if (regex) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: regex.test(fieldValue) ? "" : helperText,
      }));
    } else if (fieldName === "born_at") {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: "",
      }));
    }
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  const handleSelectDomains = (event) => {
    const selectedDomains = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      domain_ids: selectedDomains,
    }));
    setUserError((prevUserError) => ({ ...prevUserError, domain: "" }));
  };

  const renderTextField = (
    label,
    fieldName,
    value,
    errorProperty,
    helperText,
    regex,
    disabled,
    type
  ) => (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, regex, helperText)}
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && buttonClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : helperText}
        disabled={disabled}
      />
    </>
  );

  const handleCreateRrppSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setButtonClicked(true);
    if (allFieldsOk(userError)) {
      const formDataToSend = await transformObjectWithUrls(formData);
      formDataToSend.domain =
        domains?.find((domain) => domain.domain_id === formData?.domain_ids[0] || "")
          ?.domain_name || "";
      try {
        setIsSending(true);
        if (isEdit) {
          const formDataToSend = await transformObjectWithUrls(formData);
          const bodyToSend = {
            name: formDataToSend.name,
            surname: formDataToSend.surname,
            phone: formDataToSend.phone,
            domains_ids: formDataToSend.domain_ids,
            avatar_url: formDataToSend.avatar_url,
            alias: formDataToSend.alias,
          };
          console.log("bodyToSend", bodyToSend);
          setIsSending(true);
          const response = await editRrpp(rrppId, bodyToSend);
          if (response === "success" && response !== undefined) {
            toastMessageSuccess(t("EDIT_SUCCESS"));
            setTimeout(() => {
              setFormData("");
              navigate("/rrpps");
            }, 1500);
          }
        } else {
          // Check if the email already exists
          const rrppWithEmailExists = rrpps.data.some((rrpp) => rrpp.email === formData.email);
          if (rrppWithEmailExists) {
            toastMessageError(t("USER_EXISTS"));
            setIsSending(false);
            return;
          }
          const response = await createRrpp(formDataToSend);
          if (response === "success" && response !== undefined) {
            toastMessageSuccess(t("CREATE_RRPP_SUCCESS"));
            setTimeout(() => {
              setFormData("");
              navigate("/rrpps");
            }, 1500);
          }
        }
      } catch (error) {
        setIsSending(false);
        toastMessageError(error.response?.data?.error || t("EDIT_ERROR"));
      } finally {
        setIsSending(false);
      }
    } else {
      setIsSending(false);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          mt: 5,
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleCreateRrppSubmit}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("NAME")}</InputLabel>
                  {renderTextField(
                    t("NAME"),
                    "name",
                    formData.name,
                    userError.name,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("SURNAME")}</InputLabel>
                  {renderTextField(
                    t("SURNAME"),
                    "surname",
                    formData.surname,
                    userError.surname,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("ALIAS")}</InputLabel>
                  {renderTextField(
                    t("ALIAS"),
                    "alias",
                    formData.alias,
                    userError.alias,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/,
                    isEdit
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("BORN_AT")}</InputLabel>
                  <TextField
                    disabled={path !== "create_rrpp"}
                    sx={focusColor}
                    margin="normal"
                    required
                    fullWidth
                    id={"born_at"}
                    name={"born_at"}
                    autoComplete={"born_at"}
                    value={
                      formData.born_at !== "" ? formatDate(formData.born_at) : formData.born_at
                    }
                    type="date"
                    onChange={(e) => handleFieldChange(e, null, t("INCORRECT"))}
                    error={
                      (userError.born_at !== "" && userError.born_at !== "empty") ||
                      (userError.born_at === "empty" && buttonClicked)
                    }
                    helperText={
                      userError.born_at === "empty" || userError.born_at === ""
                        ? ""
                        : t("INCORRECT")
                    }
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("PHONE")}</InputLabel>
                  {renderTextField(
                    t("PHONE"),
                    "phone",
                    formData.phone,
                    userError.phone,
                    t("INCORRECT"),
                    /^\d{7,10}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12} mb={2}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("EMAIL")}</InputLabel>
                  {renderTextField(
                    t("EMAIL"),
                    "email",
                    formData.email,
                    userError.email,
                    t("INCORRECT_FORMAT"),
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    isEdit
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("AVATAR")}
                  </InputLabel>
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <ImageUpload
                      error={formData.avatar_url === "" && buttonClicked}
                      url={formData.avatar_url}
                      selectedImagesInForm={formData.avatar_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          formData,
                          url,
                          "avatar_url",
                          setFormParamsNotEvent,
                          setFormErrors,
                          true
                        )
                      }
                      multi={false}
                      disabled={imageDisable}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("SELLING_DOMAINS")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={formData.domain_ids === ""}
                  >
                    <InputLabel>{t("DOMAINS")}</InputLabel>
                    <Select
                      multiple
                      sx={{ mb: 5 }}
                      labelId="domain_ids"
                      id="domain_ids"
                      label={t("DOMAINS")}
                      value={formData.domain_ids ? formData.domain_ids : []}
                      onChange={handleSelectDomains}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: " #cceceb",
                          },
                          top: "-50px",
                          bottom: "50px",
                        },
                      }}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (value) =>
                              domains?.find((domain) => domain.domain_id === value)?.domain_name
                          )
                          .join(", ")
                      }
                    >
                      {domains?.map((domain, index) => (
                        <MenuItem key={index} value={domain.domain_id}>
                          <ListItemText primary={domain.domain_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {!allFieldsOk(userError) && buttonClicked && (
                <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                <Button
                  onClick={() => {
                    setFormData("");
                    navigate("/rrpps");
                  }}
                  className="oniria-btn-cancel"
                  fullWidth
                  variant="contained"
                  sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="submit"
                  className="oniria-btn"
                  fullWidth
                  variant="contained"
                  sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                  disabled={isSending}
                >
                  {isSending ? t("SAVING") : t("SAVE")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RrppForm;
