import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getUserById } from "../../services/authenticationServices";

export function useQueryUserInfo(id) {
  return useQuery([RQ_KEY.USER_INFO, id], () => getUserById(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}
