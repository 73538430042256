import { Button, Grid, Paper, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DomainCard from "../DomainCard";
import DomainSponsors from "../DomainSponsors";
import AdditionalInfo from "./additionalInfo/AdditionalInfo";
import { Categories } from "./categories/Categories";
import { CategoriesProvider } from "./categoriesContext/CategoriesContext";
import HeaderFooter from "./headerFooter/HeaderFooter";

const StylesEditor = ({ loadingSubmit, domainId }) => {
  const { t } = useTranslation();
  const steps = [
    t("HEADER_FOOTER"),
    t("CONTENT"),
    t("ESPECIAL_CONTENT"),
    t("DOMAIN_SPONSORS"),
    t("DOMAIN_CARD"),
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Grid
      component={Paper}
      elevation={3}
      container
      rowSpacing={2}
      sx={{ padding: 2, borderRadius: 2, mb: 15, mt: 4, maxWidth: "1300px" }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: { backgroundColor: "var(--secondary-color)" },
        }}
        sx={{ mb: 2, color: "var(--primary-color)" }}
      >
        {steps.map((label, index) => (
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "var(--secondary-color)",
              },
            }}
            key={index}
            label={label}
          />
        ))}
      </Tabs>
      <Grid item xs={12} container justifyContent="flex-end">
        {activeTab !== 4 && (
          <Button
            type="submit"
            className="oniria-btn"
            sx={{ color: "#fff" }}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? t("LOADING") : t("SAVE")}
          </Button>
        )}
      </Grid>
      <CategoriesProvider>
        {activeTab === 0 && <HeaderFooter />}
        {activeTab === 1 && <Categories />}
        {activeTab === 2 && <AdditionalInfo />}
        {activeTab === 3 && <DomainSponsors domainId={domainId} />}
        {activeTab === 4 && <DomainCard domainId={domainId} />}
      </CategoriesProvider>
    </Grid>
  );
};

export default StylesEditor;
