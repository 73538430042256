import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OrdersTable from "../../../components/orders/OrdersTable";
import { Loading } from "../../../components/shared/Loading";
import { useQueryOrdersList } from "../../../hooks/queries/useQueryOrdersList";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import usePagination from "../../../hooks/usePagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EURO, formatDate, formatHours, getPremiseName } from "../../../constants/utils";
import { getOrderTotalAmount, isOrderFailed, isOrderTotalPayed } from "../../../constants/orders";
import SelectPerPage from "../../../components/shared/SelectPerPage";
import { PAGINATION_STYLES } from "../../../constants/styles";

const UserOrders = ({ userId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, perPage, changePage, changePerPage } = usePagination();
  const { data: premises } = useQueryPremisesList();

  const filters = {
    userId: userId,
    desc: "created_at",
  };

  const { data: userOrders, isLoading, error } = useQueryOrdersList(filters, page, perPage);

  const totalPages = Math.ceil(Number(userOrders?.amount ?? 0) / Number(userOrders?.perPage ?? 10));

  console.log("userOrders", userOrders);
  return (
    <Box component={Paper} sx={{ borderRadius: "20px", mb: 2, padding: 2 }}>
      <Typography variant="h7" sx={{ fontWeight: "bold" }}>
        {t("ORDERS")}
      </Typography>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Grid>
          <Typography variant="caption">{t("ERROR_LOADING_DATA")}</Typography>
        </Grid>
      ) : userOrders?.data.length === 0 ? (
        <Grid>
          <Typography variant="caption">{t("NO_DATA")}</Typography>
        </Grid>
      ) : (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
          >
            <Grid item xs={4}>
              <Typography variant="h7">
                {t("TOTAL_ORDERS")} : {userOrders?.amount || 0}
              </Typography>
            </Grid>
            {totalPages > 1 ? (
              <Grid item xs={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage text={t("ORDERS")} change={changePerPage} value={perPage} />
                </Grid>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={changePage}
                  sx={PAGINATION_STYLES}
                />
              </Grid>
            ) : null}
          </Grid>
          <TableContainer>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell>{t("EVENT_NAME")}</TableCell>
                  <TableCell>{t("PREMISE")}</TableCell>
                  <TableCell>{t("TOTAL_AMOUNT")}</TableCell>
                  <TableCell>{t("DATE")}</TableCell>
                  <TableCell>{t("PAYMENT_TYPE")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userOrders?.data?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        className="oniria-colorText"
                        variant="subtitle2"
                        sx={{ fontWeight: "bolder", cursor: "pointer" }}
                        onClick={() => navigate(`/event/${row.event_id}`)}
                      >
                        {row.event_name || row.card_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <p>{getPremiseName(row.event_premise_id || row.card_premise_id, premises)}</p>
                      <p>{row.bought_in_domain}</p>
                    </TableCell>
                    <TableCell>{EURO.format(getOrderTotalAmount(row))}</TableCell>
                    <TableCell>
                      {row.created_at
                        ? `${formatDate(row.created_at)} ${formatHours(row.created_at)}`
                        : row.first_payment_payed_at
                        ? `${formatDate(row.first_payment_payed_at)} ${formatHours(
                            row.first_payment_payed_at
                          )}`
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: isOrderFailed(row)
                          ? "#f86f6f"
                          : isOrderTotalPayed(row)
                          ? "#7de190"
                          : "#FFC300",
                      }}
                    >
                      {row.payment_type
                        ? t(row?.payment_type?.toUpperCase())
                        : t(row?.first_payment_type?.toUpperCase())}
                      {!isOrderTotalPayed(row) && !isOrderFailed(row)
                        ? ` (${t("PENDING_SECOND_PAYMENT")})`
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default UserOrders;
