import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

const AddCategoryDialog = ({ isOpen, onClose, handleAddCategory }) => {
  const { t } = useTranslation();
  const [newCategory, setNewCategory] = useState({ name: "", description: "", slug: "" });

  const handleAcceptDialog = () => {
    handleAddCategory(newCategory);
    setNewCategory({ name: "", description: "", slug: "" });
    onClose();
  };

  const handleClose = () => {
    setNewCategory({ name: "", description: "", slug: "" });
    onClose();
  };

  const handleChange = (e) => {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
          {t("ADD_CATEGORY")}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 6 }}>
          <TextField
            id="outlined-basic"
            name="name"
            label={t("NAME")}
            variant="outlined"
            sx={focusColor}
            value={newCategory.name || ""}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            name="description"
            label={t("DESCRIPTION")}
            variant="outlined"
            sx={[focusColor, { marginTop: 2 }]}
            value={newCategory.description || ""}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            name="slug"
            label={t("SLUG")}
            variant="outlined"
            sx={[focusColor, { marginTop: 2 }]}
            value={newCategory.slug || ""}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
        >
          <Button
            type="submit"
            className="oniria-btn-cancel"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleClose}
          >
            {t("CANCEL")}
          </Button>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleAcceptDialog}
          >
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCategoryDialog;
