import { AddCard, Edit, RemoveRedEyeRounded } from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import OrderButtons from "../../components/shared/OrderButtons";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { focusColor } from "../../components/shared/textFieldStyle";
import { RQ_KEY } from "../../constants/query";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import { formatDate, formatHours, isValidCardDate } from "../../constants/utils";
import { USER_STATUS } from "../../constants/variables";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryUserInfo } from "../../hooks/queries/useQueryUserInfo";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import useUserRoles from "../../hooks/useUserRoles";
import { getUsersAffiliate } from "../../services/usersServices";
import BuyCardDialog from "./BuyCardDialog";

const UserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [order, setOrder] = useState({ asc: [], desc: ["created_at"] });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { isSuperAdmin, isManager } = useUserRoles();

  const [searchParam] = useSearchParams();

  const { data: domains } = useQueryDomains();

  const breadcrumbs = [
    {
      name: t("USER_MANAGER"),
      link: ROUTES.USERS,
    },
  ];

  const handleQuerySuccess = (data) => {
    setUsers(data.data);
  };

  //BUY CARD DIALOG
  const [showCardBuyConfirmation, setShowCardBuyConfirmation] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleBuyCard = (id) => {
    setUserId(id);
    setShowCardBuyConfirmation(true);
  };

  const handleBuyClose = () => {
    setShowCardBuyConfirmation(false);
  };

  const { data: userInfo } = useQueryUserInfo(userId);
  //END BUY CARD DIALOG

  const {
    isLoading,
    isRefetching,
    data: usersData,
  } = useQuery(
    [RQ_KEY.USERS_LIST, page, perPage, search, selectedStatus, selectedDomain, order],
    () => getUsersAffiliate(page, perPage, search, selectedStatus, selectedDomain, order),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (value) => {
    setSearch(value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleStatusChange = (event) => {
    if (event && event.target) {
      setSelectedStatus(event.target.value);
      changePage(null, 1);
    }
  };
  const handleDomainChange = (event) => {
    if (event && event.target) {
      setSelectedDomain(event.target.value);
      changePage(null, 1);
    }
  };

  const getTranslatedStatus = (status) => {
    switch (status) {
      case USER_STATUS.PENDING_VALIDATE:
        return t("REQUESTED");
      case USER_STATUS.PENDING_PAYMENT:
        return t("VALIDATED");
      case USER_STATUS.VALIDATE:
        return t("PAID");
      case USER_STATUS.NO_AFFILIATED:
        return t("NOT_REQUESTED");
      default:
        return status;
    }
  };
  //Table actions
  const handleEventClick = (id) => {
    navigate(`/users/${id}`);
  };

  const handleSeeUser = (id) => {
    navigate(`/users/info/${id}`);
  };

  // navigate from home status change
  useEffect(() => {
    const status = searchParam.get("status");
    if (status === "affiliated") {
      setSelectedStatus("affiliated");
    } else if (status === "pending_payment") {
      setSelectedStatus("pending_payment");
    }
  }, []);

  //download options
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const tableHeaders = [
    t("NAME"),
    t("SURNAME"),
    t("EMAIL"),
    t("DOMAIN"),
    t("SITE"),
    t("STATUS"),
    t("CREATED_AT"),
    t("ERASMUS_EXPIRES"),
  ];
  const tableData = users?.map((user) => [
    user.user_name,
    user.user_surname,
    user.user_email,
    user.domain,
    user.premise_name,
    getTranslatedStatus(user.affiliated_status),
    user.created_at,
    user.erasmus_expires_at,
  ]);
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
    });
    doc.save("userList.pdf");
  };

  //create user options
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const handleOpenCreateUser = (e) => {
    e.stopPropagation();
    setUserAnchorEl(e.currentTarget);
  };
  const handleCloseCreateUser = (e) => {
    e.stopPropagation();
    setUserAnchorEl(null);
  };
  const isOpenUser = Boolean(userAnchorEl);
  const userPopoverId = isOpenUser ? "simple-popover-user" : undefined;

  //Pagination
  const totalPages = Math.ceil(Number(usersData?.total ?? 0) / Number(usersData?.perpage ?? 10));

  //Order
  const handleOrder = (id, type) => {
    const orderCopy = { ...order };
    const oppositeType = type === "asc" ? "desc" : "asc";
    if (orderCopy[type]?.includes(id)) {
      orderCopy[type] = orderCopy[type]?.filter((item) => item !== id);
    } else {
      orderCopy[type].push(id);
      orderCopy[oppositeType] = orderCopy[oppositeType]?.filter((item) => item !== id);
    }
    setOrder(orderCopy);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_USERS")} />
      <Grid
        container
        spacing={2}
        mb={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Grid item>
          {(isSuperAdmin || isManager) && (
            <Button
              type="submit"
              onClick={handleOpenCreateUser}
              className="oniria-btn"
              variant="contained"
              sx={{
                fontSize: "12px",
                borderRadius: 3,
              }}
            >
              {t("CREATE_USER")}
            </Button>
          )}
          <Popover
            open={isOpenUser}
            anchorEl={userAnchorEl}
            onClose={handleCloseCreateUser}
            id={userPopoverId}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              orientation="vertical"
              aria-label="vertical button group"
            >
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                onClick={() => navigate(ROUTES.CREATE_USER)}
              >
                {t("CREATE_USER")}
              </Button>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                onClick={() => navigate(ROUTES.CREATE_USER_SIMPLE)}
              >
                {t("CREATE_USER_SIMPLE")}
              </Button>
            </ButtonGroup>
          </Popover>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={handleOpenPopover}
          >
            <CloudDownloadIcon fontSize="small" />
            {t("DOWNLOAD_LIST")}
          </Button>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            id={popoverId}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              orientation="vertical"
              aria-label="vertical button group"
            >
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download csv"
              >
                <CSVLink
                  data={tableData}
                  filename={"assistants.csv"}
                  target="_blank"
                  separator={";"}
                  headers={tableHeaders}
                  className="csv-link"
                >
                  {t("CSV_FORMAT")}
                </CSVLink>
              </Button>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download pdf"
                onClick={generatePDF}
              >
                {t("PDF_FORMAT")}
              </Button>
            </ButtonGroup>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid item xs={11} sm={7} md={6} sx={{ mb: 2 }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            onChange={(event) => debounceSearch(event?.target?.value)}
            size="small"
            sx={[focusColor, { width: "75%" }]}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={6}>
          <Grid
            container
            columnSpacing={2}
            mt={1}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
          >
            <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={selectedDomain}
                  onChange={handleDomainChange}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  defaultValue="all"
                >
                  <MenuItem value="all">{t("ALL_DOMAINS")}</MenuItem>
                  {domains?.map((domain) => (
                    <MenuItem key={domain.domain_id} value={domain.domain_id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  defaultValue="all"
                >
                  <MenuItem value="all">{t("STATUS_ALL")}</MenuItem>
                  {Object.keys(USER_STATUS)?.map((status) => (
                    <MenuItem key={status} value={USER_STATUS[status]}>
                      {getTranslatedStatus(USER_STATUS[status])}
                    </MenuItem>
                  ))}
                  <MenuItem value="expired">{t("EXPIRED")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2, mt: 2 }} container justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="center">
          <Typography>
            {t("TOTAL_USERS")} {usersData?.total ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container justifyContent="flex-end">
          <Grid item>
            <SelectPerPage
              text={t("USERS")}
              change={changePerPage}
              value={perPage}
              total={usersData?.total}
            />
          </Grid>
          <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
        </Grid>
      </Grid>
      <Grid item xs={11} md={12} mb={25}>
        {isLoading || isRefetching ? (
          <Loading />
        ) : users && users.length === 0 ? (
          <Typography>{t("NO_USERS")}</Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                    <TableCell>{t("USER_AVATAR")}</TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("NAME")}
                        id="name"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("SURNAME")}
                        id="surname"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("EMAIL")}
                        id="email"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("DOMAIN")}
                        id="domain"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("STATUS")}
                        id="status"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderButtons
                        columnName={t("CREATED_AT")}
                        id="created_at"
                        order={order}
                        handleClickAsc={handleOrder}
                        handleClickDesc={handleOrder}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user, index) => (
                    <TableRow key={index} sx={{ whiteSpace: "nowrap" }}>
                      <TableCell>
                        <Avatar src={user.user_avatar_url}></Avatar>
                      </TableCell>
                      <TableCell>{user.user_name}</TableCell>
                      <TableCell>{user.user_surname}</TableCell>
                      <TableCell>{user.user_email}</TableCell>
                      <TableCell>{user.domain_name}</TableCell>
                      <TableCell>
                        {isValidCardDate(user.erasmus_expires_at) ? (
                          <div>
                            {user.affiliated_status === USER_STATUS.VALIDATE ? (
                              <Chip
                                label={getTranslatedStatus(user.affiliated_status)}
                                color="success"
                              />
                            ) : (
                              <Chip
                                label={getTranslatedStatus(user.affiliated_status)}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </div>
                        ) : (
                          t("EXPIRED")
                        )}
                      </TableCell>
                      <TableCell>
                        {formatDate(user.created_at)} {formatHours(user.created_at)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("SEE_USER_DATA")} placement="top" arrow>
                          <IconButton
                            onClick={() => handleEventClick(user.user_id)}
                            className="oniria-icon-btn"
                          >
                            <Edit fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("SEE_USER")} placement="top" arrow>
                          <IconButton
                            onClick={() => handleSeeUser(user.user_id)}
                            className="oniria-icon-btn"
                          >
                            <RemoveRedEyeRounded fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("BUY_CARD")} placement="top" arrow>
                          <IconButton
                            onClick={() => handleBuyCard(user.user_id)}
                            className="oniria-icon-btn"
                          >
                            <AddCard fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
              />
            </Grid>
          </>
        )}
      </Grid>
      <BuyCardDialog open={showCardBuyConfirmation} onClose={handleBuyClose} data={userInfo} />
    </>
  );
};

export default UserList;
