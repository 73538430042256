import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { ROUTES } from "../../constants/routes";
import { CARD_PAYMENT_OPTIONS, PAYMENT_OPTIONS } from "../../constants/variables";
import { useQueryActiveCardsDomain } from "../../hooks/queries/useQueryActiveCardsDomain";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { buyAffiliatedCard } from "../../services/usersServices";
import { useQueryUserCards } from "../../hooks/queries/useQueryUserCards";

const BuyCardDialog = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyAccept = async () => {
    try {
      const body = {
        affiliated_card_id: selectedCard,
        domain_name: selectedDomain?.domain_name,
        user_id: data?.id,
        payment_type: selectedPayment || PAYMENT_OPTIONS.CASH,
      };
      setIsLoading(true);
      await buyAffiliatedCard(body);
      toastMessageSuccess(t("CARD_BOUGHT"));
      navigate(ROUTES.USERS);
    } catch (e) {
      toastMessageError(e.response.data.error || t("ERROR_BUYING_CARD"));
    } finally {
      onClose();
      setIsLoading(false);
      refetch();
    }
  };

  const { data: domains } = useQueryDomains();
  const { data: cards } = useQueryActiveCardsDomain(selectedDomain?.domain_id);
  const { data: userCards = [], refetch } = useQueryUserCards(data?.id);

  const disabledButton = !selectedDomain || !selectedCard;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
        {t("BUY_CARD")}
      </DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <FormControl fullWidth sx={focusColor}>
          <Select
            value={selectedDomain || "all"}
            onChange={(e) => setSelectedDomain(e.target.value)}
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <MenuItem value="all">{t("SELECT_DOMAIN")}</MenuItem>
            {domains?.map((domain) => (
              <MenuItem key={domain.domain_name} value={domain}>
                {domain.domain_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={focusColor}>
          <Select
            value={selectedCard || "all"}
            onChange={(e) => setSelectedCard(e.target.value)}
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <MenuItem value="all">{t("SELECT_CARD")}</MenuItem>
            {cards
              ?.filter((card) =>
                userCards?.every((userCard) => userCard.affiliated_card_id !== card.id)
              )
              .map((card) => (
                <MenuItem key={card.id} value={card.id}>
                  {card.name} ({card.premise_name})
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={focusColor}>
          <Select
            value={selectedPayment || "all"}
            onChange={(e) => setSelectedPayment(e.target.value)}
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <MenuItem value="all">{t("SELECT_PAYMENT_ALL")}</MenuItem>
            {CARD_PAYMENT_OPTIONS.map((option, index) => (
              <MenuItem key={index} value={option}>
                {t(option.toUpperCase())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleBuyAccept}
          disabled={disabledButton || isLoading}
        >
          {isLoading ? t("SAVING") : t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyCardDialog;
