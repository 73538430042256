import { Box, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { convertDate, formatHours } from "../../constants/utils";
import { LOGOS } from "../../constants/variables";

const EventHomeCard = ({ event }) => {
  const navigate = useNavigate();

  return (
    <Card elevation={2} sx={{ padding: "10px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
        <Grid container columns={12} onClick={() => navigate(`/event/${event.id}`)}>
          <Grid item xs={2}>
            <Box
              component="img"
              alt="Letras"
              src={event.principal_url ? event.principal_url : LOGOS.NOT_FOUND}
              sx={{ height: 50, mb: 0, mt: 0, maxWidth: "90%" }}
            />
          </Grid>
          <Grid container item xs={10} direction={"row"} className="event">
            <Grid className="event-date">
              {convertDate(event.start_date)} {formatHours(event.start_date)}
            </Grid>
            <Grid className="event-name">{event.name}</Grid>
            <Grid className="event-participants">
              {event.total_tickets_sold ? event.total_tickets_sold : 0} /{" "}
              {event.total_capacity ? event.total_capacity : 0} <br /> Tickets
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
export default EventHomeCard;
