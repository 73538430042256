import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ORDER_STATUS, PAYMENT_METHODS, PURCHASE_TYPES } from "../../constants/orders";
import { RQ_KEY } from "../../constants/query";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import { getAllEvents } from "../../services/eventsServices";
import { focusColor } from "../shared/textFieldStyle";

const OrdersFilters = ({ filters, setFilters, fromCards }) => {
  const { t } = useTranslation();

  const { data: allEvents } = useQuery(
    [RQ_KEY.ALL_EVENTS, filters.premiseId],
    () => getAllEvents(filters.premiseId),
    { refetchOnWindowFocus: false }
  );

  const sortedEvents = allEvents?.sort((a, b) => a.name?.localeCompare(b.name));

  const { data: premises } = useQueryPremisesList();

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setFilters({ ...filters, search: value });
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
        <TextField
          label="Search"
          onChange={debounceSearch}
          size="small"
          sx={[focusColor, { width: "75%" }]}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent={{ xs: "flex-start", md: "flex-end" }}
      >
        {/* PREMISES */}
        <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
          <InputLabel id="demo-simple-select-label">{t("PREMISES")}</InputLabel>
          <Select
            value={filters.premiseId}
            label={t("PREMISE")}
            onChange={handleChange}
            name="premiseId"
            sx={{ fontSize: "12px", borderRadius: 3 }}
          >
            <MenuItem value="">{t("PREMISES_ALL")}</MenuItem>
            {premises?.map((premise) => (
              <MenuItem key={premise.id} value={premise.id}>
                {premise.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* EVENTS */}
        {!fromCards && (
          <FormControl>
            <Autocomplete
              id="auto-complete"
              options={sortedEvents || []}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name} ({option.premise_name})
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("EVENTS")}
                  variant="outlined"
                  sx={[
                    focusColor,
                    {
                      "& .MuiInputBase-root": {
                        fontSize: "12px",
                        borderRadius: 3,
                        minWidth: 150,
                        width: "fit-content",
                      },
                    },
                  ]}
                />
              )}
              onChange={(_, newValue) => {
                handleChange({
                  target: {
                    name: "eventId",
                    value: newValue ? newValue.id : "",
                  },
                });
              }}
              value={filters.eventId || null}
              size="small"
            />
          </FormControl>
        )}
        {/* Tarjetas */}
        {fromCards && <FormControl> {/*TODO:*/}

        </FormControl>}
        {/* Status Pedido */}
        <FormControl sx={[focusColor, { minWidth: 160 }]} size="small">
            <InputLabel id="demo-simple-select-label">{t("ORDER_STATUS")}</InputLabel>
            <Select
              value={filters.orderStatus}
              label={t("ALL")}
              onChange={handleChange}
              name="orderStatus"
              sx={{ fontSize: "12px", borderRadius: 3 }}
            >
              <MenuItem value="">{t("ALL")}</MenuItem>
              {ORDER_STATUS.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {t(type.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        {/* PURCHASE TYPE */}
        {!fromCards && (
          <FormControl sx={[focusColor, { minWidth: 160 }]} size="small">
            <InputLabel id="demo-simple-select-label">{t("PURCHASE_TYPE")}</InputLabel>
            <Select
              value={filters.purchaseType}
              label={t("PURCHASE_TYPE")}
              onChange={handleChange}
              name="purchaseType"
              sx={{ fontSize: "12px", borderRadius: 3 }}
            >
              <MenuItem value="">{t("PURCHASE_TYPE_ALL")}</MenuItem>
              {PURCHASE_TYPES.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {t(type.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* PAYMENT METHOD */}
        <FormControl sx={[focusColor, { minWidth: 160 }]} size="small">
          <InputLabel id="demo-simple-select-label">{t("PAYMENT_METHOD")}</InputLabel>
          <Select
            value={filters.paymentMethod}
            label={t("PAYMENT_METHOD")}
            onChange={handleChange}
            name="paymentMethod"
            sx={{ fontSize: "12px", borderRadius: 3 }}
          >
            <MenuItem value="">{t("PAYMENT_METHOD_ALL")}</MenuItem>
            {PAYMENT_METHODS.map((method) => (
              <MenuItem key={method.id} value={method.id}>
                {t(method.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* FROM DATE */}
        <TextField
          label={t("FROM_DATE")}
          type="date"
          onChange={handleChange}
          name="fromDate"
          value={filters.fromDate}
          sx={[focusColor, { width: 160 }]}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputProps={{
            max: new Date().toISOString().split("T")[0],
          }}
        />
        {/* TO DATE */}
        <TextField
          label={t("TO_DATE")}
          type="date"
          onChange={handleChange}
          name="toDate"
          value={filters.toDate}
          sx={[focusColor, { width: 160 }]}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputProps={{
            max: new Date().toISOString().split("T")[0],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrdersFilters;
