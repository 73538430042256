import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: "20px", fontWeight: "bold" }}>
        {t("NEED_TO_START_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("NEED_TO_START_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("EXCLUSIVE_WEB_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("EXCLUSIVE_WEB_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("REAL_INFORMATION_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("REAL_INFORMATION_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("MANAGE_SALES_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("MANAGE_SALES_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("CLIENTES_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("CLIENTES_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("EVENT_TYPES_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("EVENT_TYPES_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("CLIENTS_ATTENTION_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("CLIENTS_ATTENTION_TEXT")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("MORE_COST_TITLE")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("MORE_COST_TEXT")}
      </Typography>
    </Box>
  );
};

export default Faqs;
