import { PDFDocument } from 'pdf-lib';

// //BASE TO BLOB
const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
        } 
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: mimeType });
    return blob;
}

//descarga xml sepa por evento o por fecha
export const handleDownloadSepa = (base64, filename = 'documento') => {
    const link = document.createElement('a');
    //objeto Blob con los datos en base64
    const blob = base64ToBlob(base64, 'application/xml');
    //url
    link.href = URL.createObjectURL(blob);
    link.download = filename+'.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const handleDownloadPdfBlob = (blob, filename = 'factura.pdf') => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
};

export const handleDownloadPdf = (base64, filename = 'factura') => {
    const link = document.createElement('a');
    const blob = base64ToBlob(base64, 'application/pdf');
    link.href = URL.createObjectURL(blob);
    link.download = filename+'.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

//funcion para juntar
export const mergePdfs = async (base64Pdfs) => {
    const mergedPdf = await PDFDocument.create();

    for (const base64Pdf of base64Pdfs) {
        const pdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0));
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach(page => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: 'application/pdf' });
};