import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteOrganization } from "../../services/organizationsServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const CardOrganization = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const [open, setOpen] = useState(false);
  const id = props.organization.id;

  const handleEventClick = (id) => {
    navigate(`/organization/${id}`);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleEditButtonClick = (e) => {
    e.stopPropagation();
    navigate(`/organization/${id}?edit=true`);
  };

  const handleDeleteButtonClick = async (e) => {
    e.stopPropagation();
    try {
      await deleteOrganization(id);
      setOpen(false);
      toastMessageSuccess(t("ORGANIZATION_DELETED"));
      props.refetch();
    } catch (error) {
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  /* popper */
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePop = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popperId = openPopover ? "simple-popover" : undefined;
  return (
    <Grid item xs={11} sm={12}>
      <Card
        sx={{
          border: props.organization.is_active === 0 ? "1px solid #d5d3d3" : "1px solid #E4E4E4",
          borderRadius: "15px",
          position: "relative",
          backgroundColor: props.organization.is_active === 0 ? "var(--grey-cancelled)" : "inherit",
        }}
      >
        {props.organization.is_active === 0 && (
          <Typography
            style={{
              position: "absolute",
              top: "5px",
              right: "30px",
              color: "#ff0000",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {t("INACTIVE")}
          </Typography>
        )}
        <Box>
          <CardContent sx={{ p: 3 }}>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle1"
                  sx={{ fontWeight: "bolder" }}
                >
                  {props.organization.legal_name}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={1}>
                  {props.organization.address}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {props.organization.postal_code}, {props.organization.city},{" "}
                  {props.organization.country}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={1}>
                  {t("LEGAL_PERS")}: {props.organization.user_name}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {t("VENUES")}: {props.organization.number_of_premises}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={1.8}>
                {/* <Typography variant="subtitle2" mb={1}>
                  {t("TOTAL_SALES")}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bolder" }} mb={1}>
                  100000€
                </Typography> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4.2}
                container
                alignItems="center"
                columnGap={0.7}
                justifyContent={{ md: "flex-end" }}
              >
                <Button
                  sx={{ borderRadius: 3 }}
                  aria-label="edit"
                  className="oniria-btn"
                  onClick={handleEditButtonClick}
                >
                  <EditIcon sx={{ color: "white" }} />
                </Button>
                <Button
                  sx={{ borderRadius: 3 }}
                  aria-label="visibility"
                  className="oniria-btn"
                  onClick={() => handleEventClick(id)}
                >
                  <VisibilityIcon sx={{ color: "white" }} />
                </Button>
                {/* <Button sx={{ borderRadius: 3 }} className="oniria-btn" aria-label="statistics" title={t("STATISTICS")}>
                <QueryStatsIcon sx={{ color: "white" }} />
              </Button> */}
                <Button
                  sx={{ borderRadius: 3 }}
                  aria-label="show more"
                  className="oniria-btn"
                  onClick={handleClick}
                  aria-describedby={popperId}
                >
                  <MoreHorizIcon sx={{ color: "white" }} fontSize="medium" />
                </Button>
                <Popover
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePop}
                  id={popperId}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ButtonGroup
                    variant="contained"
                    orientation="vertical"
                    aria-label="vertical button group"
                  >
                    <Button
                      disableElevation
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/create_premise");
                      }}
                      fullWidth
                      variant="contained"
                      className="popover-btn"
                      aria-label="add premise"
                    >
                      {t("ADD_PREMISE")}
                    </Button>
                    <Button
                      disableElevation
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="popover-btn"
                      aria-label="add admin"
                    >
                      {t("ADD_ADMIN")}
                    </Button>
                    {(isSuperAdmin || isManager) && (
                      <Button
                        disableElevation
                        aria-label="delete"
                        variant="contained"
                        className="popover-btn"
                        fullWidth
                        onClick={handleClickOpen}
                      >
                        {t("REMOVE")}
                      </Button>
                    )}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
                        {t("CANCEL_ORGANIZATION")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ color: "black" }}>
                          {t("CONFIRM_CANCEL_ORGANIZATION")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          type="submit"
                          className="oniria-btn-cancel"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleClose}
                        >
                          {t("CANCEL")}
                        </Button>
                        <Button
                          type="submit"
                          className="oniria-btn"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleDeleteButtonClick}
                        >
                          {t("ACCEPT")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ButtonGroup>
                </Popover>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default CardOrganization;
