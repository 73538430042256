import { useSelector } from "react-redux";
import { ROLES } from "../constants/variables";

const useUserRoles = () => {
  const { roles, trips } = useSelector((state) => state.userInfo);

  const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
  const isAdmin = roles.includes(ROLES.ADMIN);
  const isManager = roles.includes(ROLES.MANAGER_ADMIN);
  const isScanner = roles.includes(ROLES.SCANNER);
  const isOfficeAdmin = roles.includes(ROLES.OFFICE_ADMIN);
  const isOfficeEditor = roles.includes(ROLES.OFFICE_ADMIN_CAN_EDIT);

  const canPublishTrips = trips;

  const userCanEdit = isSuperAdmin || isAdmin || isManager || isOfficeEditor;

  return {
    isSuperAdmin,
    isAdmin,
    isManager,
    isScanner,
    isOfficeAdmin,
    isOfficeEditor,
    userCanEdit,
    canPublishTrips,
  };
};

export default useUserRoles;
