import React, { useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPendingUsers, rejectPendingUser, validatePendingUser } from '../../services/usersServices';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { focusColor } from '../../components/shared/textFieldStyle';
import { toastMessageError, toastMessageSuccess } from '../../components/shared/toastMessage';

const PendingUsers = () => {

  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([])
  const [searchUsers, setSearchUsers] = useState([]);
  const [userSelectedId, setUserSelectedId] = useState("");
  const [openCheck, setOpenCheck] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCity, setSelectedCity] = useState("all");
  const [selectedPremise, setSelectedPremise] = useState("all");
  const [rejectionText, setRejectionText] = useState('');

  const breadcrumbs = [{
    name: t("USERS"),
    link: "/users"
  },
  {
    name: t("PENDING_USERS"),
    link: `/users/pending_users`
  }];

  const handleQueryUsersPendingSucces = (data) => {
    setUsers(data)
  }

  const { isLoading, refetch: refetchUsers } = useQuery("users", () => getPendingUsers(), {
    onSuccess: handleQueryUsersPendingSucces,
    refetchOnWindowFocus: false
  })
  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    const filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(search.toLowerCase())
    );
    setSearchUsers(filteredUsers);
  };
  const handleDownloadDocument = (url) => {
    window.open(url, '_blank'); // Abre el documento en una nueva pestaña para descargarlo
  };
  const handleClickOpenCheck = (id) => {
    setUserSelectedId(id)
    setOpenCheck(true);
  };

  const handleCloseCheck = (e) => {
    setOpenCheck(false);
  };

  const handleClickOpenDelete = (id) => {
    setUserSelectedId(id)
    setOpenDelete(true);
  };

  const handleCloseDelete = (e) => {
    setOpenDelete(false);
  };
  const handleRegisterButtonClick = async (e) => {
    try {
      const response = await validatePendingUser(userSelectedId)
      setOpenCheck(false)
      if (response.result === "success" && response !== undefined) {
        toastMessageSuccess(t("VERIFY_USER_SUCCESS"));
        setTimeout(() => {
          refetchUsers();
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("VERIFY_USER_ERROR"), error.response.data.error);
    }
  };
  const handleDeleteButtonClick = async (e) => { 
    try {
      const response = await rejectPendingUser(userSelectedId, {message: rejectionText})
      setOpenDelete(false);
      if (response.result === "success" && response !== undefined) {
        toastMessageSuccess(t("REJECT_USER_SUCCESS"));
        setTimeout(() => {
          refetchUsers();
        }, 1500);
      }
    }
    catch(error) {
      toastMessageError(t("REJECT_USER_ERROR"), error.response.data.error);
    }
  };
  const cities = [...new Set(users.map(user => user.city))];
  const premises = [...new Set(users.map(user => user.premise_name))];

  const handleCityChange = (event) => {
    if (event && event.target) {
      setSelectedCity(event.target.value);
    }
  };
  const handlePremiseChange = (event) => {
    if (event && event.target) {
      setSelectedPremise(event.target.value);
    }
  };
  const filteredUsers = users.filter((user) => {
    const isCityMatch = selectedCity === "all" || user.city === selectedCity;
    const isPremiseMatch = selectedPremise === "all" || user.premise_name === selectedPremise;
    return isPremiseMatch && isCityMatch;
  });
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t('HEADER_TEXT_PEMDING_USERS')} />
      <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 2 }}>
        <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField label={t("SEARCH")} value={search} onChange={handleSearchChange} size="small" sx={[focusColor, { width: "75%" }]} />
        </Grid>
        <Grid item container xs={11} sm={11} md={6} justifyContent={{ xs: "flex-start", md: "flex-end" }}>
          <FormControl sx={[focusColor, { minWidth: 120, mr: 5 }]} size="small">
            <Select value={selectedCity} onChange={handleCityChange} sx={{ fontSize: "12px", borderRadius: 3 }}>
              <MenuItem value="all">{t("ALLS_CITIES")}</MenuItem>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={[focusColor, { minWidth: 120, mr: 2 }]} size="small">
            <Select value={selectedPremise} onChange={handlePremiseChange} sx={{ fontSize: "12px", borderRadius: 3 }}>
              <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
              {premises.map(premise => (
                <MenuItem key={premise} value={premise}>{premise}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
        <Typography>{t("TOTAL_PENDING_USERS")} {search === "" ? filteredUsers.length : searchUsers.length} </Typography>
      </Grid>
      <Grid item xs={11} sm={11} md={12} mb={25}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid var(--secondary-color)' }}>
                <TableCell>{t("NAME")}</TableCell>
                <TableCell>{t("SURNAME")}</TableCell>
                <TableCell>{t("EMAIL")}</TableCell>
                <TableCell>{t("CITY")}</TableCell>
                <TableCell>{t("SITE")}</TableCell>
                <TableCell>{t("DOCUMENTS")}</TableCell>
                <TableCell>{t("ACTION")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search === "" ?
                filteredUsers.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.surname}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.city}</TableCell>
                    <TableCell>{user.premise_name}</TableCell>
                    <TableCell>
                      <IconButton aria-label="downloadDocuments" className='oniria-btn' onClick={() => handleDownloadDocument(user.affiliated_document)} >
                        <CloudDownloadIcon sx={{ color: "white" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="check" className='oniria-btn-check' sx={{ mr: 2, mb: 0.5 }} onClick={() => handleClickOpenCheck(user.id)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='oniria-btn-delete' sx={{ mb: 0.5 }} onClick={()=>handleClickOpenDelete(user.id)}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                :
                searchUsers.map((user, index) => (
                  <TableRow key={index} sx={{ cursor: "pointer" }}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.surname}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.city}</TableCell>
                    <TableCell>{user.premise_name}</TableCell>
                    <TableCell>
                      <IconButton aria-label="downloadDocuments" className='oniria-btn' onClick={() => handleDownloadDocument(user.affiliated_document)} >
                        <CloudDownloadIcon sx={{ color: "white" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="check" className='oniria-btn-check' sx={{ mr: 2, mb: 0.5 }} onClick={() => handleClickOpenCheck(user.id)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='oniria-btn-delete' sx={{ mb: 0.5 }} onClick={()=>handleClickOpenDelete(user.id)}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* VALIDATE USER */}
      <Dialog
        open={openCheck}
        onClose={handleCloseCheck}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className='oniria-colorText' sx={{ fontWeight: "bolder" }}>
          {t("VERIFY_USER")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }} >
            {t("CONFIRM_VERIFY_USER")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <Button type="submit"
            className='oniria-btn-cancel'
            variant="contained"
            size='medium'
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleCloseCheck}>
            {t("CANCEL")}
          </Button>
          <Button type="submit"
            className='oniria-btn'
            variant="contained"
            size='medium'
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleRegisterButtonClick}>
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* REJECT USER */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
          {t("DENY_USER")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }} >
            {t("CONFIRM_DENY_USER")}
          </DialogContentText>
          <DialogContentText sx={{ color: "black", mb: 2 }} >
            {t("EXPLANATION_DENY_USER")}
          </DialogContentText>
          <TextField
            label="Razón"
            type="text"
            variant="outlined"
            inputProps={{
              maxLength: 256
            }}
            fullWidth
            multiline
            rows={2}
            value={rejectionText}
            onChange={e=>setRejectionText(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <Button type="submit"
            className='oniria-btn-cancel'
            variant="contained"
            size='medium'
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleCloseDelete}>
            {t("CANCEL")}
          </Button>
          <Button type="submit"
            className='oniria-btn'
            variant="contained"
            size='medium'
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleDeleteButtonClick}>
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PendingUsers