import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ColorInput from "../../../shared/ColorInput";

import "./headerFooter.scss";

const PrincipalStyles = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const principalColor = watch("style.principal_color") || "#ffffff";
  const textColor = watch("style.text_color") || "#000000";

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h6">{t("PRINCIPAL_STYLES")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="caption">{t("PRINCIPAL_COLORS_INFO")}</Typography>
        <Typography variant="h6" mt={2}>
          {t("PRINCIPAL_COLOR")}
        </Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <Grid item container alignItems="center" gap={2}>
          <ColorInput
            value={principalColor}
            onChange={(e) => setValue("style.principal_color", e.target.value)}
          />
        </Grid>
        <Typography variant="h6">{t("TEXT_COLOR")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <Grid container alignItems="center">
          <ColorInput
            value={textColor}
            onChange={(e) => setValue("style.text_color", e.target.value)}
          />
        </Grid>
        <Typography variant="h6">{t("PREVIEW")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <Grid container sx={{ borderRadius: "8px" }}>
          <Grid item xs={4} container flexDirection="column" gap={2}>
            <Typography variant="caption">{t("BUTTON")}</Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: principalColor,
                color: textColor,
                borderRadius: 10,
                width: 100,
                "&:hover": {
                  backgroundColor: principalColor,
                  filter: "brightness(90%) !important",
                },
              }}
            >
              {t("BUY")}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrincipalStyles;
