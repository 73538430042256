const MapIcon = ({ color, size }) => (
  <svg
    viewBox="-0.5 -0.5 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      d="m5.238437500000001 12.7769375 -3.9276875 1.30925c-0.2929375 0.097625 -0.595375 -0.120375 -0.595375 -0.429125V2.5490625c0 -0.1946875 0.1245625 -0.3675 0.3093125 -0.4290625l4.21375 -1.4046249999999998m0 12.0615625 4.523125 1.5076875m-4.523125 -1.5076875 0 -12.0615625m4.523125 13.56925 4.21375 -1.4045625c0.1846875 -0.061625 0.3093125 -0.2344375 0.3093125 -0.4291875V1.3429375c0 -0.30874999999999997 -0.3024375 -0.52675 -0.5953125 -0.429125l-3.9277499999999996 1.30925m0 12.0615625V2.2230625m0 0 -4.523125 -1.5076875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    ></path>
  </svg>
);

export default MapIcon;
