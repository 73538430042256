import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryAllCards } from "../../hooks/queries/useQueryAllCards";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { focusColor } from "../shared/textFieldStyle";
import { getPremiseName } from "../../constants/utils";

const CardFilters = ({ selectedDate, handleChangeDate, selectedCard, handleChangeCard }) => {
  const { t } = useTranslation();

  const today = new Date();

  const { data: cards } = useQueryAllCards();
  const { data: premises } = useQueryPremisesList();

  useEffect(() => {
    if (cards && cards.length > 0 && !selectedCard) {
      handleChangeCard({ target: { value: cards[0].id } });
    }
  }, [cards, selectedCard, handleChangeCard]);

  return (
    <Grid mb={2} mt={2} container gap={2}>
      <FormControl sx={[focusColor, { minWidth: "100px" }]}>
        <InputLabel id="demo-select-small-label">{t("MONTH")}</InputLabel>
        <Select
          name="month"
          label={t("MONTH")}
          value={selectedDate?.getMonth() + 1 || ""}
          onChange={handleChangeDate}
          sx={[focusColor, { marginRight: 2 }]}
        >
          {Array.from({ length: 12 }, (_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {t(`MONTH_NAMES_${index}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={[focusColor, { minWidth: "100px" }]}>
        <InputLabel id="demo-select-small-label">{t("YEAR")}</InputLabel>
        <Select
          name="year"
          label={t("YEAR")}
          value={selectedDate?.getFullYear() || ""}
          onChange={handleChangeDate}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <MenuItem key={index} value={today.getFullYear() - index}>
              {today.getFullYear() - index}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {cards && (
        <FormControl sx={[focusColor, { minWidth: "120px" }]}>
          <InputLabel id="demo-select-small-label">{t("CARDS")}</InputLabel>
          <Select
            name="name"
            label={t("CARDS")}
            value={selectedCard || ""}
            onChange={handleChangeCard}
          >
            {cards.map((card) => (
              <MenuItem key={card.id} value={card.id}>
                {card.name} ({getPremiseName(card.premise_id, premises)})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

export default CardFilters;
