import PhoneIphone from "@mui/icons-material/PhoneIphone";
import WebIcon from "@mui/icons-material/Web";
import { Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CategoriesPreview from "./CategoriesPreview";
import FooterPreview from "./FooterPreview";
import NavBarPreview from "./NavBarPreview";

const Preview = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(true);
  //const [styles, setStyles] = useState({});

  const handleMobile = () => {
    setIsMobile(!isMobile);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 250) {
  //       setStyles({
  //         top: "10px",
  //         transition: "top 0.3s ease-in-out, position 0.3s ease-in-out",
  //       });
  //     } else {
  //       setStyles({});
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Grid
      item
      container
      // sx={[{ height: "fit-content", position: { xs: "relative", md: "fixed" } }, styles]}
    >
      <Grid item container alignItems="center" gap={2}>
        <Typography>Preview {isMobile ? t("MOBILE") : t("COMPUTER")}</Typography>
        <IconButton
          onClick={handleMobile}
          sx={{ backgroundColor: isMobile ? "var(--oniria-gold)" : "" }}
        >
          <PhoneIphone />
        </IconButton>
        <IconButton
          onClick={handleMobile}
          sx={{ backgroundColor: !isMobile ? "var(--oniria-gold)" : "" }}
        >
          <WebIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          border: "2px dotted var(--oniria-gold)",
          margin: "10px",
          maxHeight: isMobile ? "736px" : "736px",
          backgroundColor: "#F2F3F3",
          overflow: isMobile ? "" : "auto",
          maxWidth: isMobile ? "425px" : "1024px",
          minWidth: "425px",
          position: "relative",
          minHeight: isMobile ? "736px" : "536px",
        }}
      >
        <NavBarPreview isMobile={isMobile} />
        <CategoriesPreview isMobile={isMobile} />
        <FooterPreview isMobile={isMobile} />
      </Grid>
    </Grid>
  );
};
export default Preview;
