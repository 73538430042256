import Close from "@mui/icons-material/Close";
import { DialogContentText, FormControl, Grid, IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { createDomain, findDomainById } from "../../../services/domainsServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

function DuplicateDomainDialog({ isOpen, onClose, domainId }) {
  const { t } = useTranslation();
  const [domainName, setDomainName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(["stylesDomain", domainId], () => findDomainById(domainId), {
    refetchOnWindowFocus: false,
    onError: () => {
      onClose();
      toastMessageError(t("ERROR_FETCHING_DOMAIN"));
    },
    enabled: isOpen,
  });

  const checkError = () => {
    if (!domainName.includes(".")) {
      setError(t("DOMAIN_NAME_ERROR"));
      return true;
    }
    return false;
  };

  const handleCreateDomain = async () => {
    if (checkError()) return;
    setLoading(true);
    const dataToSend = {
      ...data,
      name: domainName,
    };
    dataToSend.style.search_bar = data.style.search_bar === 1;
    delete dataToSend?.style?.id;
    try {
      await createDomain(dataToSend);
      toastMessageSuccess(t("SUCCESS_CREATE_STYLES"));
    } catch (e) {
      toastMessageError(e.response?.data?.error ?? t("ERROR_CREATE_STYLES"));
    } finally {
      onClose();
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setDomainName("");
      setError("");
    };
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", paddingTop: 0 }}>
        {t("DUPLICATE_DOMAIN")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("DUPLICATE_DOMAIN_DESCRIPTION")}</DialogContentText>
        <FormControl sx={{ minWidth: "250px", display: "flex" }}>
          <TextField
            id="name"
            label={t("NEW_DOMAIN_NAME")}
            type="text"
            fullWidth
            sx={[focusColor, { marginTop: "20px" }]}
            onChange={(e) => {
              setDomainName(e.target.value);
              if (error) setError("");
            }}
            error={!!error}
            helperText={error}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "16px", borderRadius: 3, fontWeight: "500" }}
          onClick={handleCreateDomain}
          disabled={loading}
        >
          {loading ? t("SAVING") : t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDomainDialog;
