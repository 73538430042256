import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryCardsStatistics } from "../../hooks/queries/useQueryCardsStatistics";
import { Loading } from "../shared/Loading";
import CardGraphs from "./CardGraphs";
import CardFilters from "./CardsFilters";
import { formatDateMonthYear } from "../../constants/utils";

const CardStatistics = () => {
  const { t } = useTranslation();
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedCard, setSelectedCard] = useState(null);

  const { data, isLoading, error } = useQueryCardsStatistics(
    selectedCard,
    formatDateMonthYear(selectedDate)
  );

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    const newDate = new Date(selectedDate);
    if (name === "month") {
      newDate.setMonth(parseInt(value, 10) - 1);
    } else if (name === "year") {
      newDate.setFullYear(parseInt(value, 10));
    }
    setSelectedDate(newDate);
  };

  const handleChangeCard = (event) => {
    setSelectedCard(event.target.value);
    setSelectedDate(null);
  };

  return (
    <>
      <CardFilters
        selectedDate={selectedDate}
        handleChangeDate={handleChangeDate}
        selectedCard={selectedCard}
        handleChangeCard={handleChangeCard}
      />
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Typography>{t("ERROR_LOADING_DATA")}</Typography>
      ) : (
        <CardGraphs data={data} />
      )}
    </>
  );
};

export default CardStatistics;
