import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getOrdersList(filters, page, perPage) {
  const params = new URLSearchParams();
  if (filters.eventId) params.append("eventid", filters.eventId);
  if (filters.premiseId) params.append("premiseid", filters.premiseId);
  if (filters.purchaseType) params.append("purchasetype", filters.purchaseType);
  if (filters.fromDate) params.append("fromdate", filters.fromDate);
  if (filters.toDate) params.append("todate", filters.toDate);
  if (filters.paymentMethod) params.append("method", filters.paymentMethod);
  if (filters.search) params.append("search", filters.search);
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (filters.asc) params.append("asc", filters.asc);
  if (filters.desc) params.append("desc", filters.desc);
  if (filters.orderStatus) params.append("status", filters.orderStatus);
  if (filters.userId) params.append("userid", filters.userId);

  const { data } = await http.get(`${SERVICE.ORDERS}?${params.toString()}`);
  return data;
}
