import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryCategoriesDomain } from "../../../../hooks/queries/useQueryCategoriesDomain";

const CategoriesContext = createContext();

const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const { domainId } = useParams();

  const handleSuccessCategories = (data) => {
    setCategories(data);
  };

  useQueryCategoriesDomain(domainId, handleSuccessCategories);

  return (
    <CategoriesContext.Provider value={{ categories, setCategories }}>
      {children}
    </CategoriesContext.Provider>
  );
};

const useCategoriesContext = () => {
  return useContext(CategoriesContext);
};

export { CategoriesProvider, useCategoriesContext };
