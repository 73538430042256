import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { ROUTES } from "../../constants/routes";
import { useQueryUserInfo } from "../../hooks/queries/useQueryUserInfo";
import BuyCardDialog from "./BuyCardDialog";
import UserAffiliatedData from "./userData/UserAffiliatedData";
import UserContactData from "./userData/UserContactData";
import UserOrders from "./userData/UserOrders";
import UserTripData from "./userData/UserTripData";

const UserInfo = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [showCardBuyConfirmation, setShowCardBuyConfirmation] = useState(false);

  const handleBuyClose = () => {
    setShowCardBuyConfirmation(false);
  };

  const { data: userInfo, isLoading, error } = useQueryUserInfo(userId);

  const breadcrumbs = [
    {
      name: t("USERS"),
      link: ROUTES.USERS,
    },
    {
      name: userInfo?.name ?? "",
      link: `/users/${userId}`,
    },
  ];

  const handleExportUserInfo = () => {};

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={t("HEADER_TEXT_USER_DETAIL") + userInfo?.name}
      />
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Typography variant="h3">{t("ERROR_LOADING_DATA")}</Typography>
      ) : (
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            mb: 10,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Grid container alignItems="center">
            <Grid p={3} container>
              <Avatar alt="user_avatar" src={userInfo?.avatar} sx={{ width: 200, height: 200 }} />
              <Grid item xs={12} sm={8} container gap={2} mt={2}>
                <Chip
                  label={userInfo.is_active === 1 ? t("ACTIVE") : t("INACTIVE")}
                  color={userInfo.is_active === 1 ? "success" : "error"}
                  icon={userInfo.is_active === 1 ? <CheckCircleIcon /> : <CancelIcon />}
                  sx={{ fontSize: "1.5rem", padding: "10px" }}
                />
                <Button
                  onClick={() => setShowCardBuyConfirmation(true)}
                  className="oniria-btn"
                  variant="contained"
                  sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
                >
                  {t("BUY_CARD")}
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              sx={{ borderBottom: "2px solid black", width: "100%" }}
            >
              <Typography variant="h3">
                {userInfo?.name} {userInfo?.surname}
              </Typography>
              <Grid container gap={2} mb={1} item xs={12} sm={6} justifyContent="flex-end">
                {/* <Button
                  className="oniria-btn"
                  sx={{
                    color: "#fff",
                    borderRadius: 3,
                  }}
                  onClick={handleExportUserInfo}
                >
                  {t("EXPORT")}
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <UserContactData userInfo={userInfo} />
            <UserTripData userInfo={userInfo} />
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <UserAffiliatedData userId={userId} />
          </Grid>
          <UserOrders userId={userId} />
        </Box>
      )}
      <BuyCardDialog open={showCardBuyConfirmation} onClose={handleBuyClose} data={userInfo} />
    </>
  );
};

export default UserInfo;
