import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, ListItem, ListItemText, Paper, Tooltip } from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { newCategoryEmpty } from "../../../classes/premiseClass";
import AddCategoryDialog from "./AddCategoryDialog";
import { Subcategories } from "./Subcategories";

export const CategoryItem = ({
  item,
  index,
  handleOpenRemove,
  handleSetSubcategories,
  handleEditCategory,
  handleAddSubcategory,
  handleDeleteSubcategory,
  handleEditSubcategory,
}) => {
  const { t } = useTranslation();
  const [newCategory, setNewCategory] = useState({ ...newCategoryEmpty });

  const [selectedCategory, setSelectedCategory] = useState({});
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openAddSubcategory, setOpenAddSubcategory] = useState(false);
  const [image, setImage] = useState("");

  const handleOpenAddCategory = (item) => {
    setNewCategory({ ...item });
    setSelectedCategory(item);
    setOpenEditCategory(true);
  };

  const handleAcceptDialog = () => {
    setOpenEditCategory(false);
    handleEditCategory(selectedCategory.id, newCategory);
  };

  const handleOpenAddSubcategory = (item) => {
    setSelectedCategory(item);
    setOpenAddSubcategory(true);
  };

  const handleAcceptAddSubcategory = () => {
    setOpenAddSubcategory(false);
    handleAddSubcategory(selectedCategory.id, newCategory, image);
  };

  return (
    <>
      <Draggable draggableId={item.id || "index"} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            component={Paper}
            sx={{
              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Grid container>
              <Grid item container xs={6} alignItems="center">
                <DragIndicatorIcon sx={{ marginRight: 2 }} />
                <Grid container flexDirection="column" item xs={10}>
                  <ListItemText
                    primary={
                      <Grid container alignItems="center">
                        <img
                          style={{
                            height: "13 px",
                            width: "20px",
                            objectFit: "cover",
                            marginRight: 10,
                          }}
                          alt="Flag"
                          src={`/media/flags/es.svg`}
                        />
                        {item.name}
                      </Grid>
                    }
                    sx={{ margin: 0, display: "flex", alignItems: "center", gap: 2 }}
                    secondary={` ( /${item.slug} )` || ""}
                  />
                  {item.category_language_infos && item.category_language_infos.length > 0
                    ? item.category_language_infos.map((item, index) => {
                        const flag = item.language === "EN" ? "sh" : item?.language?.toLowerCase();
                        return (
                          <ListItemText
                            key={`category_language_infos_${index}`}
                            primary={
                              <Grid container alignItems="center">
                                <img
                                  style={{
                                    height: "13 px",
                                    width: "20px",
                                    objectFit: "cover",
                                    marginRight: 10,
                                  }}
                                  alt="Flag"
                                  src={`/media/flags/${flag}.svg`}
                                />
                                {item.name}
                              </Grid>
                            }
                            sx={{ margin: 0, display: "flex", alignItems: "center", gap: 2 }}
                            secondary={` ( /${item.slug} )` || ""}
                          />
                        );
                      })
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                {item.is_visible ? (
                  <Tooltip title={t("HOME_VISIBLE")} placement="top">
                    <IconButton
                      onClick={() => handleEditCategory(item.id, item, false)}
                      sx={{ color: "var(--secondary-color)" }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("HOME_INVISIBLE")} placement="top">
                    <IconButton
                      onClick={() => handleEditCategory(item.id, item, true)}
                      sx={{ color: "var(--secondary-color)" }}
                    >
                      <VisibilityOffIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t("EDIT")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddCategory(item)}
                    sx={{ color: "var(--secondary-color)" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("DELETE")} placement="top">
                  <IconButton
                    onClick={() => handleOpenRemove(item.id)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("ADD_SUBCATEGORY")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddSubcategory(item)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {item.subcategories && item.subcategories.length > 0 ? (
              <Subcategories
                id={item.id}
                subcategories={item.subcategories}
                handleSetSubcategories={handleSetSubcategories}
                handleDeleteSubcategory={handleDeleteSubcategory}
                handleEditSubcategory={handleEditSubcategory}
              />
            ) : null}
          </ListItem>
        )}
      </Draggable>
      <AddCategoryDialog
        newCategory={newCategory}
        setNewCategory={setNewCategory}
        isOpen={openEditCategory}
        onClose={() => {
          setOpenEditCategory(false);
          setNewCategory({ ...newCategoryEmpty });
        }}
        onAccept={handleAcceptDialog}
        title={t("EDIT_CATEGORY")}
      />
      <AddCategoryDialog
        newCategory={newCategory}
        setNewCategory={setNewCategory}
        image={image}
        setImage={setImage}
        isSubcategory
        isOpen={openAddSubcategory}
        onClose={() => {
          setOpenAddSubcategory(false);
          setNewCategory({ ...newCategoryEmpty });
          setImage("");
        }}
        onAccept={handleAcceptAddSubcategory}
        title={t("ADD_SUBCATEGORY")}
      />
    </>
  );
};
