const FavoriteIcon = ({ color, size }) => (
  <svg
    viewBox="-0.5 -0.5 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      d="M14.337187499999999 5.3546249999999995c0 1.057375 -0.40599999999999997 2.073 -1.131 2.8242499999999997 -1.6688749999999999 1.7298749999999998 -3.2876250000000002 3.53375 -5.018875 5.2009375 -0.3968125 0.3765625 -1.0263125 0.3628125 -1.4060625 -0.03075l-4.98775 -5.1701875c-1.507625 -1.5628125000000002 -1.507625 -4.0856875 0 -5.6484375 1.5224375 -1.578125 4.002625 -1.578125 5.525 0l0.181375 0.1879375 0.1811875 -0.1878125c0.7299374999999999 -0.7570625 1.7240625 -1.1840625 2.7625624999999996 -1.1840625s2.0325625 0.4269375 2.7625624999999996 1.1839375c0.7251249999999999 0.7513124999999999 1.131 1.766875 1.131 2.8241875Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1"
    ></path>
  </svg>
);

export default FavoriteIcon;
