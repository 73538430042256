import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../shared/InfoToolTip";
import { formatDate } from "../../../constants/utils";

const SELECT_OPTIONS = {
  ALL: "all",
  SELECT: "select",
};

const ExtraTicketSelect = ({ ticketsIds, eventTickets, setFormData, extraIndex }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(() => {
    return ticketsIds?.length > 0 ? SELECT_OPTIONS.SELECT : SELECT_OPTIONS.ALL;
  });

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === SELECT_OPTIONS.ALL) {
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            tickets_ids: [],
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    }
  };

  const handleCheckBoxChange = (id, isChecked) => {
    const prevTicketsIds = [...ticketsIds];
    if (isChecked) {
      prevTicketsIds.push(id);
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            tickets_ids: [...prevTicketsIds],
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    } else {
      const index = prevTicketsIds.indexOf(id);
      if (index > -1) {
        prevTicketsIds.splice(index, 1);
      }
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            tickets_ids: [...prevTicketsIds],
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    }
  };

  return (
    <Grid item xs={12} mt={1} container>
      <Grid item container>
        <FormControl>
          <Grid container alignItems="center">
            <Typography>{t("CAN_BUY_WITH")}:</Typography>
            <InfoToolTip text={t("VISIBLE_IN_EXTRAS_INFO")} />
          </Grid>
          <RadioGroup value={selectedValue} onChange={handleRadioChange} name="radio-buttons-group">
            <FormControlLabel
              value={SELECT_OPTIONS.ALL}
              control={<Radio className="radio-oniria" />}
              label={t("ALL_TICKETS")}
            />
            <FormControlLabel
              value={SELECT_OPTIONS.SELECT}
              control={<Radio className="radio-oniria" />}
              label={t("SELECT_TICKETS")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectedValue === SELECT_OPTIONS.SELECT && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormLabel>{t("TICKETS")}</FormLabel>
            </AccordionSummary>
            <AccordionDetails>
              {eventTickets
                .sort((a, b) => a.position - b.position)
                .map((ticket, index) => (
                  <Fragment key={ticket.id}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ticketsIds?.includes(ticket.id || index)}
                            onChange={(e) =>
                              handleCheckBoxChange(ticket.id || index, e.target.checked)
                            }
                            className="checkbox-oniria"
                          />
                        }
                        label={
                          <Typography>
                            {ticket.name} {ticket.start_date ? formatDate(ticket.start_date) : ""}
                            {ticket.end_date ? ` - ${formatDate(ticket.end_date)}` : ""}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Fragment>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default ExtraTicketSelect;
