import { Avatar, FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const languageOptions = [
  { value: "en", label: "English", flag: "/media/flags/us.svg" },
  { value: "es", label: "Spanish", flag: "/media/flags/es.svg" },
];

const SwitchLanguage = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  const handleChange = (event) => {
    const lng = event.target.value;
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  return (
    <FormControl sx={focusColor} size="small">
      <Select
        value={language}
        onChange={handleChange}
        sx={{
          minWidth: "150px",
        }}
      >
        {languageOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={option.flag}
                alt={`${option.label} flag`}
                style={{ marginRight: 8, width: 20, height: 20 }}
              />
              {option.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SwitchLanguage;
