import { Grid, Pagination, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import CardEvents from "../../components/events/CardEvents";
import EventFilters from "../../components/events/filters/EventFilters";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { RQ_KEY } from "../../constants/query";
import { MUI_SELECT_PRIM_COLOR, PAGINATION_STYLES } from "../../constants/styles";
import { EVENT_TABS, getEventConfigurations } from "../../constants/variables";
import { useQueryEventsDomain } from "../../hooks/queries/events/useQueryEventsDomain";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { getAllEventsFiltered } from "../../services/eventsServices";
import { clearTabDomain, setReducerDomain, setReducerTab } from "../../store/reducers/b2bReducer";

let totalEvents = 0;

const Events = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [dateFilter, setFilterDates] = useState("upcoming");
  const [cityFilter, setCityFilter] = useState("all");
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [cityNoEvents, setCityNoEvents] = useState(false);
  const [grouping, setGrouping] = useState("all");
  const [premise, setPremise] = useState("all");
  const [eventType, setEventType] = useState("no-trip");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [description, setDescription] = useState("");
  const [organization, setOrganization] = useState("all");
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [selectedTab, setSelectedTab] = useState(0);

  const { page, perPage, changePage, changePerPage } = usePagination();

  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    }
  }, []);

  useEffect(() => {
    const eventConfig = getEventConfigurations(t);
    if (eventConfig[eventType]) {
      setBreadcrumbs(eventConfig[eventType].breadcrumbs);
      setDescription(eventConfig[eventType].description);
    } else {
      setBreadcrumbs(eventConfig.event.breadcrumbs);
      setDescription(eventConfig.event.description);
    }
  }, [eventType]);

  const handleQuerySuccessFiltered = (data) => {
    totalEvents = data.total;
    const activities = data.data;
    setEventsFiltered(activities);
    setCityNoEvents(activities?.length === 0);
  };

  const handleCityFilter = (data) => {
    setCityFilter(data);
    changePage(null, 1);
  };
  const handleDateFilter = (data) => {
    setFilterDates(data);
    changePage(null, 1);
  };

  const { isLoading: isLoadingDomain, isRefetching: isRefetchingDomain } = useQueryEventsDomain(
    page,
    perPage,
    selectedTab,
    selectedDomain,
    search,
    "",
    "",
    dateFilter,
    eventType,
    handleQuerySuccessFiltered
  );

  const { refetch, isLoading } = useQuery(
    [
      RQ_KEY.ALL_EVENTS_FILTERED,
      cityFilter,
      search,
      dateFilter,
      page,
      grouping,
      premise,
      eventType,
      organization,
    ],
    () =>
      getAllEventsFiltered(
        cityFilter,
        search,
        dateFilter,
        page,
        perPage,
        grouping,
        premise,
        eventType,
        organization
      ),
    {
      onSuccess: handleQuerySuccessFiltered,
      refetchOnWindowFocus: false,
      enabled: selectedTab === EVENT_TABS.MY_EVENTS,
    }
  );

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleChangeEventType = (e) => {
    setEventType(e);
    changePage(null, 1);
  };

  const numberPages = Math.ceil(parseFloat(totalEvents) / parseFloat(perPage));

  const handleGroupingFilter = (data) => {
    setGrouping(data);
    changePage(null, 1);
    setOrganization("all");
  };
  const handlePremiseFilter = (data) => {
    setPremise(data);
    changePage(null, 1);
  };
  const handleChangeOrganization = (data) => {
    setOrganization(data);
    changePage(null, 1);
  };

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
    changePage(null, 1);
    dispatch(setReducerTab(newValue));
  };

  const handleChangeDomain = (data) => {
    setSelectedDomain(data);
    changePage(null, 1);
    dispatch(setReducerDomain(data));
  };

  const loadingEvents = isLoading || isLoadingDomain || isRefetchingDomain;

  useEffect(() => {
    dispatch(clearTabDomain());
  }, []);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={description} />
      <Grid item xs={11} sm={12} container justifyContent="center">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{
            mb: 2,
            color: "var(--secondary-color)",
          }}
        >
          <Tab label={t("EVENTS_TABS_MY_EVENTS")} sx={MUI_SELECT_PRIM_COLOR} />
          <Tab label={t("EVENTS_TABS_B2B")} sx={MUI_SELECT_PRIM_COLOR} />
        </Tabs>
      </Grid>
      <Grid
        item
        xs={11}
        sm={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <EventFilters
          isEventsPath
          onSearchChange={debounceSearch}
          grouping={grouping}
          onGroupingFilter={handleGroupingFilter}
          premise={premise}
          onPremiseFilter={handlePremiseFilter}
          cityFilter={cityFilter}
          onCityFilter={handleCityFilter}
          dateFilter={dateFilter}
          onDateFilter={handleDateFilter}
          eventType={eventType}
          onEventFilter={handleChangeEventType}
          organization={organization}
          onOrganizationFilter={handleChangeOrganization}
          selectedTab={selectedTab}
          selectedDomain={selectedDomain}
          onDomainFilter={handleChangeDomain}
        />
      </Grid>
      {totalEvents > 0 && (
        <Grid
          item
          xs={11}
          sm={12}
          container
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={"center"}
          pb={2}
          gap={{ xs: 2, sm: 0 }}
        >
          <Typography>
            {breadcrumbs[0]?.name} : {totalEvents}
          </Typography>
          {numberPages >= 1 && (
            <Grid item xs={12} md={8} container justifyContent="flex-end">
              <Grid item>
                <SelectPerPage text={t("EVENTS")} change={changePerPage} value={perPage} />
              </Grid>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </Grid>
      )}
      {cityNoEvents ? (
        <Grid ml={3}>{t("EVENTS_NO_FOUND")}</Grid>
      ) : loadingEvents ? (
        <Loading />
      ) : (
        <Grid
          container
          rowSpacing={2}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
        >
          {eventsFiltered?.map((trip, i) => (
            <CardEvents event={trip} key={i} refetch={refetch} />
          ))}
        </Grid>
      )}
      {numberPages > 1 && (
        <Pagination
          count={numberPages}
          sx={{
            mb: 20,
            ...PAGINATION_STYLES,
          }}
          page={page}
          onChange={changePage}
        />
      )}
    </>
  );
};

export default Events;
