import { useQuery } from "react-query";
import {
  getMonthStatisticsGeneralNet,
  getMonthStatisticsGeneralOrders,
  getMonthStatisticsGeneralPremise,
  getMonthStatisticsGeneralRefunded,
  getMonthStatisticsGeneralTickets,
  getMonthStatisticsGeneralTotal,
  getMonthStatisticsSalesExtras,
  getMonthStatisticsSalesExtrasCons,
  getMonthStatisticsSalesHour,
  getMonthStatisticsSalesMethod,
  getMonthStatisticsSalesPromo,
  getMonthStatisticsSalesTicketCons,
  getMonthStatisticsSalesTickets,
  getMonthStatisticsSalesWeek,
  getMonthStatisticsUsersAge,
  getMonthStatisticsUsersCard,
  getMonthStatisticsUsersGender,
  getMonthStatisticsUsersNationality,
} from "../services/utilsServices";

const useMonthStatistics = (month, selectedDomain) => {
  const isEnabled = !!month;

  // General queries
  const generalTotalQuery = useQuery(
    ["generalTotal", month, selectedDomain],
    () => getMonthStatisticsGeneralTotal(month, selectedDomain),
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
    }
  );

  const generalPremiseQuery = useQuery(
    ["generalPremise", month, selectedDomain],
    () => getMonthStatisticsGeneralPremise(month, selectedDomain),
    {
      enabled: isEnabled && (generalTotalQuery.isSuccess || generalTotalQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const generalNetQuery = useQuery(
    ["generalNet", month, selectedDomain],
    () => getMonthStatisticsGeneralNet(month, selectedDomain),
    {
      enabled: isEnabled && (generalPremiseQuery.isSuccess || generalPremiseQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const generalOrdersQuery = useQuery(
    ["generalOrders", month, selectedDomain],
    () => getMonthStatisticsGeneralOrders(month, selectedDomain),
    {
      enabled: isEnabled && (generalNetQuery.isSuccess || generalNetQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const generalRefundedQuery = useQuery(
    ["generalRefunded", month, selectedDomain],
    () => getMonthStatisticsGeneralRefunded(month, selectedDomain),
    {
      enabled: isEnabled && (generalOrdersQuery.isSuccess || generalOrdersQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const generalTicketsQuery = useQuery(
    ["generalTickets", month, selectedDomain],
    () => getMonthStatisticsGeneralTickets(month, selectedDomain),
    {
      enabled: isEnabled && (generalRefundedQuery.isSuccess || generalRefundedQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  // Users queries
  const userStatsGenderQuery = useQuery(
    ["userStatsGender", month, selectedDomain],
    () => getMonthStatisticsUsersGender(month, selectedDomain),
    {
      enabled: isEnabled && (generalTicketsQuery.isSuccess || generalTicketsQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const userStatsCardQuery = useQuery(
    ["userStatsCard", month, selectedDomain],
    () => getMonthStatisticsUsersCard(month, selectedDomain),
    {
      enabled: isEnabled && (userStatsGenderQuery.isSuccess || userStatsGenderQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const userStatsAgeQuery = useQuery(
    ["userStatsAge", month, selectedDomain],
    () => getMonthStatisticsUsersAge(month, selectedDomain),
    {
      enabled: isEnabled && (userStatsCardQuery.isSuccess || userStatsCardQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const userStatsNationalityQuery = useQuery(
    ["userStatsNationality", month, selectedDomain],
    () => getMonthStatisticsUsersNationality(month, selectedDomain),
    {
      enabled: isEnabled && (userStatsAgeQuery.isSuccess || userStatsAgeQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  // Sales queries
  const salesStatsTicketsQuery = useQuery(
    ["salesStatsTickets", month, selectedDomain],
    () => getMonthStatisticsSalesTickets(month, selectedDomain),
    {
      enabled:
        isEnabled && (userStatsNationalityQuery.isSuccess || userStatsNationalityQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsExtrasQuery = useQuery(
    ["salesStatsExtras", month, selectedDomain],
    () => getMonthStatisticsSalesExtras(month, selectedDomain),
    {
      enabled: isEnabled && (salesStatsTicketsQuery.isSuccess || salesStatsTicketsQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsPaymentMethodQuery = useQuery(
    ["salesStatsPaymentMethod", month, selectedDomain],
    () => getMonthStatisticsSalesMethod(month, selectedDomain),
    {
      enabled: isEnabled && (salesStatsExtrasQuery.isSuccess || salesStatsExtrasQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsPromoCodeQuery = useQuery(
    ["salesStatsPromoCode", month, selectedDomain],
    () => getMonthStatisticsSalesPromo(month, selectedDomain),
    {
      enabled:
        isEnabled &&
        (salesStatsPaymentMethodQuery.isSuccess || salesStatsPaymentMethodQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsHourQuery = useQuery(
    ["salesStatsHour", month, selectedDomain],
    () => getMonthStatisticsSalesHour(month, selectedDomain),
    {
      enabled:
        isEnabled && (salesStatsPromoCodeQuery.isSuccess || salesStatsPromoCodeQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsWeekDayQuery = useQuery(
    ["salesStatsWeekDay", month, selectedDomain],
    () => getMonthStatisticsSalesWeek(month, selectedDomain),
    {
      enabled: isEnabled && (salesStatsHourQuery.isSuccess || salesStatsHourQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsTicketConsumQuery = useQuery(
    ["salesStatsTicketConsum", month, selectedDomain],
    () => getMonthStatisticsSalesTicketCons(month, selectedDomain),
    {
      enabled: isEnabled && (salesStatsWeekDayQuery.isSuccess || salesStatsWeekDayQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  const salesStatsExtrasConsumQuery = useQuery(
    ["salesStatsExtrasConsum", month, selectedDomain],
    () => getMonthStatisticsSalesExtrasCons(month, selectedDomain),
    {
      enabled:
        isEnabled && (salesStatsTicketConsumQuery.isSuccess || salesStatsTicketConsumQuery.isError),
      refetchOnWindowFocus: false,
    }
  );

  // Global loading and error states
  const isLoading = [
    generalTotalQuery,
    generalPremiseQuery,
    generalNetQuery,
    generalOrdersQuery,
    generalRefundedQuery,
    generalTicketsQuery,
    userStatsGenderQuery,
    userStatsCardQuery,
    userStatsAgeQuery,
    userStatsNationalityQuery,
    salesStatsTicketsQuery,
    salesStatsExtrasQuery,
    salesStatsPaymentMethodQuery,
    salesStatsPromoCodeQuery,
    salesStatsHourQuery,
    salesStatsWeekDayQuery,
    salesStatsTicketConsumQuery,
    salesStatsExtrasConsumQuery,
  ].some((query) => query.isLoading);

  const isError = [
    generalTotalQuery,
    generalPremiseQuery,
    generalNetQuery,
    generalOrdersQuery,
    generalRefundedQuery,
    generalTicketsQuery,
    userStatsGenderQuery,
    userStatsCardQuery,
    userStatsAgeQuery,
    userStatsNationalityQuery,
    salesStatsTicketsQuery,
    salesStatsExtrasQuery,
    salesStatsPaymentMethodQuery,
    salesStatsPromoCodeQuery,
    salesStatsHourQuery,
    salesStatsWeekDayQuery,
    salesStatsTicketConsumQuery,
    salesStatsExtrasConsumQuery,
  ].some((query) => query.isError);

  return {
    statistics: {
      netIncome: generalNetQuery.data?.netIncome,
      numberOrders: generalOrdersQuery.data?.numberOrders,
      totalSales: generalTotalQuery.data?.totalSales,
      totalTickets: generalTicketsQuery.data?.totalTickets,
      extras: salesStatsExtrasQuery.data?.extras,
      tickets: salesStatsTicketsQuery.data?.tickets,
      salesByGender: userStatsGenderQuery.data?.salesByGender,
      numberRefundedOrders: generalRefundedQuery.data?.numberRefundedOrders,
      salesByCard: userStatsCardQuery.data?.salesByCard,
      ticketsByAge: userStatsAgeQuery.data?.ticketsByAge,
      assistantsByNationality: userStatsNationalityQuery.data?.assistantsByNationality,
      salesByPaymentMethod: salesStatsPaymentMethodQuery.data?.salesByPaymentMethod,
      salesByPromoCode: salesStatsPromoCodeQuery.data?.salesByPromoCode,
      totalPremiseCommissions: generalPremiseQuery.data?.premiseCommissions,
      purchasesByWeekDay: salesStatsWeekDayQuery.data?.purchasesByWeekDay,
      purchasesByHour: salesStatsHourQuery.data?.purchasesByHour,
      ticketConsumitionsByHour: salesStatsTicketConsumQuery.data?.ticketConsumitionsByHour,
      extraConsumitionsByHour: salesStatsExtrasConsumQuery.data?.extraConsumitionsByHour,
    },
    isLoading,
    isError,
  };
};

export default useMonthStatistics;
