import { Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { focusColor } from '../../shared/textFieldStyle.jsx';

const BillingData = (props) => {
    const { t } = useTranslation();
    const { buttonClicked } = props

    return (
        <>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("BILLING_DATA")}
            </InputLabel>
            <Grid container columnSpacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={8}>
                    <TextField
                        onChange={e => props.checkField(props.classForm, 'fiscal_name', e, /^(?!.*[|\\#·$~%¬/()=^[\]*{}]).{1,50}$/, t("MAX_50"), false, props.formParams, props.setErrors)}
                        margin="normal"
                        required
                        fullWidth
                        value={props.formData.fiscal_name}
                        id="fiscal_name"
                        name="fiscal_name"
                        label={t("FISCAL_NAME")}
                        autoComplete="legal_name"
                        disabled={props.isDisabled}
                        error={(props.errors.fiscal_name !== "" && props.errors.fiscal_name !== "empty") ||(buttonClicked && props.errors.fiscal_name === "empty")}
                        helperText={props.errors.fiscal_name !== 'empty' ? props.errors.fiscal_name : ''}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        onChange={e => props.checkField(props.classForm, 'cif', e, /^[A-HJ-NP-Z][0-9]{8}$/i, t("INCORRECT"), false, props.formParams, props.setErrors)}
                        margin="normal"
                        required
                        fullWidth
                        id="cif"
                        name="cif"
                        value={props.formData.cif}
                        label="CIF"
                        autoComplete="cif"
                        disabled={props.isDisabled}
                        error={(props.errors.cif !== "" && props.errors.cif !== "empty") || (buttonClicked && props.errors.cif === "empty")}
                        helperText={props.errors.cif !== 'empty' ? props.errors.cif : ''}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        // onChange={e => props.checkField(props.classForm, 'iban', e, /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,30}$/i, t("INCORRECT"), false, props.formParams, props.setErrors)}
                        onChange={e => props.formController.checkIban(props.classForm, e)}
                        margin="normal"
                        required
                        fullWidth
                        id="iban"
                        name="iban"
                        label="IBAN"
                        value={props.formData.iban}
                        autoComplete="iban"
                        disabled={props.isDisabled}
                        error={(props.errors.iban !== "" && props.errors.iban !== "empty") || (buttonClicked && props.errors.iban === "empty")}
                        helperText={props.errors.iban !== 'empty' ? props.errors.iban : ''}
                        sx={focusColor}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default BillingData