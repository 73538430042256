import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../components/shared/Loading";
import {
  compareDates,
  convertDate,
  formatCurrency,
  formatHours,
  getOneWeekFromToday,
} from "../../constants/utils";
import { useQueryOrdersList } from "../../hooks/queries/useQueryOrdersList";

const BillingHome = () => {
  const { t } = useTranslation();
  const fromDate = getOneWeekFromToday();

  const filters = {
    fromDate: fromDate,
    desc: "created_at",
    orderStatus: "payed_succesfull",
  };

  const {
    data: bills,
    isLoading,
    isRefetching: refetchingBills,
  } = useQueryOrdersList(filters, 1, 10);

  const checkIsFirstPayment = (order) => {
    return compareDates(order.first_payment_payed_at, order.fechaEmision);
  };

  return (
    <Card elevation={2}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isLoading ? (
          <Loading />
        ) : bills?.data && bills?.data?.length > 0 ? (
          <>
            <Grid container>
              {bills?.data?.map((order, index) => (
                <Paper
                  key={`${order.transaction_id}-${index}`}
                  sx={{
                    p: 2,
                    m: 2,
                    margin: "auto",
                    width: "100%",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    columns={12}
                    sx={{ borderBottom: "1px solid #ededed" }}
                  >
                    <Grid item xs={3} container direction="column" spacing={2}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "1rem", md: "0.8rem" },
                        }}
                        component="div"
                      >
                        {convertDate(
                          checkIsFirstPayment(order) ? order.first_payment_payed_at : order.payed_at
                        )}{" "}
                        {formatHours(
                          checkIsFirstPayment(order) ? order.first_payment_payed_at : order.payed_at
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.8rem", md: "0.8rem" },
                        }}
                        color="text.secondary"
                      >
                        ID:{" "}
                        {checkIsFirstPayment(order)
                          ? order.first_payment_transaction_id
                          : order.transaction_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} container direction="column" spacing={2}>
                      <Typography
                        gutterBottom
                        component="div"
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.8rem", md: "0.8rem" },
                        }}
                      >
                        {order.email}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.7rem", md: "0.7rem" },
                        }}
                        gutterBottom
                      >
                        {order.event_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {checkIsFirstPayment(order) && (
                        <Grid item xs={6} container direction="column" spacing={2}>
                          {t("FIRST_PAYMENT")}
                        </Grid>
                      )}
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          textAlign: "right",
                          fontSize: { xs: "0.9rem", md: "0.9rem" },
                        }}
                        component="div"
                      >
                        {formatCurrency(
                          checkIsFirstPayment(order) ? order.first_payment_amount : order.amount
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>
          </>
        ) : (
          !isLoading &&
          !refetchingBills && (
            <Grid container justifyContent="center">
              <Typography>{t("NO_RESULTS")}</Typography>
            </Grid>
          )
        )}
      </Box>
    </Card>
  );
};
export default BillingHome;
