import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { focusColor } from "./textFieldStyle";
import { useTranslation } from "react-i18next";

const SelectPerPage = ({ text, change, value, total }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center" columnGap={2}>
      <Typography>
        {text} {t("PER_PAGE")}:
      </Typography>
      <FormControl sx={[focusColor]} size="small">
        <Select sx={{ fontSize: "12px", borderRadius: 3 }} onChange={change} value={value}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          {total && <MenuItem value={parseInt(total)}>{t("ALL")}</MenuItem>}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SelectPerPage;
