import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getCardsStatistics } from "../../services/cardsServices";

export function useQueryCardsStatistics(affiliatedCardId, month) {
  return useQuery(
    [RQ_KEY.CARDS_STATISTICS, affiliatedCardId, month],
    () => getCardsStatistics(affiliatedCardId, month),
    {
      refetchOnWindowFocus: false,
    }
  );
}
