const HomeIcon = ({ color, size }) => (
  <svg
    viewBox="-0.5 -0.5 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      d="M6.1325625 11.578125v-2.0511874999999997c0 -0.75525 0.6121875 -1.3674374999999999 1.3674374999999999 -1.3674374999999999h0c0.75525 0 1.3674374999999999 0.6121875 1.3674374999999999 1.3674374999999999v2.0511874999999997"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    ></path>
    <path
      d="m0.6628125 4.740937499999999 6.6537500000000005 -3.3268750000000002c0.11549999999999999 -0.057749999999999996 0.251375 -0.057749999999999996 0.36687499999999995 0l6.6537500000000005 3.3268750000000002"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    ></path>
    <path
      d="M12.96975 6.7920625v5.4697499999999994c0 0.75525 -0.6121875 1.3675000000000002 -1.3674374999999999 1.3675000000000002H3.3976875c-0.75525 0 -1.3674374999999999 -0.61225 -1.3674374999999999 -1.3675000000000002v-5.4697499999999994"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    ></path>
  </svg>
);

export default HomeIcon;
