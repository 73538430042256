import { Close, CurrencyExchange } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { Chip, Grid, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, formatHours } from "../../../constants/utils";

const MobileAssistants = ({
  currentAssistants,
  isCompletePayment,
  assistantArrived,
  handleClickOpenCheck,
  handleRowClick,
}) => {
  const { t } = useTranslation();

  return currentAssistants.map((assistant, index) => (
    <Grid
      key={index}
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: "2px solid var(--color-title-grey)",
        padding: "10px",
      }}
    >
      <Grid item xs={2}>
        {assistant.refunded_at ? (
          <Grid container sx={{ cursor: "default" }}>
            <CurrencyExchange sx={{ color: "var(--secondary-color)", fontSize: "2rem" }} />
          </Grid>
        ) : isCompletePayment(assistant) ? (
          assistantArrived(assistant) ? (
            <IconButton
              disabled
              sx={{
                backgroundColor: "var(--green-success)",
                "&.Mui-disabled": {
                  backgroundColor: "var(--green-success)",
                  color: "#fff",
                },
              }}
            >
              <CheckIcon sx={{ color: "#fff" }} />
            </IconButton>
          ) : (
            <IconButton
              disabled={
                assistant.extras.every((extra) => extra.extra_consumed_at !== null) &&
                assistant.consumed_at !== null
              }
              aria-label="check"
              className={
                assistant.extras.every((extra) => extra.extra_consumed_at !== null) &&
                assistant.consumed_at !== null
                  ? "oniria-btn-check-validated"
                  : (assistant.extras.some((extra) => extra.extra_consumed_at !== null) &&
                      assistant.extras.length > 0) ||
                    assistant.consumed_at !== null
                  ? "oniria-btn-icon-gold"
                  : "oniria-btn-check"
              }
              onClick={() => handleClickOpenCheck(assistant)}
            >
              <CheckIcon />
            </IconButton>
          )
        ) : (
          <Chip
            label={t("PENDING_PAYMENT")}
            color="error"
            icon={<Close sx={{ fontSize: "1.5rem" }} />}
          />
        )}
      </Grid>
      {/* USER INFO */}
      <Grid item xs={8}>
        <Typography variant="body1" fontWeight="bold">
          {assistant.client_name ? assistant.client_name : assistant.buyer_name}{" "}
          {assistant.client_surname ? assistant.client_surname : assistant.buyer_surname}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {assistant.ticket_name}
        </Typography>
        {assistant.extras && assistant.extras.length > 0 ? (
          <Typography variant="body2">
            {assistant.extras.map((extra, index) => (
              <Fragment key={index}>
                {extra.extra_name}
                {index < assistant.extras.length - 1 && <br />}
              </Fragment>
            ))}
          </Typography>
        ) : null}
        {assistant.consumed_at && (
          <Typography variant="caption" color="textSecondary">
            {t("VALIDATED_AT")}: {formatDate(assistant.consumed_at)}{" "}
            {formatHours(assistant.consumed_at)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <IconButton
          onClick={() => handleRowClick(assistant)}
          className="oniria-btn"
          sx={{ color: "#fff" }}
        >
          <ConfirmationNumberOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  ));
};

export default MobileAssistants;
