import { Grid } from "@mui/material";
import Preview from "../preview/Preview";
import FooterStyles from "./FooterStyles";
import HeaderStyles from "./HeaderStyles";
import PrincipalStyles from "./PrincipalStyles";
import "./headerFooter.scss";

const HeaderFooter = () => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={12} md={6}>
        <Preview />
      </Grid>
      <Grid item xs={12} md={6}>
        <PrincipalStyles />
        <HeaderStyles />
        <FooterStyles />
      </Grid>
    </Grid>
  );
};
export default HeaderFooter;
