import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import LanguageTabs from "../../../events/languages/LanguageTabs";
import { focusColor } from "../../../shared/textFieldStyle";

const CardName = ({ formData, handleChange, errors, handleChangeLanguage }) => {
  const { t } = useTranslation();
  const { affiliated_card_language_infos } = formData;

  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);

  return (
    <>
      {affiliated_card_language_infos && affiliated_card_language_infos.length > 0 ? (
        <Grid item xs={12}>
          <LanguageTabs
            event_languages_info={affiliated_card_language_infos || []}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
          <TextField
            sx={focusColor}
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            autoComplete="name"
            autoFocus
            label={t("NAME")}
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
        ) : (
          <TextField
            sx={focusColor}
            margin="normal"
            fullWidth
            id="name"
            name="name"
            autoComplete="name"
            label={t("NAME")}
            value={
              affiliated_card_language_infos.find((info) => info.language === selectedLanguage)
                ?.name || ""
            }
            onChange={(e) => handleChangeLanguage(e, selectedLanguage, "name")}
          />
        )}
      </Grid>
    </>
  );
};

export default CardName;
