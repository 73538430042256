import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import PrincipalInformation from "../../components/whiteLabel/PrincipalInformation";
import StylesEditor from "../../components/whiteLabel/appStyles/StylesEditor";
import { domainSchema } from "../../schemas/styles";
import { findDomainById, updateDomain } from "../../services/domainsServices";

const AppStyles = () => {
  const { t } = useTranslation();
  const { domainId } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const breadcrumbs = [
    {
      name: t("DOMAINS_MANAGER"),
      link: "/domains",
    },
    {
      name: t("STYLES"),
      link: "/styles",
    },
  ];

  const formMethods = useForm({
    resolver: zodResolver(domainSchema),
    mode: "onBlur",
  });
  const { handleSubmit, reset } = formMethods;

  const handleSetStyles = (data) => {
    if (data.style?.reel_images_url) {
      data.style.reel_images_url = data.style.reel_images_url.split(",");
    }
    reset(data);
  };

  const { isLoading, isRefetching, refetch, data } = useQuery(
    ["stylesDomain"],
    () => findDomainById(domainId),
    {
      onSuccess: handleSetStyles,
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      await updateDomain(domainId, data);
      refetch();
      toastMessageSuccess(t("SUCCESS_UPDATE_STYLES"));
    } catch (e) {
      console.error("Error al enviar formulario estilos", e);
      toastMessageError(e.response.data.error || t("ERROR_UPDATE_STYLES"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario estilos", errors);
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={t("DOMAIN_DESCRIPTION_1") + data?.name + t("DOMAIN_DESCRIPTION_2")}
      />
      {isLoading || isRefetching ? (
        <Loading />
      ) : data ? (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrincipalInformation />
            <StylesEditor loadingSubmit={loadingSubmit} domainId={domainId} />
          </form>
        </FormProvider>
      ) : (
        <>
          <Typography variant="h6">{t("NO_STYLES_FOUND")}</Typography>
        </>
      )}
    </>
  );
};

export default AppStyles;
