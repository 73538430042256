import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { focusColor } from './textFieldStyle'

const TimePicker = ({ onTimeChange, initialTime, disabled, error, helperText}) => {
  const [selectedTime, setSelectedTime] = useState(initialTime);

  const handleTimeChange = (event) => {
    const time = event.target.value
    setSelectedTime(time)
    onTimeChange(time)
  };

  return (

    <TextField
      onChange={handleTimeChange}
      type="time"
      margin="normal"
      required
      size='small'
      id="timepicker"
      value={selectedTime}
      autoComplete="start_date_time"
      sx={focusColor}
      disabled={disabled}
      error={error}
      helperText={error ? helperText : ''}
    />
  );
};

export default TimePicker;