import { Checkbox, DialogContentText, FormControlLabel, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PERMISSIONS = {
  EDIT: "edit",
  ASSIGN: "assign",
};

function PermissionsDomainDialog({
  isOpen,
  onClose,
  onAccept,
  selectedUser,
  setSelectedUser,
  isDelete,
}) {
  const { t } = useTranslation();
  const [assignPermission, setAssignPermission] = useState(() => (isDelete ? true : false));

  const handleChangePermissions = (event) => {
    const { value, checked } = event.target;
    if (value === PERMISSIONS.EDIT) {
      setSelectedUser({
        ...selectedUser,
        can_edit: checked,
      });
    } else if (value === PERMISSIONS.ASSIGN) {
      setAssignPermission(!assignPermission);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("USER_DOMAIN_PERMISSIONS")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isDelete ? t("DELETE_PERMISSIONS_INFO") : t("GIVE_PERMISSIONS_INFO")}
        </DialogContentText>
        <Grid container flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                name="permissions"
                checked={selectedUser?.can_edit === 1 || selectedUser?.can_edit === true}
                onChange={handleChangePermissions}
                value={PERMISSIONS.EDIT}
                className="checkbox-oniria"
              />
            }
            label={t("EDIT_PERMISSION")}
          />
          {isDelete && (
            <FormControlLabel
              control={
                <Checkbox
                  name="permissions"
                  checked={assignPermission}
                  onChange={() => setAssignPermission(!assignPermission)}
                  value={PERMISSIONS.ASSIGN}
                  className="checkbox-oniria"
                />
              }
              label={t("GIVE_ASSIGN_PERMISSIONS")}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={(e) => onAccept(e, assignPermission)}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PermissionsDomainDialog;
