import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ICONS_MAP } from "../../../../constants/styles";

const NavBarPreview = ({ isMobile }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const header_color = useWatch({ control, name: "style.header_color" });
  const logo_position = useWatch({ control, name: "style.logo_position" });
  const logo_url = useWatch({ control, name: "style.logo_url" });
  const header_type = useWatch({ control, name: "style.header_type" });
  const header_icons_color = useWatch({ control, name: "style.header_icons_color" });
  const border_header = useWatch({ control, name: "style.border_header" });
  const header_icons_string = useWatch({ control, name: "style.header_icons" });
  const appFont = useWatch("style.font_text");

  const [headerIcons, setHeaderIcons] = useState([]);

  useEffect(() => {
    const icons = header_icons_string?.split(",") || [];
    setHeaderIcons(icons);
  }, [header_icons_string]);

  const handleChangeIconsPosition = (result) => {
    if (!result.destination) return;
    const items = Array.from(headerIcons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setHeaderIcons(items);
    setValue("style.header_icons", items.join(","));
  };

  const renderIcon = (icon) => {
    const IconComponent = ICONS_MAP[icon];
    return IconComponent && (!isMobile || icon === "profile") ? (
      <IconComponent key={icon} color={header_icons_color} size={"1.3rem"} />
    ) : null;
  };

  return (
    <Grid
      container
      sx={{
        position: "relative",
        borderBottom:
          border_header !== "" ? `3px solid ${border_header}` : "2px dotted var(--oniria-gold)",
        height: isMobile ? "90px" : "100px",
        backgroundColor: header_color,
        marginBottom: 0,
        "&::before": {
          backgroundColor: header_type === "middleCircle" && header_color,
          width: "150px",
          height: isMobile ? "116px" : "105px",
        },
      }}
      className={header_type}
    >
      <Grid
        item
        xs={12}
        container
        sx={{ justifyContent: logo_position === "left" ? "flex-start" : "center" }}
      >
        <Grid
          item
          xs={2}
          sx={{
            backgroundImage: `url(${logo_url})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            margin: "10px",
            zIndex: 1,
          }}
        />
      </Grid>
      <Grid item xs={10} sx={{ position: "absolute", right: "10px", top: "30%" }}>
        <DragDropContext onDragEnd={handleChangeIconsPosition}>
          <Droppable droppableId="navbar-icons-list" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", gap: 4 }}
              >
                {headerIcons.length > 0 && headerIcons[0] !== ""
                  ? headerIcons.map((icon, index) => (
                      <Draggable key={icon} draggableId={icon} index={index}>
                        {(provided, _) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "nowrap",
                              flexDirection: "row",
                              padding: 2,
                            }}
                          >
                            {!isMobile || icon === "profile" ? (
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ backgroundColor: header_icons_color, mr: 1 }}
                              />
                            ) : null}
                            {renderIcon(icon)}
                            {!isMobile ? (
                              <Typography
                                fontFamily={`${appFont} !important`}
                                sx={{ fontSize: "1.3rem", color: header_icons_color, ml: 1 }}
                              >
                                {t(icon)}
                              </Typography>
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

export default NavBarPreview;
