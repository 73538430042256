import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants/variables";
import { getAllGrouping, getOrganizations } from "../../../services/organizationsServices";
import { getPremisesList } from "../../../services/premisesServices";
import SelectFilter from "../../events/filters/filter/SelectFilter";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";

const ChoseEntity = ({ grouping, organization, premise, handleChooseEntity }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);

  const { t } = useTranslation();

  const { data: groups = [] } = useQuery("groupings", () => getAllGrouping(), {
    refetchOnWindowFocus: false,
  });

  const { data: organizations = [] } = useQuery(
    ["organizations", grouping],
    () => getOrganizations(grouping),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: premises = [] } = useQueryPremisesList(organization, grouping);

  const handleChangeGrouping = (id) => {
    handleChooseEntity("grouping_id", id);
  };

  const handleChangeOrganization = (id) => {
    handleChooseEntity("organization_id", id);
  };

  const handleChangePremise = (id) => {
    handleChooseEntity("premise_id", id);
  };

  const premisesFormat = premises.map((premise) => ({
    id: premise.id,
    name: premise.name,
  }));

  const organizationsFormat = organizations?.map((organization) => ({
    id: organization.id,
    name: organization.legal_name,
  }));

  return (
    <Grid>
      <Grid container gap={2} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
        {isSuperAdmin && (
          <SelectFilter
            value={grouping}
            onChange={handleChangeGrouping}
            options={[{ id: "all", name: t("ALL_GROUPING") }, ...groups]}
          />
        )}
        <SelectFilter
          value={organization}
          onChange={handleChangeOrganization}
          options={[{ id: "all", name: t("ALL_ENTITIES") }, ...organizationsFormat]}
        />
        <SelectFilter
          value={premise}
          onChange={handleChangePremise}
          options={[{ id: "all", name: t("ALL_SITES") }, ...premisesFormat]}
        />
      </Grid>
    </Grid>
  );
};

export default ChoseEntity;
