import { cleanObjectOfVoidFields } from "../components/shared/FormsValidator";
import { createTicket } from "../services/eventsServices";
import { dateWithoutTZ } from "./utils";

export const createNewTicket = async (ticket) => {
  try {
    const ticketCopy = await JSON.parse(JSON.stringify(ticket));
    const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);
    ticketToSend.description =
      typeof ticketToSend.description !== "string"
        ? JSON.stringify(ticketToSend.description)
        : ticketToSend.description;
    // TODO: Revisar como gestionamos estos atributos
    ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock);
    ticketToSend.price = parseFloat(ticketToSend.price);
    ticketToSend.iva = parseInt(ticketToSend.iva);
    ticketToSend.only_in_app = ticketToSend.only_in_app === 1;
    delete ticketToSend.stock;
    // PROMO CODE DISCOUNTS
    for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
      ticketToSend.promotional_code_discounts[k].discount = parseFloat(
        ticketToSend.promotional_code_discounts[k].discount
      );
      ticketToSend.promotional_code_discounts[k].is_active =
        ticketToSend.promotional_code_discounts[k].is_active === 1 ||
        ticketToSend.promotional_code_discounts[k].is_active === true;
      ticketToSend.promotional_code_discounts[k].ticket_id = ticketToSend.id;
    }
    //
    if (ticketToSend.international_discount)
      ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
    if (ticketToSend.early_payment_discount)
      ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
    if (ticketToSend.promotional_code_discount)
      ticketToSend.promotional_code_discount = parseFloat(ticketToSend.promotional_code_discount);
    //
    for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
      ticketToSend.group_discounts[j].min_size = parseInt(ticketToSend.group_discounts[j].min_size);
      ticketToSend.group_discounts[j].max_size = parseInt(ticketToSend.group_discounts[j].max_size);
      ticketToSend.group_discounts[j].discount = parseFloat(
        ticketToSend.group_discounts[j].discount
      );
    }
    //TICKET LANGUAGES
    if (ticketToSend.ticket_language_infos.length > 0) {
      for (let j = 0; j < ticketToSend.ticket_language_infos.length; j++) {
        const language = ticketToSend.ticket_language_infos[j];
        if (typeof language.description === "object") {
          language.description = JSON.stringify(language.description);
        }
        language.ticket_id = ticketToSend.id;
        delete language.id;
      }
    }
    ticketToSend.only_international =
      ticketToSend.only_international === 1 || ticketToSend.only_international === true;
    // TICKET DATES
    ticketToSend.start_date = ticketToSend.start_date
      ? dateWithoutTZ(ticketToSend.start_date)
      : null;
    ticketToSend.end_date = ticketToSend.end_date ? dateWithoutTZ(ticketToSend.end_date) : null;
    ticketToSend.second_payment_end_date = ticketToSend.second_payment_end_date
      ? dateWithoutTZ(ticketToSend.second_payment_end_date)
      : null;
    //
    const response = await createTicket(ticketToSend);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const soldTicketsPercent = (soldTickets = 0, totalSales = 0) => {
  if (totalSales === 0) return 0;
  let percentage = (soldTickets * 100) / totalSales;
  return parseFloat(percentage.toFixed(2));
};

// To find the domain categories that match the event categories
export const getMatchingDomainCategoryNames = (domainCategories, eventCategories) => {
  const eventCategoryIds = new Set(eventCategories.map((event) => event.category_id));
  return domainCategories
    .filter((domain) => domain.categories.some((category) => eventCategoryIds.has(category.id)))
    .map((domain) => domain.name);
};
