import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { editRrpp } from "../../classes/rrppClass";
import Header from "../../components/Header";
import RrppForm from "../../components/rrpps/RrppForm";
import { Loading } from "../../components/shared/Loading";
import { RQ_KEY } from "../../constants/query";
import { getRrppById } from "../../services/rrppsServices";

const EditRrpp = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("RRPP_MANAGER"),
      link: "/rrpps",
    },
    {
      name: t("EDIT_RRPP"),
      link: `/rrpps/edit_rrpp`,
    },
  ];

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(editRrpp)));
  const { userId } = useParams();
  const rrppId = userId;

  const handleSetData = (data) => {
    const userInfo = data;
    userInfo.avatar_url = data.avatar;
    userInfo.domain_ids = data.domains.map((domain) => domain.domain_id);
    setFormData(userInfo);
  };

  const { isError, isLoading, isRefetching } = useQuery({
    queryKey: [RQ_KEY.RRPP_BY_ID, rrppId],
    queryFn: () => getRrppById(rrppId),
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: handleSetData,
  });

  if (isError) return <Navigate to="/404" />;
  if (isLoading || isRefetching) return <Loading />;

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("EDIT_RRPP_DESCRIPTION")} />
      <RrppForm formData={formData} rrppId={rrppId} />
    </>
  );
};

export default EditRrpp;
