import { Grid, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import MapContent from "../../shared/Map/LeafletMap";

const SelectLatLong = ({
  setFormParamsNotEvent,
  formData,
  checkFieldValue,
  setErrors,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const handleChangeLatLong = (lat, lng) => {
    checkFieldValue(
      "premise",
      "latitude",
      lat,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setErrors
    );
    checkFieldValue(
      "premise",
      "longitude",
      lng,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setErrors
    );
  };

  return (
    <Grid sx={{ mt: 1, mb: 1 }}>
      <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("PREMISE_LOCATION")}</InputLabel>
      <Grid sx={{ height: "250px", width: "100%" }}>
        <MapContent
          marker
          handleChangeLatLong={handleChangeLatLong}
          isDisabled={isDisabled}
          lat={formData.latitude}
          lng={formData.longitude}
        />
      </Grid>
    </Grid>
  );
};

export default SelectLatLong;
