import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CATEGORIES_STYLES, EVENT_CARD_CONTENT } from "../../../../constants/styles";
import ColorInput from "../../../shared/ColorInput";
import InfoToolTip from "../../../shared/InfoToolTip";
import "./categories.scss";

const CategoriesStyles = ({ categories }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const categoryStyles = watch("style.category_styles");

  const objectCategoryStyles = {};
  categoryStyles.forEach((item) => {
    if (item.subcategory_id) {
      objectCategoryStyles[item.subcategory_id] = item;
    } else {
      objectCategoryStyles[item.category_id] = item;
    }
  });

  const styleId = categoryStyles[0]?.style_id;
  const defaultStyles = {
    style_id: styleId,
    card_color: "#EAEAEA",
    content: "",
    text_color: "#000000",
  };

  const handleChangeCategory = (category, type, property, value, subcategoryId) => {
    const newCategoryStyles = categoryStyles.some((item) => item[type] === category)
      ? categoryStyles.map((item) => {
          if (item[type] === category) {
            return { ...item, [property]: value };
          }
          return item;
        })
      : [
          ...categoryStyles,
          {
            [type]: category,
            [property]: value,
            style_id: styleId,
            card_color: "#EAEAEA",
            content: "",
            text_color: "#000000",
            ...(subcategoryId ? { subcategory_id: subcategoryId } : {}),
          },
        ];
    setValue("style.category_styles", newCategoryStyles);
  };

  const isChecked = (category, type, value) => {
    return categoryStyles.some((item) => item[type] === category && item.content?.includes(value));
  };

  const handleCategoryOptionsChange = (category, type, e) => {
    const { value } = e.target;
    let newContent;
    const newCategoryStyles = categoryStyles.some((item) => item[type] === category)
      ? categoryStyles.map((item) => {
          if (item[type] === category) {
            if (item.content?.includes(value)) {
              newContent = item.content
                ?.split(",")
                ?.filter((icon) => icon !== value)
                ?.join(",");
            } else {
              newContent = item.content ? `${item.content},${value}` : value;
            }
            return { ...item, content: newContent };
          }
          return item;
        })
      : [
          ...categoryStyles,
          {
            [type]: category,
            content: value,
            style_id: "square",
            card_color: "#EAEAEA",
            text_color: "#000000",
          },
        ];
    setValue("style.category_styles", newCategoryStyles);
  };

  const handleAllCategoriesSameStyle = () => {
    const newCategoryStyles = categoryStyles.map((item) => {
      return {
        ...item,
        card_color: categoryStyles[0].card_color,
        format: categoryStyles[0].format,
        content: categoryStyles[0].content,
      };
    });
    setValue("style.category_styles", newCategoryStyles);
  };

  return (
    <Grid>
      <Typography variant="h6">{t("CARDS")}</Typography>
      <Divider />
      <Grid container mt={2} rowSpacing={1}>
        {categories?.map((category, index) => (
          <Grid item xs={12} key={`${category.id}-${index}`}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontWeight: "700" }}>
                  {t("CATEGORY") + ": " + category.name ?? ""}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: "500" }}>{t("FORMAT_CARD_IMAGE")}</Typography>
                <Grid container gap={1}>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format === CATEGORIES_STYLES.SQUARE &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.SQUARE
                      )
                    }
                  >
                    <Grid className="text">
                      <Typography>{t("FORMAT_1")}</Typography>
                      <Typography variant="caption">{t("SQUARE_RECOMMENDATION")}</Typography>
                    </Grid>
                    <Grid className="box-1"></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format ===
                          CATEGORIES_STYLES.FULL_WIDTH &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.FULL_WIDTH
                      )
                    }
                  >
                    <Typography className="text">
                      {t("TOTAL_WIDTH")} <br /> {t("ONLY_ONE_CARD")}
                    </Typography>
                    <Grid className="box-2"></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format === CATEGORIES_STYLES.FORMAT_59 &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.FORMAT_59
                      )
                    }
                  >
                    <Typography className="text">{t("FORMAT_59")}</Typography>
                    <Grid className="box-3"></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format === CATEGORIES_STYLES.PORTRAIT &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.PORTRAIT
                      )
                    }
                  >
                    <Typography className="text">{t("FORMAT_34")}</Typography>
                    <Grid className="box-4"></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format ===
                          CATEGORIES_STYLES.SQUARED_BORDER &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.SQUARED_BORDER
                      )
                    }
                  >
                    <Typography className="text">{t("BORDER_FORMAT")}</Typography>
                    <Grid className="box-5"></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="format-card"
                    sx={{
                      border:
                        objectCategoryStyles[category.id]?.format ===
                          CATEGORIES_STYLES.HORIZONTAL_INFO &&
                        !objectCategoryStyles[category.id]?.subcategory_id
                          ? "2px solid var(--oniria-gold)"
                          : "",
                    }}
                    onClick={() =>
                      handleChangeCategory(
                        category.id,
                        "category_id",
                        "format",
                        CATEGORIES_STYLES.HORIZONTAL_INFO
                      )
                    }
                  >
                    <Typography className="text">{t("HORIZONTAL_FORMAT")}</Typography>
                    <Grid className="box-6"></Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" mt={2}>
                  <Typography sx={{ fontWeight: "500" }}>{t("CONTENT")}</Typography>
                  <InfoToolTip text={t("CONTENT_INFORMATION")} />
                </Grid>
                <FormGroup>
                  {EVENT_CARD_CONTENT.map((content) => (
                    <FormControlLabel
                      key={content}
                      control={
                        <Checkbox
                          className="checkbox-oniria"
                          value={content}
                          checked={isChecked(category.id, "category_id", content)}
                          onChange={(e) =>
                            handleCategoryOptionsChange(category.id, "category_id", e)
                          }
                        />
                      }
                      label={t(content.toUpperCase())}
                    />
                  ))}
                </FormGroup>
                <Typography sx={{ fontWeight: "500", mt: 2 }}>{t("COLOR_CARD")}</Typography>
                <ColorInput
                  value={objectCategoryStyles[category.id]?.card_color || "#ffffff"}
                  onChange={(e) =>
                    handleChangeCategory(category.id, "category_id", "card_color", e.target.value)
                  }
                />
                <Typography sx={{ fontWeight: "500", mt: 2 }}>{t("TEXT_COLOR")}</Typography>
                <ColorInput
                  value={objectCategoryStyles[category.id]?.text_color || "#000000"}
                  onChange={(e) =>
                    handleChangeCategory(category.id, "category_id", "text_color", e.target.value)
                  }
                />
                {categories.length > 1 && index === 0 ? (
                  <Grid sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={<Checkbox className="checkbox-oniria" />}
                      label={t("SAME_CATEGORY_STYLE")}
                      onChange={handleAllCategoriesSameStyle}
                    />
                  </Grid>
                ) : null}
                {category.subcategories && category.subcategories.length > 0
                  ? category.subcategories.map((subcategory, i) => (
                      <Grid key={`${subcategory.id}-${i}`}>
                        <Typography sx={{ fontWeight: "500" }}>
                          {t("SUBCATEGORY") + ": " + subcategory.name ?? ""}
                        </Typography>
                        <Typography>{t("FORMAT_CARD_IMAGE")}</Typography>
                        <Grid container gap={1}>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.SQUARE
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.SQUARE,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">{t("FORMAT_1")}</Typography>
                            <Grid className="box-1"></Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.FULL_WIDTH
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.FULL_WIDTH,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">
                              {t("TOTAL_WIDTH")} <br /> {t("ONLY_ONE_CARD")}
                            </Typography>
                            <Grid className="box-2"></Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.FORMAT_59
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.FORMAT_59,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">{t("FORMAT_59")}</Typography>
                            <Grid className="box-3"></Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.PORTRAIT
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.PORTRAIT,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">{t("FORMAT_34")}</Typography>
                            <Grid className="box-4"></Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.SQUARED_BORDER
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.SQUARED_BORDER,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">{t("BORDER_FORMAT")}</Typography>
                            <Grid className="box-5"></Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="format-card"
                            sx={{
                              border:
                                objectCategoryStyles[subcategory.id]?.format ===
                                CATEGORIES_STYLES.HORIZONTAL_INFO
                                  ? "2px solid var(--oniria-gold)"
                                  : "",
                            }}
                            onClick={() =>
                              handleChangeCategory(
                                subcategory.id,
                                "subcategory_id",
                                "format",
                                CATEGORIES_STYLES.HORIZONTAL_INFO,
                                category.category_id
                              )
                            }
                          >
                            <Typography className="text">{t("HORIZONTAL_FORMAT")}</Typography>
                            <Grid className="box-6"></Grid>
                          </Grid>
                        </Grid>
                        <Typography>{t("CONTENT")}</Typography>
                        <FormGroup>
                          {EVENT_CARD_CONTENT.map((content) => (
                            <FormControlLabel
                              key={content}
                              control={
                                <Checkbox
                                  className="checkbox-oniria"
                                  value={content}
                                  checked={isChecked(subcategory.id, "subcategory_id", content)}
                                  onChange={(e) =>
                                    handleCategoryOptionsChange(subcategory.id, "subcategory_id", e)
                                  }
                                />
                              }
                              label={t(content.toUpperCase())}
                            />
                          ))}
                        </FormGroup>
                        <Typography>{t("COLOR_CARD")}</Typography>
                        <ColorInput
                          value={objectCategoryStyles[subcategory.id]?.card_color || "#ffffff"}
                          onChange={(e) =>
                            handleChangeCategory(
                              subcategory.id,
                              "subcategory_id",
                              "card_color",
                              e.target.value,
                              category.category_id
                            )
                          }
                        />
                      </Grid>
                    ))
                  : null}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CategoriesStyles;
