import { toast } from "react-toastify";

export const toastMessageError = (text) =>
  toast.error(text, {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

export const toastMessageSuccess = (text) =>
  toast.success(text, {
    position: "bottom-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      background: "#3D3B38",
    },
  });

export const customToastMessage = (children, backgroundColor) => {
  toast(<>{children}</>, {
    position: "bottom-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    autoClose: 3000,
    style: {
      backgroundColor: backgroundColor,
    },
  });
};
