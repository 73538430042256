import EmailIcon from "@mui/icons-material/Email";
import { default as HomeIcon } from "@mui/icons-material/Home";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NoteIcon from "@mui/icons-material/Note";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";
import { ICONS_MAP } from "../../../../constants/styles";

const FooterPreview = ({ isMobile }) => {
  const { watch, setValue, control } = useFormContext();

  const footerColor = watch("style.footer_color");
  const footerTextColor = watch("style.footer_text_color");
  const footerWebColor = watch("style.footer_web_color");
  const footerType = watch("style.footer_type");
  const footerIconsColor = watch("style.footer_icons_color");
  const footerLogo = watch("style.footer_logo");
  const footerPhone = watch("style.footer_phone");
  const footerEmail = watch("style.footer_email");
  const footerCif = watch("style.footer_cif");
  const footerAddress = watch("style.footer_address");
  const appFont = watch("style.font_text");

  const footerIconsString = useWatch({ control, name: "style.footer_icons" });
  const [footerIcons, setFooterIcons] = useState([]);

  useEffect(() => {
    const icons = footerIconsString?.split(",") || [];
    setFooterIcons(icons);
  }, [footerIconsString]);

  const handleChangeIconsPosition = (result) => {
    if (!result.destination) return;
    const items = Array.from(footerIcons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFooterIcons(items);
    setValue("style.footer_icons", items.join(","));
  };

  const renderIcon = (icon) => {
    const IconComponent = ICONS_MAP[icon];
    return IconComponent ? <IconComponent color={footerIconsColor} size={"2.3rem"} /> : null;
  };

  return (
    <Grid
      sx={{
        minHeight: "95px",
        backgroundColor: isMobile ? footerColor : footerWebColor,
        borderRadius: isMobile && footerType === "rounded" ? "20% 20% 0% 0%/ 50% 50% 10% 10%" : "",
        padding: "0 10px",
        "&::before": {
          backgroundColor: footerType === "middleCircle" && footerColor,
          width: "150px",
          height: "105px",
        },
        position: isMobile ? "absolute" : "relative",
        bottom: 0,
      }}
      container
      justifyContent="space-between"
      alignItems="center"
      className={footerType === "middleCircle" && isMobile ? "middle-circle-footer" : ""}
    >
      {isMobile && (
        <DragDropContext onDragEnd={handleChangeIconsPosition}>
          <Droppable droppableId="footer-icons-list" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", gap: 4, width: "100%", justifyContent: "space-between" }}
              >
                {footerIcons.length > 0 && footerIcons[0] !== ""
                  ? footerIcons?.map((icon, index) => (
                      <Draggable key={icon} draggableId={icon} index={index}>
                        {(provided, _) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: 2,
                            }}
                          >
                            <Grid container flexDirection="column" alignItems="center">
                              {renderIcon(icon)}
                              <Typography
                                fontFamily={`${appFont} !important`}
                                sx={{
                                  fontSize: "10px",
                                  color: footerIconsColor,
                                }}
                              >
                                {icon}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!isMobile && (
        <Grid
          item
          xs={12}
          container
          sx={{ justifyContent: "flex-start", height: "100%", alignItems: "center" }}
        >
          <Grid
            item
            xs={2}
            sx={{
              backgroundImage: `url(${footerLogo})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              margin: "10px",
              height: "60px",
            }}
          />
          <Grid sx={{ color: "#fff" }}>
            {footerPhone && (
              <Grid container alignItems="center" gap={2}>
                <LocalPhoneIcon sx={{ fontSize: "20px", color: footerTextColor }} />
                <Typography
                  sx={{ fontSize: "12px", color: footerTextColor }}
                  fontFamily={`${appFont} !important`}
                >
                  {footerPhone}
                </Typography>
              </Grid>
            )}
            {footerEmail && (
              <Grid container alignItems="center" gap={2}>
                <EmailIcon sx={{ fontSize: "20px", color: footerTextColor }} />
                <Typography
                  sx={{ fontSize: "12px", color: footerTextColor }}
                  fontFamily={`${appFont} !important`}
                >
                  {footerEmail}
                </Typography>
              </Grid>
            )}
            {footerCif && (
              <Grid container alignItems="center" gap={2}>
                <NoteIcon sx={{ fontSize: "20px", color: footerTextColor }} />
                <Typography
                  sx={{ fontSize: "12px", color: footerTextColor }}
                  fontFamily={`${appFont} !important`}
                >
                  {footerCif}
                </Typography>
              </Grid>
            )}
            {footerAddress && (
              <Grid container alignItems="center" gap={2}>
                <HomeIcon sx={{ fontSize: "20px", color: footerTextColor }} />
                <Typography
                  sx={{ fontSize: "12px", color: footerTextColor }}
                  fontFamily={`${appFont} !important`}
                >
                  {footerAddress}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FooterPreview;
