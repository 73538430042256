import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PROGRESS_BAR_STYLES } from "../../../constants/styles";

const TicketsSold = ({ tickets, totalSales, soldTicketsPercent, isMobile }) => {
  const { t } = useTranslation();

  const numberValidated = tickets?.reduce((total, ticket) => {
    return total + (ticket.consumed_tickets || 0);
  }, 0);

  return (
    <Box
      component={Paper}
      elevation={3}
      sx={{
        py: 2,
        px: 1,
        mb: 2,
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        maxWidth: "100%",
      }}
    >
      <Grid item container flexDirection="row" justifyContent="space-between" mb={1}>
        <Grid item xs={6} container alignItems="center" gap={1}>
          {isMobile && <CheckCircleIcon sx={{ color: "var(--green-success)" }} />}
          <Typography variant="subtitle2">
            {numberValidated}/{totalSales} {!isMobile && t("REGISTER_ASSISTANTS_STOCK")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {soldTicketsPercent(numberValidated, totalSales)}% {t("PERCENTAGE_ARRIVED")}
          </Typography>
        </Grid>
      </Grid>
      <LinearProgress
        variant="determinate"
        value={soldTicketsPercent(numberValidated, totalSales)}
        sx={PROGRESS_BAR_STYLES}
      />
      {tickets && tickets.length > 0
        ? tickets.map((ticket, index) => (
            <Grid
              item
              container
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              xs={12}
              pl={2}
              key={`ticket-${index}`}
            >
              <Grid item xs={6} container alignItems="center" gap={1}>
                <Typography variant="subtitle2">
                  {ticket.consumed_tickets || 0}/{ticket.sold_tickets || 0} {ticket.ticket_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {soldTicketsPercent(ticket.consumed_tickets || 0, ticket.sold_tickets || 0)}%{" "}
                  {t("PERCENTAGE_ARRIVED")}
                </Typography>
              </Grid>
              <LinearProgress
                variant="determinate"
                value={soldTicketsPercent(ticket.consumed_tickets || 0, ticket.sold_tickets || 0)}
                sx={PROGRESS_BAR_STYLES}
              />
            </Grid>
          ))
        : null}
    </Box>
  );
};

export default TicketsSold;
