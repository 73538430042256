import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import { focusColor } from "../../../shared/textFieldStyle";

const CategorySlug = ({ newCategory, setNewCategory, selectedLanguage }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setNewCategory({ ...newCategory, slug: e.target.value });
  };

  const handleChangeIdiom = (e) => {
    const newCategoryLanguageInfos = newCategory?.category_language_infos.map((info) => {
      if (info.language === selectedLanguage) {
        return { ...info, slug: e.target.value };
      }
      return info;
    });
    setNewCategory({ ...newCategory, category_language_infos: newCategoryLanguageInfos });
  };

  return selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
    <TextField
      id="outlined-basic"
      label={t("SLUG")}
      variant="outlined"
      sx={[focusColor, { mt: 2 }]}
      value={newCategory.slug || ""}
      onChange={handleChange}
      fullWidth
    />
  ) : (
    <TextField
      id="outlined-basic"
      label={t("SLUG")}
      variant="outlined"
      sx={[focusColor, { mt: 2 }]}
      value={
        newCategory.category_language_infos?.find((info) => info.language === selectedLanguage)
          ?.slug || ""
      }
      onChange={handleChangeIdiom}
      fullWidth
    />
  );
};

export default CategorySlug;
