import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const NationalityGraph = ({ assistantsByNationality }) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      component={Paper}
      elevation={3}
      xs={12}
      sx={{ padding: "10px", border: "1px solid #E4E4E4", borderRadius: "15px" }}
    >
      <Typography variant="h6">{t("NATIONALITIES")}</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t("CUSTOMER_NATIONALITY")}</TableCell>
            <TableCell>{t("ASSISTANTS_NUMBER")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: "fit-content", maxWidth: "100%" }}>
          {assistantsByNationality?.map(
            (row, index) =>
              row.nationality && (
                <TableRow key={index}>
                  <TableCell>{row.nationality}</TableCell>
                  <TableCell>{row.number}</TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default NationalityGraph;
