import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RQ_KEY } from "../../../constants/query";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { EVENT_TABS } from "../../../constants/variables";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import { getAllAssistants, validateExtra, validateTicket } from "../../../services/eventsServices";
import { Loading } from "../../shared/Loading";
import SelectPerPage from "../../shared/SelectPerPage";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import AssistantDetail from "../AssistantDetail";
import TicketsStock from "../ticketComponents/TicketsStock";
import AssistantsTable from "./AssistantsTable";
import MobileAssistants from "./MobileAssistants";
import RefundsAndPayments from "./RefundsAndPayments";
import RegisterAssistantDialog from "./RegisterDialog";
import TicketsSold from "./TicketsSold";

const ListAssistants = ({ eventId, isDoublePayment, qr }) => {
  const { t } = useTranslation();

  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [showDetailAssistant, setShowDetailAssistant] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [validateAssistan, setValidateAssistan] = useState("");
  const [ticketsTypes, setTicketsTypes] = useState([]);
  const [numberRefunds, setNumberRefunds] = useState(0);
  const [searchParam] = useSearchParams();
  const [filters, setFilters] = useState({
    search: "",
    ticket_name: "",
    asc: [],
    desc: ["created_at"],
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    selectedTab: selectedEventTab,
    domains: userDomains = [],
    selectedDomain: stateSelectedDomain,
  } = useSelector((state) => state.b2bInfo);

  const selectedDomain = useMemo(() => {
    if (selectedEventTab === EVENT_TABS.MY_EVENTS) {
      return stateSelectedDomain;
    }
    return stateSelectedDomain || userDomains.join(";");
  }, [selectedEventTab, stateSelectedDomain, userDomains]);

  const handleQuerySuccess = (data) => {
    const assistantsData = data.data;
    setNumberRefunds(data.refunded_tickets);
    setAssistants(assistantsData.data);
    setTicketsTypes(data.tickets);
    setTotalSales(data.sold_tickets);
  };

  const totalPages = Math.ceil(totalSales / perPage) || 1;

  const { isLoading, refetch: refetchAssistants } = useQuery(
    [RQ_KEY.ASSISTANTS_EVENT_ID, eventId, selectedDomain, filters, page, perPage],
    () => getAllAssistants(eventId, selectedDomain, page, perPage, filters),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (e) => {
    setFilters((prev) => ({ ...prev, search: e.target.value }));
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  //type ticket
  const handleChangeTypeTicket = (e) => {
    setFilters((prev) => ({ ...prev, ticket_name: e.target.value }));
  };

  const handleSortAssistants = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    if (filters[type]?.includes(id)) {
      setFilters((prev) => ({ ...prev, [type]: prev[type]?.filter((item) => item !== id) }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [type]: [...(prev[type] || []), id],
        [oppositeType]: prev[oppositeType]?.filter((item) => item !== id),
      }));
    }
  };

  const handleRowClick = (assistant) => {
    setSelectedAssistant(assistant);
    setShowDetailAssistant(true);
  };

  const handleClickOpenCheck = (assistant) => {
    setValidateAssistan(assistant);
    setOpenCheck(true);
  };

  const handleCloseCheck = () => {
    setSelectedOption("");
    setOpenCheck(false);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const optionValue = e.target.value;
    if (optionValue === "all") {
      if (selectedOption.includes("all")) {
        setSelectedOption([]);
      } else {
        const allOptions = [
          "all",
          "ticket",
          ...validateAssistan.extras.map((_, index) => `extra${index}`),
          ...validateAssistan.extras.flatMap((extra, index2) => {
            if (extra.extra_consumitions != null && extra.times_can_be_consumed > 1) {
              return [...Array(extra.times_can_be_consumed)]?.map(
                (_, i) => `extra${index2}-item${i}`
              );
            } else {
              return [];
            }
          }),
        ];
        setSelectedOption(allOptions);
      }
    } else {
      setSelectedOption((prevSelectedOption) => {
        //Para bonos copas
        let relatedItems = [];
        if (prevSelectedOption.includes(optionValue)) {
          return prevSelectedOption.filter(
            (option) => option !== optionValue && !option?.includes(optionValue)
          );
        } else {
          if (optionValue.includes("extra") && !optionValue.includes("-item")) {
            const indexExtra = parseInt(optionValue.replace("extra", ""), 10);
            relatedItems = validateAssistan.extras.flatMap((extra, index) => {
              if (
                extra.extra_consumitions != null &&
                index === indexExtra &&
                extra.times_can_be_consumed > 1
              ) {
                return [...Array(extra.times_can_be_consumed)]?.map(
                  (_, i) => `extra${index}-item${i}`
                );
              } else {
                return [];
              }
            });
          }
          return [...prevSelectedOption, optionValue, ...relatedItems];
        }
      });
    }
  };
  const handleRegisterButtonClick = async (e, selectedOptions) => {
    setOpenCheck(false);
    e.stopPropagation();
    try {
      let response = null;
      if (selectedOptions.includes("ticket") && validateAssistan.consumed_at === null) {
        response = await validateTicket(validateAssistan?.order_ticket_id);
      }
      //limpiamos los bonos para que no haya peticiones de mas
      const prefixes = new Set(
        selectedOptions
          .filter((option) => option.includes("-item"))
          .map((option) => option.split("-")[0])
      );
      const filteredOptions = selectedOptions.filter((option) => !prefixes.has(option));

      for (let i = 0; i < filteredOptions.length; i++) {
        if (filteredOptions[i].startsWith("extra")) {
          const index = parseInt(filteredOptions[i].replace("extra", ""), 10);
          const { extra_consumed, extra_id } = validateAssistan.extras[index];
          if (extra_consumed === null) {
            const extraResponse = await validateExtra(extra_id);
            if (response === null) {
              response = extraResponse;
            }
          }
        }
      }
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        refetchAssistants();
      }, 1500);
    } catch (error) {
      toastMessageError(error.response?.data?.error ?? t("EDIT_ERROR"));
    } finally {
      setSelectedOption("");
      setOpenCheck(false);
    }
  };

  //download options
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  //PDF with essential information
  const headerPdf = [
    t("NAME"),
    t("SURNAME"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("EMAIL"),
    t("PHONE_CODE"),
    t("PHONE"),
    t("TICKET"),
    t("EXTRAS"),
  ];

  const tableDataPdf = assistants.map((item) => [
    item.client_name ?? item.buyer_name ?? "",
    item.client_surname ?? item.buyer_surname ?? "",
    item.customer_phone_code ?? item.customer_phone_code ?? "",
    item.customer_phone ?? item.customer_phone ?? "",
    item.client_email ? item.client_email : "",
    item.client_phone_code ? item.client_phone_code : "",
    item.client_phone ? item.client_phone : "",
    item.ticket_name ?? "",
    item.extras
      .map((extra) => {
        return extra.extra_name + "\n";
      })
      .join(""),
  ]);
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [headerPdf],
      body: tableDataPdf,
    });
    doc.save("assistants.pdf");
  };
  //CSV WITH ALL INFORMATION
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("BIRTHDAY"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("NUMBER_ORDER"),
    t("TICKET_ID"),
    t("TICKET_NAME"),
    t("TICKET_PRICE"),
    t("CREATED_AT"),
    t("CONSUMED_AT"),
    t("PAYMENT_TYPE"),
    t("CUSTOMER_EMAIL"),
    t("CUSTOMER_DNI_TYPE"),
    t("CUSTOMER_DNI"),
    t("CUSTOMER_DNI_EXPIRATION"),
    t("CUSTOMER_NATIONALITY"),
    t("CUSTOMER_GENDER"),
    t("AFFILIATED_STATUS"),
    t("ORDER_TICKET_ID"),
    t("EMAIL"),
    t("PHONE_CODE"),
    t("PHONE"),
    t("NATIONALITY"),
    t("GENDER"),
    t("DOCUMENT_TYPE"),
    t("NIE"),
    t("EXPIRATION_NIE"),
    t("EXPEDITION_NIE"),
    t("TICKET_PRICE"),
  ];

  const numberExtras = assistants.map((item) => item.extras.length);
  const numberExtrasMax = Math.max(...numberExtras);
  for (let i = 0; i < numberExtrasMax; i++) {
    const num = i + 1;
    tableHeadersCsv.push("Extra" + num + ": " + t("EXTRA_NAME"));
    tableHeadersCsv.push("Extra" + num + ": " + t("PRICE"));
    tableHeadersCsv.push("Extra" + num + ": " + t("CONSUMED_AT"));
    tableHeadersCsv.push("Extra" + num + ": " + t("ID"));
  }

  const tableDataCsv = assistants.map((item) => {
    const row = [
      item.client_name ?? item.buyer_name,
      item.client_surname ?? item.buyer_surname,
      item.client_birth_date ? new Date(item.client_birth_date).toLocaleDateString() : "",
      item.customer_phone_code,
      item.customer_phone,
      item.order_id,
      item.ticket_id,
      item.ticket_name,
      item.ticket_price,
      item.created_at ? new Date(item.created_at).toLocaleDateString() : "",
      item.consumed_at ? new Date(item.consumed_at).toLocaleDateString() : "",
      item.payment_type,
      item.customer_email,
      item.customer_dni_type,
      item.customer_dni,
      item.customer_dni_expiration
        ? new Date(item.customer_dni_expiration).toLocaleDateString()
        : "",
      item.customer_nationality,
      item.customer_gender,
      item.affiliated_status,
      item.order_ticket_id,
      item.client_email,
      item.client_phone_code,
      item.client_phone,
      item.client_nationality,
      item.client_gender,
      item.client_document_type,
      item.client_document_id,
      item.client_document_expiry_date
        ? new Date(item.client_document_expiry_date).toLocaleDateString()
        : "",
      item.client_document_expedition_date
        ? new Date(item.client_document_expedition_date).toLocaleDateString()
        : "",
      item.final_payed_price,
    ];
    item.extras.forEach((extra) => {
      row.push(extra.extra_name);
      row.push(extra.extra_price);
      row.push(
        extra.extra_consumed_at
          ? new Date(extra.extra_consumed_at).toLocaleDateString()
          : "No consumido"
      );
      row.push(extra.extra_id);
    });
    return row;
  });
  //END DOWNLOAD PDF & CSV OPTIONS

  const soldTicketsPercent = (soldTickets, totalSales) => {
    if (totalSales === 0) return 0;
    let percentage = (soldTickets * 100) / totalSales;
    return parseFloat(percentage.toFixed(2));
  };

  useEffect(() => {
    refetchAssistants();
  }, [qr]);

  //Check if the payment is complete
  const isCompletePayment = (assistant) => !!assistant.payed_at;

  const assistantArrived = (assistant) => {
    const complete = isCompletePayment(assistant);
    if (!complete || assistant.refunded_at) return false;
    return (
      assistant.consumed_at !== null &&
      assistant?.extras?.every((extra) => extra.extra_consumed_at !== null)
    );
  };

  // Search the ticketId in the URL and show the assistant detail
  useEffect(() => {
    const ticketParamId = searchParam.get("ticket");
    if (ticketParamId) {
      setSelectedAssistant(
        assistants.find((assistant) => assistant.order_ticket_id === ticketParamId)
      );
      setShowDetailAssistant(true);
    }
  }, [searchParam, assistants, selectedAssistant]);

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 3 }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "column" }}>
          {/* TICKETS SOLD */}
          <TicketsSold
            tickets={ticketsTypes}
            totalSales={totalSales}
            soldTicketsPercent={soldTicketsPercent}
            isMobile={isMobile}
          />
          {/* TICKETS STOCK BY DOMAIN */}
          <TicketsStock tickets={ticketsTypes} />
          {/* REFUNDS AND DOBLE PAYMENTS */}
          <RefundsAndPayments
            ticketsTypes={ticketsTypes}
            isDoublePayment={isDoublePayment}
            numberRefunds={numberRefunds}
            assistants={assistants}
          />
          {showDetailAssistant ? (
            <AssistantDetail
              ticketId={selectedAssistant?.order_ticket_id}
              onBack={() => setShowDetailAssistant(false)}
            />
          ) : (
            <>
              <Grid
                container
                gap={{ xs: 1, lg: 2 }}
                sx={{ flexDirection: "row-reverse", alignItems: "space-between", mb: 5, mt: 2 }}
              >
                <Grid item mb={2}>
                  <Button
                    className="oniria-btn"
                    variant="contained"
                    size="medium"
                    sx={{
                      fontSize: "12px",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={handleOpenPopover}
                  >
                    <CloudDownloadIcon />
                    {t("DOWNLOAD_LIST")}
                  </Button>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    id={popoverId}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ButtonGroup
                      variant="contained"
                      orientation="vertical"
                      aria-label="vertical button group"
                    >
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download csv"
                      >
                        <CSVLink
                          data={tableDataCsv}
                          filename={"assistants.csv"}
                          target="_blank"
                          separator={";"}
                          headers={tableHeadersCsv}
                          className="csv-link"
                          style={{ textAlign: "center" }}
                        >
                          {t("CSV_FORMAT")}
                          <p style={{ fontSize: "10px" }}>{t("INFO_FULL")}</p>
                        </CSVLink>
                      </Button>
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download pdf"
                        onClick={generatePDF}
                        style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                      >
                        {t("PDF_FORMAT")}
                        <p style={{ fontSize: "10px" }}>{t("INFO_BASE")}</p>
                      </Button>
                    </ButtonGroup>
                  </Popover>
                </Grid>
                <Grid item xs={5.3} sm={3} lg={2} mb={2}>
                  <FormControl fullWidth sx={focusColor}>
                    <InputLabel id="demo-simple-select-label" size="small">
                      {t("TICKET_TYPE")}
                    </InputLabel>
                    <Select
                      value={filters.ticket_name}
                      onChange={handleChangeTypeTicket}
                      label={t("TICKET_TYPE")}
                      sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
                    >
                      <MenuItem value="">{t("ALL_TICKETS")}</MenuItem>
                      {ticketsTypes?.map((ticket, index) => (
                        <MenuItem key={index} value={ticket.ticket_name}>
                          {ticket.ticket_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item mb={2} xs={12} md={5} container>
                  <SearchIcon sx={{ height: 35, width: 35, color: "var(--secondary-color)" }} />
                  <TextField
                    label={t("SEARCH")}
                    size="small"
                    onChange={(event) => debounceSearch(event)}
                    sx={[focusColor, { width: "85%" }]}
                  />
                </Grid>
              </Grid>
              <Grid item mb={25}>
                {isLoading ? (
                  <Loading />
                ) : assistants.length === 0 ? (
                  <Grid item>
                    <Typography variant="h6" sx={{ textAlign: "center", mt: 20 }}>
                      {t("NO_ASSISTANTS_FOUND")}
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid container justifyContent="flex-end" alignItems="center" mb={2}>
                      <Grid item>
                        <SelectPerPage
                          text={t("USERS")}
                          change={changePerPage}
                          value={perPage}
                          total={totalSales}
                        />
                      </Grid>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={changePage}
                        sx={PAGINATION_STYLES}
                      />
                    </Grid>
                    {isMobile ? (
                      <MobileAssistants
                        currentAssistants={assistants}
                        isCompletePayment={isCompletePayment}
                        assistantArrived={assistantArrived}
                        handleClickOpenCheck={handleClickOpenCheck}
                        handleRowClick={handleRowClick}
                      />
                    ) : (
                      <AssistantsTable
                        currentAssistants={assistants}
                        handleSortAssistants={handleSortAssistants}
                        filters={filters}
                        assistantArrived={assistantArrived}
                        handleRowClick={handleRowClick}
                        isCompletePayment={isCompletePayment}
                        handleClickOpenCheck={handleClickOpenCheck}
                      />
                    )}
                    <Grid container justifyContent="center" mt={5}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={changePage}
                        sx={PAGINATION_STYLES}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <RegisterAssistantDialog
        openCheck={openCheck}
        handleCloseCheck={handleCloseCheck}
        validateAssistan={validateAssistan}
        selectedOption={selectedOption}
        handleCheckboxChange={handleCheckboxChange}
        handleRegisterButtonClick={handleRegisterButtonClick}
      />
    </>
  );
};

export default ListAssistants;
