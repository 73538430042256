import { useQuery } from "react-query";
import { getPremisesList } from "../../services/premisesServices";

export function useQueryPremisesList(organization_id, group_id) {
  return useQuery(
    ["premiseList", organization_id, group_id],
    () => getPremisesList(organization_id, group_id),
    {
      refetchOnWindowFocus: false,
    }
  );
}
