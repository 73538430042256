import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createAdminErrors, createAdminUser } from "../../classes/userClass";
import Header from "../../components/Header";
import LegalPerson from "../../components/account/LegalPerson";
import { transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { ROUTES } from "../../constants/routes";
import { changeEmptyStringNull } from "../../constants/utils";
import { ADMIN_ROLE, ROLES } from "../../constants/variables";
import { createAdmin } from "../../services/adminsServices";
import { givePermissions } from "../../services/domainsServices";

const CreateAdmin = ({ isAdminSimple }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createAdminUser)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAdminErrors))
  );
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDomains, setSelectedDomains] = useState([]);

  const breadcrumbs = [
    {
      name: t("ADMINISTRATOR_MANAGER"),
      link: "/admins",
    },
    {
      name: t("CREATE_ADMIN"),
      link: "/create_admin",
    },
  ];

  const setFormAdminRoles = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    const valueToAdd = e.target[atribute];
    const correspondingValue =
      valueToAdd === ROLES.ADMIN
        ? ROLES.DELEGATE
        : valueToAdd === ROLES.DELEGATE
        ? ROLES.ADMIN
        : valueToAdd === ROLES.AFFILIATED
        ? ""
        : ROLES.AFFILIATED;

    if (object[field]?.includes(valueToAdd)) return;
    object[field] = object[field]?.filter((role) => role !== correspondingValue);
    object[field] = [...object[field], valueToAdd];

    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleSubmit = async () => {
    let adminToSend = await transformObjectWithUrls(formData.user);
    // Es premise_ids como lo gestiona
    adminToSend.premises_ids = adminToSend.premises_id;

    if (!adminToSend.roles || adminToSend.roles.length === 0) {
      adminToSend.roles = [ROLES.ADMIN];
    }
    adminToSend.roles =
      typeof adminToSend.roles === "string"
        ? [ADMIN_ROLE[adminToSend.roles] || adminToSend.roles]
        : adminToSend.roles?.map((role) => ADMIN_ROLE[role] || role)?.filter((role) => role !== "");
    const adminWithNull = changeEmptyStringNull(adminToSend);
    try {
      const response = await createAdmin(adminWithNull);
      if (response !== undefined) {
        if (isAdminSimple) {
          for (const domain of selectedDomains) {
            const body = {
              user_id: response.id,
              domain_id: domain,
              can_edit: false,
            };
            await givePermissions(body);
          }
        }
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate(ROUTES.ADMINS);
        }, 1500);
      }
    } catch (error) {
      toastMessageError(error.response?.data?.error || t("EDIT_ERROR"));
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 0;
  };

  const formController = {
    formData,
    formParams: setFormParams,
    formAddRolesAdminParams: setFormAdminRoles,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
  };

  const addComponent = () => {
    return (
      activeStep === 0 && (
        <LegalPerson
          formController={formController}
          isAdminSimple={isAdminSimple}
          editPremises
          selectedDomains={selectedDomains}
          setSelectedDomains={setSelectedDomains}
        />
      )
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_ADMIN")} />
      {addComponent()}
    </>
  );
};

export default CreateAdmin;
