import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const WebBarItem = ({ icon, text, onClick, open }) => {
  return (
    <ListItem disablePadding sx={{ display: "block", color: "white" }}>
      <ListItemButton
        onClick={onClick}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default WebBarItem;
