import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HEADER_FORMATS, HEADER_OPTIONS, LOGO_POSITIONS } from "../../../../constants/styles";
import ColorInput from "../../../shared/ColorInput";
import "./headerFooter.scss";
import InfoToolTip from "../../../shared/InfoToolTip";

const HeaderStyles = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const principalColor = watch("style.principal_color") || "#ffffff";
  const selectedHeader = watch("style.header_type");
  const logoPosition = watch("style.logo_position");
  const headerColor = watch("style.header_color") || "#ffffff";
  const headerIconsColor = watch("style.header_icons_color") || "#ffffff";
  const headerIcons = watch("style.header_icons") || "";
  const headerBorderColor = watch("style.border_header") || "#ffffff";

  const handleSetHeader = (type) => {
    setValue("style.header_type", type);
  };

  const handleHeaderIconsChange = (e) => {
    const { value } = e.target;
    let newIconsArray = headerIcons ? headerIcons.split(",") : [];

    if (newIconsArray.includes(value)) {
      newIconsArray = newIconsArray.filter((icon) => icon !== value);
    } else {
      newIconsArray.push(value);
    }

    const newIcons = newIconsArray.join(",");
    setValue("style.header_icons", newIcons);
  };

  const isCheckedHeader = (value) => headerIcons?.split(",").includes(value);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h6">{t("HEADER")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{t("HEADER_DESCRIPTION")}</Typography>
        <Divider />
        <Grid container mt={2} gap={1}>
          <Grid
            item
            xs={12}
            md={6}
            className={HEADER_FORMATS.BASIC}
            onClick={() => handleSetHeader(HEADER_FORMATS.BASIC)}
            sx={{
              backgroundColor: selectedHeader === HEADER_FORMATS.BASIC ? headerColor : "#D9D9D9",
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={6}
            mb={2}
            className={HEADER_FORMATS.MIDDLE_CIRCLE}
            onClick={() => handleSetHeader(HEADER_FORMATS.MIDDLE_CIRCLE)}
            sx={{
              backgroundColor:
                selectedHeader === HEADER_FORMATS.MIDDLE_CIRCLE ? headerColor : "#D9D9D9",
              "&::before": {
                backgroundColor:
                  selectedHeader === HEADER_FORMATS.MIDDLE_CIRCLE ? headerColor : "#D9D9D9",
              },
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={HEADER_FORMATS.ROUNDED}
            onClick={() => handleSetHeader(HEADER_FORMATS.ROUNDED)}
            sx={{
              backgroundColor: selectedHeader === HEADER_FORMATS.ROUNDED ? headerColor : "#D9D9D9",
            }}
          ></Grid>
          <Grid item container alignItems="center" gap={2}>
            <Typography>{t("COLOR")}: </Typography>
            <ColorInput
              value={headerColor}
              onChange={(e) => setValue("style.header_color", e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className="checkbox-oniria"
                  checked={principalColor === headerColor}
                  onChange={(e) => {
                    setValue("style.header_color", e.target.checked ? principalColor : "#ffffff");
                  }}
                />
              }
              label={t("SAME_AS_PRINCIPAL")}
            />
          </Grid>
        </Grid>
        <Typography variant="h6">{t("LOGO_POSITION")}</Typography>
        <Divider />
        <RadioGroup
          value={logoPosition}
          defaultValue=""
          onChange={(e) => setValue("style.logo_position", e.target.value)}
        >
          <FormControlLabel
            value={LOGO_POSITIONS.LEFT}
            control={<Radio className="radio-oniria" />}
            label={t("LEFT_SIDE")}
          />
          <FormControlLabel
            value={LOGO_POSITIONS.CENTER}
            control={<Radio className="radio-oniria" />}
            label={t("IN_MIDDLE")}
          />
        </RadioGroup>
        <Grid container alignItems="center">
          <Typography variant="h6">{t("HEADER_ICONS")}</Typography>
          <InfoToolTip text={t("FOOTER_ICONS_TOOLTIP")} />
        </Grid>
        <Divider sx={{ marginBottom: "10px" }} />
        <FormGroup>
          {HEADER_OPTIONS.map((icon) => (
            <Grid key={icon}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox-oniria"
                    value={icon}
                    checked={isCheckedHeader(icon)}
                    onChange={handleHeaderIconsChange}
                  />
                }
                label={t(icon.toUpperCase())}
              />
              <Typography variant="caption">
                {icon !== "profile" ? t("VISIBLE_WEB") : ""}
              </Typography>
            </Grid>
          ))}
        </FormGroup>
        <Typography variant="h6">{t("COLOR_ICONS")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <Grid item container alignItems="center" gap={2}>
          <ColorInput
            value={headerIconsColor}
            onChange={(e) => setValue("style.header_icons_color", e.target.value)}
          />
        </Grid>
        <Typography variant="h6">{t("BORDER_BOTTOM_COLOR")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <Grid container alignItems="center">
          <ColorInput
            value={headerBorderColor}
            onChange={(e) => setValue("style.border_header", e.target.value)}
          />
          {headerBorderColor !== "" && (
            <IconButton
              component="label"
              role={undefined}
              sx={{ ml: 2 }}
              onClick={() => setValue("style.border_header", null)}
            >
              <DeleteIcon sx={{ color: "var(--oniria-red)" }} />
            </IconButton>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default HeaderStyles;
