import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./index.css";
import i18n from "./lang/i18n";
import { I18nextProvider } from "react-i18next";
import store from "./store/store";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
          {/* <Footer /> */}
        </BrowserRouter>
      </QueryClientProvider>
    </I18nextProvider>
  </Provider>
);
