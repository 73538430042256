import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getCategoriesDomainByAssignPermission } from "../../services/categoriesServices";

export function useQueryAssignDomains() {
  return useQuery(
    [RQ_KEY.CATEGORIES_DOMAINS_BY_PREMISE],
    () => getCategoriesDomainByAssignPermission(),
    {
      refetchOnWindowFocus: false,
    }
  );
}
