import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FOOTER_FORMATS, FOOTER_OPTIONS, LOPD_TYPES } from "../../../../constants/styles";
import ColorInput from "../../../shared/ColorInput";
import ImageUpload from "../../../shared/ImageUpload";
import InfoToolTip from "../../../shared/InfoToolTip";
import "./headerFooter.scss";

const FooterStyles = () => {
  const { t } = useTranslation();
  const { getValues, watch, setValue } = useFormContext();

  const selectedFooter = watch("style.footer_type");
  const footerColor = watch("style.footer_color") || "#ffffff";
  const headerColor = watch("style.header_color") || "#ffffff";
  const footerIconsColor = watch("style.footer_icons_color") || "#ffffff";
  const footerWebColor = watch("style.footer_web_color") || "#000000";
  const footerTextColor = watch("style.footer_text_color") || "#ffffff";
  const lopd = watch("style.lopd");
  const footerIcons = watch("style.footer_icons");

  const handleSetFooter = (type) => {
    setValue("style.footer_type", type);
  };

  const handleFooterIconsChange = (e) => {
    const { value } = e.target;
    let newIconsArray = footerIcons ? footerIcons.split(",") : [];

    if (newIconsArray.includes(value)) {
      newIconsArray = newIconsArray.filter((icon) => icon !== value);
    } else {
      newIconsArray.push(value);
    }

    const newIcons = newIconsArray.join(",");
    setValue("style.footer_icons", newIcons);
  };

  const isCheckedFooter = (value) => footerIcons?.split(",").includes(value);

  const handleSelectImage = (url, type) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setValue(type, null);
      } else {
        setValue(type, imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setValue(type, imageObject);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h6">{t("FOOTER_APP")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <Grid container mt={2} gap={1}>
          <Grid
            item
            xs={12}
            md={6}
            className={FOOTER_FORMATS.BASIC}
            onClick={() => handleSetFooter(FOOTER_FORMATS.BASIC)}
            sx={{
              backgroundColor: selectedFooter === FOOTER_FORMATS.BASIC ? footerColor : "#D9D9D9",
            }}
            component="label"
          ></Grid>
          <Grid
            item
            xs={12}
            md={6}
            mt={2}
            className="middle-circle-footer"
            onClick={() => handleSetFooter(FOOTER_FORMATS.MIDDLE_CIRCLE)}
            sx={{
              backgroundColor:
                selectedFooter === FOOTER_FORMATS.MIDDLE_CIRCLE ? footerColor : "#D9D9D9",
              "&::before": {
                backgroundColor:
                  selectedFooter === FOOTER_FORMATS.MIDDLE_CIRCLE ? footerColor : "#D9D9D9",
              },
            }}
            component="label"
          ></Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="rounded-footer"
            onClick={() => handleSetFooter(FOOTER_FORMATS.ROUNDED)}
            sx={{
              backgroundColor: selectedFooter === FOOTER_FORMATS.ROUNDED ? footerColor : "#D9D9D9",
            }}
            component="label"
          ></Grid>
          <Grid item container alignItems="center" gap={2}>
            <Typography>{t("COLOR")}: </Typography>
            <ColorInput
              value={footerColor}
              onChange={(e) => setValue("style.footer_color", e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className="checkbox-oniria"
                  checked={footerColor === headerColor}
                  onChange={(e) => {
                    setValue("style.footer_color", e.target.checked ? headerColor : "#ffffff");
                  }}
                />
              }
              label={t("SAME_HEADER_COLOR")}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Typography variant="h6">{t("FOOTER_ICONS")}</Typography>
          <InfoToolTip text={t("FOOTER_ICONS_TOOLTIP")} />
        </Grid>
        <Divider />
        <FormGroup>
          {FOOTER_OPTIONS.map((icon) => (
            <FormControlLabel
              key={icon}
              control={
                <Checkbox
                  className="checkbox-oniria"
                  value={icon}
                  checked={isCheckedFooter(icon)}
                  onChange={handleFooterIconsChange}
                />
              }
              label={t(icon.toUpperCase())}
            />
          ))}
        </FormGroup>
        <Typography variant="h6">{t("TEXT_COLOR")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <ColorInput
          value={footerTextColor}
          onChange={(e) => setValue("style.footer_text_color", e.target.value)}
        />
        <Typography variant="h6">{t("COLOR_ICONS")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <ColorInput
          value={footerIconsColor}
          onChange={(e) => setValue("style.footer_icons_color", e.target.value)}
        />
        <Typography variant="h6">{t("COLOR_WEB")}</Typography>
        <Divider sx={{ marginBottom: "10px" }} />
        <ColorInput
          value={footerWebColor}
          onChange={(e) => setValue("style.footer_web_color", e.target.value)}
        />
        <Typography variant="h6">{t("FOOTER_WEB")}</Typography>
        <Divider />
        <RadioGroup
          value={lopd}
          defaultValue=""
          onChange={(e) => setValue("style.lopd", e.target.value)}
        >
          <FormControlLabel
            value={LOPD_TYPES.ONIRIA}
            control={<Radio className="radio-oniria" />}
            label={t("LOPD_ONIRIA")}
            checked={lopd === LOPD_TYPES.ONIRIA}
          />
          <FormControlLabel
            checked={lopd !== LOPD_TYPES.ONIRIA}
            value={""}
            control={<Radio className="radio-oniria" />}
            label={t("LOPD_CUSTOM")}
          />
        </RadioGroup>
        {lopd !== LOPD_TYPES.ONIRIA && (
          <CKEditor
            editor={ClassicEditor}
            data={lopd}
            onChange={(_, editor) => {
              const data = editor.getData();
              setValue("style.lopd", data);
            }}
            config={{
              removePlugins: [
                "CKFinderUploadAdapter",
                "CKFinder",
                "EasyImage",
                "Image",
                "ImageCaption",
                "ImageStyle",
                "ImageToolbar",
                "ImageUpload",
                "MediaEmbed",
              ],
            }}
          />
        )}
        <Grid container>
          <Typography variant="h6">{t("LOGO_IN_FOOTER")}</Typography>
          <InfoToolTip text={t("LOGO_TOOLTIP")} />
        </Grid>
        <Divider />
        <ImageUpload
          url={getValues("style.footer_logo") || ""}
          multi={false}
          onImageSelect={(e) => handleSelectImage(e, "style.footer_logo")}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default FooterStyles;
