import PersonIcon from "@mui/icons-material/Person";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createAccountErrorsAllOk } from "../../classes/createAccountClass";
import { premise } from "../../classes/premiseClass";
import Header from "../../components/Header";
import Premise from "../../components/account/Premise";
import {
  isValidIBANNumber,
  objectWithBooleanParams,
  objectWithTemplateKeys,
  transformObjectWithUrls,
} from "../../components/shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { getPremiseById, putPremise } from "../../services/premisesServices";
import { getBicFromIban } from "../../services/utilsServices";

const PremiseDetail = () => {
  const { t } = useTranslation();
  const { premiseId } = useParams();
  const [formData, setFormData] = useState({});
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAccountErrorsAllOk))
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [premiseName, setPremiseName] = useState("");
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    const isEdit = searchParam.get("edit");
    if (isEdit) {
      setIsDisabled(false);
    }
  }, []);

  const handleQuerySuccess = (data) => {
    const premiseFromQuery = data[0];
    const premiseTemplated = objectWithTemplateKeys(premiseFromQuery, premise);
    const premiseReady = objectWithBooleanParams(premiseTemplated, [
      "events",
      "trips",
      "is_international",
    ]);
    const weekScheduleParsed = JSON.parse(premiseFromQuery.week_schedule);
    premiseReady.week_schedule = weekScheduleParsed;
    setFormData({ premise: premiseReady });
    setPremiseName(premiseReady.name);
  };

  const { isLoading } = useQuery("premise", () => getPremiseById(premiseId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  const breadcrumbs = [
    {
      name: t("VENUES"),
      link: "/premises",
    },
    {
      name: premiseName ? premiseName : "",
      link: "/create_premise",
    },
  ];

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const isFirstStep = () => {
    return true;
  };
  const isLastStep = () => {
    return true;
  };

  const submitPremise = async () => {
    const bodyToSend = await transformObjectWithUrls(formData.premise);
    bodyToSend.organization_id =
      bodyToSend.organization_id === "" ? userInfo.organization_id : bodyToSend.organization_id;
    bodyToSend.is_international = formData.premise.is_international === "true";
    bodyToSend.week_schedule = JSON.stringify(formData.premise.week_schedule);

    bodyToSend.latitude =
      !isNaN(bodyToSend.latitude) && bodyToSend.latitude !== "" ? bodyToSend.latitude : 0;
    bodyToSend.longitude =
      !isNaN(bodyToSend.longitude) && bodyToSend.longitude !== "" ? bodyToSend.longitude : 0;

    delete bodyToSend.created_at;
    delete bodyToSend.last_modified_at;
    try {
      const response = await putPremise(premiseId, bodyToSend);
      if (response !== undefined && !response.error) {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate("/premises");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(error.response.data.error ?? t("EDIT_ERROR"));
    }
  };

  const handlePendingUsers = (premiseId) => {
    navigate(`/premise/${premiseId}/pending_users`);
  };
  const checkIban = (classForm, e) => {
    let error = "";
    if (e.target.value === "") {
      error = "empty";
    } else {
      error = isValidIBANNumber(e.target.value) ? "" : t("INCORRECT");
      // BIC
      if (error === "") {
        getBicFromIban(e.target.value).then((result) => {
          // If the result returns a BIC...
          if (result.bankData.bic) {
            setFormParamsNotEvent(classForm, "bic", result.bankData.bic);
          }
          // And if not IBAN as BIC
          else {
            setFormParamsNotEvent(classForm, "bic", result.iban);
          }
        });
      }
    }
    setFormErrors(classForm, "iban", error);
    setFormParams(classForm, "iban", e);
  };

  const formController = {
    formData,
    formParams: setFormParams,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: submitPremise,
    setFormParamsNotEvent,
    checkIban,
  };

  const formActivate = () => {
    setIsDisabled(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={t("HEADER_TEXT_PREMISE_DETAIL") + `${premiseName}`}
      />
      <Box sx={{ position: "relative" }}>
        {isDisabled && (
          <Grid item xs={11} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              onClick={() => formActivate()}
              className="oniria-btn"
              variant="contained"
              sx={{
                fontSize: "12px",
                borderRadius: 3,
                ml: 2,
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {t("EDIT")}
            </Button>
            <Button
              onClick={() => handlePendingUsers(premiseId)}
              className="oniria-btn"
              variant="contained"
              sx={{
                fontSize: "12px",
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <PersonIcon />
              {t("PENDING_USERS")}
            </Button>
          </Grid>
        )}
        {formData.premise && (
          <Premise
            title={t("PREMISE_INFO")}
            formController={formController}
            isDisabled={isDisabled}
          />
        )}
      </Box>
    </>
  );
};

export default PremiseDetail;
