import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FONT_TEXT_OPTIONS } from "../../constants/styles";
import { ROLES } from "../../constants/variables";
import ImageUpload from "../shared/ImageUpload";
import InfoToolTip from "../shared/InfoToolTip";
import { focusColor } from "../shared/textFieldStyle";
import EmailInformationDomain from "./EmailInformation";
import SocialMediaDomain from "./SocialMedia";

const PrincipalInformation = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);

  const { setValue, getValues, register, control, formState } = useFormContext();
  const { errors } = formState;

  const handleSelectImage = (url, type) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setValue(type, null);
      } else {
        setValue(type, imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setValue(type, imageObject);
    }
  };

  return (
    <Accordion
      component={Paper}
      sx={{
        padding: 1,
        borderRadius: 2,
        mb: 2,
        maxWidth: "1300px",
        mt: 2,
        borderTopRightRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography variant="h5">{t("PRINCIPAL_INFORMATION")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {isSuperAdmin && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Typography>{t("URL_DOMAIN")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("URL_DOMAIN")}
                  sx={focusColor}
                  error={!!errors?.name}
                  {...register("name")}
                />
                {errors?.name && (
                  <Typography className="form-input-error">{errors?.name?.message}</Typography>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("WEB_NAME")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("NAME_SPREAD")}
                sx={focusColor}
                error={!!errors?.style?.web_name}
                {...register("style.web_name")}
              />
              {errors?.style?.web_name && (
                <Typography className="form-input-error">
                  {errors?.style?.web_name?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("APP_NAME")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("NAME_SPREAD")}
                sx={focusColor}
                error={!!errors?.style?.app_name}
                {...register("style.app_name")}
              />
              {errors?.style?.app_name && (
                <Typography className="form-input-error">
                  {errors?.style?.app_name?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={focusColor}>
              <Typography>{t("DEFAULT_LANGUAGE")}</Typography>
              <Controller
                name="style.default_language"
                control={control}
                render={({ field }) => (
                  <Select {...field} defaultValue={""}>
                    <MenuItem value="ES">{t("SPANISH")}</MenuItem>
                    <MenuItem value="EN">{t("ENGLISH")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={focusColor}>
              <Typography>{t("DEFAULT_FONT_TEXT")}</Typography>
              <Controller
                name="style.font_text"
                control={control}
                render={({ field }) => (
                  <Select {...field} defaultValue={""}>
                    {FONT_TEXT_OPTIONS.map((font) => (
                      <MenuItem value={font} key={font}>
                        <Typography fontFamily={`${font} !important`}>{font}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {/* FOOTER SECTION */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("FOOTER_PHONE")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("PHONE")}
                sx={focusColor}
                error={!!errors?.style?.footer_phone}
                {...register("style.footer_phone")}
              />
              {errors?.style?.footer_phone && (
                <Typography className="form-input-error">
                  {errors?.style?.footer_phone?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("FOOTER_CIF")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("CIF")}
                sx={focusColor}
                error={!!errors?.style?.footer_cif}
                {...register("style.footer_cif")}
              />
              {errors?.style?.footer_cif && (
                <Typography className="form-input-error">
                  {errors?.style?.footer_cif?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("FOOTER_EMAIL")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("EMAIL")}
                sx={focusColor}
                error={!!errors?.style?.footer_email}
                {...register("style.footer_email")}
              />
              {errors?.style?.footer_email && (
                <Typography className="form-input-error">
                  {errors?.style?.footer_email?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("FOOTER_ADDRESS")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("ADDRESS_PLACEHOLDER")}
                sx={focusColor}
                error={!!errors?.style?.footer_address}
                {...register("style.footer_address")}
              />
              {errors?.style?.footer_address && (
                <Typography className="form-input-error">
                  {errors?.style?.footer_address?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("WEB_FOOTER")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("WEB_FOOTER")}
                sx={focusColor}
                error={!!errors?.style?.footer_web}
                {...register("style.footer_web")}
              />
              {errors?.style?.footer_web && (
                <Typography className="form-input-error">
                  {errors?.style?.footer_web?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* SOCIAL MEDIA SECTION */}
        <SocialMediaDomain />
        {/* EMAIL SECTION */}
        <EmailInformationDomain />
        {/* LOGO SECTION */}
        <Grid item xs={12} sm={10} container mt={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Grid container>
                <Typography>{t("LOGO")}</Typography>
                <InfoToolTip text={t("LOGO_TOOLTIP")} />
              </Grid>
              <ImageUpload
                url={getValues("style.logo_url") || ""}
                multi={false}
                onImageSelect={(e) => handleSelectImage(e, "style.logo_url")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Grid container>
                <Typography>{t("DARK_LOGO")}</Typography>
                <InfoToolTip text={t("DARK_LOGO_TOOLTIP")} />
              </Grid>
              <ImageUpload
                url={getValues("style.dark_logo_url") || ""}
                multi={false}
                onImageSelect={(e) => handleSelectImage(e, "style.dark_logo_url")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Grid container>
                <Typography>{t("ICON")}</Typography>
                <InfoToolTip text={t("LOGO_TOOLTIP")} />
              </Grid>
              <ImageUpload
                url={getValues("style.icon_url") || ""}
                multi={false}
                onImageSelect={(e) => handleSelectImage(e, "style.icon_url")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrincipalInformation;
