import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { focusColor } from "../../../shared/textFieldStyle";

const SearchFilter = ({ onSearchChange }) => {
  return (
    <>
      <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
      <TextField
        label="Search"
        onChange={onSearchChange}
        size="small"
        sx={[focusColor, { width: "75%" }]}
      />
    </>
  );
};

export default SearchFilter;
