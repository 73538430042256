import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAccount, createAccountErrors } from "../../classes/createAccountClass";
import Header from "../../components/Header";
import Premise from "../../components/account/Premise";
import { isValidIBANNumber, transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { createPremise } from "../../services/premisesServices";
import { getBicFromIban } from "../../services/utilsServices";

const CreatePremise = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createAccount)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAccountErrors))
  );
  const [activeStep, setActiveStep] = useState(0);
  const userInfo = useSelector((state) => state.userInfo);
  const [redirecting, setRedirecting] = useState(false);
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      name: t("VENUES"),
      link: "/premises",
    },
    {
      name: t("CREATE_SITE"),
      link: "/create_premise",
    },
  ];

  //console.log(formData)
  function setFormParams(objectName, field, e, atribute = "value") {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  }

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.premise.organization_id || formData.premise.organization_id === "undefined") {
      formData.premise.organization_id = userInfo.organization_id;
    }
    formData.premise.is_international = formData.premise.is_international === "true";
    const premiseToSend = await transformObjectWithUrls(formData.premise);
    premiseToSend.week_schedule = JSON.stringify(formData.premise.week_schedule);
    premiseToSend.vat_number = premiseToSend.country + premiseToSend.cif;

    premiseToSend.latitude =
      !isNaN(premiseToSend.latitude) && premiseToSend.latitude !== "" ? premiseToSend.latitude : 0;
    premiseToSend.longitude =
      !isNaN(premiseToSend.longitude) && premiseToSend.longitude !== ""
        ? premiseToSend.longitude
        : 0;
    try {
      const response = await createPremise(premiseToSend);
      if (response !== undefined && !response.error) {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setRedirecting(true);
        setTimeout(() => {
          navigate("/premises");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 0;
  };
  const checkIban = (classForm, e) => {
    // console.log("New IBAN: ", e);
    // console.log("New IBAN: ", e.target.value);
    // console.log("IBAN OK: ", isValidIBANNumber(e.target.value));
    let error = "";
    if (e.target.value === "") {
      error = "empty";
    } else {
      error = isValidIBANNumber(e.target.value) ? "" : t("INCORRECT");
      // BIC
      if (error === "") {
        getBicFromIban(e.target.value).then((result) => {
          // If the result returns a BIC...
          if (result.bankData.bic) {
            setFormParamsNotEvent(classForm, "bic", result.bankData.bic);
          }
          // And if not IBAN as BIC
          else {
            setFormParamsNotEvent(classForm, "bic", result.iban);
          }
        });
      }
    }
    setFormErrors(classForm, "iban", error);
    setFormParams(classForm, "iban", e);
  };

  const formController = {
    formData,
    formParams: setFormParams,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    checkIban,
  };

  const addComponent = () => {
    return activeStep === 0 && <Premise formController={formController} />;
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_PREMISE")} />
      {addComponent()}
    </>
  );
};

export default CreatePremise;
