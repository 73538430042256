import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { focusColor } from "../../components/shared/textFieldStyle";
import { EVENT_TABS } from "../../constants/variables";
import { useQueryAssignDomains } from "../../hooks/queries/useQueryAssignDomains";
import { setReducerDomain } from "../../store/reducers/b2bReducer";

const SelectDomain = ({ eventCategories }) => {
  const dispatch = useDispatch();
  const { data: domainCategories } = useQueryAssignDomains();
  const selectedDomain = useSelector((state) => state.b2bInfo.selectedDomain);
  const selectedEventTab = useSelector((state) => state.b2bInfo.selectedTab);
  const userDomains = useSelector((state) => state.b2bInfo.domains);
  const [eventDomains, setEventDomains] = useState([]);

  const handleChange = (event) => {
    dispatch(setReducerDomain(event.target.value));
  };

  // Filter domains by event categories
  useEffect(() => {
    if (eventCategories && domainCategories) {
      const filteredDomains = domainCategories.filter((domain) =>
        domain.categories.some((category) =>
          eventCategories.some((eventCategory) => eventCategory.category_id === category.id)
        )
      );
      setEventDomains([...new Set(filteredDomains)]);
    }
  }, [eventCategories, domainCategories]);

  // Filter user domains based on event domains
  const filteredUserDomains = userDomains?.filter((domain) =>
    eventDomains.some((eventDomain) => eventDomain.name === domain)
  );

  // If there is only one domain, select it
  useEffect(() => {
    if (filteredUserDomains.length === 1 && selectedEventTab === EVENT_TABS.B2B) {
      dispatch(setReducerDomain(filteredUserDomains[0]));
    }
  }, [filteredUserDomains, selectedEventTab]);

  return (
    <FormControl sx={[focusColor, { minWidth: "170px" }]} size="small">
      <InputLabel id="domain-label">{t("ALL_DOMAINS")}</InputLabel>
      <Select
        labelId="domain-label"
        id="domain"
        value={selectedDomain || ""}
        label={t("ALL_DOMAINS")}
        onChange={handleChange}
      >
        <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
        {filteredUserDomains.map((domain) => (
          <MenuItem key={domain} value={domain}>
            {domain}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDomain;
