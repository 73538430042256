import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../constants/utils";
import { validateExtra, validateTicket } from "../../../services/eventsServices";
import { customToastMessage } from "../../shared/toastMessage";
import ToastTicketInfo from "./ToastTicketInfo";

const ValidateAssistant = ({ assistant, setQrResponse, setOpenCheck, eventId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleGoTicket = (ticketId) => {
    setOpenCheck(false);
    navigate(`/event/${eventId}?newValueTab=1&ticket=${ticketId}`);
  };

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const optionValue = e.target.value;
    if (optionValue === "all") {
      if (selectedOption.includes("all")) {
        setSelectedOption([]);
      } else {
        const allOptions = [
          "all",
          "ticket",
          ...assistant.extras.map((_, index) => `extra${index}`),
          ...assistant.extras.flatMap((extra, index2) => {
            if (extra.extra_consumitions != null && extra.times_can_be_consumed > 1) {
              return [...Array(extra.times_can_be_consumed)].map(
                (_, i) => `extra${index2}-item${i}`
              );
            } else {
              return [];
            }
          }),
        ];
        setSelectedOption(allOptions);
      }
    } else {
      setSelectedOption((prevSelectedOption) => {
        let relatedItems = [];
        if (prevSelectedOption.includes(optionValue)) {
          return prevSelectedOption.filter(
            (option) => option !== optionValue && !option?.includes(optionValue)
          );
        } else {
          if (optionValue.includes("extra") && !optionValue.includes("-item")) {
            const indexExtra = parseInt(optionValue.replace("extra", ""), 10);
            relatedItems = assistant.extras.flatMap((extra, index) => {
              if (
                extra.extra_consumitions != null &&
                index === indexExtra &&
                extra.times_can_be_consumed > 1
              ) {
                return [...Array(extra.times_can_be_consumed)].map(
                  (_, i) => `extra${index}-item${i}`
                );
              } else {
                return [];
              }
            });
          }
          return [...prevSelectedOption, optionValue, ...relatedItems];
        }
      });
    }
  };

  const handleRegisterButtonClick = async (e, selectedOptions) => {
    e.stopPropagation();
    try {
      let ticketId = "";
      let responseTicket = "";
      let responseExtra = "";

      if (selectedOptions.includes("ticket") && assistant.consumed_at === null) {
        const ticketResponse = await validateTicket(assistant.order_ticket_id);
        if (ticketResponse?.ticketExtraName) {
          responseTicket += ticketResponse.ticketExtraName;
        }
        if (ticketResponse?.ticketId) {
          ticketId = ticketResponse.ticketId;
        }
        setQrResponse(t("TICKET_OK"));
        setOpenCheck(false);
      }
      const prefixes = new Set(
        selectedOptions
          .filter((option) => option.includes("-item"))
          .map((option) => option.split("-")[0])
      );
      const filteredOptions = selectedOptions.filter((option) => !prefixes.has(option));

      for (let i = 0; i < filteredOptions.length; i++) {
        if (filteredOptions[i].startsWith("extra")) {
          const index = parseInt(filteredOptions[i].replace("extra", ""), 10);

          const { consumed_at, order_ticket_extra_id } = assistant.extras[index];
          if (consumed_at === null) {
            const extraResponse = await validateExtra(order_ticket_extra_id);
            console.log("extraResponse", extraResponse);
            if (extraResponse?.extraName) {
              responseExtra += extraResponse.extraName;
            }
          }
        }
      }

      customToastMessage(
        <ToastTicketInfo
          icon={<CheckIcon />}
          title={t("TICKET_VALIDATED_SUCCESS")}
          message={responseTicket + responseExtra}
          clickButton={() => handleGoTicket(ticketId)}
        />,
        "var(--green-success)"
      );
    } catch (error) {
      console.log("error en handleRegisterButtonClick -->", error);
      customToastMessage(
        <ToastTicketInfo
          icon={<CloseIcon />}
          title={t("ERROR")}
          message={error?.response?.data?.error || t("ERROR")}
        />,
        "var(--red-error)"
      );
    } finally {
      setSelectedOption("");
      setOpenCheck(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedOption("");
      setOpenCheck(false);
    };
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center" mt={5}>
      <Grid item xs={10}>
        <Grid container justifyContent="space-between" alignItems="center" gap={3}>
          {t("REGISTER_ATTENDANT")}
          <IconButton onClick={handleCloseCheck}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={10} mt={1} mb={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value="ticket"
                disabled={assistant?.consumed_at !== null}
                checked={assistant?.consumed_at !== null || selectedOption.includes("ticket")}
                onChange={handleCheckboxChange}
                className="checkbox-oniria"
                size="large"
              />
            }
            label={`${assistant?.ticket_name} ${
              assistant?.start_date ? formatDate(assistant?.start_date) : ""
            } ${assistant?.end_date ? `- ${formatDate(assistant?.end_date)}` : ""}`}
          />
          {assistant?.extras &&
            assistant.extras
              .filter((extra) => !extra.refunded_at)
              .map((extra, index) => (
                <>
                  <FormControlLabel
                    key={`extra${index}`}
                    control={
                      <Checkbox
                        key={extra.extra_id}
                        disabled={extra.consumed_at !== null}
                        checked={
                          extra.consumed_at !== null || selectedOption.includes(`extra${index}`)
                        }
                        value={`extra${index}`}
                        onChange={(e) => handleCheckboxChange(e, extra)}
                        className="checkbox-oniria"
                        size="large"
                      />
                    }
                    label={extra.extra_name}
                  />
                  <Grid container flexDirection="column">
                    {extra.extra_consumptions && extra.times_can_be_consumed > 1
                      ? [...Array(extra.times_can_be_consumed)].map((_, i) => (
                          <FormControlLabel
                            key={`extra-item-${i}`}
                            control={
                              <Checkbox
                                key={index}
                                disabled={extra.extra_consumptions[i]?.consumed_at !== null}
                                checked={
                                  extra.extra_consumptions[i]?.consumed_at !== null ||
                                  selectedOption.includes(`extra${index}-item${i}`)
                                }
                                value={`extra${index}-item${i}`}
                                onChange={(e) => handleCheckboxChange(e, extra)}
                                className="checkbox-oniria"
                                size="large"
                              />
                            }
                            label={extra.extra_name}
                          />
                        ))
                      : null}
                  </Grid>
                </>
              ))}
          <FormControlLabel
            control={
              <Checkbox
                value="all"
                checked={selectedOption.includes("all")}
                onChange={handleCheckboxChange}
                disabled={
                  assistant?.consumed_at !== null ||
                  assistant?.extras.some((extra) => extra.consumed_at !== null)
                }
                className="checkbox-oniria"
                size="large"
              />
            }
            label="Validar todo"
          />
        </FormGroup>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Button
          onClick={(e) => handleRegisterButtonClick(e, selectedOption)}
          fullWidth
          className="oniria-btn"
          sx={{ color: "#fff", fontWeight: "bold" }}
        >
          {t("VALIDATE")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ValidateAssistant;
