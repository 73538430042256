import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { focusColor } from "../../../shared/textFieldStyle";

const SelectFilter = (props) => {
  const { value, onChange, options } = props;

  return (
    <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ fontSize: "12px", borderRadius: 3 }}
      >
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFilter;
