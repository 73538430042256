import { Grid, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEventLanguage from "../../../../hooks/useEventLanguage";
import { checkField } from "../../../shared/FormsValidator";
import { focusColor } from "../../../shared/textFieldStyle";
import LanguageTabs from "../../languages/LanguageTabs";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";

const EventOutfit = ({
  trip,
  formParams,
  setFormErrors,
  detailsErrors,
  isDisabled,
  buttonClicked,
  setFormData,
  setEventLanguageInfoError,
}) => {
  const { t } = useTranslation();
  const { event_language_infos } = trip;

  const {
    selectedLanguage,
    setSelectedLanguage,
    selectedLanguageInfo,
    handleChangeAttribute,
    selectedLanguageIndex,
  } = useEventLanguage(event_language_infos, setFormData, setEventLanguageInfoError);

  const languageErrors = detailsErrors?.event_language_infos[selectedLanguageIndex];

  return (
    <Grid item xs={12}>
      <InputLabel
        sx={{
          fontSize: "18px",
          color: "black",
          mb: 2,
          mt: 1.8,
          whiteSpace: "unset",
        }}
      >
        {t("OUTFIT")}
      </InputLabel>
      {event_language_infos?.length > 0 ? (
        <LanguageTabs
          event_languages_info={trip.event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      ) : null}
      {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "outfit",
              e,
              /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{0,250}$/s,
              t("MAX_250"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.outfit || ""}
          margin="normal"
          multiline
          rows={2}
          fullWidth
          id="outfit"
          name="outfit"
          label={t("OUTFIT")}
          autoComplete="outfit"
          error={
            (detailsErrors.outfit !== "" && detailsErrors.outfit !== "empty") ||
            (detailsErrors.outfit === "empty" && buttonClicked)
          }
          helperText={detailsErrors.outfit !== "empty" ? detailsErrors.outfit : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeAttribute(
              "outfit",
              e.target.value,
              /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{0,250}$/s,
              t("MAX_250")
            )
          }
          value={selectedLanguageInfo?.outfit || ""}
          margin="normal"
          multiline
          rows={2}
          fullWidth
          id="outfit"
          name="outfit"
          label={t("OUTFIT")}
          autoComplete="outfit"
          error={
            (languageErrors.outfit !== "" && languageErrors?.outfit !== "empty") ||
            (languageErrors?.outfit === "empty" && buttonClicked)
          }
          helperText={languageErrors?.outfit !== "empty" ? languageErrors?.outfit : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default EventOutfit;
