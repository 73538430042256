import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getOrganizations } from "../../services/organizationsServices";
import { getCnae } from "../../services/utilsServices";
import SelectLatLong from "../premises/map/SelectLatLong";
import {
  allFieldsOk,
  checkField,
  checkFieldValue,
  handleImageSelect,
} from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { Loading } from "../shared/Loading";
import { focusColor } from "../shared/textFieldStyle";
import Address from "./forms/Address";
import BillingData from "./forms/BillingData";
import ButtonsForms from "./forms/ButtonsForms";
import Contact from "./forms/Contact";
import FiscalAddress from "./forms/FiscalAddress";
import HeaderForms from "./forms/HeaderForms";
import SocialMedia from "./forms/SocialMedia";
import WeekSchedule from "./forms/WeekSchedule";

const MyCheckbox = (props) => {
  const { error, ...otherProps } = props;

  return (
    <Checkbox
      {...otherProps}
      sx={{
        color: error ? "red" : "var(--secondary-color)",
        "&.Mui-checked": {
          color: error ? "red" : "var(--secondary-color)",
        },
      }}
      className={error ? "error-checkbox" : ""}
    />
  );
};

const Premise = (props) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const formController = props.formController;
  const { premise } = formController.formData;
  const { premise: premiseErrors } = formController.validation;
  const { formParams, setFormErrors, setFormParamsNotEvent } = formController;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isAffiliate = roles.find((element) => element === "affiliate");
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const [sameAddress, setSameAddress] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");

  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");

  const handleQuerySuccess = (data) => {
    setCategories(data);
  };
  const { isLoading } = useQuery("cnae", () => getCnae(), {
    onSuccess: handleQuerySuccess,
  });

  //Get de organizaciones solo para super-admin y manager
  const handleOrganizationsSuccess = (data) => {
    setOrganizations(data);
  };

  const getOrganizationInfo = useQuery("organizations", () => getOrganizations(), {
    onSuccess: handleOrganizationsSuccess,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleChangeOrganization = (e) => {
    setSelectedOrganization(e.target.value);
    premise.organization_id = e.target.value;
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    premise.business_category = event.target.value?.cnae_name;
    checkField(
      "premise",
      "business_category",
      event,
      /.*/,
      "There are incorrect characters",
      false,
      formParams,
      setFormErrors
    );
  };

  const handleCheckboxEvents = (event) => {
    const checkboxOK = event.target.checked || premise.trips;
    setFormErrors("premise", "events", checkboxOK ? "" : "empty");
    setFormErrors("premise", "trips", checkboxOK ? "" : "empty");
    formParams("premise", "events", event, "checked");
  };
  const handleCheckboxTravels = (event) => {
    const checkboxOK = event.target.checked || premise.events;
    setFormErrors("premise", "trips", checkboxOK ? "" : "empty");
    setFormErrors("premise", "events", checkboxOK ? "" : "empty");
    formParams("premise", "trips", event, "checked");
  };
  const handleCheckboxClients = (event) => {
    const newValue = event.target.value === "true";
    setFormParamsNotEvent("premise", "is_international", newValue);
  };

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };
  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  //Change fiscal address = address
  const handleCheckAddress = () => {
    setSameAddress(!sameAddress);
  };

  const handleChangeAddress = () => {
    if (sameAddress) {
      premise.fiscal_address = premise.address;
      premise.fiscal_postal_code = premise.postal_code;
      premise.fiscal_country = premise.country;
      premise.fiscal_state = premise.state;
      premise.fiscal_city = premise.city;
      setFormErrors("premise", "fiscal_address", "");
      setFormErrors("premise", "fiscal_city", "");
      setFormErrors("premise", "fiscal_postal_code", "");
      setFormErrors("premise", "fiscal_country", "");
      setFormErrors("premise", "fiscal_state", "");
    }
  };

  useEffect(() => {
    handleChangeAddress();
  }, [sameAddress, premise]);

  useEffect(() => {
    if (isSuperAdmin || isManager) {
      getOrganizationInfo.refetch();
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {/* HEADER */}
      <HeaderForms title={props.title} description={props.description} />
      {/* END HEADER */}
      {/* BODY */}
      <Grid
        container
        sx={{
          mt: 5,
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2 }}>
              <Grid container sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {(isSuperAdmin || isManager) &&
                  path !== "create_organization" &&
                  path !== "createaccount" && (
                    <Grid item xs={11} md={12}>
                      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                        {t("NAME_ORGANIZATION")}
                      </InputLabel>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={[focusColor, { mb: 2 }]}
                        error={premise.organization_id === "" && buttonClicked}
                      >
                        <InputLabel id="demo-select-small-label">
                          {t("NAME_ORGANIZATION")}
                        </InputLabel>
                        <Select
                          label={t("NAME_ORGANIZATION")}
                          value={premise.organization_id ? premise.organization_id : ""}
                          onChange={handleChangeOrganization}
                          disabled={isDisabled()}
                        >
                          {organizations?.map((organization) => (
                            <MenuItem key={organization.id} value={organization.id}>
                              {organization.legal_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                <Grid item xs={11} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("SITE_NAME")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "premise",
                        "name",
                        e,
                        /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_-]).{1,50}$/,
                        t("MAX_50"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    margin="normal"
                    label={t("SITE_NAME")}
                    required
                    fullWidth
                    id="name"
                    name="name"
                    value={premise.name}
                    autoComplete="name"
                    autoFocus
                    error={
                      (premiseErrors.name !== "" && premiseErrors.name !== "empty") ||
                      (premiseErrors.name === "empty" && buttonClicked)
                    }
                    helperText={premiseErrors.name !== "empty" ? premiseErrors.name : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("SITE_DESCRIPTION")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "premise",
                        "description",
                        e,
                        /^(?!.*[|\\#·$~%&¬/()^[\]+*{}_])[\s\S]{1,1000}$/,
                        t("MAX_1000"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    margin="normal"
                    fullWidth
                    required
                    multiline
                    rows={4}
                    id="description"
                    name="description"
                    label={t("SITE_DESCRIPTION")}
                    value={premise.description}
                    autoComplete="description"
                    error={
                      (premiseErrors.description !== "" && premiseErrors.description !== "empty") ||
                      (premiseErrors.description === "empty" && buttonClicked)
                    }
                    helperText={
                      premiseErrors.description !== "empty" ? premiseErrors.description : ""
                    }
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                {path !== "createaccount" && (
                  <>
                    <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                          <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                            {t("LOGO")} {t("MAX_4MB")}
                            <Tooltip title={t("LOGO_BACKGROUND_INFO")} sx={{ color: "black" }}>
                              <IconButton>
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </InputLabel>
                          <ImageUpload
                            url={premise.logo_url}
                            selectedImagesInForm={premise.logo_url}
                            onImageSelect={(url) =>
                              handleImageSelect(
                                "premise",
                                url,
                                "logo_url",
                                setFormParamsNotEvent,
                                setFormErrors
                              )
                            }
                            multi={false}
                            disabled={isDisabled()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                            {t("BACKGROUND")} {t("MAX_4MB")}
                            <Tooltip title={t("LOGO_BACKGROUND_INFO")} sx={{ color: "black" }}>
                              <IconButton>
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </InputLabel>
                          <ImageUpload
                            url={premise.header_url}
                            selectedImagesInForm={premise.header_url}
                            onImageSelect={(url) =>
                              handleImageSelect(
                                "premise",
                                url,
                                "header_url",
                                setFormParamsNotEvent,
                                setFormErrors
                              )
                            }
                            multi={false}
                            disabled={isDisabled()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Address
                    classForm={"premise"}
                    formParams={formParams}
                    formData={premise}
                    checkField={checkField}
                    errors={premiseErrors}
                    setErrors={setFormErrors}
                    isDisabled={isDisabled()}
                    buttonClicked={buttonClicked}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="sameAddress"
                        checked={sameAddress}
                        onChange={handleCheckAddress}
                        value={sameAddress}
                        disabled={isDisabled()}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={t("SAME_ADDRESS")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Contact
                    classForm={"premise"}
                    formParams={formParams}
                    formData={premise}
                    checkField={checkField}
                    errors={premiseErrors}
                    setErrors={setFormErrors}
                    isDisabled={isDisabled()}
                    buttonClicked={buttonClicked}
                    hasToCheckMail={false}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <WeekSchedule formController={formController} isDisabled={isDisabled()} />
                  <Grid item xs={12}>
                    <BillingData
                      classForm={"premise"}
                      formController={formController}
                      formParams={formParams}
                      formData={premise}
                      checkField={checkField}
                      errors={premiseErrors}
                      setErrors={setFormErrors}
                      isDisabled={isDisabled()}
                      buttonClicked={buttonClicked}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FiscalAddress
                      classForm={"premise"}
                      formParams={formParams}
                      formData={premise}
                      checkField={checkField}
                      errors={premiseErrors}
                      setErrors={setFormErrors}
                      isDisabled={isDisabled() || sameAddress}
                      buttonClicked={buttonClicked}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                      {t("BUSINESS_CATEGORY")}
                    </InputLabel>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={focusColor}
                        error={buttonClicked && premiseErrors.business_category === "empty"}
                      >
                        <InputLabel id="demo-select-small-label">{t("CATEGORY")}</InputLabel>
                        <Select
                          label="Categoría Empresarial"
                          value={selectedCategory ?? ""}
                          onChange={handleChangeCategory}
                          disabled={isDisabled()}
                          renderValue={(selected) => <Typography>{selected}</Typography>}
                        >
                          {categories.map((category) => (
                            <MenuItem key={category.cnae_code} value={category.cnae_name}>
                              {category.cnae_code} - {category.cnae_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {isAffiliate !== "affiliate" && (
                  <Grid item xs={12}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mt: 2 }}>
                      {t("WHO_ARE_YOUR_EVENTS")} *
                    </InputLabel>
                    <InputLabel sx={{ fontSize: "14px" }}>
                      {t("NATIONAL_OR_INTERNATIONAL")}
                    </InputLabel>
                    <Grid container sx={{ mt: 2, mb: 2, textAlign: "center" }}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="is_international"
                              checked={!premise.is_international}
                              onChange={handleCheckboxClients}
                              value="false"
                              disabled={isDisabled()}
                              sx={{
                                color: "var(--secondary-color)",
                                "&.Mui-checked": {
                                  color: "var(--secondary-color)",
                                },
                              }}
                            />
                          }
                          label={t("NATIONALS")}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="is_international"
                              checked={premise.is_international}
                              onChange={handleCheckboxClients}
                              value="true"
                              disabled={isDisabled()}
                              sx={{
                                color: "var(--secondary-color)",
                                "&.Mui-checked": {
                                  color: "var(--secondary-color)",
                                },
                              }}
                            />
                          }
                          label={t("INTERNATIONALS")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("ACTIVITIES_OFFER")} *
                  </InputLabel>
                  <InputLabel sx={{ fontSize: "14px" }}>{t("TWO_CHECKED")}</InputLabel>
                  <Grid container sx={{ mt: 2, textAlign: "center" }}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <MyCheckbox
                            name="events"
                            checked={premise.events}
                            onChange={handleCheckboxEvents}
                            disabled={isDisabled()}
                            error={premiseErrors.events === "empty" && buttonClicked}
                          />
                        }
                        label={t("EVENTS")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <MyCheckbox
                            name="trips"
                            checked={premise.trips}
                            onChange={handleCheckboxTravels}
                            disabled={isDisabled()}
                            error={
                              (premiseErrors.trips !== "" && premiseErrors.trips !== "empty") ||
                              (premiseErrors.trips === "empty" && buttonClicked)
                            }
                          />
                        }
                        label={t("TRAVELS")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mt: 1, mb: 1 }}>
                    {t("WEBSITE")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "premise",
                        "website",
                        e,
                        /^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[a-zA-Z]{2,}$/,
                        t("VALID_URL"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    margin="normal"
                    fullWidth
                    id="website"
                    name="website"
                    label="Web url"
                    required
                    value={premise.website}
                    autoComplete="website"
                    disabled={isDisabled()}
                    error={
                      (premiseErrors.website !== "" && premiseErrors.website !== "empty") ||
                      (premiseErrors.website === "empty" && buttonClicked)
                    }
                    helperText={premiseErrors.website !== "empty" ? premiseErrors.website : ""}
                    sx={focusColor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectLatLong
                    setFormParamsNotEvent={setFormParamsNotEvent}
                    formData={premise}
                    checkFieldValue={checkFieldValue}
                    setErrors={setFormErrors}
                    isDisabled={isDisabled()}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                  <SocialMedia
                    classForm={"premise"}
                    formParams={formParams}
                    formData={premise}
                    checkField={checkField}
                    errors={premiseErrors}
                    setErrors={setFormErrors}
                    isDisabled={isDisabled()}
                  />
                </Grid>
              </Grid>
            </Box>
            {!allFieldsOk(premiseErrors) && buttonClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
            )}
            {!isDisabled() ? (
              <ButtonsForms
                formController={formController}
                allFieldsOk={allFieldsOk}
                errors={premiseErrors}
                sendButtonClicked={catchButtonClicked}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Premise;
