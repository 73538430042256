import { Box, Divider, LinearProgress, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { soldTicketsPercent } from "../../../constants/eventsUtils";
import { PROGRESS_BAR_STYLES } from "../../../constants/styles";

const TicketsStock = ({ tickets }) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={3} sx={{ padding: 3, mb: 2, borderRadius: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("TICKETS_STOCK")}
      </Typography>
      {tickets.map((ticket, index) => (
        <Box key={`${ticket.id}-${index}`} sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="body1" fontWeight="bold">
              {ticket.ticket_name}
            </Typography>
            <Typography variant="body1">
              {ticket.sold_tickets}/{ticket.total_stock}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={soldTicketsPercent(ticket.sold_tickets, ticket.total_stock)}
            sx={PROGRESS_BAR_STYLES}
          />
          {/** SOLD TICKETS BY DOMAIN **/}
          {ticket?.sold_tickets_by_domain.map((soldTicket, index) => (
            <Box key={`${soldTicket.id}-${index}`} sx={{ mb: 1, pl: 2, mt: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography variant="body2">{soldTicket.bought_in_domain}</Typography>
                <Typography variant="body2">{soldTicket.sold_tickets}</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={soldTicketsPercent(soldTicket.sold_tickets, ticket.total_stock)}
                sx={PROGRESS_BAR_STYLES}
              />
            </Box>
          ))}
          {tickets.length > 1 ? <Divider sx={{ mt: 2 }} /> : null}
        </Box>
      ))}
    </Paper>
  );
};

export default TicketsStock;
