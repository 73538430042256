export const rrppClient = {
  alias: "",
  avatar_url: "",
  born_at: "",
  domain: "",
  domain_ids: [],
  email: "",
  name: "",
  phone: "",
  surname: "",
};

export const rrppClientErrors = {
  alias: "empty",
  avatar_url: "",
  born_at: "empty",
  domain_ids: [],
  domain: "",
  email: "",
  name: "empty",
  phone: "empty",
  surname: "empty",
};

export const rrppClientErrorsAllOk = {
  alias: "",
  avatar_url: "",
  born_at: "",
  domain_ids: [],
  domain: "",
  email: "",
  name: "",
  phone: "",
  surname: "",
};

export const editRrpp = {
  name: "",
  surname: "",
  phone: "",
  domain_ids: [],
};

export const editRrppErrors = {
  name: "",
  surname: "",
  phone: "",
  domain_ids: "",
};
