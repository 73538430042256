import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { focusColor } from "../../components/shared/textFieldStyle";
import { formatDate, getAgeByBirth } from "../../constants/utils";
import { getRrppById } from "../../services/rrppsServices";
import { RQ_KEY } from "../../constants/query";
import { PAGINATION_STYLES } from "../../constants/styles";

let perPage = 5;

const RrppDetail = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [page, setPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [originalEvents, setOriginalEvents] = useState([]);

  const { data, isLoading } = useQuery([RQ_KEY.RRPP_BY_ID, userId], () => getRrppById(userId), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setEvents(data.data_event);
      setOriginalEvents(data.data_event);
    },
  });

  function createTable(name, data) {
    return { name, data };
  }

  const basicDatas = useMemo(
    () => [
      createTable(t("PHONE_NUMBER") + ":", data?.phone),
      createTable(t("EMAIL") + ":", data?.email),
      createTable(t("BORN_AT") + ":", formatDate(data?.born_at)),
      createTable(
        t("DOMAINS") + ":",
        data?.domains
          ?.map((domain) => {
            return domain.domain_name + ", ";
          })
          .join("")
      ),
    ],
    [data, t]
  );

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setEvents(
      originalEvents.filter((event) =>
        event?.event_name?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
  };

  const breadcrumbs = [
    {
      name: t("RRPPS"),
      link: "/rrpps",
    },
    {
      name: data?.name ?? "",
      link: `/rrpp/${userId}`,
    },
  ];

  const urlDeploy = (deploy) => {
    if (deploy?.endsWith("/")) {
      return deploy;
    } else {
      return deploy + "/";
    }
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Box
        component={Paper}
        elevation={3}
        sx={{
          py: 2,
          px: 3,
          mb: 10,
          border: "1px solid #E4E4E4",
          borderRadius: "15px",
        }}
      >
        <Grid container alignItems="center">
          <Grid p={3}>
            <Avatar alt="rrpp_avatar" src={data?.avatar} sx={{ width: 200, height: 200 }} />
          </Grid>
          <Grid justifyContent="center" alignItems="center">
            <Typography variant="h3">{data?.name}</Typography>
            <Typography variant="h5">{data?.alias}</Typography>
            <Typography variant="h6">
              {getAgeByBirth(data?.born_at)} {t("AGES")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid style={{ overflowX: "auto" }}>
            <TableContainer size="small">
              <Table>
                <TableBody>
                  {basicDatas?.map((row) => (
                    <TableRow key={row?.name} sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container p={5}>
          <Table>
            <TableHead>
              <TableRow sx={{ "td, th": { borderBottom: 0 } }}>
                <TableCell align="center" sx={{ borderRight: 1, borderColor: "gray" }}>
                  {t("TOTAL_EVENTS_PARTICIPATED")}
                </TableCell>
                <TableCell align="center">{t("TOTAL_TICKETS_SOLD")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ "td, th": { borderBottom: 0 } }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 60,
                    borderRight: 1,
                    borderColor: "gray",
                  }}
                  align="center"
                >
                  {data.total_events ?? 0}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 60 }} align="center">
                  {data.total_tickets ?? 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {data.total_events !== 0 ? (
          <>
            <Grid container sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
              <Grid item>
                <SearchIcon
                  sx={{
                    height: 35,
                    width: 35,
                    mr: 1,
                    color: "var(--secondary-color)",
                  }}
                />
                <TextField
                  label={t("SEARCH")}
                  onChange={handleSearchChange}
                  size="small"
                  sx={[focusColor, { width: "75%" }]}
                />
              </Grid>

              <Pagination
                count={Math.ceil(data.data_event.length / perPage)}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
            <TableContainer sx={{ marginBottom: "10px", border: 1, borderColor: "lightgray" }}>
              <Table>
                <TableHead sx={{ backgroundColor: "lightgray" }}>
                  <TableRow sx={{ "td, th": { fontWeight: "bold" } }}>
                    <TableCell align="left">{t("PROPER_CASE_EVENT")}</TableCell>
                    <TableCell align="left">{t("JUST_DATE")}</TableCell>
                    <TableCell align="left">{t("JUST_LINK")}</TableCell>
                    <TableCell align="left">{t("NUMBER_TICKETS_SOLD")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "td, th": { borderBottom: 1, borderColor: "lightgray" },
                      }}
                    >
                      <TableCell align="left">{event.event_name}</TableCell>
                      <TableCell align="left">{formatDate(event.start_date)}</TableCell>
                      <TableCell align="left">
                        {urlDeploy(event.name) + "events/" + event.event_id + "?rrpp=" + data.alias}
                      </TableCell>
                      <TableCell align="left">{event.tickets_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default RrppDetail;
