import React from "react";
import Sidebar from "../components/Sidebar";
import { CssBaseline, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";


const Layout = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Grid container sx={{ display: 'flex', flexDirection: "row", justifyContent: "center" }}>
        <Grid item  sx={{ flexShrink: 0}}>
          <Sidebar />
        </Grid>
        <Grid item xs sx={{flexGrow: 1, overflow: 'auto', paddingInline: 4}}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
