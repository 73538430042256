import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const SocialMediaDomain = () => {
  const { t } = useTranslation();

  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography variant="h5">{t("SOCIAL_MEDIA")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>Facebook</Typography>
              <TextField
                variant="outlined"
                placeholder={"Facebook"}
                sx={focusColor}
                error={!!errors?.style?.social_facebook_url}
                {...register("style.social_facebook_url")}
              />
              {errors?.style?.social_facebook_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_facebook_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>WhatsApp</Typography>
              <TextField
                variant="outlined"
                placeholder={"WhatsApp"}
                sx={focusColor}
                error={!!errors?.style?.social_whatsapp_url}
                {...register("style.social_whatsapp_url")}
              />
              {errors?.style?.social_whatsapp_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_whatsapp_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>Instagram</Typography>
              <TextField
                variant="outlined"
                placeholder={"Instagram"}
                sx={focusColor}
                error={!!errors?.style?.social_instagram_url}
                {...register("style.social_instagram_url")}
              />
              {errors?.style?.social_instagram_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_instagram_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>Twitter</Typography>
              <TextField
                variant="outlined"
                placeholder={"Twitter"}
                sx={focusColor}
                error={!!errors?.style?.social_twitter_url}
                {...register("style.social_twitter_url")}
              />
              {errors?.style?.social_twitter_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_twitter_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>YouTube</Typography>
              <TextField
                variant="outlined"
                placeholder={"YouTube"}
                sx={focusColor}
                error={!!errors?.style?.social_youtube_url}
                {...register("style.social_youtube_url")}
              />
              {errors?.style?.social_youtube_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_youtube_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>TikTok</Typography>
              <TextField
                variant="outlined"
                placeholder={"TikTok"}
                sx={focusColor}
                error={!!errors?.style?.social_tiktok_url}
                {...register("style.social_tiktok_url")}
              />
              {errors?.style?.social_tiktok_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_tiktok_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>Spotify</Typography>
              <TextField
                variant="outlined"
                placeholder={"Spotify"}
                sx={focusColor}
                error={!!errors?.style?.social_spotify_url}
                {...register("style.social_spotify_url")}
              />
              {errors?.style?.social_spotify_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_spotify_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>iTunes</Typography>
              <TextField
                variant="outlined"
                placeholder={"iTunes"}
                sx={focusColor}
                error={!!errors?.style?.social_itune_url}
                {...register("style.social_itune_url")}
              />
              {errors?.style?.social_itune_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_itune_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>SoundCloud</Typography>
              <TextField
                variant="outlined"
                placeholder={"SoundCloud"}
                sx={focusColor}
                error={!!errors?.style?.social_soundcloud_url}
                {...register("style.social_soundcloud_url")}
              />
              {errors?.style?.social_soundcloud_url && (
                <Typography className="form-input-error">
                  {errors?.style?.social_soundcloud_url?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SocialMediaDomain;
