import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { EURO, formatDate, formatHours } from "../../../constants/utils";
import { useQueryUserCards } from "../../../hooks/queries/useQueryUserCards";
import { Loading } from "../../../components/shared/Loading";

const UserAffiliatedData = ({ userId }) => {
  const { t } = useTranslation();

  const { data: userCards, isLoading, error } = useQueryUserCards(userId);

  const numberCards = userCards ? userCards.length : 0;

  return (
    <Grid item xs={12} md={numberCards > 1 ? 12 : 12}>
      <TableContainer size="small">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="center" sx={{ fontWeight: "bold" }}>
                {t("AFFILIATED_DATA")}
              </TableCell>
            </TableRow>
            {isLoading ? (
              <Loading />
            ) : error ? (
              <Typography mt={2}>{t("ERROR_LOADING_DATA")}</Typography>
            ) : userCards && userCards.length > 0 ? (
              <Grid container mt={2} gap={1} justifyContent="center">
                {userCards.map((card, index) => (
                  <Grid
                    key={index}
                    item
                    sx={{ borderRadius: 3, border: "1px solid grey" }}
                    xs={12}
                    sm={5}
                  >
                    <TableRow sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {t("CARD_NAME")}:
                      </TableCell>
                      <TableCell align="left">
                        {card.card_name} ({card.premise_name})
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {t("AFFILIATED_NUMBER")}:
                      </TableCell>
                      <TableCell align="left">{card.affiliated_number}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {t("BOUGHT_DATE")}:
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(card.created_at)} {formatHours(card.created_at)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {t("EXPIRES_AT")}:
                      </TableCell>
                      <TableCell align="left">
                        {!card.expires_at
                          ? t("FOREVER")
                          : `${formatDate(card.expires_at)} ${formatHours(card.expires_at)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td, th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                        {t("PRICE")}:
                      </TableCell>
                      <TableCell align="left">{EURO.format(card.price)}</TableCell>
                    </TableRow>
                    {card.bought_in_domain && (
                      <TableRow sx={{ "td, th": { border: 0 } }}>
                        <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                          {t("BOUGHT_IN_DOMAIN")}:
                        </TableCell>
                        <TableCell align="left">{card.bought_in_domain}</TableCell>
                      </TableRow>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography mt={2}>{t("USER_HAVE_NOT_CARDS")}</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UserAffiliatedData;
