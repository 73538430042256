import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const EmailInformationDomain = () => {
  const { t } = useTranslation();

  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography variant="h5">{t("EMAILS_INFO")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("ANDROID_APP_URL")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("ANDROID_APP_URL")}
                sx={focusColor}
                error={!!errors?.android_url}
                {...register("android_url")}
              />
              {errors?.android_url && (
                <Typography className="form-input-error">{errors?.android_url?.message}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography>{t("IOS_APP_URL")}</Typography>
              <TextField
                variant="outlined"
                placeholder={t("IOS_APP_URL")}
                sx={focusColor}
                error={!!errors?.ios_url}
                {...register("ios_url")}
              />
              {errors?.ios_url && (
                <Typography className="form-input-error">{errors?.ios_url?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailInformationDomain;
