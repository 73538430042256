import { createSlice } from "@reduxjs/toolkit";

const initialState = { domains: [], selectedTab: 0, selectedDomain: "" };

const b2bSlice = createSlice({
  name: "b2bInfo",
  initialState: initialState,
  reducers: {
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
    setReducerTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setReducerDomain: (state, action) => {
      state.selectedDomain = action.payload;
    },
    clearTabDomain: (state) => {
      state.selectedDomain = "";
      state.selectedTab = 0;
    },
  },
});

export const { setDomains, setReducerTab, setReducerDomain, clearTabDomain } = b2bSlice.actions;

export default b2bSlice.reducer;
