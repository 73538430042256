import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Marker, useMapEvents } from "react-leaflet";

const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

function LocationMarker({ position, handleSetPosition }) {
  useMapEvents({
    click(e) {
      handleSetPosition(e.latlng.lat, e.latlng.lng);
    },
  });
  return position === null ? null : <Marker position={position} icon={customMarker} />;
}

export default LocationMarker;
