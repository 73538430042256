import React, { useState } from "react";
import Footer from "../components/Footer";
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { focusColor } from "../components/shared/textFieldStyle.jsx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPassword } from "../services/authenticationServices";
import { SALT } from "../constants/salt";
import bcrypt from "bcryptjs";
import { LOGOS } from "../constants/variables.jsx";
import { toastMessageError } from "../components/shared/toastMessage.jsx";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorPass, setErrorPass] = useState(false);
  const [errorRepeatPass, setErrorRepeatPass] = useState(false);
  const [differentPass, setDifferentPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParam] = useSearchParams();
  const [disabled, setDisabled] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePassChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrorPass(!validatePassword(value));
  };
  const handleRepeatPassChange = (e) => {
    const value = e.target.value;
    setRepeatPassword(value);
    setErrorRepeatPass(!validatePassword(value));
  };
  const validatePassword = (value) => {
    setDifferentPass("");
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{7,}$/;
    return regex.test(value);
  };
  const isFormValid = () => {
    if (!password || errorPass || !repeatPassword || errorRepeatPass) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const token = searchParam.get("token");
    const hashedPassword = await bcrypt.hash(password, SALT);
    const body = {
      token: token,
      password: hashedPassword,
    };
    const bodyToSend = JSON.parse(JSON.stringify(body));
    if (password !== repeatPassword) {
      setDifferentPass(true);
      setDisabled(false);
    } else {
      setDifferentPass(false);
      try {
        const response = await resetPassword(bodyToSend);
        if (response === "success") {
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        } else {
          setDisabled(false);
        }
      } catch (error) {
        setDisabled(false);
        toastMessageError(error.response.data.error || t("ERROR"));
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        sx={{ mt: 3, mb: 8, display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "row", mb: 5 }}>
          <Typography
            variant="subtitle1"
            sx={{
              mt: 5,
              ml: 1,
              cursor: "pointer",
              transition: "transform 0.3s, transform 0.3s",
              "&:hover": {
                fontWeight: "bold",
                transform: "scale(1.1)",
              },
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("GO_LOGIN")}
          </Typography>
        </Grid>
        <Grid
          container
          mx={2}
          spacing={4}
          sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
          <Grid
            item
            xs={11}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Box sx={{ height: 80 }} component="img" alt="Logo" src={LOGOS.PRINCIPAL_WHITE} />
          </Grid>
          <Grid
            item
            xs={11}
            md={6}
            sx={{ mb: 8, display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Box
              component={Paper}
              elevation={2}
              sx={{
                py: 2,
                px: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
                width: "100%",
              }}
            >
              <Typography className="oniria-colorText" variant="h6">
                {t("SET_PASSWORD")}
              </Typography>
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: "100%" }}
                onSubmit={handleSubmit}
              >
                <Typography>{t("SET_PASSWORD")}</Typography>
                <FormControl fullWidth variant="outlined" sx={{ ...focusColor, mt: 1, mb: 1 }}>
                  <OutlinedInput
                    fullWidth
                    autoComplete="password"
                    id="password"
                    name="password"
                    required
                    value={password}
                    onChange={handlePassChange}
                    type={showPassword ? "text" : "password"}
                    error={errorPass}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "var(--secondary-color)" }} />
                          ) : (
                            <Visibility sx={{ color: "var(--secondary-color)" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errorPass && (
                    <FormHelperText sx={{ color: "red" }}>{t("PASSWORD_ERROR")}</FormHelperText>
                  )}
                </FormControl>
                <Typography>{t("REPEAT_PASSWORD")}</Typography>
                <FormControl fullWidth variant="outlined" sx={[focusColor, { mt: 1, mb: 1 }]}>
                  <OutlinedInput
                    autoComplete="repeatPassword"
                    id="repeatPassword"
                    name="repeatPassword"
                    required
                    value={repeatPassword}
                    onChange={handleRepeatPassChange}
                    type={showPassword ? "text" : "password"}
                    error={errorRepeatPass}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "var(--secondary-color)" }} />
                          ) : (
                            <Visibility sx={{ color: "var(--secondary-color)" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errorRepeatPass && (
                    <FormHelperText sx={{ color: "red" }}>{t("PASSWORD_ERROR")}</FormHelperText>
                  )}
                </FormControl>
                {differentPass && (
                  <FormHelperText sx={{ color: "red" }}>{t("PASSWORD_REPEAT")}</FormHelperText>
                )}
                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    disabled={!isFormValid() || disabled}
                    className="oniria-btn"
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
                  >
                    {t("REGISTER")}
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default ForgotPassword;
