import { Checkbox, FormControlLabel, Grid, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkField } from "../../shared/FormsValidator";
import { focusColor } from "../../shared/textFieldStyle";

const TripMetrics = ({
  trip,
  formParams,
  setFormErrors,
  detailsErrors,
  buttonClicked,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          disabled={isDisabled}
          control={
            <Checkbox
              name="without_night"
              checked={trip.without_night === true || trip.without_night === 1}
              value={trip.without_night}
              className="checkbox-oniria"
              onChange={(e) =>
                checkField(
                  "details",
                  "without_night",
                  e,
                  /^(true|false)$/,
                  t("CHOOSE_OPTION"),
                  true,
                  formParams,
                  setFormErrors,
                  "checked"
                )
              }
            />
          }
          label={t("WITHOUT_NIGHT")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          disabled={isDisabled}
          control={
            <Checkbox
              name="passport_required"
              checked={trip.passport_required === true || trip.passport_required === 1}
              className="checkbox-oniria"
              value={trip.passport_required}
              onChange={(e) =>
                checkField(
                  "details",
                  "passport_required",
                  e,
                  /^(true|false)$/,
                  t("CHOOSE_OPTION"),
                  true,
                  formParams,
                  setFormErrors,
                  "checked"
                )
              }
            />
          }
          label={t("PASSPORT_REQUIRED")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            mt: 1.8,
            whiteSpace: "unset",
          }}
        >
          {t("NUMBER_COUNTRIES")}
        </InputLabel>
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "countries",
              e,
              /^(|[1-9]\d*)$/,
              t("INCORRECT"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.countries}
          margin="normal"
          type="number"
          fullWidth
          id="countries"
          name="countries"
          label={t("COUNTRIES")}
          autoComplete="countries"
          error={
            (detailsErrors.countries !== "" && detailsErrors.countries !== "empty") ||
            (detailsErrors.countries === "empty" && buttonClicked)
          }
          helperText={detailsErrors.countries !== "empty" ? detailsErrors.countries : ""}
          disabled={isDisabled}
          sx={focusColor}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            mt: 1.8,
            whiteSpace: "unset",
          }}
        >
          {t("NUMBER_CITIES")}
        </InputLabel>
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "cities",
              e,
              /^(|[1-9]\d*)$/,
              t("INCORRECT"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.cities}
          margin="normal"
          type="number"
          fullWidth
          id="cities"
          name="cities"
          label={t("CITIES")}
          autoComplete="cities"
          error={
            (detailsErrors.cities !== "" && detailsErrors.cities !== "empty") ||
            (detailsErrors.cities === "empty" && buttonClicked)
          }
          helperText={detailsErrors.cities !== "empty" ? detailsErrors.cities : ""}
          disabled={isDisabled}
          sx={focusColor}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            mt: 1.8,
            whiteSpace: "unset",
          }}
        >
          {t("NUMBER_DAYS")}
        </InputLabel>
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "days",
              e,
              /^(|[1-9]\d*)$/,
              t("INCORRECT"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.days}
          margin="normal"
          type="number"
          fullWidth
          id="days"
          name="days"
          label={t("NUMBER_DAYS")}
          autoComplete="days"
          error={
            (detailsErrors.days !== "" && detailsErrors.days !== "empty") ||
            (detailsErrors.days === "empty" && buttonClicked)
          }
          helperText={detailsErrors.days !== "empty" ? detailsErrors.days : ""}
          disabled={isDisabled}
          sx={focusColor}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            mt: 1.8,
            whiteSpace: "unset",
          }}
        >
          {t("NUMBER_NIGHTS")}
        </InputLabel>
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "nights",
              e,
              /^(|[1-9]\d*)$/,
              t("INCORRECT"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.nights}
          margin="normal"
          type="number"
          fullWidth
          id="nights"
          name="nights"
          label={t("NUMBER_NIGHTS")}
          autoComplete="nights"
          error={
            (detailsErrors.nights !== "" && detailsErrors.nights !== "empty") ||
            (detailsErrors.nights === "empty" && buttonClicked)
          }
          helperText={detailsErrors.nights !== "empty" ? detailsErrors.nights : ""}
          disabled={isDisabled}
          sx={focusColor}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            mt: 1.8,
            whiteSpace: "unset",
          }}
        >
          {t("RATING")}
        </InputLabel>
        <TextField
          onChange={(e) => {
            checkField(
              "details",
              "rating",
              e,
              /^(?:[0-4](?:\.[0-9])?|5(?:\.0)?)$|^$/,
              t("INCORRECT_RATING"),
              true,
              formParams,
              setFormErrors
            );
          }}
          value={trip.rating}
          margin="normal"
          type="number"
          fullWidth
          id="rating"
          name="rating"
          label={t("RATING")}
          autoComplete="rating"
          error={
            (detailsErrors.rating !== "" && detailsErrors.rating !== "empty") ||
            (detailsErrors.rating === "empty" && buttonClicked)
          }
          helperText={detailsErrors.rating !== "empty" ? detailsErrors.rating : ""}
          disabled={isDisabled}
          sx={focusColor}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0, max: 5 }}
        />
      </Grid>
    </Grid>
  );
};

export default TripMetrics;
