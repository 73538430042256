import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getEventsDomains } from "../../../services/eventsServices";
import { EVENT_TABS } from "../../../constants/variables";

export function useQueryEventsDomain(
  page,
  perPage,
  selectedTab,
  domain,
  search,
  category_id,
  subcategory_id,
  date,
  type,
  onSuccess
) {
  return useQuery(
    [RQ_KEY.EVENTS_DOMAIN, page, perPage, domain, search, category_id, subcategory_id, date, type],
    () => getEventsDomains(page, perPage, domain, search, category_id, subcategory_id, date, type),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === EVENT_TABS.B2B,
      onSuccess: onSuccess,
    }
  );
}
