import Close from "@mui/icons-material/Close";
import { FormControl, Grid, IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateSponsorDomain } from "../../../services/domainsServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

function UpdateSponsor({ isOpen, onClose, sponsor, refetch }) {
  const { t } = useTranslation();
  const [newSponsor, setNewSponsor] = useState({
    name: "",
    link: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNewSponsor(sponsor);
  }, [sponsor]);

  const handleUpdateSponsor = async () => {
    setLoading(true);
    try {
      await updateSponsorDomain(newSponsor.id, newSponsor);
      toastMessageSuccess(t("SUCCESS_EDIT_SPONSOR"));
    } catch (e) {
      toastMessageError(e.response?.data?.error ?? t("ERROR"));
    } finally {
      onClose();
      setLoading(false);
      refetch();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", paddingTop: 0 }}>
        {t("EDIT_SPONSOR")}
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ minWidth: "250px" }}>
          <TextField
            id="name"
            label={t("NAME")}
            type="text"
            fullWidth
            sx={[focusColor, { marginTop: "20px" }]}
            value={newSponsor?.name}
            onChange={(e) => setNewSponsor({ ...newSponsor, name: e.target.value })}
          />
          <TextField
            id="name"
            label={t("NEW_SPONSOR_ULR")}
            type="text"
            fullWidth
            sx={[focusColor, { marginTop: "20px" }]}
            value={newSponsor?.link}
            onChange={(e) => setNewSponsor({ ...newSponsor, link: e.target.value })}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "16px", borderRadius: 3, fontWeight: "500" }}
          onClick={handleUpdateSponsor}
          disabled={loading}
        >
          {loading ? t("SAVING") : t("EDIT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateSponsor;
