import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { extra, extraErrors, extraLanguageInfo } from "../../../classes/tripClass";
import { deleteExtra } from "../../../services/eventsServices";
import ButtonsForms from "../../account/forms/ButtonsForms";
import ExtraCard from "./ExtraCard";

const Extras = ({ formController, buttonClicked, setButtonClicked, isDisabled }) => {
  const { t } = useTranslation();
  const {
    formData: {
      extras,
      details: { event_language_infos },
    },
    validation,
    setFormData,
    setFormErrorsTicketsAndExtras,
  } = formController;
  const { extras: extrasErrors } = validation;

  const setErrors = (extrasErrors) => {
    setFormErrorsTicketsAndExtras("extras", extrasErrors);
  };

  const addExtra = () => {
    //
    const newIdioms = event_language_infos.map((language) => {
      return {
        ...extraLanguageInfo,
        language: language.language,
      };
    });
    //
    const newExtra = {
      ...extra,
      extra_language_infos: [...newIdioms],
    };
    //
    setFormData((prev) => ({
      ...prev,
      extras: [...extras, newExtra],
    }));
    //
    const newExtraErrors = JSON.parse(JSON.stringify(extraErrors));
    setErrors([...extrasErrors, newExtraErrors]);
  };

  const removeExtra = async (index) => {
    //
    if (extras[index].id) {
      await deleteExtra(extras[index].id);
    }
    //
    const updatedExtras = extras.filter((_, extraIndex) => extraIndex !== index);
    setFormData((prev) => ({
      ...prev,
      extras: updatedExtras,
    }));
    //
    const updatedExtrasErrors = extrasErrors.filter((_, extraIndex) => extraIndex !== index);
    setErrors(updatedExtrasErrors);
  };

  const checkExtraField = (key, e, regex, errorMessage, canBeEmpty, index, parameter = "value") => {
    //
    const newValue = e.target[parameter];
    let errorValue = "";
    //
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (
      key === "initial_stock" &&
      parseInt(extras[index].stock) + (parseInt(newValue) - parseInt(extras[index].initial_stock)) <
        0
    ) {
      errorValue = t("STOCK_ERROR");
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    //
    const errorsToModify = JSON.parse(JSON.stringify(extrasErrors));
    const currentExtraErrors = errorsToModify[index];
    currentExtraErrors[key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    const updatedExtras = JSON.parse(JSON.stringify(extras));
    updatedExtras[index][key] = newValue;
    setFormData((prev) => ({
      ...prev,
      extras: updatedExtras,
    }));
  };

  const allExtrasOk = () => {
    for (const extraErrors of extrasErrors) {
      for (const key in extraErrors) {
        if (extraErrors[key] !== "" && key !== "extra_language_infos") {
          return false;
        }
      }
    }
    return true;
  };

  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  // drag and drop
  const handleSetExtrasPositions = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    const newExtras = [...extras];
    const [reorderedExtra] = newExtras.splice(source.index, 1);
    newExtras.splice(destination.index, 0, reorderedExtra);
    setFormData((prev) => ({
      ...prev,
      extras: newExtras,
    }));
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <DragDropContext onDragEnd={handleSetExtrasPositions}>
              <Droppable droppableId="extras">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {extras && extras.length > 0
                      ? extras.map((_, index) => {
                          return (
                            <ExtraCard
                              key={index}
                              formController={formController}
                              index={index}
                              checkExtraField={checkExtraField}
                              errors={extrasErrors}
                              removeExtra={removeExtra}
                              sendButtonClicked={buttonClicked}
                              isDisabled={isDisabled}
                              event_language_infos={event_language_infos}
                              setFormData={setFormData}
                            />
                          );
                        })
                      : ""}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              sx={{
                color: "var(--secondary-color)",
                borderColor: "var(--secondary-color)",
                "&:hover": {
                  color: "var(--secondary-color)",
                  borderColor: "var(--secondary-color)",
                  backgroundColor: "white",
                },
              }}
              onClick={() => addExtra()}
            >
              {t("ADD_EXTRA")}
            </Button>
          </Grid>
          {!isDisabled && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allExtrasOk}
              errors={extrasErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Extras;
