import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { focusColor } from '../../shared/textFieldStyle.jsx'
import { useQuery } from 'react-query'
import { getCountries, getRegions } from '../../../services/utilsServices.jsx'

const Address = props => {
  const { t } = useTranslation()
  const { buttonClicked } = props
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [regions, setRegions] = useState([])
  const [selectedState, setSelectedState] = useState('')

  useEffect(() => {
    if (props.formData.country !== '' && countries.length > 0) {
      const result = countries.find(obj => obj.country_code === props.formData.country)
      setSelectedCountry(result?.country_code.toLowerCase() + '-' + result?.official_name_en)
      getRegions(props.formData.country).then(response => {
        setRegions(response)
      })
    }
  }, [props.formData.country, countries])

  useEffect(() => {
    if (props.formData.state !== '' && regions.length > 0) {
      setSelectedState(props.formData.state)
    }
  }, [props.formData.state, regions])

  const handleQueryCountriesSuccess = data => {
    setCountries(data)
  }

  const { isLoading } = useQuery('countries', () => getCountries(), {
    onSuccess: handleQueryCountriesSuccess,
  })

  const handleChangeCountry = event => {
    setSelectedCountry(event.target.value)
    props.formData.country = event.target.value?.split('-')[0].toUpperCase()
    props.setErrors(props.classForm, 'country', '')
  }

  const handleStateChange = event => {
    setSelectedState(event.target.value)
    props.formData.state = event.target.value
    props.setErrors(props.classForm, 'state', '')
  }

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false
  }

  if (isLoading) {
    return <></>
  }
  return (
    <>
      <InputLabel sx={{ fontSize: '18px', color: 'black' }}>{t('ADDRESS')}</InputLabel>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'address',
                e,
                /^(?!.*[|\\#·$~%&¬()^[\]+*{}_]).{1,250}$/,
                t('MAX_250'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="address"
            name="address"
            value={props.formData.address}
            label={t('STREET')}
            autoComplete="address"
            disabled={props.isDisabled}
            error={
              (props.errors.address !== '' && props.errors.address !== 'empty') ||
              (buttonClicked && props.errors.address === 'empty')
            }
            helperText={props.errors.address !== 'empty' ? props.errors.address : ''}
            sx={focusColor}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'postal_code',
                e,
                /^[0-9A-Za-z]{4,}$/,
                t('INCORRECT'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="postal_code"
            name="postal_code"
            value={props.formData.postal_code}
            label={t('POSTAL_CODE')}
            autoComplete="postal_code"
            disabled={props.isDisabled}
            error={
              (props.errors.postal_code !== '' && props.errors.postal_code !== 'empty') ||
              (buttonClicked && props.errors.postal_code === 'empty')
            }
            helperText={props.errors.postal_code !== 'empty' ? props.errors.postal_code : ''}
            sx={focusColor}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} mt={1}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={focusColor}
            error={selectedCountry === '' && buttonClicked}
          >
            <InputLabel id="demo-select-small-label">{t('COUNTRY')}</InputLabel>
            <Select
              label={t('COUNTRY')}
              value={selectedCountry ? selectedCountry : ''}
              onChange={handleChangeCountry}
              disabled={isDisabled()}
              renderValue={selected => (
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                  <img
                    style={{ height: '20px', width: '35px', objectFit: 'cover', marginRight: 10 }}
                    alt="Flag"
                    src={`/media/flags/${selected.split('-')[0]}.svg`}
                  />
                  <Typography>{selected.split('-')[1]}</Typography>
                </Grid>
              )}
            >
              {countries.map(country => (
                <MenuItem
                  key={country.country_code}
                  value={country.country_code.toLowerCase() + '-' + country.official_name_en}
                >
                  <img
                    style={{ height: '20px', width: '35px', objectFit: 'cover', marginRight: 10 }}
                    alt="Flag"
                    src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                  />
                  {country.official_name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={focusColor}
            error={selectedState === '' && buttonClicked}
          >
            <InputLabel id="state-label">{t('STATE')}</InputLabel>
            <Select
              disabled={isDisabled()}
              label={t('STATE')}
              id="state"
              value={selectedState}
              onChange={handleStateChange}
            >
              {regions.map(state => (
                <MenuItem key={state.code} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'city',
                e,
                /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_-]).{1,50}$/,
                t('MAX_50'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="city"
            name="city"
            value={props.formData.city}
            label={t('CITY')}
            autoComplete="city"
            disabled={props.isDisabled}
            error={
              (props.errors.city !== '' && props.errors.city !== 'empty') ||
              (buttonClicked && props.errors.city === 'empty')
            }
            helperText={props.errors.city !== 'empty' ? props.errors.city : ''}
            sx={focusColor}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Address
