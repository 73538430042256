import React, { useState } from "react"
import Footer from "../components/Footer"
import { Box, Button, Grid, TextField, Typography, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { focusColor } from "../components/shared/textFieldStyle.jsx"
import { emailExists, forgotPassword } from "../services/authenticationServices"
import {
  toastMessageError,
  toastMessageSuccess,
} from "../components/shared/toastMessage.jsx"
import { LOGOS } from "../constants/variables.jsx"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)

  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    setEmailError(!validateEmail(value))
  }
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(value)
  }
  const isFormValid = () => {
    if (!email || emailError) {
      return false
    }
    return true
  }

  //Check si el email pertenece a algún usuario
  const userExists = async () => {
    try {
      let exist = await emailExists(email)
      if (exist.user_exists) {
        return true
      } else {
        toastMessageError(t("USER_NOT_EXIST"))
        return false
      }
    } catch (error) {
      console.error("Error userExists ForgotPassword", error)
      toastMessageError(error.response?.data?.error ?? "Something went wrong")
      return false
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid()) {
      return
    }
    try {
      const userExistsResult = await userExists()
      if (!userExistsResult) {
        return
      }
      const dataToSend = JSON.stringify({ email: email })
      let response = await forgotPassword(dataToSend)
      if (response === "success") {
        toastMessageSuccess(t("CHECK_EMAIL"))
        setTimeout(() => {
          navigate("/login")
        }, 1500)
      }
    } catch (error) {
      console.error("Error handleSubmit ForgotPassword", error)
      toastMessageError(error.response?.data?.error ?? "Something went wrong")
    }
  }

  return (
    <>
      <Grid
        container
        spacing={4}
        sx={{
          mb: 8,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid item xs={11} sx={{ m:3, p: 0, display: "flex", flexDirection: "row", cursor: "pointer" }} onClick={() => {
              navigate("/login")
            }}>
          <Typography
            variant="subtitle1"
            sx={{
              ml: 1,
              cursor: "pointer",
              transition: "font-weight 0.3s, font-size 0.3s",
              "&:hover": {
                fontWeight: "bold",
                fontSize: "1.1rem",
              },
            }}
            onClick={() => {
              navigate("/login")
            }}
          >
            {t("GO_LOGIN")}
          </Typography>
        </Grid>
        <Grid
          container
          mx={2}
          spacing={4}
          sx={{
            mb: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ height: 80 }}
              component="img"
              alt="Logo"
              src={LOGOS.PRINCIPAL_WHITE}
            />
          </Grid>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              component={Paper}
              elevation={2}
              sx={{
                py: 2,
                px: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
              }}
            >
              <Typography className="oniria-colorText" variant="h6">
                {t("FORGOT_PASSWORD")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={focusColor}
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError && t("INCORRECT_EMAIL")}
                />

                <Button
                  disabled={!isFormValid()}
                  className="oniria-btn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
                  onClick={handleSubmit}
                >
                  {t("SEND_EMAIL")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default ForgotPassword
