import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

const InfoToolTip = ({ text, styles }) => {
  return (
    <Tooltip
      title={text}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "var(--grey-blue)",
            fontSize: "1rem",
          },
          textAlign: "center",
          textWrap: "pretty",
          ...styles,
        },
      }}
    >
      <IconButton sx={{ height: 25, width: 35 }}>
        <InfoOutlinedIcon sx={{ color: "var(--grey-dark)" }} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoToolTip;
