import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { findAllDomains } from "../../services/domainsServices";
import { useDispatch } from "react-redux";
import { setDomains } from "../../store/reducers/b2bReducer";

export function useQueryDomains() {
  const dispatch = useDispatch();

  return useQuery([RQ_KEY.DOMAINS], findAllDomains, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const domainNames = data.map((domain) => domain.domain_name);
      dispatch(setDomains(domainNames));
    },
  });
}
