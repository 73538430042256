import Close from "@mui/icons-material/Close";
import { FormControl, Grid, IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_STYLES } from "../../../constants/styles";
import { createDomain } from "../../../services/domainsServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import ChoseEntity from "../choseEntity/ChooseEntity";

function AddDomain({ isOpen, onClose, refetch }) {
  const { t } = useTranslation();
  const [selectedEntity, setSelectedEntity] = useState({
    grouping_id: "all",
    organization_id: "all",
    premise_id: "all",
  });
  const [domainName, setDomainName] = useState("");
  const [error, setError] = useState("");

  const handleChooseEntity = (name, value) => {
    setSelectedEntity({
      grouping_id: "all",
      organization_id: "all",
      premise_id: "all",
      [name]: value,
    });
  };

  const handleChangeDomainName = (e) => {
    setDomainName(e.target.value);
    if (error) setError("");
  };

  const checkError = () => {
    if (!domainName.includes(".")) {
      setError(t("DOMAIN_NAME_ERROR"));
      return true;
    }
    return false;
  };

  const handleCreateDomain = async () => {
    if (checkError()) return;

    const { grouping_id, organization_id, premise_id } = selectedEntity;
    try {
      const bodyToSend = {
        style: {
          ...DEFAULT_STYLES,
        },
        name: domainName,
        grouping_id: grouping_id === "all" ? "" : grouping_id,
        organization_id: organization_id === "all" ? "" : organization_id,
        premise_id: premise_id === "all" ? "" : premise_id,
      };
      await createDomain(bodyToSend);
      toastMessageSuccess(t("SUCCESS_CREATE_STYLES"));
    } catch (e) {
      toastMessageError(e.response?.data?.error ?? t("ERROR_CREATE_STYLES"));
    } finally {
      onClose();
      refetch();
      setSelectedEntity({
        grouping_id: "all",
        organization_id: "all",
        premise_id: "all",
      });
      setDomainName("");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", paddingTop: 0 }}>
        {t("ADD_DOMAIN")}
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <ChoseEntity
            grouping={selectedEntity.grouping_id}
            premise={selectedEntity.premise_id}
            organization={selectedEntity.organization_id}
            handleChooseEntity={handleChooseEntity}
          />
          <TextField
            id="name"
            label={t("DOMAIN_NAME")}
            type="text"
            fullWidth
            value={domainName}
            onChange={handleChangeDomainName}
            sx={[focusColor, { marginTop: "20px" }]}
            error={!!error}
            helperText={error}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "16px", borderRadius: 3, fontWeight: "600" }}
          onClick={handleCreateDomain}
        >
          {t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDomain;
