import { Grid, Paper, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useState } from "react";
import Statistics from "../events/statistics/Statistics";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const HomeStatistics = () => {
  const { t } = useTranslation();

  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${month}/${year}`;
  };

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    const newDate = new Date(selectedDate); // Clonar el objeto Date actual
    if (name === "month") {
      newDate.setMonth(parseInt(value, 10) - 1);
    } else if (name === "year") {
      newDate.setFullYear(parseInt(value, 10));
    }
    setSelectedDate(newDate);
  };

  return (
    <Grid container component={Paper} elevation={4} mb={20} borderRadius="15px" padding={2}>
      <Grid container justifyContent="space-between">
        <Typography
          className="oniria-colorText"
          sx={{ fontWeight: "bold", mb: 2, ml: 2 }}
          variant="h5"
        >
          {t("STATISTICS")}
        </Typography>
        <Grid>
          <FormControl sx={focusColor}>
            <InputLabel id="demo-select-small-label">{t("MONTH")}</InputLabel>
            <Select
              name="month"
              label={t("MONTH")}
              value={selectedDate.getMonth() + 1}
              onChange={handleChangeDate}
              sx={[focusColor, { marginRight: 2 }]}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {t(`MONTH_NAMES_${index}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={focusColor}>
            <InputLabel id="demo-select-small-label">{t("YEAR")}</InputLabel>
            <Select
              name="year"
              label={t("YEAR")}
              value={selectedDate.getFullYear()}
              onChange={handleChangeDate}
            >
              {Array.from({ length: 6 }, (_, index) => (
                <MenuItem key={index} value={today.getFullYear() - index}>
                  {today.getFullYear() - index}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Statistics month={formatDate(selectedDate)} fromHome />
      </Grid>
    </Grid>
  );
};

export default HomeStatistics;
