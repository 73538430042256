export const focusColor = {
  "& label.Mui-focused": {
    color: "var(--secondary-color)",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "var(--secondary-color)",
    },
  },
};

export const steeperStyle = {
  "& .MuiStepLabel-root .Mui-disabled": { color: "var(--secondary-color)" },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "var(--secondary-color)",
    opacity: "100%",
    fontWeight: "bolder",
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "var(--secondary-color)",
    opacity: "100%",
    fontWeight: "bolder",
  },
  "& .MuiSvgIcon-root": { color: "var(--secondary-color)" },
  "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
  "& .MuiStepIcon-text ": { fill: "white" },
  mx: 2,
};
