import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getOrganizations(grouping) {
  const params = new URLSearchParams();
  if (grouping && grouping !== "all") {
    params.append("grouping_id", grouping);
  }

  const { data } = await http.get(`${SERVICE.ORGANIZATIONS}?${params.toString()}`);
  return data;
}

export async function getOrganizationById(id) {
  const { data } = await http.get(`${SERVICE.ORGANIZATIONS}${id}`);
  return data;
}

export async function createOrganization(body) {
  const { data } = await http.post(`${SERVICE.ORGANIZATIONS}`, body);
  return data;
}

export async function putOrganization(id, body) {
  const { data } = await http.put(`${SERVICE.ORGANIZATIONS}${id}`, body);
  return data;
}

export async function deleteOrganization(id) {
  const { data } = await http.delete(`${SERVICE.ORGANIZATIONS}${id}`);
  return data;
}

export async function getAllOrganizationsValidation() {
  const { data } = await http.get(`${SERVICE.ORGANIZATIONS_VALIDATION}`);
  return data;
}

export async function putValidateOrganization(id, body) {
  const { data } = await http.put(`${SERVICE.ORGANIZATION_VALID}${id}`);
  return data;
}

export async function getAllGrouping() {
  const { data } = await http.get(`${SERVICE.ORGANIZATIONS}groupings`);
  return data;
}
