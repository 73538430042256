import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BrushIcon from "@mui/icons-material/Brush";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteDomain } from "../../services/domainsServices";
import CustomDialog from "../shared/CustomDialog";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import AssignDomain from "./domainDialogs/AssignDomain";
import DuplicateDomainDialog from "./domainDialogs/DuplicateDomain";
import { useSelector } from "react-redux";
import { ROLES } from "../../constants/variables";

const DomainsTable = ({ domains, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [openDeleteDomain, setOpenDeleteDomain] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const { roles } = useSelector((state) => state.userInfo);

  const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
  const isManagerAdmin = roles.includes(ROLES.MANAGER_ADMIN);

  const handleOpenAssign = (e, domainId) => {
    e.preventDefault();
    setOpenAssign(true);
    setSelectedDomain(domainId);
  };

  const handleSeeStyles = (e, domainId) => {
    e.preventDefault();
    navigate(`/styles/${domainId}`);
  };

  const handleOpenDuplicate = (e, domain) => {
    e.preventDefault();
    setSelectedDomain(domain);
    setOpenDuplicate(true);
  };

  const handleNavigateAdmins = (e, domainId) => {
    e.preventDefault();
    navigate(`/domains/admins/${domainId}`);
  };

  //DELETE ACTIONS
  const handleOpenDelete = (e, domain) => {
    e.preventDefault();
    setSelectedDomain(domain);
    setOpenDeleteDomain(true);
  };

  const handleConfirmDeleteDomain = async () => {
    try {
      await deleteDomain(selectedDomain);
      toastMessageSuccess(t("DOMAIN_DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error.response.data.error ?? t("ERROR_DELETING_DOMAIN"));
    } finally {
      refetch();
      setOpenDeleteDomain(false);
    }
  };

  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
    refetch();
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell>{t("GROUPING")}</TableCell>
            <TableCell>{t("ORGANIZATION")}</TableCell>
            <TableCell>{t("SITE")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domains?.map((domain, index) => (
            <TableRow key={`${domain.domain_id}-${index}`} sx={{ whiteSpace: "nowrap" }}>
              <TableCell>{domain.domain_name}</TableCell>
              <TableCell>{domain.grouping_name}</TableCell>
              <TableCell>{domain.organization_name}</TableCell>
              <TableCell>{domain.premise_name}</TableCell>
              {domain.can_edit === 1 || isSuperAdmin || isManagerAdmin ? (
                <TableCell>
                  <Tooltip title={t("ASSIGN")} placement="top" arrow>
                    <IconButton
                      onClick={(e) => handleOpenAssign(e, domain.domain_id)}
                      className="oniria-icon-btn"
                    >
                      <Edit fontSize="inherit" className="" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("SEE_STYLES")} placement="top" arrow>
                    <IconButton
                      onClick={(e) => handleSeeStyles(e, domain.domain_id)}
                      className="oniria-icon-btn"
                    >
                      <BrushIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("DUPLICATE")} placement="top" arrow>
                    <IconButton
                      onClick={(e) => handleOpenDuplicate(e, domain.domain_id)}
                      className="oniria-icon-btn"
                    >
                      <ControlPointDuplicateIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("PERMISSIONS")} placement="top" arrow>
                    <IconButton
                      onClick={(e) => handleNavigateAdmins(e, domain.domain_id)}
                      className="oniria-icon-btn"
                    >
                      <AdminPanelSettingsIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("DELETE")} placement="top" arrow>
                    <IconButton
                      onClick={(e) => handleOpenDelete(e, domain.domain_id)}
                      className="oniria-icon-btn-delete"
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomDialog
        isOpen={openDeleteDomain}
        onClose={() => setOpenDeleteDomain(false)}
        title={t("DELETE_DOMAIN")}
        content={t("DELETE_DOMAIN_DESCRIPTION")}
        onAccept={handleConfirmDeleteDomain}
      />
      <DuplicateDomainDialog
        isOpen={openDuplicate}
        onClose={handleCloseDuplicate}
        domainId={selectedDomain}
      />

      <AssignDomain
        isOpen={openAssign}
        onClose={() => setOpenAssign(false)}
        domainId={selectedDomain}
        refetch={refetch}
      />
    </TableContainer>
  );
};

export default DomainsTable;
