import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";

const CategorySelect = ({ trip, domainCategories, setFormData, buttonClicked, detailsErrors }) => {
  const { t } = useTranslation();
  const [selectedDomains, setSelectedDomains] = useState([]);

  const handleChangeCategories = (category, isChecked) => {
    let newCategories = [...(trip.event_categories || [])];
    const categoryId = category.id;
    if (isChecked) {
      if (!newCategories.some((c) => c.category_id === categoryId)) {
        newCategories.push({
          category_id: categoryId,
          category_name: category.name,
        });
      }
    } else {
      newCategories = newCategories.filter((c) => c.category_id !== categoryId);
    }
    setFormData((prevTrip) => ({
      ...prevTrip,
      details: {
        ...prevTrip.details,
        event_categories: newCategories,
      },
    }));
  };

  const handleChangeSubcategories = (subcategory, isChecked) => {
    let newCategories = [...(trip.event_categories || [])];
    const subcategoryId = subcategory.id;
    if (isChecked) {
      if (!newCategories.some((c) => c.subcategory_id === subcategoryId)) {
        newCategories.push({
          subcategory_id: subcategoryId,
          subcategory_name: subcategory.name,
        });
      }
    } else {
      newCategories = newCategories.filter((c) => c.subcategory_id !== subcategoryId);
    }
    setFormData((prevTrip) => ({
      ...prevTrip,
      details: {
        ...prevTrip.details,
        event_categories: newCategories,
      },
    }));
  };

  const isDomainChecked = (domain) => {
    if (selectedDomains.includes(domain.id)) {
      return true;
    }
    return domain.categories.some(
      (category) =>
        trip.event_categories?.some(
          (selectedCategory) => selectedCategory.category_id === category.id
        ) ||
        category?.subcategories.some((subcategory) =>
          trip.event_categories?.some(
            (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
          )
        )
    );
  };

  const handleDomainChange = (domain, isChecked) => {
    setSelectedDomains((prev) => {
      if (isChecked) {
        return [...prev, domain.id];
      }
      return prev.filter((id) => id !== domain.id);
    });
  };

  const isCategoryChecked = (category) => {
    const categoryId = category.id;
    return trip.event_categories?.some((c) => c.category_id === categoryId);
  };

  const isSubcategoryChecked = (subcategory) => {
    const subcategoryId = subcategory.id;
    return trip.event_categories?.some((c) => c.subcategory_id === subcategoryId);
  };

  const renderCategories = (categories) => {
    return categories?.map((category, index) => (
      <Box key={`category-${index}`} sx={{ ml: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCategoryChecked(category)}
              onChange={(e) => handleChangeCategories(category, e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={category.name}
        />
        {category.subcategories &&
          category.subcategories.map((subcategory, i) => (
            <Box key={`subcategory-${i}`} sx={{ ml: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSubcategoryChecked(subcategory)}
                    onChange={(e) => handleChangeSubcategories(subcategory, e.target.checked)}
                    className="checkbox-oniria"
                  />
                }
                label={subcategory.name}
              />
            </Box>
          ))}
      </Box>
    ));
  };

  return (
    <FormControl
      fullWidth
      sx={focusColor}
      error={
        (detailsErrors.eventCategories === "empty" && buttonClicked) ||
        (detailsErrors.eventCategories !== "" && detailsErrors.eventCategories !== "empty")
      }
    >
      {domainCategories && domainCategories.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("DOMAIN_CATEGORIES")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {domainCategories.map((domain) => (
              <Fragment key={domain.id}>
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDomainChecked(domain)}
                        onChange={(e) => handleDomainChange(domain, e.target.checked)}
                        className="checkbox-oniria"
                      />
                    }
                    label={<Typography>{domain.name}</Typography>}
                  />
                </Grid>
                {isDomainChecked(domain) ? (
                  <FormGroup>{renderCategories(domain.categories)}</FormGroup>
                ) : null}
              </Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
      {/* {eventTypes && eventTypes.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("PREMISE_CATEGORIES")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>{renderCategories(eventTypes)}</FormGroup>
          </AccordionDetails>
        </Accordion>
      ) : null} */}
    </FormControl>
  );
};

export default CategorySelect;
