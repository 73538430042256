import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../constants/variables";
import { deletePremise } from "../../services/premisesServices";
import CustomDialog from "../shared/CustomDialog";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const CardPremise = ({ premise, refetch }) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const id = premise.premise_id;
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);
  const isAffiliated = roles.find((element) => element === ROLES.AFFILIATED);
  const isDelegate = roles.find((element) => element === ROLES.DELEGATE);

  const [open, setOpen] = useState(false);

  const handleEventClick = (id) => {
    navigate(`/premise/${id}`);
  };
  const handlePendingUsers = (id) => {
    navigate(`/premise/${id}/pending_users`);
  };
  const handleMemberCard = (id) => {
    navigate(`/premise/${id}/member_card`);
  };

  const handleShowCategories = () => {
    navigate(`/premise/${id}/categories`);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDeleteButtonClick = async (e) => {
    e.stopPropagation();
    try {
      await deletePremise(id);
      setOpen(false);
      toastMessageSuccess(t("PREMISE_DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error.response.data.error);
    }
    refetch();
  };

  const handleEditButtonClick = (e, id) => {
    e.stopPropagation();
    navigate(`/premise/${id}?edit=true`);
  };

  return (
    <Grid item xs={11} sm={12}>
      <Card sx={{ border: "1px solid #E4E4E4", borderRadius: "15px" }}>
        <Box>
          <CardContent sx={{ p: 3 }}>
            <Grid item container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle1"
                  sx={{ fontWeight: "bolder" }}
                >
                  {premise.premise_name}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={2}>
                  {t("ORGANIZATION")}: {premise.organization_name}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {premise.address}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {premise.postal_code}, {premise.city}, {premise.country}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={2}>
                  {t("AFFILIATE")}: {premise.is_international ? "Si" : "No"}
                </Typography>
                <Typography variant="subtitle2" mb={2}>
                  {t("NUMBER_AFFILIATE")}: {premise.number_of_affiliates}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  CIF: {premise.cif}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  IBAN: **** **** **** {premise.iban}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={0.5}></Grid>
              <Grid
                item
                xs={12}
                lg={5.5}
                container
                rowSpacing={1}
                columnSpacing={2}
                justifyContent={{ xs: "flex-start", lg: "center" }}
                alignItems="center"
              >
                {!isDelegate && (
                  <Grid item>
                    <Tooltip title={t("EDIT")} placement="top" arrow>
                      <Button
                        sx={{ borderRadius: 3 }}
                        aria-label="edit"
                        className="oniria-btn"
                        onClick={(e) => handleEditButtonClick(e, premise.premise_id)}
                      >
                        <EditIcon sx={{ color: "white" }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <Tooltip title={t("SEE")} placement="top" arrow>
                    <Button
                      sx={{ borderRadius: 3 }}
                      aria-label="visibility"
                      className="oniria-btn"
                      onClick={() => handleEventClick(premise.premise_id)}
                    >
                      <VisibilityIcon sx={{ color: "white" }} />
                    </Button>
                  </Tooltip>
                </Grid>
                {(isSuperAdmin || isManager || isAffiliated) && (
                  <Grid item>
                    <Tooltip title={t("PENDING_USERS")} placement="top" arrow>
                      <Button
                        sx={{ borderRadius: 3 }}
                        className="oniria-btn"
                        aria-label="statistics"
                        onClick={() => handlePendingUsers(premise.premise_id)}
                      >
                        <GroupAddIcon sx={{ color: "white" }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                {(isSuperAdmin || isManager || isAffiliated) && (
                  <Grid item>
                    <Tooltip title={t("MEMBER_CARD")} placement="top" arrow>
                      <Button
                        sx={{ borderRadius: 3 }}
                        className="oniria-btn"
                        onClick={() => handleMemberCard(premise.premise_id)}
                      >
                        <CardMembershipIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                {/* <Grid item>
                  <Tooltip title={t("EVENT_CATEGORIES")} placement="top" arrow>
                    <Button
                      sx={{ borderRadius: 3 }}
                      className="oniria-btn"
                      onClick={handleShowCategories}
                    >
                      <EventIcon sx={{ color: "#fff" }} />
                    </Button>
                  </Tooltip>
                </Grid> */}
                {(isSuperAdmin || isManager) && (
                  <Grid item>
                    <Tooltip title={t("DELETE")} placement="top" arrow>
                      <Button
                        sx={{ borderRadius: 3 }}
                        className="oniria-btn"
                        onClick={handleClickOpen}
                      >
                        <DeleteIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      <CustomDialog
        isOpen={open}
        onClose={handleClose}
        onAccept={handleDeleteButtonClick}
        title={t("CANCEL_PREMISE")}
        content={t("CONFIRM_CANCEL_PREMISE")}
      />
    </Grid>
  );
};

export default CardPremise;
