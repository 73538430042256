import {
  CampaignOutlined,
  CategoryOutlined,
  DescriptionOutlined,
  LanguageOutlined,
  PeopleOutline,
  PointOfSaleOutlined,
  SettingsOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { LOGOS } from "../../constants/variables";
import useUserRoles from "../../hooks/useUserRoles";
import WebBarItem from "./WebBarItem";

const DrawerHeaderSideBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const WebSideBar = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isAffiliate,
    isSuperAdmin,
    isAdmin,
    isManager,
    canPublishTrips,
    isOfficeAdmin,
    isOfficeEditor,
  } = useUserRoles();

  return (
    <>
      <DrawerHeaderSideBar
        sx={{
          p: 3,
          mr: 2,
          color: "white",
          maxHeight: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mr: 2,
            color: "white",
            maxHeight: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            imageResolution: "from-image",
            maxWidth: "80%",
          }}
          component="img"
          alt="Logo"
          src={LOGOS.PRINCIPAL_WHITE}
        />
        <IconButton onClick={() => props.setOpen(!props.open)}>
          {props.open ? (
            <ChevronLeftIcon sx={{ color: "white" }} />
          ) : (
            <ChevronRightIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeaderSideBar>
      <Divider />
      <List>
        {/* HOME */}
        <ListItem disablePadding sx={{ display: "block", color: "white" }}>
          <ListItemButton
            onClick={() => {
              navigate("/home");
            }}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt={t("HOME")}
                src={"/media/sidebarIcons/home.svg"}
                sx={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <ListItemText primary={t("HOME")} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Productos */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <EventAvailableIcon />
            {props.open ? <Typography ml={2}>{t("PRODUCTS")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            <WebBarItem
              icon={
                <Box
                  component="img"
                  alt={t("EVENT_MANAGER")}
                  src={"/media/sidebarIcons/events.svg"}
                  sx={{ height: 25, width: 25 }}
                />
              }
              text={t("EVENT_MANAGER")}
              onClick={() => {
                navigate("/events");
              }}
              open={props.open}
            />
            {/* TRIP MANAGER */}
            {(canPublishTrips || isSuperAdmin) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("TRAVEL_MANAGER")}
                    src={"/media/sidebarIcons/travels.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("TRAVEL_MANAGER")}
                onClick={() => {
                  navigate("/trips");
                }}
                open={props.open}
              />
            )}
            {/* B2B, son aquellos eventos y viajes que no son tuyos */}
            {/* CATEGORIAS */}
            <WebBarItem
              icon={<CategoryOutlined sx={{ height: "25px", width: "25px", color: "#fff" }} />}
              text={t("CATEGORIES")}
              onClick={() => {
                navigate(ROUTES.CATEGORIES);
              }}
              open={props.open}
            />
            {/* RECURSOS */}
            {/* <Accordion
              sx={{
                backgroundColor: "var(--secondary-color)",
                color: "#fff",
                border: 0,
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              >
                <EventAvailableIcon />
                {props.open ? (
                  <Typography ml={2}>{t("RESOURCES")}</Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                {/* TRANSPORT 
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("TRANSPORT")}
                      src={"/media/sidebarIcons/events.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("TRANSPORT_MANAGER")}
                  onClick={() => {
                    navigate(ROUTES.TRANSPORT);
                  }}
                  open={props.open}
                />
                {/* HOTELS 
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("HOTELS")}
                      src={"/media/sidebarIcons/events.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("MANAGER_HOTELS")}
                  onClick={() => {
                    navigate(ROUTES.HOTELS);
                  }}
                  open={props.open}
                />
                {/* TOUR LEADERS 
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("TOUR_LEADERS")}
                      src={"/media/sidebarIcons/events.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("MANAGER_TOUR_LEADERS")}
                  onClick={() => {
                    navigate(ROUTES.TOUR_LEADERS);
                  }}
                  open={props.open}
                />
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("EXTRA_ACTIVITIES")}
                      src={"/media/sidebarIcons/events.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("MANAGER_EXTRA_ACTIVITIES")}
                  onClick={() => {
                    navigate(ROUTES.EXTRA_ACTIVITIES);
                  }}
                  open={props.open}
                />
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("INCLUDES")}
                      src={"/media/sidebarIcons/events.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("MANAGER_INCLUDES")}
                  onClick={() => {
                    navigate(ROUTES.INCLUDES);
                  }}
                  open={props.open}
                />
                {/* B2B, son aquellos eventos y viajes que no son tuyos 
              </AccordionDetails>
            </Accordion>*/}
            {/* CALENDAR */}
            <ListItem disablePadding sx={{ display: "block", color: "white" }}>
              <ListItemButton
                onClick={() => {
                  navigate("/calendar");
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    alt={t("CALENDAR")}
                    src={"/media/sidebarIcons/calendar.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("CALENDAR")}
                  sx={{ opacity: props.open ? 1 : 0, fontSize: "8px" }}
                />
              </ListItemButton>
            </ListItem>

            {/* Gestión de STOCK */}
            {/* BEDS ONLINE */}
          </AccordionDetails>
        </Accordion>
        {/* VENTAS */}
        {(isSuperAdmin || isAffiliate || isAdmin || isOfficeAdmin || isOfficeEditor) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
              <AccountBalanceIcon />
              {props.open ? <Typography ml={2}>{t("SALES")}</Typography> : null}
            </AccordionSummary>
            <AccordionDetails>
              {/* CLIENTS MANAGER */}
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("USER_MANAGER")}
                    src={"/media/sidebarIcons/users.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("MANAGER_CLIENTS")}
                onClick={() => {
                  navigate(ROUTES.USERS);
                }}
                open={props.open}
              />
              {/* PEDIDOS DE TODO TIPO */}
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("ORDERS")}
                    src={"/media/sidebarIcons/orders.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("ORDERS")}
                onClick={() => {
                  navigate(ROUTES.ORDERS);
                }}
                open={props.open}
              />
              {/* VENTAS DE TARJETA */}
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("MEMBER_CARDS")}
                    src={"/media/sidebarIcons/card.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("MEMBER_CARDS")}
                onClick={() => {
                  navigate(ROUTES.CARDS);
                }}
                open={props.open}
              />
              {/* STATISTICS */}
              {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
                <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                  <ListItemButton
                    onClick={() => {
                      navigate("/statistics");
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: props.open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        alt={t("STATISTICS")}
                        src={"/media/sidebarIcons/statistics.svg"}
                        sx={{ height: 19, width: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={t("STATISTICS")} sx={{ opacity: props.open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* Facturas cliente-vendedor */}
              {(isSuperAdmin || isManager || isAdmin) && (
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("SALES")}
                      src={"/media/sidebarIcons/bills.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("SALES")}
                  onClick={() => {
                    navigate("/sales");
                  }}
                  open={props.open}
                />
              )}
              {/* FACTURACION con oniria*/}
              {(isSuperAdmin || isManager || isAdmin) && (
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("BILLING_PLUS")}
                      src={"/media/sidebarIcons/bill_plus.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("BILLING_PLUS")}
                  onClick={() => {
                    navigate("/billing");
                  }}
                  open={props.open}
                />
              )}
              {/* DEVOLUCIONES */}
              {(isSuperAdmin || isManager || isAdmin) && (
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("REFUNDS")}
                      src={"/media/sidebarIcons/refund.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("REFUNDS")}
                  onClick={() => {
                    navigate("/refunds");
                  }}
                  open={props.open}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {/* MARKETING */}
        {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
              <CampaignOutlined />
              {props.open ? <Typography ml={2}>{t("MARKETING")}</Typography> : null}
            </AccordionSummary>
            <AccordionDetails>
              {/* SEO DE VENTAS */}
              <WebBarItem
                icon={<TrendingUpOutlined sx={{ height: "25px", width: "25px", color: "#fff" }} />}
                text={t("SALESSEO")}
                onClick={() => {
                  navigate(ROUTES.SALESSEO);
                }}
                open={props.open}
              />
              {/* BLOG */}
              <WebBarItem
                icon={<DescriptionOutlined sx={{ height: "25px", width: "25px", color: "#fff" }} />}
                text={t("BLOG")}
                onClick={() => {
                  navigate(ROUTES.BLOG);
                }}
                open={props.open}
              />
              {/* RRPP MANAGER */}
              <WebBarItem
                icon={<PeopleOutline sx={{ height: "25px", width: "25px", color: "#fff" }} />}
                text={t("RRPP_MANAGER")}
                onClick={() => {
                  navigate(ROUTES.RRPPS);
                }}
                open={props.open}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {/* SETTINGS */}
        {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
              <SettingsOutlined />
              {props.open ? <Typography ml={2}>{t("SETTINGS")}</Typography> : null}
            </AccordionSummary>
            <AccordionDetails>
              {/* ORGANIZATION MANAGER */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("ENTITIES")}
                      src={"/media/sidebarIcons/organizations.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("ENTITIES")}
                  onClick={() => {
                    navigate(ROUTES.ORGANIZATIONS);
                  }}
                  open={props.open}
                />
              )}
              {/* PREMISES MANAGER */}
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("VENUES")}
                    src={"/media/sidebarIcons/premise.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("VENUES")}
                onClick={() => {
                  navigate(ROUTES.PREMISES);
                }}
                open={props.open}
              />
              {/* ADMINS MANAGER */}
              {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
                <WebBarItem
                  icon={
                    <Box
                      component="img"
                      alt={t("ADMINISTRATOR_MANAGER")}
                      src={"/media/sidebarIcons/admin.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  }
                  text={t("ADMINISTRATOR_MANAGER")}
                  onClick={() => {
                    navigate(ROUTES.ADMINS);
                  }}
                  open={props.open}
                />
              )}

              {/* DOMAINS MANAGER */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <WebBarItem
                  icon={<LanguageOutlined sx={{ height: "25px", width: "25px", color: "#fff" }} />}
                  text={t("STYLES_MANAGER_APP")}
                  onClick={() => {
                    navigate(ROUTES.DOMAINS);
                  }}
                  open={props.open}
                />
              )}
              {/* TPV */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <WebBarItem
                  icon={
                    <PointOfSaleOutlined sx={{ height: "25px", width: "25px", color: "#fff" }} />
                  }
                  text={t("TPV_MANAGER")}
                  onClick={() => {
                    navigate(ROUTES.TPV);
                  }}
                  open={props.open}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </>
  );
};

export default WebSideBar;
