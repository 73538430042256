export function saveB2bInfoToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state.b2bInfo);
    localStorage.setItem("b2bInfo", serializedState);
  } catch (e) {
    console.warn("Error al guardar b2bInfo en localStorage", e);
  }
}

export function loadB2bInfoFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("b2bInfo");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("Error al cargar b2bInfo de localStorage", e);
    return undefined;
  }
}
