import { Grid, InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import LanguageTabs from "../../../events/languages/LanguageTabs";
import { focusColor } from "../../../shared/textFieldStyle";
import TextEditor from "../../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../../textEditor/tools";

const CardDescription = ({ formData, handleChange, setFormData }) => {
  const { t } = useTranslation();
  const { affiliated_card_language_infos } = formData;
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);

  const handleChangeDescription = (data) => {
    setFormData((prev) => ({ ...prev, description: data }));
  };

  const handleChangeLanguageDescription = (data) => {
    const newAffiliatedCardLanguageInfos = [...affiliated_card_language_infos];
    const index = newAffiliatedCardLanguageInfos.findIndex(
      (info) => info.language === selectedLanguage
    );
    newAffiliatedCardLanguageInfos[index].description = data;
    setFormData((prev) => ({
      ...prev,
      affiliated_card_language_infos: newAffiliatedCardLanguageInfos,
    }));
  };

  return (
    <>
      {affiliated_card_language_infos && affiliated_card_language_infos.length > 0 ? (
        <Grid item xs={12}>
          <LanguageTabs
            event_languages_info={affiliated_card_language_infos || []}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} sx={{ mt: 1 }}>
        <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("CARD_DESCRIPTION")}</InputLabel>
        {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
          checkRenderEditorJs("", formData.description) ? (
            <TextEditor
              data={formData.description}
              setData={handleChangeDescription}
              id="premise-card-des"
            />
          ) : (
            <TextField
              sx={focusColor}
              margin="normal"
              fullWidth
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              autoComplete="description"
              label={t("CARD_DESCRIPTION")}
              multiline
              rows={4}
            />
          )
        ) : (
          <>
            <TextEditor
              data={
                affiliated_card_language_infos.find((info) => info.language === selectedLanguage)
                  ?.description || ""
              }
              setData={handleChangeLanguageDescription}
              id={`premise-card-description-${selectedLanguage}`}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default CardDescription;
