import { useState } from "react";

const usePagination = (initialPerPage = 10) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initialPerPage);

  const changePage = (_, page) => {
    setPage(page);
  };

  const changePerPage = (e) => {
    const perPage = e.target.value;
    setPerPage(perPage);
    setPage(1);
  };

  return { page, perPage, changePage, changePerPage };
};

export default usePagination;
