export const newPartnerErrors = {
  title: "",
  short_description: "",
  image_url: "",
  description: "",
  position: "",
  default_language: "",
  partner_language_infos: "",
};

export const newPartner = {
  affiliated_card_id: "",
  title: "",
  short_description: "",
  image_url: "",
  description: "",
  position: "",
  default_language: "",
  partner_language_infos: [],
};

export const partnerLanguageInfos = {
  id: "",
  partner_id: "",
  language: "",
  title: "",
  short_description: "",
  description: "",
};
