import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const AgeGraph = ({ ticketsByAge }) => {
  const { t } = useTranslation();

  const showByAge = ticketsByAge?.map((ticket) => {
    const ages = ticket.age.split(" - ");
    return {
      ...ticket,
      age: ages[0] === ages[1] ? ages[0] : ticket.age,
    };
  });

  return (
    <Grid
      item
      component={Paper}
      elevation={3}
      xs={12}
      sx={{
        padding: "10px",
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        height: "fit-content",
      }}
    >
      <Typography variant="h6">{t("ASSISTANTS_AGE")}</Typography>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={showByAge} margin={{ top: 40 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="age" height={40}>
            <Label value={t("ASSISTANTS_AGE")} offset={0} position="insideBottomRight" />
          </XAxis>
          <YAxis domain={[0, "dataMax + 20"]} tickCount={10}>
            <Label position="top" width={200} offset={15} dx={40}>
              {t("PERCENTAGE_TICKETS_SOLD")}
            </Label>
          </YAxis>
          <Legend />
          <Bar dataKey="number" fill="var(--secondary-color)" legendType="none" />
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default AgeGraph;
