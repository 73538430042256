import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate, formatHours } from "../../../constants/utils";

const UserContactData = ({ userInfo }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={4}>
      <TableContainer size="small">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="center" sx={{ fontWeight: "bold" }}>
                {t("CONTACT_DATA")}
              </TableCell>
            </TableRow>
            {userInfo?.name && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("NAME")}:
                </TableCell>
                <TableCell align="left">
                  {userInfo.name}, {userInfo.surname}
                </TableCell>
              </TableRow>
            )}
            {userInfo?.email && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("EMAIL")}:
                </TableCell>
                <TableCell align="left">{userInfo.email}</TableCell>
              </TableRow>
            )}
            {userInfo?.phone && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("PHONE")}:
                </TableCell>
                <TableCell align="left">{userInfo.phone}</TableCell>
              </TableRow>
            )}
            {userInfo?.created_at && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("REGISTER_DATE")}:
                </TableCell>
                <TableCell align="left">
                  {formatDate(userInfo.created_at)} {formatHours(userInfo.created_at)}
                </TableCell>
              </TableRow>
            )}
            {userInfo?.domain && (
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  {t("DOMAIN")}:
                </TableCell>
                <TableCell align="left">{userInfo.domain}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UserContactData;
