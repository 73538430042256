import React from "react";
import { useTranslation } from "react-i18next";
import { rrppClient } from "../../classes/rrppClass";
import Header from "../../components/Header";
import RrppForm from "../../components/rrpps/RrppForm";

const CreateRrppUser = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("RRPP_MANAGER"),
      link: "/rrpps",
    },
    {
      name: t("CREATE_RRPP"),
      link: `/rrpps/create_rrpp`,
    },
  ];

  const formData = JSON.parse(JSON.stringify(rrppClient));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("CREATE_RRPP_DESCRIPTION")} />
      <RrppForm formData={formData} />
    </>
  );
};

export default CreateRrppUser;
