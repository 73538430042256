import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCountries } from "../../../services/utilsServices.jsx";
import { emailExists } from "../../../services/authenticationServices.jsx";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle.jsx";
import { useQuery } from "react-query";

const Contact = (props) => {
  const { t } = useTranslation();
  const { buttonClicked, hasToCheckMail, isUserSimple } = props;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    if (
      typeof props.formData.phone_code === "string" &&
      props.formData.phone_code !== "" &&
      countries.length > 0
    ) {
      let code = props.formData.phone_code?.startsWith("+")
        ? props.formData.phone_code?.substring(1)
        : props.formData.phone_code;

      let country = countries.find((obj) => obj.phone_code === code || obj.country_code === code);

      setSelectedCountry(
        country ? country.country_code.toLowerCase() + "-" + country.phone_code : ""
      );
    }
  }, [props.formData.phone_code, countries]);

  const handleQueryCountriesSuccess = (data) => {
    setCountries(data);
  };

  const { isLoading } = useQuery("countries", () => getCountries(), {
    onSuccess: handleQueryCountriesSuccess,
  });
  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
    props.formData.phone_code = event.target.value?.split("-")[1].toUpperCase();
    props.setErrors(props.classForm, "phone_code", "");
  };

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };

  const checkEmail = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors
  ) => {
    if (hasToCheckMail) {
      let errorValue = "";
      const newValue = e.target.value;
      if (newValue === "" && !canBeEmpty) {
        errorValue = "empty";
      } else if (!regex.test(newValue)) {
        errorValue = errorMessage;
      } else {
        errorValue = "";
        emailExists(newValue).then((response) => {
          if (response.user_exists) {
            setErrors(classForm, key, t("USER_EXISTS"));
          }
        });
      }
      setErrors(classForm, key, errorValue);
      formParams(classForm, key, e, "value");
    } else {
      props.checkField(classForm, key, e, regex, errorMessage, canBeEmpty, formParams, setErrors);
    }
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("CONTACT")}</InputLabel>
      {!isUserSimple && (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={4} mt={2}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={focusColor}
              error={selectedCountry === "" && buttonClicked}
            >
              <InputLabel id="demo-select-small-label">{t("PHONE_CODE")}</InputLabel>
              <Select
                required
                label={t("PHONE_CODE")}
                value={selectedCountry ? selectedCountry : ""}
                onChange={handleChangeCountry}
                disabled={isDisabled()}
                renderValue={(selected) => (
                  <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                    <img
                      style={{ height: "20px", width: "35px", objectFit: "cover", marginRight: 10 }}
                      alt="Flag"
                      src={`/media/flags/${selected.split("-")[0]}.svg`}
                    />
                    <Typography> +{selected.split("-")[1]}</Typography>
                  </Grid>
                )}
              >
                {countries.map((country) => (
                  <MenuItem
                    key={country.country_code}
                    value={country.country_code.toLowerCase() + "-" + country.phone_code}
                  >
                    <img
                      style={{ height: "20px", width: "35px", objectFit: "cover", marginRight: 10 }}
                      alt="Flag"
                      src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                    />
                    +{country.phone_code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              onChange={(e) =>
                props.checkField(
                  props.classForm,
                  "phone",
                  e,
                  /^\d{1,4}(?:\s?\d{1,4}){1,3}$/,
                  t("INCORRECT"),
                  false,
                  props.formParams,
                  props.setErrors
                )
              }
              margin="normal"
              required
              fullWidth
              id="phone"
              name="phone"
              label={t("PHONE")}
              value={props.formData.phone}
              autoComplete="phone"
              disabled={props.isDisabled}
              error={
                (props.errors.phone !== "" && props.errors.phone !== "empty") ||
                (buttonClicked && props.errors.phone === "empty")
              }
              helperText={props.errors.phone !== "empty" ? props.errors.phone : ""}
              sx={focusColor}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <TextField
          onChange={(e) =>
            checkEmail(
              props.classForm,
              "email",
              e,
              /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              t("INCORRECT"),
              false,
              props.formParams,
              props.setErrors
            )
          }
          margin="normal"
          required
          fullWidth
          id="email"
          name="email"
          label={t("EMAIL")}
          value={props.formData.email}
          autoComplete="email"
          disabled={props.isDisabled}
          error={
            (props.errors.email !== "" && props.errors.email !== "empty") ||
            (buttonClicked && props.errors.email === "empty")
          }
          helperText={props.errors.email !== "empty" ? props.errors.email : ""}
          sx={focusColor}
        />
      </Grid>
    </>
  );
};

export default Contact;
