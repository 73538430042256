import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import ListaPdfPlus from "../../components/bills/ListPdfPlus";
import ListSepasPlus from "../../components/bills/ListSepasPlus";

const BillingPlus = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("BILLING"),
      link: "/billing",
    },
  ];
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, value) => {
    setValueTab(value);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_BILLING_PLUS")} />
      {isSuperAdmin && (
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          centered
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{ marginBottom: 2 }}
        >
          <Tab sx={{ "&.Mui-selected": { color: "var(--secondary-color)" } }} label={"Sepas"} />
          <Tab sx={{ "&.Mui-selected": { color: "var(--secondary-color)" } }} label={"PDF"} />
        </Tabs>
      )}
      {valueTab === 0 && <ListSepasPlus isSuperAdmin={isSuperAdmin} />}
      {isSuperAdmin && valueTab === 1 && <ListaPdfPlus />}
    </>
  );
};

export default BillingPlus;
