import { useQuery } from "react-query";
import { getCategoriesDomain } from "../../services/categoriesServices";
import { RQ_KEY } from "../../constants/query";

export function useQueryCategoriesDomain(domainId, handleSuccessCategories) {
  return useQuery([RQ_KEY.CATEGORIES_DOMAIN, domainId], () => getCategoriesDomain(domainId), {
    refetchOnWindowFocus: false,
    onSuccess: handleSuccessCategories,
    enabled: !!domainId,
  });
}
