import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import HomeStatistics from "../components/home/HomeStatistics";
import "./styles.css";

const Home = () => {
  const { t } = useTranslation();

  const [greet, setGreet] = useState("");
  const actualDate = new Date();
  const actualHour = actualDate.getHours();
  const userInfo = useSelector((state) => state.userInfo);
  const allUserData = userInfo.userData;

  const breadcrumbs = [
    {
      name: `${greet}, ${allUserData.name} ${allUserData.surname}`,
      link: "/home",
    },
  ];

  useEffect(() => {
    if (actualHour >= 5 && actualHour < 14) {
      setGreet(t("GOOD_MORNING"));
    } else if (actualHour >= 14 && actualHour < 21) {
      setGreet(t("GOOD_AFTERNOON"));
    } else {
      setGreet(t("GOOD_EVENING"));
    }
  }, [actualHour]);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <HomeStatistics />
    </>
  );
};

export default Home;
