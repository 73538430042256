import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { focusColor } from '../../shared/textFieldStyle.jsx'
import { getCountries, getRegions } from '../../../services/utilsServices.jsx'
import { useQuery } from 'react-query'

const FiscalAddress = props => {
  const { t } = useTranslation()
  const { buttonClicked } = props
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [regions, setRegions] = useState([])
  const [selectedState, setSelectedState] = useState('')

  useEffect(() => {
    if (props.formData.fiscal_country !== '' && countries.length > 0) {
      const result = countries.find(obj => obj.country_code === props.formData.fiscal_country)
      setSelectedCountry(result?.country_code.toLowerCase() + '-' + result?.official_name_en)
      getRegions(props.formData.fiscal_country).then(response => {
        setRegions(response)
      })
    }
  }, [props.formData.fiscal_country, countries])

  useEffect(() => {
    if (props.formData.fiscal_state !== '' && regions.length > 0) {
      setSelectedState(props.formData.fiscal_state)
    }
  }, [props.formData.fiscal_state, regions])

  const handleQueryCountriesSuccess = data => {
    setCountries(data)
  }

  const { isLoading } = useQuery('countries', () => getCountries(), {
    onSuccess: handleQueryCountriesSuccess,
  })

  const handleChangeCountry = event => {
    setSelectedCountry(event.target.value)
    props.formData.fiscal_country = event.target.value?.split('-')[0].toUpperCase()
    props.setErrors(props.classForm, 'fiscal_country', '')
  }

  const handleStateChange = event => {
    setSelectedState(event.target.value)
    props.formData.fiscal_state = event.target.value
    props.setErrors(props.classForm, 'fiscal_state', '')
  }

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false
  }

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <InputLabel sx={{ fontSize: '18px', color: 'black' }}>{t('FISCAL_ADDRESS')}</InputLabel>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'fiscal_address',
                e,
                /^(?!.*[|\\#·$~%&¬()^[\]+*{}_]).{1,250}$/,
                t('MAX_250'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="fiscal_address"
            name="fiscal_address"
            value={props.formData.fiscal_address}
            label={t('STREET')}
            autoComplete="fiscal_address"
            disabled={props.isDisabled}
            error={
              (props.errors.fiscal_address !== '' && props.errors.fiscal_address !== 'empty') ||
              (buttonClicked && props.errors.fiscal_address === 'empty')
            }
            helperText={props.errors.fiscal_address !== 'empty' ? props.errors.fiscal_address : ''}
            sx={focusColor}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'fiscal_postal_code',
                e,
                /^[0-9A-Za-z]{4,}$/,
                t('INCORRECT'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="fiscal_postal_code"
            name="fiscal_postal_code"
            value={props.formData.fiscal_postal_code}
            label={t('POSTAL_CODE')}
            autoComplete="fiscal_postal_code"
            disabled={props.isDisabled}
            error={
              (props.errors.fiscal_postal_code !== '' &&
                props.errors.fiscal_postal_code !== 'empty') ||
              (buttonClicked && props.errors.fiscal_postal_code === 'empty')
            }
            helperText={
              props.errors.fiscal_postal_code !== 'empty' ? props.errors.fiscal_postal_code : ''
            }
            sx={focusColor}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={focusColor}
            error={selectedCountry === '' && buttonClicked}
          >
            <InputLabel id="demo-select-small-label">{t('COUNTRY')}</InputLabel>
            <Select
              label={t('COUNTRY')}
              value={selectedCountry ? selectedCountry : ''}
              onChange={handleChangeCountry}
              disabled={isDisabled()}
              renderValue={selected => (
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                  <img
                    style={{ height: '20px', width: '35px', objectFit: 'cover', marginRight: 10 }}
                    alt="Flag"
                    src={`/media/flags/${selected.split('-')[0]}.svg`}
                  />
                  <Typography>{selected.split('-')[1]}</Typography>
                </Grid>
              )}
            >
              {countries.map(country => (
                <MenuItem
                  key={country.country_code}
                  value={country.country_code.toLowerCase() + '-' + country.official_name_en}
                >
                  <img
                    style={{ height: '20px', width: '35px', objectFit: 'cover', marginRight: 10 }}
                    alt="Flag"
                    src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                  />
                  {country.official_name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={focusColor}
            error={selectedState === '' && buttonClicked}
          >
            <InputLabel id="state-label">{t('STATE')}</InputLabel>
            <Select
              disabled={
                regions.length === 0 || props.formData.fiscal_country === ''
                  ? true
                  : false || isDisabled()
              }
              label={t('STATE')}
              id="state"
              value={selectedState}
              onChange={handleStateChange}
            >
              {regions.map(state => (
                <MenuItem key={state.code} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={e =>
              props.checkField(
                props.classForm,
                'fiscal_city',
                e,
                /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_-]).{1,50}$/,
                t('MAX_50'),
                false,
                props.formParams,
                props.setErrors,
              )
            }
            margin="normal"
            required
            fullWidth
            id="fiscal_city"
            name="fiscal_city"
            value={props.formData.fiscal_city}
            label={t('CITY')}
            autoComplete="fiscal_city"
            disabled={props.isDisabled}
            error={
              (props.errors.fiscal_city !== '' && props.errors.fiscal_city !== 'empty') ||
              (buttonClicked && props.errors.fiscal_city === 'empty')
            }
            helperText={props.errors.fiscal_city !== 'empty' ? props.errors.fiscal_city : ''}
            sx={focusColor}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FiscalAddress
