import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Typography, Button, Box } from "@mui/material";
import { LOGOS } from "../constants/variables";

const AccountSuccess = () => {

    const {t} = useTranslation();

    return(
        <Grid container justifyContent="center" alignItems="center" flexDirection="row" columnGap={2} sx={{padding: {xs: "30px", sm:"50px"}, mt: 9, mb: {xs: 20, sm: 12}}}>
            <Grid item >
                <Box component="img" src={LOGOS.PRINCIPAL_WHITE} sx={{width: "160px"}}/>
            </Grid>
            <Grid item sx={{width: "420px"}} container rowGap={2}  alignItems="center">
                <Typography variant="h4" sx={{fontWeight: "bolder", fontSize: {xs: "1.8rem", sm:"2.125rem"}}}>{t("ALL_GOOD")}</Typography>
                <Typography>{t("REQUEST_CREATED")}</Typography>
                <Typography>{t("REVIEWED_APPLICATION")}</Typography>
                <Grid item xs={12} container justifyContent="center">
                    <Button
                        className='oniria-btn'
                        href='/home'
                        variant="contained"
                        size='small'
                        sx={{fontSize: "18px", borderRadius: 3, padding: "5px 25px"}}
                        >
                            {t("BACK_HOME")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AccountSuccess