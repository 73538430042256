import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: "20px", fontWeight: "bold" }}>
        {t("WHATS_TIKITUP")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("TIKITUP_DESCRIPTION")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("CUSTOMERS_PAY")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("CUSTOMERS_PAY_DESCRIPTION")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("TICKETS_WORKING")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("TICKETS_WORKING_DESCRIPTION")}
      </Typography>
      <Typography variant="h6" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
        {t("USERS_REGISTER")}
      </Typography>
      <Typography variant="body1" sx={{ mb: "20px" }}>
        {t("USERS_REGISTER_DESCRIPTION")}
      </Typography>
    </Box>
  );
};

export default AboutUs;
