import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import LanguageTabs from "../../../events/languages/LanguageTabs";
import { focusColor } from "../../../shared/textFieldStyle";

const CardCheckList = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const { affiliated_card_language_infos } = formData;

  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);

  const selectedLanguageCheckList =
    affiliated_card_language_infos.find((info) => info.language === selectedLanguage)?.check_list ||
    [];

  const handleAddAdvantage = () => {
    if (selectedLanguage === DEFAULT_EVENT_LANGUAGE) {
      setFormData((prev) => ({
        ...prev,
        check_list: [...prev.check_list, ""],
      }));
      return;
    } else {
      const newAffiliatedCardLanguageInfos = [...affiliated_card_language_infos];
      const index = newAffiliatedCardLanguageInfos.findIndex(
        (info) => info.language === selectedLanguage
      );
      newAffiliatedCardLanguageInfos[index].check_list = [
        ...newAffiliatedCardLanguageInfos[index].check_list,
        "",
      ];
      setFormData((prev) => ({
        ...prev,
        affiliated_card_language_infos: newAffiliatedCardLanguageInfos,
      }));
    }
  };

  const handleChangeAdvantage = (e, index) => {
    if (selectedLanguage === DEFAULT_EVENT_LANGUAGE) {
      const newAdvantages = [...formData?.check_list];
      newAdvantages[index] = e.target.value;
      setFormData((prev) => ({ ...prev, check_list: newAdvantages }));
    } else {
      const newAffiliatedCardLanguageInfos = [...affiliated_card_language_infos];
      const indexLanguage = newAffiliatedCardLanguageInfos.findIndex(
        (info) => info.language === selectedLanguage
      );
      newAffiliatedCardLanguageInfos[indexLanguage].check_list[index] = e.target.value;
      setFormData((prev) => ({
        ...prev,
        affiliated_card_language_infos: newAffiliatedCardLanguageInfos,
      }));
    }
  };

  const handleDeleteAdvantage = (index) => {
    if (selectedLanguage === DEFAULT_EVENT_LANGUAGE) {
      const newAdvantages = [...formData?.check_list];
      newAdvantages?.splice(index, 1);
      setFormData((prev) => ({ ...prev, check_list: newAdvantages }));
    } else {
      const newAffiliatedCardLanguageInfos = [...affiliated_card_language_infos];
      const indexLanguage = newAffiliatedCardLanguageInfos.findIndex(
        (info) => info.language === selectedLanguage
      );
      newAffiliatedCardLanguageInfos[indexLanguage].check_list?.splice(index, 1);
      setFormData((prev) => ({
        ...prev,
        affiliated_card_language_infos: newAffiliatedCardLanguageInfos,
      }));
    }
  };

  return (
    <Grid item xs={12} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
          {t("CARD_ADVANTAGES")}
        </InputLabel>
        {affiliated_card_language_infos && affiliated_card_language_infos.length > 0 ? (
          <Grid item xs={12}>
            <LanguageTabs
              event_languages_info={affiliated_card_language_infos || []}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </Grid>
        ) : null}
        {(selectedLanguage === DEFAULT_EVENT_LANGUAGE
          ? formData.check_list
          : selectedLanguageCheckList
        )?.map((advantage, index) => (
          <Grid item xs={12} key={index} container alignItems="center" columnSpacing={2}>
            <TextField
              sx={[focusColor, { width: "80%", pl: 2 }]}
              margin="normal"
              id={`advantage-${index}`}
              name={`advantage-${index}`}
              value={advantage}
              placeholder={t("ADVANTAGE")}
              onChange={(e) => handleChangeAdvantage(e, index)}
            />
            <Grid item xs={2}>
              <IconButton onClick={() => handleDeleteAdvantage(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="center">
          <Button
            className="oniria-border-btn"
            onClick={handleAddAdvantage}
            sx={{ mt: 2, color: "#fff" }}
          >
            {t("ADD_ADVANTAGE")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardCheckList;
