import { useQuery } from "react-query";
import { getSponsorsByDomain } from "../../services/domainsServices";
import { RQ_KEY } from "../../constants/query";

export const useQuerySponsorsDomain = (domainId) => {
  return useQuery([RQ_KEY.SPONSORS_DOMAIN, domainId], () => getSponsorsByDomain(domainId), {
    enabled: !!domainId,
    refetchOnWindowFocus: false,
  });
};
