import { createSlice } from '@reduxjs/toolkit';

const initialState = { trips: [] }

const tripSlice = createSlice({
  name: 'trips',
  initialState: initialState,
  reducers: {
    allTrips: (state, action) => {
      return action.payload;
    }
  }
});

export const { allTrips } = tripSlice.actions;
export default tripSlice.reducer;