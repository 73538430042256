import { TableCell } from "@mui/material";
import { EURO } from "../../../constants/utils";
import { Fragment } from "react";

export const renderExtrasOrderDetail = (extras) => {
  const extraCounts = extras?.reduce((acc, { extra_name, transaction_id }) => {
    const key = `${extra_name}_${transaction_id}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  return (
    extraCounts &&
    Object.keys(extraCounts).map((extraName, index, arr) => (
      <span key={index}>
        {extraCounts[extraName]}x {extraName.split("_")[0]}
        {index < arr.length - 1 && <br />}
      </span>
    ))
  );
};

const formatPrice = (price) => EURO.format(price);

export const renderExtrasPrice = (extras) => {
  const extraPrices = extras?.reduce((acc, { extra_name, extra_price, transaction_id }) => {
    const key = `${extra_name}_${transaction_id}`;
    acc[key] = (acc[key] || 0) + parseFloat(extra_price);
    return acc;
  }, {});

  return (
    extraPrices &&
    Object.keys(extraPrices).map((extraName, index, arr) => (
      <span key={extraName}>
        {formatPrice(extraPrices[extraName])}
        {index < arr.length - 1 && <br />}
      </span>
    ))
  );
};

export const renderExtrasCellOrders = (extras, last) => {
  const extraCounts = extras?.reduce((acc, extra) => {
    acc[extra.extra_name] = (acc[extra.extra_name] || 0) + 1;
    return acc;
  }, {});

  return (
    <TableCell
      sx={{
        textWrap: "nowrap",
        borderBottom: last ? "" : "none",
        padding: 0.5,
        paddingLeft: "36px",
      }}
    >
      {extraCounts &&
        Object.entries(extraCounts).map(([extraName, count], index, arr) => (
          <Fragment key={extraName}>
            {count}x {extraName}
            {index < arr.length - 1 && <br />}
          </Fragment>
        ))}
    </TableCell>
  );
};

export const renderTicketCell = (tickets, onlyExtras) => {
  const ticketCounts = tickets?.reduce((acc, ticket) => {
    acc[ticket.ticket_name] = (acc[ticket.ticket_name] || 0) + 1;
    return acc;
  }, {});

  return (
    <TableCell sx={{ textWrap: "nowrap", borderBottom: onlyExtras ? "none" : "" }}>
      {ticketCounts &&
        Object.entries(ticketCounts).map(([ticketName, count], index, arr) => (
          <Fragment key={ticketName}>
            {count}x {ticketName}
            {index < arr.length - 1 && <br />}
          </Fragment>
        ))}
    </TableCell>
  );
};
