import { configureStore } from "@reduxjs/toolkit";
import b2bReducer from "./reducers/b2bReducer";
import eventReducer from "./reducers/eventsReducer";
import tripsReducer from "./reducers/tripsReducer";
import userInfoReducer from "./reducers/userInfoReducer";
import { loadB2bInfoFromLocalStorage, saveB2bInfoToLocalStorage } from "./utils";

// Load the state from the local storage
const persistB2bInfo = loadB2bInfoFromLocalStorage();

const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    allEvents: eventReducer,
    allTrips: tripsReducer,
    b2bInfo: b2bReducer,
  },
  preloadedState: {
    b2bInfo: persistB2bInfo,
  },
});

// Save the state to the local storage
store.subscribe(() => {
  saveB2bInfoToLocalStorage(store.getState());
});

export default store;
