import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const RefundsAndPayments = ({ ticketsTypes, isDoublePayment, numberRefunds, assistants }) => {
  const { t } = useTranslation();
  const [incompletePayment, setIncompletePayment] = useState(0);

  const isCompletePayment = (assistant) => !!assistant.payed_at;

  useEffect(() => {
    if (isDoublePayment && assistants) {
      const numberOfIncompletePayments = assistants.filter(
        (assistant) => !isCompletePayment(assistant)
      ).length;
      setIncompletePayment(numberOfIncompletePayments);
    }
  }, [isDoublePayment, assistants]);

  if (!ticketsTypes || ticketsTypes.length === 0 || (isDoublePayment && numberRefunds === 0)) {
    return null;
  }

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        rowGap={1}
        xs={12}
        sm={10}
        md={7}
        lg={5}
        mb={1}
        component={Paper}
        sx={{
          padding: "10px",
          border: "1px solid #E4E4E4",
          borderRadius: "15px",
          maxWidth: "100%",
        }}
        elevation={3}
      >
        {isDoublePayment && incompletePayment > 0 ? (
          <Grid item container sx={{ borderBottom: "1px solid lightgrey" }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("PENDING_PAYMENT")}:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Typography variant="subtitle2">{incompletePayment}</Typography>
            </Grid>
          </Grid>
        ) : null}
        {numberRefunds > 0 && (
          <Grid item container sx={{ borderBottom: "1px solid lightgrey" }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("REFUNDS")}:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Typography variant="subtitle2">{numberRefunds}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RefundsAndPayments;
