import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ButtonGroup, Grid, IconButton } from "@mui/material";

const OrderButtons = ({ columnName, id, order, handleClickAsc, handleClickDesc }) => {
  const getButtonClass = (type) => {
    return order[type].includes(id) ? "oniria-btn-check-validated" : "oniria-btn-check";
  };

  const commonButtonProps = {
    size: "small",
    sx: { height: "20px", width: "20px" },
  };

  return (
    <Grid container flexDirection="row" wrap="nowrap" alignItems="center" columnGap={1}>
      {columnName}
      <ButtonGroup sx={{ fontSize: "5px" }}>
        <IconButton
          aria-label="Orden ascendente"
          name="asc"
          onClick={() => handleClickAsc(id, "asc")}
          className={getButtonClass("asc")}
          {...commonButtonProps}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          aria-label="Orden descendente"
          name="desc"
          onClick={() => handleClickDesc(id, "desc")}
          className={getButtonClass("desc")}
          {...commonButtonProps}
        >
          <ExpandMore />
        </IconButton>
      </ButtonGroup>
    </Grid>
  );
};

export default OrderButtons;
