import { ORDER_STATUS_TYPES } from "./variables";

export const CSV_ORDERS_HEADERS = [
  "Evento",
  "Sede",
  "Dominio",
  "Cantidad",
  "Gastos del servicio(oniria)",
  "Gastos gestión ",
  "Total",
  "Fecha",
  "Email",
  "Tipo de pago",
];

export const PURCHASE_TYPES = [
  { id: "event", name: "EVENT" },
  { id: "trip", name: "TRIP" },
  { id: "card", name: "CARD" },
  { id: "refund", name: "REFUND_TYPE" },
];

export const ORDER_STATUS = [
  { id: "failed", name: "ORDER_FAILED" }, //rechazado por pasarela
  { id: "cancel", name: "ORDER_CANCEL" }, //timeout o rechazado por user
  { id: "parcial", name: "ORDER_PARCIAL" },
  { id: "payed_succesfull", name: "ORDER_PAYED_SUCCESFULL" }, // todos los pagados exitosos
  { id: "payed", name: "ORDER_PAYED" }, //pagado pero no terminado el evento
  { id: "finish", name: "ORDER_FINISH" }, //pagado y terminado el evento
];

export const PURCHASE_TYPE = {
  CARD: "card",
};

export const PAYMENT_METHODS = [
  { id: "app", name: "APP" },
  { id: "office", name: "OFFICE" },
  { id: "app_redsys", name: "REDSYS" },
  { id: "app_paypal", name: "PAYPAL" },
  { id: "app_free", name: "FREE" },
  { id: "office_cash", name: "OFFICE_CASH" },
  { id: "office_card", name: "OFFICE_CARD" },
  { id: "office_guest", name: "OFFICE_GUEST" },
  { id: "office_bank_transfer", name: "OFFICE_TRANSFER_BANK" },
];

export const getOrderAmount = (order) => {
  return parseFloat(order.amount) || 0 + parseFloat(order.first_payment_amount) || 0;
};

export const getOrderCommission = (order) => {
  return parseFloat(order.commission) || 0 + parseFloat(order.first_payment_commission) || 0;
};

export const getOrderPremiseCommission = (order) => {
  return (
    parseFloat(order.premise_commission) ||
    0 + parseFloat(order.first_payment_premise_commission) ||
    0
  );
};

export const getOrderTotalAmount = (order) => {
  const total =
    getOrderAmount(order) + getOrderCommission(order) + getOrderPremiseCommission(order);
  return total.toFixed(2);
};

export const isOrderStatus = (order) => {
  if (order.payment_type?.includes("failed") || order.first_payment_type?.includes("failed")) {
    return ORDER_STATUS_TYPES.FAILED;
  } else if (order.payed_at) {
    return ORDER_STATUS_TYPES.PAYED;
  } else if (!order.payed_at && order.first_payment_payed_at) {
    return ORDER_STATUS_TYPES.PARCIAL;
  } else if (!order.payed_at && !order.first_payment_payed_at) {
    return ORDER_STATUS_TYPES.CANCEL;
  }
};

export const isOrderTotalPayed = (order) => {
  return order.payed_at;
};

export const isOrderFailed = (order) => {
  return (
    order.payment_type?.includes("failed") ||
    order.first_payment_type?.includes("failed") ||
    order.payment_type?.includes("timeout") ||
    order.first_payment_type?.includes("timeout")
  );
};

export const isOrderTimeout = (order) => {
  return order.payment_type?.includes("timeout") || order.first_payment_type?.includes("timeout");
};
export const isOrderCancelled = (order) => {
  return !order.first_payment_type && !order.payment_type;
};

export const getOneMonthAgo = () => {
  let today = new Date();

  today.setMonth(today.getMonth() - 1);
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0");
  let day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
