import React from 'react'
import { Box, Grid, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { focusColor } from '../../shared/textFieldStyle.jsx';

const SocialMedia = (props) => {

    const { t } = useTranslation();

    return (
        <>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("SOCIAL_MEDIA")}
            </InputLabel>
            <Grid container  sx={{ ml: 3 }}>
                <Grid item xs={2}>
                    <Box component="img" alt="Instagram" src={'/media/social/instagram.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'instagram_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="instagram_url"
                        name="instagram_url"
                        label="Link Instagram"
                        value={props.formParams.instagram_url}
                        autoComplete="instagram_url"
                        disabled={props.isDisabled}
                        error={props.errors.instagram_url !== ""}
                        helperText={props.errors.instagram_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Facebook" src={'/media/social/facebook.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'facebook_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="facebook_url"
                        name="facebook_url"
                        label="Link Facebook"
                        value={props.formParams.facebook_url}
                        autoComplete="facebook_url"
                        disabled={props.isDisabled}
                        error={props.errors.facebook_url !== ""}
                        helperText={props.errors.facebook_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Whatsapp" src={'/media/social/whatsapp.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'whatsapp_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="whatsapp_url"
                        name="whatsapp_url"
                        label="Link Whatsapp"
                        value={props.formParams.whatsapp_url}
                        autoComplete="whatsapp_url"
                        disabled={props.isDisabled}
                        error={props.errors.whatsapp_url !== ""}
                        helperText={props.errors.whatsapp_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Twitter" src={'/media/social/twitter.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'twitter_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="twitter_url"
                        name="twitter_url"
                        label="Link Twitter"
                        value={props.formParams.twitter_url}
                        autoComplete="twitter_url"
                        disabled={props.isDisabled}
                        error={props.errors.twitter_url !== ""}
                        helperText={props.errors.twitter_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="YouTube" src={'/media/social/youtube.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'youtube_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="youtube_url"
                        name="youtube_url"
                        label="Link Youtube"
                        value={props.formParams.youtube}
                        autoComplete="youtube_url"
                        disabled={props.isDisabled}
                        error={props.errors.youtube_url !== ""}
                        helperText={props.errors.youtube_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Tiktok" src={'/media/social/tiktok.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'tiktok_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="tiktok_url"
                        name="tiktok_url"
                        label="Link Tiktok"
                        value={props.formParams.tiktok_url}
                        autoComplete="tiktok_url"
                        disabled={props.isDisabled}
                        error={props.errors.tiktok_url !== ""}
                        helperText={props.errors.tiktok_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Spotify" src={'/media/social/spotify.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'spotify_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="spotify_url"
                        name="spotify_url"
                        label="Link Spotify"
                        value={props.formParams.spotify_url}
                        autoComplete="spotify_url"
                        disabled={props.isDisabled}
                        error={props.errors.spotify_url !== ""}
                        helperText={props.errors.spotify_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Itunes" src={'/media/social/itunes.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'itune_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="itune_url"
                        name="itune_url"
                        label="Link Itunes"
                        value={props.formParams.itune_url}
                        autoComplete="itune_url"
                        disabled={props.isDisabled}
                        error={props.errors.itune_url !== ""}
                        helperText={props.errors.itune_url}
                        sx={focusColor}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box component="img" alt="Soundcloud" src={'/media/social/soundcloud.svg'} sx={{ height: 35, width: 35, mt: 3 }} />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        onChange={e => props.checkField(props.classForm,'soundcloud_url', e, /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/, t("INCORRECT"), true, props.formParams, props.setErrors)}
                        margin="normal"
                        fullWidth
                        id="soundcloud_url"
                        name="soundcloud_url"
                        label="Link Soundcloud"
                        value={props.formParams.soundcloud_url}
                        autoComplete="soundcloud_url"
                        disabled={props.isDisabled}
                        error={props.errors.soundcloud_url !== ""}
                        helperText={props.errors.soundcloud_url}
                        sx={focusColor}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SocialMedia