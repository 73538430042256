import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, List, CssBaseline, IconButton, AppBar, Toolbar, Hidden, Drawer } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { Menu as MenuIcon } from "@mui/icons-material";
import MobileNavBar from "./navbars/MobileNavBar";
import WebSideBar from "./navbars/WebSideBar";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerSideBar = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerMobile = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
    height: "fit-content",
    marginTop: "50px",
  },
}));

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <div>
        <Hidden smUp implementation="css">
          <AppBar
            position="absolute"
            sx={{ backgroundColor: "var(--secondary-color) !important", zIndex: 10000 }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                edge="start"
                aria-haspopup="true"
                aria-controls="menu-appbar"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        <nav>
          <Hidden mdUp implementation="css">
            <DrawerMobile
              variant="temporary"
              anchor="top"
              open={isMobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Mejora el rendimiento en dispositivos móviles
              }}
            >
              <MobileNavBar isMobileOpen={isMobileOpen} setIsMobileOpen={setIsMobileOpen} />
            </DrawerMobile>
          </Hidden>
          <Hidden smDown implementation="css">
            <DrawerSideBar variant="permanent" open={open}>
              <WebSideBar open={open} setOpen={setOpen} />
            </DrawerSideBar>
          </Hidden>
        </nav>
      </div>
    </Box>
  );
};

export default Sidebar;
