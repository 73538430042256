import React, { useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from 'react-query';
import { getAllPremisesValidation, putValidatePremise } from '../../services/premisesServices';
import { focusColor } from '../../components/shared/textFieldStyle';
import { toastMessageError, toastMessageSuccess } from '../../components/shared/toastMessage';


const ValidationPremises = () => {
    const { t } = useTranslation();
    const breadcrumbs = [{
        name: t("PREMISES"),
        link: "/premises"
    },
    {
        name: t("PREMISES_VALIDATION"),
        link: "/premises/validation"
    }];
    const [search, setSearch] = useState("");
    const [premises, setPremises] = useState([]);
    const [filteredPremises, setFilteredPremises] = useState([]);
    const [premiseSelectedId, setPremiseSelectedId] = useState("");
    const [organizationFilter, setOrganizationFilter] = useState("all");
    const [openCheck, setOpenCheck] = useState(false);
    // const [openDelete, setOpenDelete] = useState(false);


    const handleQuerySuccess = (data) => {
        setPremises(data)
    }
    const { isLoading, refetch: refetchPremises } = useQuery('premises', () => getAllPremisesValidation(), {
        onSuccess: handleQuerySuccess
    })

    const handleSearchChange = (event) => {
        const search = event.target.value;
        setSearch(search);
        const filteredPremises = premises.filter((premise) =>
            premise.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredPremises(filteredPremises);
    };
    // Obtener ciudades
    const organizations = [...new Set(premises.map(premise => premise.organization))];
    const menuItems = organizations.map(organization => (
        <MenuItem key={organization} value={organization}>{organization}</MenuItem>
    ));

    const handleOrganizationFilterChange = (event) => {
        const selectedOrganization= event.target.value;
        setOrganizationFilter(selectedOrganization);
        const filteredOrgs = selectedOrganization === "all"
            ? premises
            : premises.filter((premise) => premise.organization === selectedOrganization);

        setFilteredPremises(filteredOrgs);
    };

    const handleClickOpenCheck = (id) => {
        setPremiseSelectedId(id)
        setOpenCheck(true);
    };

    const handleCloseCheck = (e) => {
        setOpenCheck(false);
    };

    const handleRegisterButtonClick = async (e) => {
        try {
            const response = await putValidatePremise(premiseSelectedId);
            setOpenCheck(false)
            if (response.result === "success" && response !== undefined) {
                toastMessageSuccess(t('VALIDATION_PREMISE_SUCCESS'))
                setTimeout(() => {
                    refetchPremises();
                }, 1500);
            }
        }
        catch (error) {
            toastMessageError(t('VALIDATION_PREMISE_ERROR') + error.response.data.error)
        }
    }

    // const handleClickOpenDelete = (e) => {
    //     setOpenDelete(true);
    // };

    // const handleCloseDelete = (e) => {
    //     setOpenDelete(false);
    // };

    // const handleDeleteButtonClick = async (e) => {
    //     //await deleteAssistant(id);
    //     setOpenDelete(false)
    //     //props.refetch()
    // }
    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress sx={{ color: "var(--secondary-color)" }} />
            </Box>
        );
    }
    return (
        <>
            <Header breadcrumbs={breadcrumbs} />
            <Grid container>
            <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
                <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
                <TextField label={t("SEARCH")} value={search} onChange={handleSearchChange} size="small" sx={[focusColor, { width: "75%" }]} />
            </Grid>
            <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
                    <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                        <Select value={organizationFilter} onChange={handleOrganizationFilterChange} sx={{ fontSize: "12px", borderRadius: 3 }}>
                            <MenuItem value="all">{t("ALL_ENTITIES")}</MenuItem>
                            {menuItems}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
                <Typography>{t("TOTAL_PENDING_PREMISES")} {premises.length} </Typography>
            </Grid>
            <Grid item xs={11} md={11} mb={25}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderBottom: '2px solid var(--secondary-color)' }}>
                                <TableCell>{t("NAME")}</TableCell>
                                <TableCell>{t("ENTITY")}</TableCell>
                                <TableCell>{t("CITY")}</TableCell>
                                <TableCell>{t("INTERNATIONAL")}</TableCell>
                                <TableCell>{t("ACTIVITIES")}</TableCell>
                                {/* <TableCell>{t("DOCUMENTS")}</TableCell> */}
                                <TableCell>{t("ACTION")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {search === "" && organizationFilter==="all" ? 
                                premises.map((premise, index) => (
                                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                                        <TableCell>{premise.name}</TableCell>
                                        <TableCell>{premise.organization}</TableCell>
                                        <TableCell>{premise.city}</TableCell>
                                        {/* <TableCell>
                                            <IconButton aria-label="downloadDocuments" className='oniria-btn' >
                                                <CloudDownloadIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell>{premise.is_international === 0 ? "No" : "Si"}</TableCell>
                                        <TableCell><div>
                                            {premise.events === 1 && premise.trips === 1
                                                ? t("EVENTS")
                                                : premise.events === 1 && premise.trips === 0
                                                    ? t("EVENTS")
                                                    : premise.events === 0 && premise.trips === 1
                                                        ? t("TRAVELS")
                                                        : ""}
                                        </div>
                                            <div>
                                                {premise.events === 1 && premise.trips === 1 ? t("TRAVELS") : ""}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="check" className='oniria-btn-check' sx={{ mr: 2, mb: 0.5 }} onClick={() => handleClickOpenCheck(premise.id)}>
                                                <CheckIcon />
                                            </IconButton>
                                            {/* <IconButton aria-label="delete" className='oniria-btn-delete' sx={{ mb: 0.5 }} onClick={handleClickOpenDelete}>
                                                <ClearIcon />
                                            </IconButton>
                                            <Dialog
                                                open={openDelete}
                                                onClose={handleCloseDelete}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
                                                    {t("DENY_PREMISE")}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText sx={{ color: "black" }} >
                                                        {t("CONFIRM_DENY_PREMISE")}
                                                    </DialogContentText>
                                                    <DialogContentText sx={{ color: "black", mb: 2 }} >
                                                        {t("EXPLANATION_DENY_PREMISE")}
                                                    </DialogContentText>
                                                    <TextField
                                                        label="Razón"
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 256
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                    />
                                                </DialogContent>
                                                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <Button type="submit"
                                                        className='oniria-btn-cancel'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleCloseDelete}>
                                                        {t("CANCEL")}
                                                    </Button>
                                                    <Button type="submit"
                                                        className='oniria-btn'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleDeleteButtonClick}>
                                                        {t("ACCEPT")}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> */}
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                filteredPremises.map((premise, index) => (
                                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                                        <TableCell>{premise.name}</TableCell>
                                        <TableCell>{premise.organization}</TableCell>
                                        <TableCell>{premise.city}</TableCell>
                                        {/* <TableCell>
                                            <IconButton aria-label="downloadDocuments" className='oniria-btn' >
                                                <CloudDownloadIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell>{premise.is_international === 0 ? "No" : "Si"}</TableCell>
                                        <TableCell><div>
                                            {premise.events === 1 && premise.trips === 1
                                                ? t("EVENTS")
                                                : premise.events === 1 && premise.trips === 0
                                                    ? t("EVENTS")
                                                    : premise.events === 0 && premise.trips === 1
                                                        ? t("TRAVELS")
                                                        : ""}
                                        </div>
                                            <div>
                                                {premise.events === 1 && premise.trips === 1 ? t("TRAVELS") : ""}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="check" className='oniria-btn-check' sx={{ mr: 2 }} onClick={() => handleClickOpenCheck(premise.id)}>
                                                <CheckIcon />
                                            </IconButton>
                                            {/* <IconButton aria-label="delete" className='oniria-btn-delete' onClick={handleClickOpenDelete}>
                                                <ClearIcon />
                                            </IconButton>
                                            <Dialog
                                                open={openDelete}
                                                onClose={handleCloseDelete}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
                                                    {t("DENY_PREMISE")}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText sx={{ color: "black" }} >
                                                        {t("CONFIRM_DENY_PREMISE")}
                                                    </DialogContentText>
                                                    <DialogContentText sx={{ color: "black", mb: 2 }} >
                                                        {t("EXPLANATION_DENY_PREMISE")}
                                                    </DialogContentText>
                                                    <TextField
                                                        label="Razón"
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 256
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                    />
                                                </DialogContent>
                                                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <Button type="submit"
                                                        className='oniria-btn-cancel'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleCloseDelete}>
                                                        {t("CANCEL")}
                                                    </Button>
                                                    <Button type="submit"
                                                        className='oniria-btn'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleDeleteButtonClick}>
                                                        {t("ACCEPT")}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> */}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Dialog
                open={openCheck}
                onClose={handleCloseCheck}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className='oniria-colorText' sx={{ fontWeight: "bolder" }}>
                    {t("VERIFY_PREMISE")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: "black" }} >
                        {t("CONFIRM_VERIFY_PREMISE")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Button type="submit"
                        className='oniria-btn-cancel'
                        variant="contained"
                        size='medium'
                        sx={{ fontSize: "12px", borderRadius: 3 }}
                        onClick={handleCloseCheck}>
                        {t("CANCEL")}
                    </Button>
                    <Button type="submit"
                        className='oniria-btn'
                        variant="contained"
                        size='medium'
                        sx={{ fontSize: "12px", borderRadius: 3 }}
                        onClick={handleRegisterButtonClick}>
                        {t("ACCEPT")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ValidationPremises