import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header'
import { useTranslation } from 'react-i18next'
import Organization from '../../components/account/Organization'
import { createAccountErrorsAllOk } from '../../classes/createAccountClass'
import { useQuery } from 'react-query'
import { isValidIBANNumber, transformObjectWithUrls } from '../../components/shared/FormsValidator'
import { getOrganizationById, putOrganization } from '../../services/organizationsServices'
import { getBicFromIban } from '../../services/utilsServices'
import { toastMessageError, toastMessageSuccess } from '../../components/shared/toastMessage'

const OrganizationDetail = () => {
  const { t } = useTranslation()
  const { organizationId } = useParams()
  const [formData, setFormData] = useState({})
  const [formParamsErrors, setFormDataErrors] = useState(createAccountErrorsAllOk)
  const [isDisabled, setIsDisabled] = useState(true)
  const [organizationName, setOrganizationName] = useState('')
  const [searchParam] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const isEdit = searchParam.get('edit')
    if (isEdit) {
      setIsDisabled(false)
    }
  }, [])

  const handleQuerySuccess = data => {
    setFormData({ organization: data[0] })
    setOrganizationName(data[0].legal_name)
  }

  const { isLoading } = useQuery('organization', () => getOrganizationById(organizationId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  })

  const breadcrumbs = [
    {
      name: t('ENTITIES'),
      link: '/organizations',
    },
    {
      name: organizationName ? organizationName : '',
      link: '/create_organization',
    },
  ]

  const setFormParams = (objectName, field, e, atribute = 'value') => {
    let object = formData[objectName]
    object[field] = e.target[atribute]
    setFormData(prev => ({
      ...prev,
      [objectName]: object,
    }))
  }
  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName]
    object[field] = value
    setFormData(prev => ({
      ...prev,
      [objectName]: object,
    }))
  }
  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName]
    object[field] = value
    setFormDataErrors(prev => ({
      ...prev,
      [objectName]: object,
    }))
  }

  const isFirstStep = () => {
    return true
  }

  const isLastStep = () => {
    return true
  }

  const submitOrganization = async () => {
    const bodyToSend = await transformObjectWithUrls(formData.organization)
    bodyToSend.is_active = bodyToSend.is_active === 1
    delete bodyToSend.created_at
    delete bodyToSend.last_modified_at
    try {
      const response = await putOrganization(organizationId, bodyToSend)
      if (response.result === 'success' && response !== undefined) {
        toastMessageSuccess(t('EDIT_SUCCESS'))
        setTimeout(() => {
          navigate('/organizations')
        }, 1500)
      }
    } catch (error) {
      toastMessageError(t('EDIT_ERROR') + error.response.data.error)
    }
  }

  const checkIban = (classForm, e) => {
    // console.log("New IBAN: ", e);
    // console.log("New IBAN: ", e.target.value);
    // console.log("IBAN OK: ", isValidIBANNumber(e.target.value));
    let error = ''
    if (e.target.value === '') {
      error = 'empty'
    } else {
      error = isValidIBANNumber(e.target.value) ? '' : t('INCORRECT')
      // BIC
      if (error === '') {
        getBicFromIban(e.target.value).then(result => {
          // If the result returns a BIC...
          if (result.bankData.bic) {
            setFormParamsNotEvent('organization', 'bic', result.bankData.bic)
          }
          // And if not IBAN as BIC
          else {
            setFormParamsNotEvent('organization', 'bic', result.iban)
          }
        })
      }
    }
    setFormErrors(classForm, 'iban', error)
    setFormParams(classForm, 'iban', e)
  }

  const formController = {
    formData,
    formParams: setFormParams,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: submitOrganization,
    checkIban,
  }

  const formActivate = () => {
    setIsDisabled(false)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: 'var(--secondary-color)' }} />
      </Box>
    )
  }
  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={t('HEADER_TEXT_ORGANIZATION_DETAIL') + `${organizationName}`}
      />
      <Box sx={{ position: 'relative' }}>
        {isDisabled && (
          <Grid item xs={11} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              onClick={() => formActivate()}
              className="oniria-btn"
              variant="contained"
              sx={{
                fontSize: '12px',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {t('EDIT')}
            </Button>
          </Grid>
        )}
        {formData.organization && (
          <Organization
            title={t('ORGANIZATION_INFO')}
            formController={formController}
            isDisabled={isDisabled}
          />
        )}
      </Box>
    </>
  )
}

export default OrganizationDetail
