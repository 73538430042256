import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "react-query";
import {
  getAllOrganizationsValidation,
  putValidateOrganization,
} from "../../services/organizationsServices";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";

const ValidationOrganizations = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("ENTITIES"),
      link: "/organizations",
    },
    {
      name: t("ORGANIZATION_VALIDATION"),
      link: "/organizations/validation",
    },
  ];
  const [search, setSearch] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [userSelectedId, setUserSelectedId] = useState("");
  const [cityFilter, setCityFilter] = useState("all");
  const [openCheck, setOpenCheck] = useState(false);

  const handleQuerySuccess = (data) => {
    setOrganizations(data);
  };
  const { isLoading, refetch: refetchOrganizations } = useQuery(
    "organizations",
    () => getAllOrganizationsValidation(),
    {
      onSuccess: handleQuerySuccess,
    }
  );

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    const filteredOrganizations = organizations.filter((organization) =>
      organization.entity_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOrganizations(filteredOrganizations);
  };
  // Obtener ciudades
  const cities = [...new Set(organizations.map((organization) => organization.city))];
  const menuItems = cities.map((city) => (
    <MenuItem key={city} value={city}>
      {city}
    </MenuItem>
  ));

  const handleCityFilterChange = (event) => {
    const selectedCity = event.target.value;
    setCityFilter(selectedCity);
    const filteredOrgs =
      selectedCity === "all"
        ? organizations
        : organizations.filter((organization) => organization.city === selectedCity);

    setFilteredOrganizations(filteredOrgs);
  };

  const handleClickOpenCheck = (id) => {
    setUserSelectedId(id);
    setOpenCheck(true);
  };

  const handleCloseCheck = (e) => {
    setOpenCheck(false);
  };

  const handleRegisterButtonClick = async (e) => {
    try {
      const response = await putValidateOrganization(userSelectedId);
      setOpenCheck(false);
      if (response.result === "success" && response !== undefined) {
        toastMessageSuccess(t("VALIDATION_ORGANIZATION_SUCCESS"));
        setTimeout(() => {
          refetchOrganizations();
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("VALIDATION_ORGANIZATION_ERROR") + error.response.data.error);
    }
  };
  // const handleClickOpenDelete = (e) => {
  //     setOpenDelete(true);
  // };

  // const handleCloseDelete = (e) => {
  //     setOpenDelete(false);
  // };

  // const handleDeleteButtonClick = async (e) => {
  //     //await deleteAssistant(id);
  //     setOpenDelete(false)
  //     //props.refetch()
  // }
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Grid container>
        <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            value={search}
            onChange={handleSearchChange}
            size="small"
            sx={[focusColor, { width: "75%" }]}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
          <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
            <Select
              value={cityFilter}
              onChange={handleCityFilterChange}
              sx={{ fontSize: "12px", borderRadius: 3 }}
            >
              <MenuItem value="all">{t("ALLS_CITIES")}</MenuItem>
              {menuItems}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={11} md={6} sx={{ mb: 2 }}>
        <Typography>
          {t("TOTAL_PENDING_ENTITIES")} {organizations.length}
        </Typography>
      </Grid>
      <Grid item xs={11} md={11} mb={25}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                <TableCell>{t("NAME")}</TableCell>
                <TableCell>{t("LEGAL_PERSON")}</TableCell>
                <TableCell>{t("CITY")}</TableCell>
                {/* <TableCell>{t("DOCUMENTS")}</TableCell> */}
                <TableCell>{t("ACTION")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search === "" && cityFilter === "all"
                ? organizations.map((organization, index) => (
                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                      <TableCell>{organization.entity_name}</TableCell>
                      <TableCell>{organization.legal_representative}</TableCell>
                      <TableCell>{organization.city}</TableCell>
                      {/* <TableCell>
                                            <IconButton aria-label="downloadDocuments" className='oniria-btn' >
                                                <CloudDownloadIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </TableCell> */}
                      <TableCell>
                        <IconButton
                          aria-label="check"
                          className="oniria-btn-check"
                          sx={{ mr: 2, mb: 0.5 }}
                          onClick={() => handleClickOpenCheck(organization.user_id)}
                        >
                          <CheckIcon />
                        </IconButton>
                        <Dialog
                          open={openCheck}
                          onClose={handleCloseCheck}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
                            {t("VERIFY_ENTITY")}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText sx={{ color: "black" }}>
                              {t("CONFIRM_VERIFY_ENTITY")}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              type="submit"
                              className="oniria-btn-cancel"
                              variant="contained"
                              size="medium"
                              sx={{ fontSize: "12px", borderRadius: 3 }}
                              onClick={handleCloseCheck}
                            >
                              {t("CANCEL")}
                            </Button>
                            <Button
                              type="submit"
                              className="oniria-btn"
                              variant="contained"
                              size="medium"
                              sx={{ fontSize: "12px", borderRadius: 3 }}
                              onClick={handleRegisterButtonClick}
                            >
                              {t("ACCEPT")}
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* <IconButton aria-label="delete" className='oniria-btn-delete' sx={{ mb: 0.5 }} onClick={handleClickOpenDelete}>
                                                <ClearIcon />
                                            </IconButton>
                                            <Dialog
                                                open={openDelete}
                                                onClose={handleCloseDelete}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
                                                    {t("DENY_ENTITY")}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText sx={{ color: "black" }} >
                                                        {t("CONFIRM_DENY_ENTITY")}
                                                    </DialogContentText>
                                                    <DialogContentText sx={{ color: "black", mb: 2 }} >
                                                        {t("EXPLANATION_DENY_ENTITY")}
                                                    </DialogContentText>
                                                    <TextField
                                                        label="Razón"
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 256
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                    />
                                                </DialogContent>
                                                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <Button type="submit"
                                                        className='oniria-btn-cancel'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleCloseDelete}>
                                                        {t("CANCEL")}
                                                    </Button>
                                                    <Button type="submit"
                                                        className='oniria-btn'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleDeleteButtonClick}>
                                                        {t("ACCEPT")}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> */}
                      </TableCell>
                    </TableRow>
                  ))
                : filteredOrganizations.map((organization, index) => (
                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                      <TableCell>{organization.entity_name}</TableCell>
                      <TableCell>{organization.legal_representative}</TableCell>
                      <TableCell>{organization.city}</TableCell>
                      {/* <TableCell>
                                            <IconButton aria-label="downloadDocuments" className='oniria-btn' >
                                                <CloudDownloadIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </TableCell> */}
                      <TableCell>
                        <IconButton
                          aria-label="check"
                          className="oniria-btn-check"
                          sx={{ mr: 2 }}
                          onClick={handleClickOpenCheck}
                        >
                          <CheckIcon />
                        </IconButton>
                        <Dialog
                          open={openCheck}
                          onClose={handleCloseCheck}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
                            {t("VERIFY_ENTITY")}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText sx={{ color: "black" }}>
                              {t("CONFIRM_VERIFY_ENTITY")}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              type="submit"
                              className="oniria-btn-cancel"
                              variant="contained"
                              size="medium"
                              sx={{ fontSize: "12px", borderRadius: 3 }}
                              onClick={handleCloseCheck}
                            >
                              {t("CANCEL")}
                            </Button>
                            <Button
                              type="submit"
                              className="oniria-btn"
                              variant="contained"
                              size="medium"
                              sx={{ fontSize: "12px", borderRadius: 3 }}
                              onClick={() => handleRegisterButtonClick(organization.user_id)}
                            >
                              {t("ACCEPT")}
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* <IconButton aria-label="delete" className='oniria-btn-delete' onClick={handleClickOpenDelete}>
                                                <ClearIcon />
                                            </IconButton>
                                            <Dialog
                                                open={openDelete}
                                                onClose={handleCloseDelete}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
                                                    {t("DENY_ENTITY")}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText sx={{ color: "black" }} >
                                                        {t("CONFIRM_DENY_ENTITY")}
                                                    </DialogContentText>
                                                    <DialogContentText sx={{ color: "black", mb: 2 }} >
                                                        {t("EXPLANATION_DENY_ENTITY")}
                                                    </DialogContentText>
                                                    <TextField
                                                        label="Razón"
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 256
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                    />
                                                </DialogContent>
                                                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <Button type="submit"
                                                        className='oniria-btn-cancel'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleCloseDelete}>
                                                        {t("CANCEL")}
                                                    </Button>
                                                    <Button type="submit"
                                                        className='oniria-btn'
                                                        variant="contained"
                                                        size='medium'
                                                        sx={{ fontSize: "12px", borderRadius: 3 }}
                                                        onClick={handleDeleteButtonClick}>
                                                        {t("ACCEPT")}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> */}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default ValidationOrganizations;
