const ProfileIcon = ({ color, size }) => (
  <svg
    viewBox="-0.5 -0.5 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      d="M7.5 0.6628125C3.7239375000000003 0.6628125 0.6628125 3.7239375000000003 0.6628125 7.5c0 3.7760624999999997 3.061125 6.837187500000001 6.837187500000001 6.837187500000001 3.7760624999999997 0 6.837187500000001 -3.061125 6.837187500000001 -6.837187500000001 0 -3.7760624999999997 -3.061125 -6.837187500000001 -6.837187500000001 -6.837187500000001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
    <path
      d="M2.2155 11.8386875S3.7395625 9.892999999999999 7.5 9.892999999999999s5.2845625 1.9456875 5.2845625 1.9456875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
    <path
      d="M7.5 7.5c1.132875 0 2.0511874999999997 -0.9183125000000001 2.0511874999999997 -2.0511874999999997 0 -1.1328125 -0.9183125000000001 -2.051125 -2.0511874999999997 -2.051125S5.4488125 4.316 5.4488125 5.4488125C5.4488125 6.581687499999999 6.367125 7.5 7.5 7.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>
);

export default ProfileIcon;
