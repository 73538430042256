import Edit from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useQueryCardsDomain } from "../../hooks/queries/useQueryCardsDomain";
import { updateCardDomain } from "../../services/domainsServices";
import { Loading } from "../shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const DomainCard = ({ domainId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: cards,
    isLoading,
    isRefetching,
    refetch: refetchCards,
  } = useQueryCardsDomain(domainId);

  const handleNavigatePremises = () => {
    navigate(ROUTES.PREMISES);
  };

  const handleUpdateCard = async (e, cardId) => {
    e.preventDefault();
    const body = {
      domain_id: "",
    };
    if (e.target.checked) {
      body.domain_id = domainId;
    } else {
      body.domain_id = null;
    }

    try {
      await updateCardDomain(cardId, body);
      toastMessageSuccess(t("CARD_UPDATED_SUCCESSFULLY"));
    } catch (error) {
      console.error(error);
      toastMessageError(error.response.data.error ?? t("ERROR"));
    } finally {
      refetchCards();
    }
  };

  const handleEditCard = (e, premiseId) => {
    e.preventDefault();
    navigate(`${ROUTES.PREMISE}/${premiseId}/member_card`);
  };

  return (
    <Grid>
      <Typography variant="h5">{t("DOMAIN_CARD")}</Typography>
      <Typography
        variant="caption"
        sx={{
          textWrap: "pretty",
          width: { xs: "100%", sm: "80%" },
          fontSize: "1rem",
          fontWeight: 400,
        }}
      >
        {t("DOMAIN_CARD_DESCRIPTION")}
      </Typography>
      <br></br>
      <Button onClick={handleNavigatePremises} className="oniria-border-btn">
        {t("GO_PREMISES")}
      </Button>
      {isLoading || isRefetching ? (
        <Loading />
      ) : cards && cards.length > 0 ? (
        <Grid mt={2} gap={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t("CARD_NAME")}</TableCell>
                  <TableCell>{t("PREMISE_NAME")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards.map((card) => {
                  return (
                    <TableRow key={card.id} hover>
                      <TableCell padding="none">
                        <Checkbox
                          value={card.id}
                          className="radio-oniria"
                          checked={card.domain_id === domainId}
                          onChange={(event) => handleUpdateCard(event, card.id)}
                        />
                      </TableCell>
                      <TableCell padding="none">{card.name}</TableCell>
                      <TableCell padding="none"> {card.premise_name}</TableCell>
                      <TableCell padding="none">
                        <Tooltip title={t("EDIT_CARD")}>
                          <IconButton onClick={(e) => handleEditCard(e, card.premise_id)}>
                            <Edit className="oniria-icon-btn" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Typography>{t("NO_CARDS")}</Typography>
      )}
    </Grid>
  );
};

export default DomainCard;
