import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getActiveCardsByDomain } from "../../services/domainsServices";

export function useQueryActiveCardsDomain(domainId) {
  return useQuery([RQ_KEY.ACTIVE_CARDS, domainId], () => getActiveCardsByDomain(domainId), {
    refetchOnWindowFocus: false,
    enabled: !!domainId,
  });
}
