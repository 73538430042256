import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, Grid, ListItem, ListItemText, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { RQ_KEY } from "../../constants/query";
import { formatDate } from "../../constants/utils";
import { getAssistantDetailNoEvent } from "../../services/eventsServices";
import { Loading } from "../shared/Loading";

const AssistantDetail = ({ ticketId, onBack }) => {
  const { t } = useTranslation();
  const [assistantDetail, setAssistantDetail] = useState([]);

  const handleQuerySuccess = (data) => {
    setAssistantDetail(data);
  };
  const { isLoading } = useQuery(
    [RQ_KEY.ASSISTANT_DETAIL, ticketId],
    () => getAssistantDetailNoEvent(ticketId),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
      enabled: !!ticketId,
    }
  );

  const showClientInfo = () => {
    if (assistantDetail.length === 0) return false;
    return assistantDetail[0].client_name !== null;
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "row", mb: 3, mt: 2 }}>
        <Link
          className="oniria-colorText"
          underline="none"
          onClick={onBack}
          style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
        >
          <ChevronLeftIcon style={{ marginRight: "4px" }} />
          <span style={{ verticalAlign: "middle" }}>{t("LIST_ATTENDEES")}</span>
        </Link>
      </Grid>
      {assistantDetail.length === 0 ? (
        <Typography variant="h6">{t("NO_ASSISTANT_FOUND")}</Typography>
      ) : (
        assistantDetail?.map((assistant, index) => (
          <Grid container spacing={2} key={`${assistant.id}-${index}`}>
            {showClientInfo() && (
              <>
                <Grid item xs={12} mt={2}>
                  <Grid container>
                    <Typography variant="h6">{t("ATTENDEE_DATA")}:</Typography>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={2}>
                          <ListItem>
                            <ListItemText
                              primary={t("NAME_SURNAME")}
                              secondary={
                                (assistant.client_name ?? "") +
                                " " +
                                (assistant.client_surname ?? "")
                              }
                            />
                          </ListItem>
                        </Grid>
                        {assistant.client_birth_date && (
                          <Grid item xs={6} md={4} lg={2}>
                            <ListItem>
                              <ListItemText
                                primary={t("DATE_BIRTH")}
                                secondary={formatDate(assistant.client_birth_date)}
                              />
                            </ListItem>
                          </Grid>
                        )}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <ListItem>
                            <ListItemText
                              primary={t("NATIONALITY")}
                              secondary={assistant.client_nationality}
                            />
                          </ListItem>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                          <ListItem>
                            <ListItemText
                              primary={t("GENRE")}
                              secondary={
                                assistant.client_gender === "woman"
                                  ? t("WOMAN")
                                  : assistant.client_gender === "man"
                                  ? t("MAN")
                                  : assistant.client_gender === "other"
                                  ? t("OTHER")
                                  : ""
                              }
                            />
                          </ListItem>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                          <ListItem>
                            <ListItemText
                              primary={t("CUSTOMER_DNI_TYPE")}
                              secondary={assistant.client_document_type}
                            />
                          </ListItem>
                        </Grid>
                        {assistant.client_document_expiry_date && (
                          <Grid item xs={6} md={4} lg={2}>
                            <ListItem>
                              <ListItemText
                                primary={t("EXPIRY_DATE")}
                                secondary={formatDate(assistant.client_document_expiry_date)}
                              />
                            </ListItem>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ mb: 2 }} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container>
                <Typography variant="h6">{t("BUYER_DETAILS")}:</Typography>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4} lg={4}>
                      <ListItem>
                        <ListItemText
                          primary={t("NAME_SURNAME")}
                          secondary={assistant.buyer_name + " " + assistant.buyer_surname}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={4} lg={4}>
                      <ListItem>
                        <ListItemText primary={t("EMAIL")} secondary={assistant.buyer_email} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={4} lg={4}>
                      <ListItem>
                        <ListItemText
                          primary={t("PHONE_NUMBER")}
                          secondary={assistant.buyer_phone_code + " " + assistant.buyer_phone}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} mb={25}>
              <Grid container>
                <Typography variant="h6">{t("TICKET_DATA")}:</Typography>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4} lg={3}>
                      <ListItem>
                        <ListItemText
                          primary={t("TICKET_NAME")}
                          secondary={assistant.ticket_name}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                      <ListItem>
                        <ListItemText
                          primary={t("TICKET_PRICE")}
                          secondary={assistant.ticket_price + "€"}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                      <ListItem>
                        <ListItemText
                          primary={t("EXTRA")}
                          secondary={assistant.extras.map((extra, index) => (
                            <Fragment key={`${extra.extra_id}-${index}`}>
                              {extra.extra_name}
                              <br />
                            </Fragment>
                          ))}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                      <ListItem>
                        <ListItemText
                          primary={t("EXTRAS_PRICE")}
                          secondary={assistant.extras.map((extra, index) => (
                            <Fragment key={`price-${extra.extra_id}-${index}`}>
                              {extra.extra_price + "€"}
                              <br />
                            </Fragment>
                          ))}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
    </>
  );
};

export default AssistantDetail;
