import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { isOrderFailed, isOrderTimeout, isOrderTotalPayed } from "../../constants/orders";
import { EURO, formatDate, formatHours, getPremiseName } from "../../constants/utils";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { getOrderDetail } from "../../services/eventsServices";
import { Loading } from "../shared/Loading";
import { renderExtrasOrderDetail, renderExtrasPrice } from "./orderDetails/IndividualCells";

const OrderDetail = (props) => {
  const { t } = useTranslation();
  const orderId = props.order;

  const [orderDetail, setOrderDetail] = useState({});
  const { data: premises } = useQueryPremisesList();

  const transactionId = orderDetail.transaction_id || props.transactionId;
  const firstPaymentTransactionId =
    orderDetail.first_payment_transaction_id || props.firstPaymentTransactionId;

  const paymentMethod = orderDetail.payment_type ? t(orderDetail.payment_type.toUpperCase()) : "";
  const firstPaymentMethod = orderDetail.first_payment_type
    ? t(orderDetail.first_payment_type)
    : "";

  const handleQuerySuccess = (data) => {
    setOrderDetail(data);
  };
  const { isLoading, error } = useQuery("order", () => getOrderDetail(orderId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  //Precio de los tickets
  function sumarTicketPrices() {
    let sumaExtras = 0;
    let amount = 0;
    for (const ticket of orderDetail.tickets) {
      for (const extra of ticket.extras) {
        sumaExtras += parseFloat(extra.extra_price) || 0;
      }
      amount += parseFloat(ticket.final_payed_price) || 0;
    }
    let total = amount + sumaExtras;
    return total.toFixed(2);
  }
  //Precio de todas las comisiones
  const totalCommission = () => {
    let commission = 0;
    const addCommission = (value) => {
      const num = parseFloat(value);
      return isNaN(num) ? 0 : num;
    };
    if (orderDetail.payed_at) {
      commission += addCommission(orderDetail.commission);
    }
    if (orderDetail.first_payment_payed_at) {
      commission += addCommission(orderDetail.first_payment_commission);
    }
    return commission;
  };
  //Comisiones sede
  const totalPremiseCommission = () => {
    let premiseCommission = 0;
    const addCommission = (value) => {
      const num = parseFloat(value);
      return isNaN(num) ? 0 : num;
    };
    if (orderDetail.payed_at) {
      premiseCommission += addCommission(orderDetail.premise_commission);
    }
    if (orderDetail.first_payment_payed_at) {
      premiseCommission += addCommission(orderDetail.first_payment_premise_commission);
    }
    return premiseCommission;
  };
  //Precio total
  const totalAmount = () => {
    let sumaExtras = 0;
    let amount = 0;
    if (orderDetail.payed_at && orderDetail.tickets) {
      for (const ticket of orderDetail.tickets) {
        for (const extra of ticket.extras) {
          sumaExtras += parseFloat(extra.extra_price) || 0;
        }
        amount += parseFloat(ticket.final_payed_price) || 0;
      }
    } else if (orderDetail.first_payment_amount) {
      amount += parseFloat(orderDetail.first_payment_amount) || 0;
    } else {
      amount += parseFloat(orderDetail.amount) || 0;
    }

    let total = amount + sumaExtras + totalCommission() + totalPremiseCommission();
    return EURO.format(total);
  };

  const toralFirstPayment = () => {
    let amount = 0;
    if (orderDetail.first_payment_amount) {
      amount += parseFloat(orderDetail.first_payment_amount) || 0;
      amount += parseFloat(orderDetail.first_payment_commission) || 0;
      amount += parseFloat(orderDetail.first_payment_premise_commission) || 0;
    }
    return amount.toFixed(2);
  };
  const totalSecondPayment = () => {
    let amount = 0;
    if (orderDetail.amount) {
      amount += parseFloat(orderDetail.amount) || 0;
      amount += parseFloat(orderDetail.commission) || 0;
      amount += parseFloat(orderDetail.premise_commission) || 0;
    }
    return amount.toFixed(2);
  };
  const finalExtrasPrice = (extras) => {
    if (extras === null || extras.length === 0) {
      return 0;
    }
    return extras.reduce((acc, extra) => {
      return acc + parseFloat(extra.extra_final_payed_price || 0);
    }, 0);
  };

  // total
  const totalPrice = () => {
    let amount = 0;
    if (orderDetail.amount) {
      amount += parseFloat(orderDetail.amount) || 0;
      amount += parseFloat(orderDetail.commission) || 0;
      amount += parseFloat(orderDetail.premise_commission) || 0;
    }
    if (orderDetail.first_payment_amount) {
      amount += parseFloat(orderDetail.first_payment_amount) || 0;
      amount += parseFloat(orderDetail.first_payment_commission) || 0;
      amount += parseFloat(orderDetail.first_payment_premise_commission) || 0;
    }
    return amount.toFixed(2);
  };

  const hasPendingPayment = () => {
    return orderDetail.first_payment_payed_at && !orderDetail.payed_at;
  };

  const statusColor = () => {
    if (isOrderFailed(orderDetail)) {
      return "error";
    } else if (orderDetail.payed_at) {
      return "success";
    } else {
      return "warning";
    }
  };

  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <Typography>{t("ERROR_LOADING_DATA")}</Typography>;
  }
  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "row", mb: 3, mt: 2 }}>
        <Link
          className="oniria-colorText"
          underline="none"
          onClick={props.onBack}
          style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
        >
          <ChevronLeftIcon style={{ marginRight: "4px" }} />
          <span style={{ verticalAlign: "middle" }}>
            {props.billing ? t("LIST_BILLS") : props.refunds ? t("REFUNDS_LIST") : t("LIST_ORDERS")}
          </span>
        </Link>
      </Grid>
      <Grid container rowGap={2}>
        {/* REFUND */}
        {orderDetail?.refund_status ? (
          <Typography color="error" fontWeight="bold">
            {t("REFUND_STATUS")} {t(orderDetail.refund_status.toUpperCase())}
          </Typography>
        ) : (
          <Grid item xs={12} mt={1}>
            <Chip
              label={`${t("STATUS")}: ${
                isOrderTotalPayed(orderDetail)
                  ? t("ORDER_PAYED_SUCCESSFULLY")
                  : isOrderTimeout(orderDetail)
                  ? t("ORDER_TIMEOUT")
                  : isOrderFailed(orderDetail)
                  ? t("ORDER_FAILED")
                  : t("ORDER_SECOND_PAYMENT")
              }`}
              color={statusColor()}
            />
          </Grid>
        )}
        {/* EVENT DATA */}
        <Grid
          item
          xs={12}
          mt={1}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
              textTransform: "capitalize",
            }}
          >
            {t("EVENT")}:
          </Typography>
          <Grid item xs={12}>
            {orderDetail?.order_type === "card" ? (
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText primary={t("MEMBER_CARD")} />
                </ListItem>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText primary={t("EVENT_NAME")} secondary={orderDetail.event_name} />
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText primary={t("EVENT_TYPE")} secondary={orderDetail.event_type} />
                  </ListItem>
                </Grid>
                {orderDetail.start_date && (
                  <Grid item xs={6} md={4} lg={2}>
                    <ListItem>
                      <ListItemText
                        primary={t("START_DATE")}
                        secondary={formatDate(orderDetail.start_date)}
                      />
                    </ListItem>
                  </Grid>
                )}
                <Grid item xs={6} md={4} lg={2}>
                  <ListItem>
                    <ListItemText
                      primary={t("PREMISE")}
                      secondary={getPremiseName(orderDetail.premise_id, premises)}
                    />
                  </ListItem>
                </Grid>
                {orderDetail.bought_in_domain && (
                  <Grid item xs={6} md={4} lg={2}>
                    <ListItem>
                      <ListItemText
                        primary={t("DOMAIN")}
                        secondary={orderDetail.bought_in_domain}
                      />
                    </ListItem>
                  </Grid>
                )}
                {orderDetail.sku && (
                  <Grid item xs={6} md={4} lg={2}>
                    <ListItem>
                      <ListItemText primary={t("SKU")} secondary={orderDetail.sku} />
                    </ListItem>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* CLIENT INFO */}
        <Grid
          item
          xs={12}
          mt={2}
          mb={1}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
            }}
          >
            {t("BUYER_DATA")}:
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("NAME_SURNAME")}
                    secondary={orderDetail.name + " " + orderDetail.surname}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("EMAIL")}
                    secondary={orderDetail.email}
                    sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("PHONE")}
                    secondary={(orderDetail.phone_code || "") + " " + (orderDetail.phone || "")}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText primary={t("ORDER_NUMBER")} secondary={orderDetail.order_id} />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("Método pago")}
                    secondary={
                      <>
                        {`${paymentMethod} \n ${firstPaymentMethod}`
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {firstPaymentMethod &&
                                paymentMethod &&
                                `${index + 1} ${t("PAYMENT")}: `}
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                      </>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <ListItem>
                  <ListItemText
                    primary={t("Transaction id")}
                    secondary={
                      <>
                        {`${transactionId || ""}\n${firstPaymentTransactionId || ""}`
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {firstPaymentTransactionId &&
                                transactionId &&
                                `${index + 1} ${t("PAYMENT")}: `}
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                      </>
                    }
                    sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* TICKETS INFO  */}
        <Grid
          item
          xs={12}
          mb={25}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
            }}
          >
            {t("TICKETS_PURCHASED")}:
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <TableContainer sx={{ padding: "0px 20px" }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                      <TableCell>{t("NAME_TICKET")}</TableCell>
                      <TableCell>{t("NAME_EXTRA")}</TableCell>
                      <TableCell>{t("DISCOUNT")}</TableCell>
                      <TableCell>{t("PAYMENT_DATE")}</TableCell>
                      <TableCell>{t("PRICE")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(orderDetail.tickets) && orderDetail.tickets?.length >= 0 ? (
                      orderDetail.tickets.map((ticket, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell sx={{ border: "none" }}>{ticket.ticket_name}</TableCell>
                            <TableCell sx={{ border: "none" }}></TableCell>
                            <TableCell sx={{ border: "none" }}></TableCell>
                            <TableCell sx={{ border: "none" }}></TableCell>
                            <TableCell sx={{ border: "none" }}>{ticket.ticket_price}</TableCell>
                          </TableRow>
                          {ticket?.early_payment_discount && (
                            <TableRow>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {t("EARLY_PAYMENT_DISCOUNT")}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {"- " + ticket.early_payment_discount}
                              </TableCell>
                            </TableRow>
                          )}
                          {ticket?.international_discount && (
                            <TableRow>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {t("INTERNATIONAL_DISCOUNT")}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {"- " + ticket.international_discount}
                              </TableCell>
                            </TableRow>
                          )}
                          {ticket?.group_discount && (
                            <TableRow>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>{t("GROUP_DISCOUNT")}</TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {"- " + ticket.group_discount}
                              </TableCell>
                            </TableRow>
                          )}
                          {ticket?.promotional_code_discount && (
                            <TableRow>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {t("PROMOTIONAL_CODE_DISCOUNT")}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {"- " + ticket.promotional_code_discount}
                              </TableCell>
                            </TableRow>
                          )}
                          {ticket.extras.length > 0 && (
                            <TableRow>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                <ListItem sx={{ pl: 0 }}>
                                  <ListItemText
                                    secondary={renderExtrasOrderDetail(ticket.extras)}
                                  />
                                </ListItem>
                              </TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                <ListItem sx={{ pl: 0 }}>
                                  <ListItemText secondary={renderExtrasPrice(ticket.extras)} />
                                </ListItem>
                              </TableCell>
                            </TableRow>
                          )}
                          {/* si hay mas de un ticket calculamos el precio de ese ticket */}
                          {orderDetail.tickets?.length > 1 && (
                            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
                              <TableCell sx={{ border: "none", fontWeight: 600 }}>
                                {t("TOTAL_TICKET_PRICE")}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}></TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {parseInt(ticket.final_payed_price || 0) +
                                  finalExtrasPrice(ticket.extras)}
                                €
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} sx={{ border: "none" }}>
                          {isLoading && (
                            <Box display="flex" justifyContent="center">
                              <CircularProgress sx={{ color: "var(--secondary-color)" }} />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {orderDetail.extras &&
                      orderDetail.extras.map((extra) => (
                        <TableRow>
                          <TableCell sx={{ border: "none" }} />
                          <TableCell
                            sx={{ border: "none", fontWeight: "bolder", textWrap: "nowrap" }}
                          >
                            {extra.extra_name}
                          </TableCell>
                          <TableCell sx={{ border: "none" }} />
                          <TableCell sx={{ border: "none" }}></TableCell>
                          <TableCell sx={{ border: "none", textWrap: "nowrap" }}>
                            {extra.extra_final_payed_price} €
                          </TableCell>
                        </TableRow>
                      ))}
                    {orderDetail.order_type === "card" && (
                      <TableRow>
                        <TableCell sx={{ border: "none" }}>{t("MEMBER_CARD")}</TableCell>
                        <TableCell sx={{ border: "none" }}></TableCell>
                        <TableCell sx={{ border: "none" }}></TableCell>
                        <TableCell sx={{ border: "none" }}></TableCell>
                        <TableCell sx={{ border: "none" }}>{orderDetail.amount}€</TableCell>
                      </TableRow>
                    )}
                    <TableRow sx={{ borderTop: "2px solid lightgrey" }}>
                      <TableCell sx={{ border: "none", fontWeight: "bolder", textWrap: "nowrap" }}>
                        {orderDetail.tickets?.length > 0
                          ? `Total: ${orderDetail.tickets.length} tickets`
                          : ""}
                      </TableCell>
                      <TableCell sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }}></TableCell>
                      <TableCell sx={{ border: "none", textWrap: "nowrap" }}>
                        {orderDetail.tickets?.length > 0 ? sumarTicketPrices() + " €" : ""}
                      </TableCell>
                    </TableRow>
                    {/* Comisiones */}
                    <TableRow>
                      <TableCell sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }}>
                        {t("COMMISION_SERVICE")}(oniria)
                      </TableCell>
                      <TableCell sx={{ border: "none" }}></TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {totalCommission()?.toFixed(2)} €
                      </TableCell>
                    </TableRow>
                    {totalPremiseCommission() > 0 ? (
                      <TableRow>
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }}>
                          {t("PREMISE_COMMISSIONS")}({t("PREMISE")})
                        </TableCell>
                        <TableCell sx={{ border: "none" }}></TableCell>
                        <TableCell sx={{ border: "none" }}>
                          {totalPremiseCommission()?.toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {hasPendingPayment() && (
                      <TableRow>
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }} />
                        <TableCell
                          sx={{
                            border: "none",
                            fontWeight: "bolder",
                            color: "var(--oniria-gold)",
                          }}
                        >
                          {t("TOTAL_TO_PAY")}:{" "}
                          {`${sumarTicketPrices()} + ${totalCommission()} ${
                            totalPremiseCommission() !== 0 ? `+ ${totalPremiseCommission()}` : ""
                          }`}
                        </TableCell>
                        <TableCell sx={{ border: "none" }} />
                        <TableCell
                          sx={{
                            border: "none",
                            fontWeight: "bolder",
                            textWrap: "nowrap",
                            color: "var(--oniria-gold)",
                          }}
                        >
                          {totalPrice()} €
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }} />
                      <TableCell
                        sx={{ border: "none", fontWeight: hasPendingPayment() ? "" : "bolder" }}
                      >
                        {t("TOTAL_PAYED")}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {!orderDetail.first_payment_payed_at &&
                          `${
                            formatDate(orderDetail.payed_at) +
                            " " +
                            formatHours(orderDetail.payed_at)
                          }`}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontWeight: hasPendingPayment() ? "" : "bolder",
                          textWrap: "nowrap",
                        }}
                      >
                        {totalAmount()}
                      </TableCell>
                    </TableRow>
                    {/* FECHAS PAGOS */}
                    {orderDetail.first_payment_payed_at && (
                      <TableRow sx={{ borderTop: "2px solid lightgrey" }}>
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }} />
                        <TableCell
                          sx={{ border: "none", fontWeight: !hasPendingPayment() ? "" : "bolder" }}
                        >
                          {t("FIRST_PAYMENT")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", fontWeight: !hasPendingPayment() ? "" : "bolder" }}
                        >
                          {formatDate(orderDetail.first_payment_payed_at)}
                          {"  "}
                          {formatHours(orderDetail.first_payment_payed_at)}
                          {"  "}
                          {orderDetail.first_payment_percentage}%
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", fontWeight: !hasPendingPayment() ? "" : "bolder" }}
                        >
                          {toralFirstPayment()} €
                        </TableCell>
                      </TableRow>
                    )}
                    {orderDetail.amount && orderDetail.first_payment_payed_at && (
                      <TableRow>
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }}>{t("SECOND_PAYMENT")}</TableCell>
                        <TableCell sx={{ border: "none" }}>
                          {orderDetail.payed_at ? (
                            `${
                              formatDate(orderDetail.payed_at) +
                              " " +
                              formatHours(orderDetail.payed_at) +
                              " " +
                              (100 - orderDetail.first_payment_percentage)
                            }%`
                          ) : (
                            <Typography color={"var(--oniria-red)"}>
                              {t("PENDING_PAYMENT")}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ border: "none" }}>{totalSecondPayment()} €</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* } */}
      </Grid>
    </>
  );
};

export default OrderDetail;
