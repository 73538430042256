import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getOrdersList } from "../../services/orderServices";

export function useQueryOrdersList(filters, page, perPage) {
  return useQuery(
    [RQ_KEY.ORDERS_LIST, filters, page, perPage],
    () => getOrdersList(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  );
}
