import { createSlice } from '@reduxjs/toolkit';

const initialState = { events: [] }

const eventSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    allEvents: (state, action) => {
      return action.payload;
    }
  }
});

export const { allEvents } = eventSlice.actions;
export default eventSlice.reducer;