import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const DomainSelector = ({ domains, selectedDomain, onSelectDomain }) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={focusColor}>
      <InputLabel>{t("DOMAIN")}</InputLabel>
      <Select
        value={selectedDomain || ""}
        onChange={onSelectDomain}
        label={t("DOMAIN")}
        aria-label={t("SELECT_DOMAIN")}
      >
        {domains?.map((domain) => (
          <MenuItem key={domain.domain_id} value={domain.domain_id}>
            {domain.domain_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DomainSelector;
