import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const WeeklyPurchasesGraph = ({ salesByDay }) => {
  const { t } = useTranslation();
  const isXsScreen = useMediaQuery("(max-width:863px)");

  const daysOfWeek = [
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
    t("SUNDAY"),
  ];

  const salesByDayWithNames = salesByDay.map((sale) => ({
    ...sale,
    day: daysOfWeek[sale.day - 1],
  }));

  return (
    <Grid
      item
      xs={12}
      component={Paper}
      elevation={3}
      sx={{
        padding: "10px",
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        height: "100%",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("WEEKLY_PURCHASES")}
      </Typography>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart data={salesByDayWithNames} margin={{ top: 30, bottom: 20, right: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            angle={isXsScreen ? -45 : 0}
            height={isXsScreen ? 140 : 50}
            tickMargin={isXsScreen && 50}
            padding={isXsScreen && { right: 50 }}
          >
            <Label value={t("DAY")} offset={-5} position="insideBottomRight" />
          </XAxis>
          <YAxis domain={[0, "dataMax + 10"]} tickCount={10}>
            <Label position="top" width={200} offset={15} dx={40}>
              {t("QUANTITY")}
            </Label>
          </YAxis>
          <Legend />
          <Bar dataKey="amount" fill="var(--secondary-color)" legendType="none" />
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default WeeklyPurchasesGraph;
