import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const DataCard = (props) => {
  const { value = 0, label, icon_url } = props;

  return (
    <Grid
      item
      component={Paper}
      elevation={1}
      columns={16}
      xs={12}
      sm={5}
      md={2.5}
      sx={{
        p: 2,
        m: 1,
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon_url && (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="card-icon" style={{ background: "#d8fad7" }}>
            <img src={icon_url} alt={label} />
          </div>
          <Typography className="oniria-title" variant="h6">
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xs={10} sm={10} md={10}>
        <Typography className="oniria-title" variant="h5">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DataCard;
